import React, { FunctionComponent, useEffect, useLayoutEffect, useState } from 'react';
import './App.less';
import { BrowserRouter } from 'react-router-dom';
import Routes from 'Routes';
import { useService, useStores } from 'Hooks';
import { Locale } from 'antd/lib/locale-provider';
import { useTranslation } from 'react-i18next';
import { SupportedLanguage } from 'Stores/LanguageStore';
import 'moment/locale/fr-ca';
import fr_CA from 'antd/es/locale/fr_CA';
import { ConfigProvider } from 'antd';
import moment from 'moment';
import { AuthenticationService } from 'Services/AuthenticationService';
import { ToastContainer } from 'Components/toast-container';
import GlobalLoadingContainer from 'Components/global-loading-container/global-loading-container';
import ConfirmationModalContainer from 'Components/confirmation-modal/confirmation-modal-container';
import CookieConsent from 'Components/cookie-consent';
import DeploymentCacheBuster from 'Components/deployment-cache-buster';
import { CacheBuster } from 'CacheBuster';
import { HelmetProvider } from 'react-helmet-async';

const App: FunctionComponent = () => {
    const { languageStore } = useStores();
    const authenticationService = useService(AuthenticationService);
    const [currentLanguage, setCurrentLanguage] = useState<Locale>(fr_CA);
    const { i18n } = useTranslation();

    useLayoutEffect(() => {
        //http intercepter needs to be replaced on language change
        authenticationService.installInterceptors(languageStore.currentLanguage);
    }, [authenticationService, languageStore.currentLanguage]);

    useEffect(() => {
        //Triggered on language change or first render. Set appropriate local
        switch (languageStore.currentLanguage) {
            // case SupportedLanguage.EN:
            //     setCurrentLanguage(en_US);
            //     i18n.changeLanguage('en');
            //     moment.locale('en');
            //     break;
            case SupportedLanguage.FR:
                setCurrentLanguage(fr_CA);
                i18n.changeLanguage('fr');
                moment.locale('fr-ca');
                break;
        }
    }, [languageStore.currentLanguage, i18n]);

    return (
        <CacheBuster
            currentVersion={process.env.REACT_APP_VERSION ?? ''}
            isEnabled={process.env.NODE_ENV === 'production'} //If false, the library is disabled.
            isVerboseMode={false}
        >
            <DeploymentCacheBuster>
                <HelmetProvider>
                    <ConfigProvider locale={currentLanguage}>
                        <BrowserRouter>
                            <Routes />
                            <ToastContainer />
                            <GlobalLoadingContainer />
                            <ConfirmationModalContainer />
                        </BrowserRouter>
                        <CookieConsent />
                    </ConfigProvider>
                </HelmetProvider>
            </DeploymentCacheBuster>
        </CacheBuster>
    );
};

export default App;
