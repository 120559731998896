import React from 'react';
import { inject } from 'aurelia-dependency-injection';
import ToastStore from 'Stores/ToastStore';
import AuthenticationStore from 'Stores/AuthenticationStore';
import AuthorizationStore from 'Stores/AuthorizationStore';
import LoadingStore from 'Stores/LoadingStore';
import LanguageStore from 'Stores/LanguageStore';
import NavigationStore from 'Stores/NavigationStore';
import ConfirmationModalStore from 'Stores/ConfirmationModalStore';
import FilterStore from 'Stores/FilterStore';
import IndustrieIntroStore from 'Stores/IndustrieIntroStore';
import UserStore from 'Stores/UserStore';
import MakesStore from 'Stores/MakesStore';
import DeploymentCacheStore from 'Stores/DeploymentCacheStore';

export interface GlobalStores {
    authenticationStore: AuthenticationStore;
    authorizationStore: AuthorizationStore;
    globalLoadingStore: LoadingStore;
    languageStore: LanguageStore;
    toastStore: ToastStore;
    navigationStore: NavigationStore;
    confirmationModalStore: ConfirmationModalStore;
    filterStore: FilterStore;
    industrieIntroStore: IndustrieIntroStore;
    userStore: UserStore;
    makesStore: MakesStore;
    deploymentCacheStore: DeploymentCacheStore;
}

@inject(
    AuthenticationStore,
    AuthorizationStore,
    LoadingStore,
    ToastStore,
    LanguageStore,
    NavigationStore,
    ConfirmationModalStore,
    FilterStore,
    IndustrieIntroStore,
    UserStore,
    MakesStore,
    DeploymentCacheStore
)
export class StoresContext {
    context: React.Context<GlobalStores>;

    constructor(
        authenticationStore: AuthenticationStore,
        authorizationStore: AuthorizationStore,
        globalLoadingStore: LoadingStore,
        toastStore: ToastStore,
        languageStore: LanguageStore,
        navigationStore: NavigationStore,
        confirmationModalStore: ConfirmationModalStore,
        filterStore: FilterStore,
        industrieIntroStore: IndustrieIntroStore,
        userStore: UserStore,
        makesStore: MakesStore,
        deploymentCacheStore: DeploymentCacheStore
    ) {
        this.context = React.createContext({
            authenticationStore,
            authorizationStore,
            globalLoadingStore,
            toastStore,
            languageStore,
            navigationStore,
            confirmationModalStore,
            filterStore,
            industrieIntroStore,
            userStore,
            makesStore,
            deploymentCacheStore,
        });
    }
}
