import React, { FunctionComponent, useEffect } from 'react';
import { autorun } from 'mobx';
import { useStores } from '../../Hooks';
import { message } from 'antd';
import './toast-container.less';

const ToastContainer: FunctionComponent = () => {
    const { toastStore } = useStores();
    useEffect(
        () =>
            autorun(() => {
                if (toastStore.currentToast) {
                    message[toastStore.currentToast.type]({
                        content: (
                            <div>
                                <div className="text-caption-1-bold">
                                    {toastStore.currentToast.title || ''}
                                </div>
                                {toastStore.currentToast.subTitle && (
                                    <div className="text-caption-1">
                                        {toastStore.currentToast.subTitle}
                                    </div>
                                )}
                            </div>
                        ),
                        className: 'custom-toast',
                        // icon:
                        //     toastStore.currentToast.type === 'success' ? (
                        //         <CheckmarkIcon />
                        //     ) : (
                        //         <CloseIcon />
                        //     ),
                        icon: <div></div>,
                        duration: 5,
                    });
                }
            }),
        [toastStore.currentToast]
    );
    return null;
};

export default ToastContainer;
