import { FunctionComponent } from 'react';
import './index.less';
import Copydeck from 'i18n/Copydeck';
import TabsCustom, { TabItem } from 'Components/tabs-custom';
import AccountForm from '../../account-form';
import { CandidateDetailsDto } from 'Api/Features/Candidates/Dtos/CandidateDetailsDto';
import { useWindowDimensions } from 'Hooks';
import JobApplications from '../../job-applications';
import { ACTIVE_TAB_PARAM } from 'Models/Constants';
import { useTranslation } from 'react-i18next';
import AccountQuiz from 'Routes/account/quiz/quiz';

interface AccountTabsProps {
    candidate?: CandidateDetailsDto;
}

enum ProfileTabs {
    profile = 'profile',
    quiz = 'quiz',
    applications = 'applications',
}

const AccountTabs: FunctionComponent<AccountTabsProps> = ({ candidate }) => {
    const { windowWidth } = useWindowDimensions();
    const { t } = useTranslation();
    const search = new URLSearchParams(location.search);

    const items: TabItem[] = [
        {
            tabKey: ProfileTabs.applications,
            tab: Copydeck.accountAccountCandidatures,
            children: <JobApplications />,
        },
        {
            tabKey: ProfileTabs.quiz,
            tab: t('quiz'),
            children: <AccountQuiz />,
        },
        {
            tabKey: ProfileTabs.profile,
            tab: windowWidth > 500 ? Copydeck.accountAccountParametres : Copydeck.settings,
            children: <AccountForm candidate={candidate} />,
        },
    ];

    return (
        <div className="AccountTabs">
            <TabsCustom
                items={items}
                tabClassName="AccountTabs__tab"
                activeTabClassName="AccountTabs__tab AccountTabs__tab--active"
                defaultActiveTabKey={search.get(ACTIVE_TAB_PARAM) ?? ProfileTabs.applications}
                contentClassName="AccountTabs__contentItem"
                className="AccountTabs__tabsCustom"
            />
        </div>
    );
};

export default AccountTabs;
