import Content from 'Components/Content';
import Copydeck from 'i18n/Copydeck';
import './index.less';

const NotFound = () => {
    return (
        <div className="NotFound">
            <Content className="NotFound__content">
                <div className="NotFound__title">{Copydeck.notFoundNotFoundTitle}</div>
                <div className="text-headline-medium NotFound__subtitle">
                    {Copydeck.notFoundNotFoundSubtitle}
                </div>
            </Content>
        </div>
    );
};

export default NotFound;
