import { MakeDto } from 'Api/Features/Concessions/Dtos/MakeDto';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import { ConcessionService } from 'Services/ConcessionService';
import { inject } from 'aurelia-dependency-injection';
import { action, observable } from 'mobx';
import moment from 'moment';

@inject(ConcessionService)
class MakesStore {
    constructor(private readonly concessionService: ConcessionService) {}

    private makesPromise: any;
    private lastMakesCall: string | undefined = undefined;

    @observable
    public makes: MakeDto[] = [];

    @observable
    public makesSelectOptions: SingleSelectCustomOption[] = [];

    async setMakes(): Promise<void> {
        const response = await this.concessionService.getMakes();
        if (response?.items) {
            this.makes = response.items.filter((x) => x !== null).map((x) => x!);

            this.makesSelectOptions = this.makes.map((x) => ({
                label: x.name ?? '',
                value: x.id ?? '',
            }));

            this.lastMakesCall = moment().format();
        }
    }

    @action
    async cachedGetMakes(): Promise<[SingleSelectCustomOption[], number]> {
        if (
            this.lastMakesCall === undefined ||
            moment(this.lastMakesCall).add(2, 'hour').isBefore(moment())
        ) {
            if (!this.makesPromise) {
                this.makesPromise = this.setMakes();
            }

            await this.makesPromise;
            this.makesPromise = null;
            return [this.makesSelectOptions, this.makesSelectOptions.length];
        } else return [this.makesSelectOptions, this.makesSelectOptions.length];
    }
}

export default MakesStore;
