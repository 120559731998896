import { FormInstance } from 'antd/lib/form';

export const scrollToErrors = (errors: Map<string, string[]>, form?: FormInstance): void => {
    // scroll to first error
    if (form && errors.size > 0) {
        const errorKey = errors.keys().next();
        if (errorKey) {
            form.scrollToField(errorKey.value, {
                block: 'center',
                behavior: 'smooth',
                scrollMode: 'if-needed',
                // inline: 'center',
            });
        }
    }
};
