import React, {
    FunctionComponent,
    ReactNode,
    useEffect,
    useState,
    useCallback,
    useRef,
} from 'react';
import './multi-select-common.less';
import Select, { components } from 'react-select';
import { MultiSelectCommonStyles, MultiSelectCustomOption } from './multi-select-common';
import { Checkbox } from 'antd';
import { theme } from 'Style/theme';
import { useTranslation } from 'react-i18next';

export interface StaticMultiSelectProps {
    className?: string;
    options?: MultiSelectCustomOption[];
    onChange?: (value: MultiSelectCustomOption[]) => void | undefined;
    placeholder?: string | null;
    placeholderOnSelectedOptions: string | null;
    // defaultImg?: ReactNode;
    strongLabel?: boolean;
    closeMenuOnSelect?: boolean;
    hideSelectedOptions?: boolean;
    selected?: string[] | null;
    disabled?: boolean;
    borderless?: boolean;
    round?: boolean;
    dropdownIndicatorIcon?: ReactNode;
    blueStyle?: boolean; //SPECIFIC TO CCAQ METIER
    /**document.body  permet au menu ouvert de sortir de l'espace modal*/
    menuPortalTarget?: HTMLElement;
}

const TaglessStaticMultiSelect: FunctionComponent<StaticMultiSelectProps> = ({
    className,
    options,
    onChange,
    placeholder,
    placeholderOnSelectedOptions,
    // defaultImg,
    closeMenuOnSelect = true,
    hideSelectedOptions = false,
    selected,
    disabled,
    borderless,
    round,
    dropdownIndicatorIcon,
    blueStyle,
    menuPortalTarget,
}) => {
    const { t } = useTranslation();
    const [value, setValue] = useState<MultiSelectCustomOption[]>([]);
    const refSelect = useRef<any>(null);

    useEffect(() => {
        if (options === undefined) return;
        if (selected) {
            setValue(
                options?.filter((x) => {
                    return selected?.includes(x.value);
                })
            );
        } else {
            setValue([]);
        }
    }, [selected, options]);

    const handleOnChange = (data: any): void => {
        if (onChange) {
            onChange(data ?? []);
        }
    };

    useEffect(() => {
        //keep focus on select when menus is open and option is selected. This will help cross click closing when closeMenuOnSelect is false
        if (refSelect?.current && refSelect.current.props.menuIsOpen) {
            refSelect.current.focus();
        }
    }, [refSelect?.current?.props.menuIsOpen, value]);

    /* -- The open dropdown menu -- */
    const menuOptionDetails = (data: MultiSelectCustomOption, selectProps: any) => {
        let labelColor = 'black';
        let contentColor = theme['text-mid-contrast'];

        if (data?.isDisabled) {
            labelColor = theme['text-disabled'];
            contentColor = theme['text-disabled'];
        }

        let optionIsSelected = false;
        if (selectProps.value.some((option) => option.value === data.value)) {
            optionIsSelected = true;
        }

        return (
            <div className="menuOptionDetails">
                <div className="selected-checkmark">
                    <Checkbox checked={optionIsSelected} value={optionIsSelected} />
                </div>

                <div className="center-content">
                    <div className="dropdown-menu-option-label">
                        {selectProps.strongLabel ? (
                            <strong>{data.label}</strong>
                        ) : (
                            <div style={{ color: labelColor }}>{data.label}</div>
                        )}
                    </div>
                    {data.content && (
                        <div
                            className={`${data.content.className ?? ''}`}
                            style={{ color: contentColor }}
                        >
                            {data.content.node}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const Option = (props: any) => {
        return (
            <div>
                <components.Option {...props} className="multi-dropdown-menu-option-container">
                    {/* {defaultImg && optionImage(props.data, props.selectProps)} */}

                    {menuOptionDetails(props.data, props.selectProps)}
                </components.Option>
            </div>
        );
    };
    /* -- The open dropdown menu -- */

    /* -- The selected values in dropdown -- */
    const ValueContainer = ({ children, ...props }: any) => {
        // eslint-disable-next-line prefer-const
        let [values, input] = children as any;

        if (Array.isArray(values)) {
            const plural = values.length === 1 ? '' : 's';
            values = `${placeholderOnSelectedOptions}${plural} (${values.length})`;
        }

        return (
            <components.ValueContainer {...props} className="ValueContainer">
                {values}
                {input}
            </components.ValueContainer>
        );
    };

    const Input = useCallback(({ ...props }: any) => {
        return <components.Input {...props} autoComplete="new-password" />;
    }, []);

    const DropdownIndicator = useCallback(
        (props): any => {
            return dropdownIndicatorIcon ? (
                <components.DropdownIndicator {...props}>
                    {dropdownIndicatorIcon}
                </components.DropdownIndicator>
            ) : (
                <components.DropdownIndicator {...props}></components.DropdownIndicator>
            );
        },
        [dropdownIndicatorIcon]
    );

    return (
        <Select
            ref={refSelect}
            components={{
                Option,
                ValueContainer,
                DropdownIndicator,
                Input,
            }}
            theme={(defaultTheme) => ({
                ...defaultTheme,
                colors: {
                    ...defaultTheme.colors,
                    primary: 'transparent', //dictates the color of select option
                    primary25: theme['surface-brand-rest-secondary'], //dictates the color hovering options
                },
            })}
            className={`MultiSelect StaticMultiSelect TaglessStaticMultiSelect ${className || ''} ${
                disabled ? 'disabled' : ''
            } ${borderless ? 'borderless' : ''}  ${round ? 'round' : ''} ${
                blueStyle && 'blue-style'
            }`}
            value={value}
            styles={MultiSelectCommonStyles}
            options={options}
            onChange={handleOnChange}
            closeMenuOnSelect={closeMenuOnSelect}
            hideSelectedOptions={hideSelectedOptions}
            placeholder={placeholder ?? t('SelectCustom.default_select_placeholder')}
            isDisabled={disabled}
            noOptionsMessage={(): string => t('SelectCustom.select_no_options')}
            isMulti
            isClearable={false}
            menuPlacement="auto"
            menuPortalTarget={menuPortalTarget}
        />
    );
};

export default TaglessStaticMultiSelect;
