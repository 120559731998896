import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const MenuIcon: FunctionComponent<SvgProps> = ({ fill, ...props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            {...props}
        >
            <path
                d="M3.75 22.5H26.25V20H3.75V22.5ZM3.75 16.25H26.25V13.75H3.75V16.25ZM3.75 7.5V10H26.25V7.5H3.75Z"
                fill={fill || 'white'}
            />
        </svg>
    );
};

export default MenuIcon;
