import { JobApplicationStatusDto } from 'Api/Features/JobApplications/Dtos/JobApplicationStatusDto';
import { TagColor } from 'Components/tag/tag';
import { TFunction } from 'i18next';

export const JobApplicationStatusToTagProps = (
    status: JobApplicationStatusDto,
    t: TFunction
): { text: string; color: TagColor } => {
    switch (status) {
        case JobApplicationStatusDto.Accepted:
            return {
                color: TagColor.green,
                text: t(`JobApplicationStatusDto.JobApplicationStatusDto_${status}`),
            };
        case JobApplicationStatusDto.AwaitingResponse:
            return {
                color: TagColor.blue,
                text: t(`JobApplicationStatusDto.JobApplicationStatusDto_${status}`),
            };
        case JobApplicationStatusDto.Rejected:
            return {
                color: TagColor.grey,
                text: t(`JobApplicationStatusDto.JobApplicationStatusDto_${status}`),
            };
        case JobApplicationStatusDto.WithdrawnByCandidate:
            return {
                color: TagColor.black,
                text: t(`JobApplicationStatusDto.JobApplicationStatusDto_${status}`),
            };
    }
};
