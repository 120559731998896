import { CandidateQuizDto } from 'Api/Features/Candidates/Dtos/CandidateQuizDto';
import { JobCategory, QuizDefinition } from 'Models/QuizDefinitions';

export const getPreferredJobCategoryForQuiz = (quiz: CandidateQuizDto): JobCategory => {
    const jobCategoryPoints: Map<JobCategory, number> = Object.values(JobCategory).reduce(
        (map, category) => map.set(category, 0),
        new Map()
    );

    QuizDefinition.questions.forEach((question) => {
        const choiceId = quiz.responses?.find((x) => x?.questionId === question.id)?.choiceId;
        const categories = question.choices.find((x) => x.id === choiceId)?.categories;

        if (categories) {
            categories.forEach((category) => {
                const currentPoints = jobCategoryPoints.get(category) || 0;
                jobCategoryPoints.set(category, currentPoints + 1);
            });
        }
    });

    let highestPoints = 0;
    let highestCategory: JobCategory = JobCategory.Other;
    jobCategoryPoints.forEach((points, category) => {
        if (points > highestPoints) {
            highestCategory = category;
            highestPoints = points;
        }
    });

    return highestCategory;
};
