import React, { FunctionComponent, useRef } from 'react';
import './index.less';
import { useWindowDimensions } from 'Hooks';
import JobApplicationsDesktopView from './components/desktop-view';
import Content from 'Components/Content';
import TableFilters from 'Components/table-filters/table-filters';
import FilterStore from 'Stores/FilterStore';
import JobApplicationsMobileView from './components/mobile-view';

const JobApplications: FunctionComponent = () => {
    const MOBILE_TABLE_BREAKPOINT = 900;
    const { windowWidth } = useWindowDimensions();
    const filterStoreRef = useRef(new FilterStore());

    return (
        <div className="JobApplications">
            <Content>
                <TableFilters
                    filterStore={filterStoreRef.current}
                    includeSearch
                    includeMakesSelect
                    includeApplicationStatusSelect
                    includeDistanceSelect
                />
                {windowWidth > MOBILE_TABLE_BREAKPOINT ? (
                    <JobApplicationsDesktopView filterStoreRef={filterStoreRef} />
                ) : (
                    <JobApplicationsMobileView filterStoreRef={filterStoreRef} />
                )}
            </Content>
        </div>
    );
};

export default JobApplications;
