import { useTranslation } from 'react-i18next';
import './index.less';
import { useState } from 'react';
import { CloseIcon, FeedbackIcon } from 'Components/icons';
import { theme } from 'Style/theme';
import Button from 'Components/button';
import { LOCALSTORAGE_QUIZ_CTA, QUIZ_URL } from 'Models/Constants';
import { useHistory } from 'react-router-dom';
import useLocalStorage from 'Hooks/use-local-storage';

const QuizCtaBox = () => {
    const history = useHistory();

    const { setLocalStorage } = useLocalStorage();
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const onButtonClick = (response: boolean) => {
        setLocalStorage(LOCALSTORAGE_QUIZ_CTA, 'true');

        if (response) {
            history.push(QUIZ_URL);
        }

        setIsOpen(false);
    };
    return (
        <div className="QuizCtaBox">
            {isOpen ? (
                <div className="open-container">
                    <div className="close">
                        <CloseIcon
                            fill={theme['surface-grey-primary']}
                            onClick={() => setIsOpen(false)}
                        />
                    </div>

                    <div className="title-container">
                        <FeedbackIcon fill={theme['surface-brand-rest-primary']} />
                        <span className="text-display-medium uppercase custom-font text-primary">
                            {t('quiz')}
                        </span>
                    </div>

                    <div className="question text-body-large">{t('QuizCta.QuizCta_question')}</div>

                    <div className="btns">
                        <Button
                            type="blue-border"
                            text={t('QuizCta.QuizCta_rejection')}
                            width="hugged"
                            onClick={() => onButtonClick(false)}
                        />
                        <Button
                            type="red"
                            text={t('QuizCta.QuizCta_confirmation')}
                            width="hugged"
                            onClick={() => onButtonClick(true)}
                        />
                    </div>
                </div>
            ) : (
                <div className="closed-container" onClick={() => setIsOpen(true)}>
                    <div className="notification text-body-large text-white">1</div>
                    <FeedbackIcon fill={theme['surface-brand-rest-primary']} />
                </div>
            )}
        </div>
    );
};

export default QuizCtaBox;
