import { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import './index.less';
import moment from 'moment';
import { useWindowDimensions } from 'Hooks';
import { SOCIAL_MEDIA_LINKS, TERMS_CONDITIONS_URL } from 'Models/Constants';
import * as VanillaCookieConsent from 'vanilla-cookieconsent';
import { cookieConfig } from 'Components/cookie-consent/cookieConfig';
import useGa4 from 'Hooks/use-ga4';
import FooterLogo from 'Components/footer-logo';
import { useHistory } from 'react-router-dom';

const Footer: FunctionComponent = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { windowWidth } = useWindowDimensions();
    const isMobile = useMemo(() => windowWidth < 985, [windowWidth]);
    const { initReactGa, resetReactGa } = useGa4(
        process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID || '',
        {
            testMode: process.env.REACT_APP_GOOGLE_ANALYTICS_TEST_MODE === 'true',
        }
    );

    return (
        <div className="Footer">
            {!isMobile && (
                <>
                    <FooterLogo /> <div className="line" />
                </>
            )}

            <div className="mr-auto text-body-small copyright">
                {moment().year()}
                {t('Footer.tous_droit')}
            </div>

            <div
                className="text-body-small cursor-pointer legal-link"
                onClick={() => {
                    VanillaCookieConsent.showPreferences();
                    VanillaCookieConsent.run(
                        cookieConfig({
                            t,
                            initReactGa,
                            resetReactGa,
                        })
                    );
                }}
            >
                {t('Footer.reglage_temoins')}
            </div>

            <div className="line" />

            <div
                className="text-body-small cursor-pointer legal-link"
                onClick={() => history.push(TERMS_CONDITIONS_URL)}
            >
                {t('Footer.terme_conditions')}
            </div>

            <div className="line" />

            <div className="social-medias cursor-pointer">
                {SOCIAL_MEDIA_LINKS.map((media) => (
                    <a
                        key={media.label}
                        href={media.href}
                        aria-label={media.label}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <media.icon />
                    </a>
                ))}
            </div>
        </div>
    );
};

export default Footer;
