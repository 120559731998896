import { useCacheBuster } from 'CacheBuster';
import { useStores } from 'Hooks';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

const DeploymentCacheBuster = observer(({ children }) => {
    const { deploymentCacheStore } = useStores();
    const { checkCacheStatus } = useCacheBuster();

    useEffect(() => {
        if (deploymentCacheStore.bustCache) {
            deploymentCacheStore.updateLast();
            checkCacheStatus();
        }
    }, [deploymentCacheStore.bustCache, deploymentCacheStore, checkCacheStatus]);

    return <>{children}</>;
});

export default DeploymentCacheBuster;
