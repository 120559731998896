import React, { FC, FunctionComponent, ReactElement } from 'react';
import * as Icons from 'Components/icons';
import { SvgProps } from './SvgProps';

export type IconName = keyof typeof Icons;

type IconProps = { iconName: IconName } & SvgProps;

export type Ic = IconProps & SvgProps;

const Icon: FunctionComponent<Ic> = ({ iconName, ...rest }) => {
    const getIconComponent = (iconName: string): ReactElement => {
        const IconName = iconName;
        const Icon: FC<SvgProps> = Icons[IconName];

        return <Icon {...rest} />;
    };

    return getIconComponent(iconName);
};

export default Icon;
