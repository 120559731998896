import Button from 'Components/button';
import './index.less';
import { useHistory } from 'react-router-dom';
import { CONCESSIONS_URL } from 'Models/Constants';
import Copydeck from 'i18n/Copydeck';
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import useElementIsVisible from 'Hooks/use-element-is-visible';
import ScrollAnimationWrapper from 'Components/scroll-animation-wrapper';

const StatsConcessionnaire = () => {
    const history = useHistory();
    const containerRef = useRef<HTMLDivElement>(null);
    const concessionnaireRef = useRef<HTMLDivElement>(null);

    const containerIsVisible = useElementIsVisible(containerRef);

    //Make a count up animation on the stats
    const MAX_CONCESSIONNAIRE_COUNT = parseInt(Copydeck.industrieIndustrieStats890, 10);
    const MAX_EMPLOIS_COUNT = parseInt(
        Copydeck.industrieIndustrieStats10000.split(' ').splice(1).join(''),
        10
    );
    const INITIAL_CONCESSIONNAIRE_COUNT = 800;
    const INITIAL_EMPLOIS_COUNT = 9800;

    const [countConcessionnaire, setCountConcessionnaire] = useState<number>(
        INITIAL_CONCESSIONNAIRE_COUNT
    );
    const [countEmplois, setCountEmplois] = useState<number>(INITIAL_EMPLOIS_COUNT);

    const countUpSlowly = useCallback(
        (
            startNumber: number,
            targetNumber: number,
            speed: number,
            slowDownTarget: number,
            setState: Dispatch<SetStateAction<number>>
        ) => {
            let count = startNumber;
            let duration = speed;

            const timerFunction = () => {
                count += 1;
                setState(() => count);

                if (count < targetNumber) {
                    if (targetNumber - count < slowDownTarget) {
                        duration = duration * 1.2;
                    }
                    return setTimeout(timerFunction, duration);
                }
            };

            const timeout = timerFunction();

            return () => clearTimeout(timeout);
        },
        []
    );

    useEffect(() => {
        if (containerIsVisible && setCountConcessionnaire) {
            countUpSlowly(
                INITIAL_CONCESSIONNAIRE_COUNT,
                MAX_CONCESSIONNAIRE_COUNT,
                10,
                20,
                setCountConcessionnaire
            );
            countUpSlowly(INITIAL_EMPLOIS_COUNT, MAX_EMPLOIS_COUNT, 10, 20, setCountEmplois);
        }
    }, [containerIsVisible, MAX_CONCESSIONNAIRE_COUNT, MAX_EMPLOIS_COUNT, countUpSlowly]);

    useEffect(() => {
        if (!containerIsVisible) {
            setCountConcessionnaire(INITIAL_CONCESSIONNAIRE_COUNT);
            setCountEmplois(INITIAL_EMPLOIS_COUNT);
        }
    }, [containerIsVisible]);

    return (
        <div className="StatsConcessionnaire" ref={concessionnaireRef}>
            <ScrollAnimationWrapper containerRef={concessionnaireRef}>
                <div className="StatsConcessionnaire__content" ref={containerRef}>
                    <div className="StatsConcessionnaire__row StatsConcessionnaire__row--1">
                        <div className="StatsConcessionnaire__gridItem">
                            <span className="StatsConcessionnaire__highlight">
                                {countConcessionnaire}
                            </span>
                            <span className="StatsConcessionnaire__highlight__small">
                                {' '}
                                {Copydeck.industrieIndustrieStatsConcessionnaires}{' '}
                            </span>
                        </div>
                        <div className="StatsConcessionnaire__gridItem StatsConcessionnaire__gridItem--middle">
                            <span className="StatsConcessionnaire__highlight__small">
                                {Copydeck.industrieIndustrieStatsPartoutAu}
                            </span>
                            <span className="StatsConcessionnaire__highlight">
                                {Copydeck.industrieIndustrieStatsQuebec}
                            </span>
                        </div>
                        <div className="StatsConcessionnaire__gridItem">
                            <span className="StatsConcessionnaire__highlight">
                                {`+ ${countEmplois}`}
                            </span>
                            <span className="StatsConcessionnaire__highlight__small">
                                {Copydeck.industrieIndustrieStatsEmplois}
                            </span>
                        </div>
                    </div>
                    <div className="StatsConcessionnaire__row StatsConcessionnaire__row--2">
                        <div className="StatsConcessionnaire__buttonContainer">
                            <Button
                                withRightArrow
                                text={Copydeck.industrieIndustrieStatsCta}
                                onClick={() => history.push(CONCESSIONS_URL)}
                                height="large"
                                type="red"
                                width="full"
                            />
                        </div>
                    </div>
                </div>
            </ScrollAnimationWrapper>
        </div>
    );
};

export default StatsConcessionnaire;
