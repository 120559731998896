// GENERATED FILE - DO NOT MODIFY
import { CreateJobApplicationRequestDto } from 'Api/Features/JobApplications/Dtos/CreateJobApplicationRequestDto';
import { CreateJobApplicationResponseDto } from 'Api/Features/JobApplications/Dtos/CreateJobApplicationResponseDto';
import { GetJobApplicationsRequestDto } from 'Api/Features/JobApplications/Dtos/GetJobApplicationsRequestDto';
import { GetJobApplicationsResponseDto } from 'Api/Features/JobApplications/Dtos/GetJobApplicationsResponseDto';
import { JobApplicationDetailsDto } from 'Api/Features/JobApplications/Dtos/JobApplicationDetailsDto';
import { UpdateJobApplicationStatusRequestDto } from 'Api/Features/JobApplications/Dtos/UpdateJobApplicationStatusRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class JobApplicationProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getJobApplication(jobApplicationId: string): Promise<JobApplicationDetailsDto | null> {
        const uri = this.buildUri(
            "/job-applications/{jobApplicationId}",
            { jobApplicationId: jobApplicationId },
            null
        );

        const data: JobApplicationDetailsDto | null = await this.httpClient.get<JobApplicationDetailsDto | null>(uri);
        return data;
    }

    public async getJobApplications(request: GetJobApplicationsRequestDto | null): Promise<GetJobApplicationsResponseDto | null> {
        const uri = this.buildUri(
            "/job-applications",
            null,
            { ...request || undefined }
        );

        const data: GetJobApplicationsResponseDto | null = await this.httpClient.get<GetJobApplicationsResponseDto | null>(uri);
        return data;
    }

    public async createJobApplication(request: CreateJobApplicationRequestDto | null): Promise<CreateJobApplicationResponseDto | null> {
        const uri = this.buildUri(
            "/job-applications",
            null,
            null
        );

        const data: CreateJobApplicationResponseDto | null = await this.httpClient.post<CreateJobApplicationRequestDto | null, CreateJobApplicationResponseDto | null>(uri, request);
        return data;
    }

    public async updateJobApplicationStatus(jobApplicationId: string, request: UpdateJobApplicationStatusRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/job-applications/{jobApplicationId}/update-status",
            { jobApplicationId: jobApplicationId },
            null
        );

        await this.httpClient.post<UpdateJobApplicationStatusRequestDto | null, void>(uri, request);
    }

    public async withdrawJobApplication(jobApplicationId: string): Promise<void> {
        const uri = this.buildUri(
            "/job-applications/{jobApplicationId}/withdraw",
            { jobApplicationId: jobApplicationId },
            null
        );

        await this.httpClient.post<void, void>(uri, );
    }
}