import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const BriefcaseIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <path
                    d="M6.8 22C6.305 22 5.88125 21.8351 5.52875 21.5053C5.17625 21.1754 5 20.7789 5 20.3158V11.0526C5 10.5895 5.17625 10.193 5.52875 9.86316C5.88125 9.53333 6.305 9.36842 6.8 9.36842H10.4V7.68421C10.4 7.22105 10.5763 6.82456 10.9288 6.49474C11.2812 6.16491 11.705 6 12.2 6H15.8C16.295 6 16.7188 6.16491 17.0712 6.49474C17.4237 6.82456 17.6 7.22105 17.6 7.68421V9.36842H21.2C21.695 9.36842 22.1187 9.53333 22.4713 9.86316C22.8237 10.193 23 10.5895 23 11.0526V20.3158C23 20.7789 22.8237 21.1754 22.4713 21.5053C22.1187 21.8351 21.695 22 21.2 22H6.8ZM12.2 9.36842H15.8V7.68421H12.2V9.36842Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default BriefcaseIcon;
