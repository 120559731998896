import { JobApplicationStatusDto } from 'Api/Features/JobApplications/Dtos/JobApplicationStatusDto';
import { MultiSelectCustomOption } from 'Components/select-custom/multi-select/multi-select-common';
import TaglessStaticMultiSelect from 'Components/select-custom/multi-select/tagless-static-multi-select';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

interface JobApplicationFilterProps {
    onChange: (options: MultiSelectCustomOption[]) => void;
    selected: MultiSelectCustomOption[];
}

const JobApplicationFilter: FunctionComponent<JobApplicationFilterProps> = ({
    onChange,
    selected,
}) => {
    const { t } = useTranslation();
    return (
        <div className="JobApplicationFilter">
            <TaglessStaticMultiSelect
                onChange={onChange}
                options={Object.keys(JobApplicationStatusDto).map((x) => ({
                    label: t(`JobApplicationStatusDto.JobApplicationStatusDto_${x}`),
                    value: x,
                }))}
                selected={selected.map((x) => x.value)}
                closeMenuOnSelect={false}
                placeholderOnSelectedOptions={t('statut')}
            />
        </div>
    );
};

export default JobApplicationFilter;
