import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const LogoutIcon: FunctionComponent<SvgProps> = ({ width, height, fill, ...props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width || '24'}
            height={height || '24'}
            viewBox="0 0 24 24"
            fill="none"
            {...props}
        >
            <path
                d="M5.10425 5H11.1042C11.6542 5 12.1042 4.55 12.1042 4C12.1042 3.45 11.6542 3 11.1042 3H5.10425C4.00425 3 3.10425 3.9 3.10425 5V19C3.10425 20.1 4.00425 21 5.10425 21H11.1042C11.6542 21 12.1042 20.55 12.1042 20C12.1042 19.45 11.6542 19 11.1042 19H5.10425V5Z"
                fill={fill || 'white'}
            />
            <path
                d="M20.7542 11.65L17.9642 8.86C17.6442 8.54 17.1042 8.76 17.1042 9.21V11H10.1042C9.55425 11 9.10425 11.45 9.10425 12C9.10425 12.55 9.55425 13 10.1042 13H17.1042V14.79C17.1042 15.24 17.6442 15.46 17.9542 15.14L20.7442 12.35C20.9442 12.16 20.9442 11.84 20.7542 11.65Z"
                fill={fill || 'white'}
            />
        </svg>
    );
};

export default LogoutIcon;
