import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const UserIcon: FunctionComponent<SvgProps> = ({ fill, ...props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            {...props}
        >
            <path
                d="M19.9997 3.33331C10.7997 3.33331 3.33301 10.8 3.33301 20C3.33301 29.2 10.7997 36.6666 19.9997 36.6666C29.1997 36.6666 36.6663 29.2 36.6663 20C36.6663 10.8 29.1997 3.33331 19.9997 3.33331ZM19.9997 9.99998C23.2163 9.99998 25.833 12.6166 25.833 15.8333C25.833 19.05 23.2163 21.6666 19.9997 21.6666C16.783 21.6666 14.1663 19.05 14.1663 15.8333C14.1663 12.6166 16.783 9.99998 19.9997 9.99998ZM19.9997 33.3333C16.6163 33.3333 12.6163 31.9666 9.76634 28.5333C12.583 26.3333 16.133 25 19.9997 25C23.8663 25 27.4163 26.3333 30.233 28.5333C27.383 31.9666 23.383 33.3333 19.9997 33.3333Z"
                fill={fill || 'white'}
            />
        </svg>
    );
};

export default UserIcon;
