import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import * as VanillaCookieConsent from 'vanilla-cookieconsent';
import './index.less';
import { cookieConfig } from './cookieConfig';
import useGa4 from 'Hooks/use-ga4';

const CookieConsent = () => {
    const { t } = useTranslation();
    const { initReactGa, resetReactGa } = useGa4(
        process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID || '',
        {
            testMode: process.env.REACT_APP_GOOGLE_ANALYTICS_TEST_MODE === 'true',
        }
    );

    useEffect(() => {
        VanillaCookieConsent.run(
            cookieConfig({
                t,
                initReactGa,
                resetReactGa,
            })
        );
    }, [initReactGa, resetReactGa, t]);

    return <div />;
};

export default CookieConsent;
