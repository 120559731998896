import { inject } from 'aurelia-dependency-injection';
import { observable, action, computed } from 'mobx';
import AuthorizationStore from './AuthorizationStore';
import { CreateAccessTokenResponseDto } from 'Api/Features/Authentication/Dtos/CreateAccessTokenResponseDto';

@inject(AuthorizationStore)
class AuthenticationStore {
    constructor(private readonly authorizationStore: AuthorizationStore) {}

    @observable accessToken?: string | null | undefined = sessionStorage.accessToken;
    @observable refreshToken?: string | null = sessionStorage.refreshToken;
    @observable tokenType?: string | null = sessionStorage.tokenType;
    @observable expiresIn?: number = parseInt(sessionStorage.expiresIn, 10);
    @observable userId?: string | null = sessionStorage.userId;
    @observable expirationTimeStamp: number | null = parseInt(
        sessionStorage.expirationTimeStamp,
        10
    );
    @observable returnUrl?: string | null = sessionStorage.returnUrl;

    @computed
    get loggedIn(): boolean {
        return !!this.accessToken;
    }

    @action
    setSession(result: CreateAccessTokenResponseDto): void {
        this.userId = sessionStorage.userId = result.user_id;
        this.tokenType = sessionStorage.tokenType = result.token_type;
        this.accessToken = sessionStorage.accessToken = result.access_token;
        this.expiresIn = sessionStorage.expiresIn = result.expires_in;
        this.refreshToken = sessionStorage.refreshToken = result.refresh_token;
        this.expirationTimeStamp = sessionStorage.expirationTimeStamp =
            Math.round(Date.now() / 1000) + (result.expires_in || -1);
    }

    @action
    clearSession(): void {
        this.accessToken = '';
        this.expiresIn = 0;
        this.userId = '';
        sessionStorage.clear();
    }

    @action setReturnUrl(returnUrl: string): void {
        this.returnUrl = sessionStorage.returnUrl = returnUrl;
    }

    @action clearReturnUrl(): void {
        this.returnUrl = null;
        sessionStorage.removeItem('returnUrl');
    }
}

export default AuthenticationStore;
