import Modal from 'Components/modal';
import React, { ReactNode } from 'react';
import './confirmation-modal.less';
import Button from 'Components/button';

interface ModalAction {
    text: string;
    action: (args: any) => void;
}

export interface ConfirmationModalProps {
    id: string;
    title?: string;
    message?: string | ReactNode;
    positive: ModalAction;
    negative?: ModalAction;
    width?: number | null;
    centerButtons?: boolean;
    icon?: ReactNode;
}

const ConfirmationModal: React.FunctionComponent<ConfirmationModalProps> = ({
    title,
    message,
    positive,
    negative,
    width,
    icon,
}) => {
    return (
        <Modal
            className="ConfirmationModal"
            visible
            onCancel={negative && negative.action}
            width={width ? width : 600}
        >
            <div className="top-container">
                {icon && icon}

                {title && <div className="title custom-font">{title}</div>}
            </div>

            {message && <div className="message text-title-large-bold">{message}</div>}
            <div className="actions">
                {negative && negative.text && (
                    <Button text={negative.text} type="white-border" onClick={negative.action} />
                )}

                <Button text={positive.text} type={'red'} onClick={positive.action} />
            </div>
        </Modal>
    );
};

export default ConfirmationModal;
