// This is a generated file, do not edit!
import i18n from 'i18next';

class Copydeck {
    static get temoignageDetailsTemoignageDetailsBackButton() { return i18n.t('TemoignageDetails.temoignageDetails_back_button'); }
    static get temoignageDetailsTemoignageDetailsMoreInfo() { return i18n.t('TemoignageDetails.temoignageDetails_more_info'); }
    static get temoignageDetailsTemoignageDetailsCta() { return i18n.t('TemoignageDetails.temoignageDetails_cta'); }
    static get metierDetailsMetierDetailsTemoignageTitle() { return i18n.t('MetierDetails.MetierDetails_temoignage_title'); }
    static get metierDetailsMetierDetailsCta() { return i18n.t('MetierDetails.MetierDetails_cta'); }
    static get metierDetailsMetierDetailsCtaMobile() { return i18n.t('MetierDetails.MetierDetails_cta_mobile'); }
    static get metierDetailsMetierDetailsCtaTitle() { return i18n.t('MetierDetails.MetierDetails_cta_title'); }
    static get metierDetailsMetierDetailsBackButton() { return i18n.t('MetierDetails.MetierDetails_back_button'); }
    static get envoyer() { return i18n.t('envoyer'); }
    static get explorer() { return i18n.t('explorer'); }
    static get decouvreTonMetier() { return i18n.t('decouvre_ton_metier'); }
    static get notFoundNotFoundTitle() { return i18n.t('NotFound.NotFound_title'); }
    static get notFoundNotFoundSubtitle() { return i18n.t('NotFound.NotFound_subtitle'); }
    static get retroaction() { return i18n.t('retroaction'); }
    static get meLocaliser() { return i18n.t('me_localiser'); }
    static get enSavoirPlus() { return i18n.t('en_savoir_plus'); }
    static get quizQ2Answer0() { return i18n.t('Quiz.q2_answer0'); }
    static get quizQ5Answer2() { return i18n.t('Quiz.q5_answer2'); }
    static get quizProchaineQuestion() { return i18n.t('Quiz.prochaine_question'); }
    static get quizFelicitationsPlusDe() { return i18n.t('Quiz.felicitations_plus_de'); }
    static get quizQ2Question() { return i18n.t('Quiz.q2_question'); }
    static get quizQ5Answer0() { return i18n.t('Quiz.q5_answer0'); }
    static get quizQ4Question() { return i18n.t('Quiz.q4_question'); }
    static get quizQ3Answer2() { return i18n.t('Quiz.q3_answer2'); }
    static get quizQ5Question() { return i18n.t('Quiz.q5_question'); }
    static get quizQ4Answer1() { return i18n.t('Quiz.q4_answer1'); }
    static get quizMetierTrouve() { return i18n.t('Quiz.metier_trouve'); }
    static get quizQuestionPrecedente() { return i18n.t('Quiz.question_precedente'); }
    static get quizPlusQuuneEtape() { return i18n.t('Quiz.plus_quune_etape'); }
    static get quizQ3Answer1() { return i18n.t('Quiz.q3_answer1'); }
    static get quizQ5Answer1() { return i18n.t('Quiz.q5_answer1'); }
    static get quizQ2Answer1() { return i18n.t('Quiz.q2_answer1'); }
    static get quizGraceATesReponses() { return i18n.t('Quiz.grace_a_tes_reponses'); }
    static get quizQ1Answer2() { return i18n.t('Quiz.q1_answer2'); }
    static get quizQ1Question() { return i18n.t('Quiz.q1_question'); }
    static get quizCestParti() { return i18n.t('Quiz.cest_parti'); }
    static get quizQ1Answer0() { return i18n.t('Quiz.q1_answer0'); }
    static get quizLandingP1() { return i18n.t('Quiz.landing_p1'); }
    static get quizQ4Answer0() { return i18n.t('Quiz.q4_answer0'); }
    static get quizQ1Answer1() { return i18n.t('Quiz.q1_answer1'); }
    static get quizPretACommencer() { return i18n.t('Quiz.pret_a_commencer'); }
    static get quizQ4Answer2() { return i18n.t('Quiz.q4_answer2'); }
    static get quizQ3Question() { return i18n.t('Quiz.q3_question'); }
    static get quizQ2Answer2() { return i18n.t('Quiz.q2_answer2'); }
    static get quizFinaliserLeCompte() { return i18n.t('Quiz.finaliser_le_compte'); }
    static get quizQ3Answer0() { return i18n.t('Quiz.q3_answer0'); }
    static get aPropos() { return i18n.t('a_propos'); }
    static get rechercher() { return i18n.t('rechercher'); }
    static get voirPlus() { return i18n.t('voir_plus'); }
    static get felicitations() { return i18n.t('felicitations'); }
    static get concessionOrderFilterConcessionOrderFilterAlphabetic() { return i18n.t('ConcessionOrderFilter.ConcessionOrderFilter_alphabetic'); }
    static get concessionOrderFilterConcessionOrderFilterDistance() { return i18n.t('ConcessionOrderFilter.ConcessionOrderFilter_distance'); }
    static get mettreAJour() { return i18n.t('mettre_a_jour'); }
    static get jobApplicationStatusDtoJobApplicationStatusDtoWithdrawnByCandidate() { return i18n.t('JobApplicationStatusDto.JobApplicationStatusDto_WithdrawnByCandidate'); }
    static get jobApplicationStatusDtoJobApplicationStatusDtoAwaitingResponse() { return i18n.t('JobApplicationStatusDto.JobApplicationStatusDto_AwaitingResponse'); }
    static get jobApplicationStatusDtoJobApplicationStatusDtoAccepted() { return i18n.t('JobApplicationStatusDto.JobApplicationStatusDto_Accepted'); }
    static get jobApplicationStatusDtoJobApplicationStatusDtoRejected() { return i18n.t('JobApplicationStatusDto.JobApplicationStatusDto_Rejected'); }
    static get formNavBlockerFormNavMessage() { return i18n.t('FormNavBlocker.form_nav_message'); }
    static get formNavBlockerFormNavTitle() { return i18n.t('FormNavBlocker.form_nav_title'); }
    static get filterPar() { return i18n.t('filter_par'); }
    static get toastStoreDefaultSuccessToast() { return i18n.t('ToastStore.default_success_toast'); }
    static get etapeSuivante() { return i18n.t('etape_suivante'); }
    static get concession() { return i18n.t('concession'); }
    static get candidatureEnvoyee() { return i18n.t('candidature_envoyee'); }
    static get temoignagesTemoignagesCard3Url() { return i18n.t('Temoignages.Temoignages_card3_url'); }
    static get temoignagesTemoignagesCard2Description() { return i18n.t('Temoignages.Temoignages_card2_description'); }
    static get temoignagesTemoignagesCard1Name() { return i18n.t('Temoignages.Temoignages_card1_name'); }
    static get temoignagesTemoignagesCard1Url() { return i18n.t('Temoignages.Temoignages_card1_url'); }
    static get temoignagesTemoignagesCard4Name() { return i18n.t('Temoignages.Temoignages_card4_name'); }
    static get temoignagesTemoignagesCard3Name() { return i18n.t('Temoignages.Temoignages_card3_name'); }
    static get temoignagesTemoignagesCard1Description() { return i18n.t('Temoignages.Temoignages_card1_description'); }
    static get temoignagesTemoignagesCard3Quote() { return i18n.t('Temoignages.Temoignages_card3_quote'); }
    static get temoignagesTemoignagesCard1JobTitle() { return i18n.t('Temoignages.Temoignages_card1_job_title'); }
    static get temoignagesTemoignagesCard3JobTitle() { return i18n.t('Temoignages.Temoignages_card3_job_title'); }
    static get temoignagesTemoignagesCard3LongDescription() { return i18n.t('Temoignages.Temoignages_card3_long_description'); }
    static get temoignagesTemoignagesCard4LongDescription() { return i18n.t('Temoignages.Temoignages_card4_long_description'); }
    static get temoignagesTemoignagesCard2Url() { return i18n.t('Temoignages.Temoignages_card2_url'); }
    static get temoignagesTemoignagesCard4Quote() { return i18n.t('Temoignages.Temoignages_card4_quote'); }
    static get temoignagesTemoignagesCard2Quote() { return i18n.t('Temoignages.Temoignages_card2_quote'); }
    static get temoignagesTemoignagesCard4JobTitle() { return i18n.t('Temoignages.Temoignages_card4_job_title'); }
    static get temoignagesTemoignagesCardPlayVideo() { return i18n.t('Temoignages.Temoignages_card_play_video'); }
    static get temoignagesTemoignagesCard3Description() { return i18n.t('Temoignages.Temoignages_card3_description'); }
    static get temoignagesTemoignagesCard4Url() { return i18n.t('Temoignages.Temoignages_card4_url'); }
    static get temoignagesTemoignagesTitle1() { return i18n.t('Temoignages.Temoignages_title_1'); }
    static get temoignagesTemoignagesCard4Description() { return i18n.t('Temoignages.Temoignages_card4_description'); }
    static get temoignagesTemoignagesCard2Name() { return i18n.t('Temoignages.Temoignages_card2_name'); }
    static get temoignagesTemoignagesCard2JobTitle() { return i18n.t('Temoignages.Temoignages_card2_job_title'); }
    static get temoignagesTemoignagesCard1LongDescription() { return i18n.t('Temoignages.Temoignages_card1_long_description'); }
    static get temoignagesTemoignagesCard1Quote() { return i18n.t('Temoignages.Temoignages_card1_quote'); }
    static get temoignagesTemoignagesCard2LongDescription() { return i18n.t('Temoignages.Temoignages_card2_long_description'); }
    static get temoignagesTemoignagesTitle3() { return i18n.t('Temoignages.Temoignages_title_3'); }
    static get temoignagesTemoignagesTitle2() { return i18n.t('Temoignages.Temoignages_title_2'); }
    static get metiersMetiersDescriptionDirecteurPiecesH31() { return i18n.t('Metiers.Metiers_description_directeur_pieces_h3_1'); }
    static get metiersMetiersDescriptionDirecteurPiecesH2() { return i18n.t('Metiers.Metiers_description_directeur_pieces_h2'); }
    static get metiersMetiersDescriptionCommisPiecesMecaniqueH32() { return i18n.t('Metiers.Metiers_description_commis_pieces_mecanique_h3_2'); }
    static get metiersMetiersDescriptionCarrossierH34() { return i18n.t('Metiers.Metiers_description_carrossier_h3_4'); }
    static get metiersMetiersDescriptionMecanicienApprentiH33() { return i18n.t('Metiers.Metiers_description_mecanicien_apprenti_h3_3'); }
    static get metiersMetiersDescriptionCommisComptableH35() { return i18n.t('Metiers.Metiers_description_commis_comptable_h3_5'); }
    static get metiersMetiersDescriptionDirecteurOperationsFixesP1() { return i18n.t('Metiers.Metiers_description_directeur_operations_fixes_p1'); }
    static get metiersMetiersJobTitleCommisReclamations() { return i18n.t('Metiers.Metiers_jobTitle_commis_reclamations'); }
    static get metiersMetiersDescriptionDirecteurCarrosserieP2() { return i18n.t('Metiers.Metiers_description_directeur_carrosserie_p2'); }
    static get metiersMetiersDescriptionCommisComptesRecevoirH33() { return i18n.t('Metiers.Metiers_description_commis_comptes_recevoir_h3_3'); }
    static get metiersMetiersDescriptionSecretaireVentesH35() { return i18n.t('Metiers.Metiers_description_secretaire_ventes_h3_5'); }
    static get metiersMetiersDescriptionPeintreP1() { return i18n.t('Metiers.Metiers_description_peintre_p1'); }
    static get metiersMetiersDescriptionServiceClienteleH2() { return i18n.t('Metiers.Metiers_description_service_clientele_h2'); }
    static get metiersMetiersDescriptionConseillerTechniqueH36() { return i18n.t('Metiers.Metiers_description_conseiller_technique_h3_6'); }
    static get metiersMetiersDescriptionRhH33() { return i18n.t('Metiers.Metiers_description_rh_h3_3'); }
    static get metiersMetiersDescriptionDirecteurAdjointVentesH34() { return i18n.t('Metiers.Metiers_description_directeur_adjoint_ventes_h3_4'); }
    static get metiersMetiersJobTitleAdjointDirectionPiecesOperationsFixes() { return i18n.t('Metiers.Metiers_jobTitle_adjoint_direction_pieces_operations_fixes'); }
    static get metiersMetiersJobTitleAdjointDirectionsPiecesOperationsFixes() { return i18n.t('Metiers.Metiers_jobTitle_adjoint_directions_pieces_operations_fixes'); }
    static get metiersMetiersDescriptionVpFinancesH2() { return i18n.t('Metiers.Metiers_description_vp_finances_h2'); }
    static get metiersMetiersDescriptionAdjointAdministratifH34() { return i18n.t('Metiers.Metiers_description_adjoint_administratif_h3_4'); }
    static get metiersMetiersDescriptionAdjointDirectionPiecesOperationsFixesH34() { return i18n.t('Metiers.Metiers_description_adjoint_direction_pieces_operations_fixes_h3_4'); }
    static get metiersMetiersDescriptionConseillerVenteH31() { return i18n.t('Metiers.Metiers_description_conseiller_vente_h3_1'); }
    static get metiersMetiersDescriptionMecanicienApprentiH35() { return i18n.t('Metiers.Metiers_description_mecanicien_apprenti_h3_5'); }
    static get metiersMetiersDescriptionPreposeLivraisonPiecesH32() { return i18n.t('Metiers.Metiers_description_prepose_livraison_pieces_h3_2'); }
    static get metiersMetiersDescriptionCommisComptableH33() { return i18n.t('Metiers.Metiers_description_commis_comptable_h3_3'); }
    static get metiersMetiersDescriptionCommisComptesRecevoirH2() { return i18n.t('Metiers.Metiers_description_commis_comptes_recevoir_h2'); }
    static get metiersMetiersDescriptionDgVentesH33() { return i18n.t('Metiers.Metiers_description_dg_ventes_h3_3'); }
    static get metiersMetiersDescriptionReceptionnisteP1() { return i18n.t('Metiers.Metiers_description_receptionniste_p1'); }
    static get metiersMetiersCategoryGestion() { return i18n.t('Metiers.Metiers_category_gestion'); }
    static get metiersMetiersDescriptionCommisPiecesMecaniqueP2() { return i18n.t('Metiers.Metiers_description_commis_pieces_mecanique_p2'); }
    static get metiersMetiersDescriptionContremaitreAtelierCarrosserieH31() { return i18n.t('Metiers.Metiers_description_contremaitre_atelier_carrosserie_h3_1'); }
    static get metiersMetiersDescriptionDirecteurCommercialH32() { return i18n.t('Metiers.Metiers_description_directeur_commercial_h3_2'); }
    static get metiersMetiersDescriptionDgVentesP1() { return i18n.t('Metiers.Metiers_description_dg_ventes_p1'); }
    static get metiersMetiersDescriptionEstimateurP1() { return i18n.t('Metiers.Metiers_description_estimateur_p1'); }
    static get metiersMetiersDescriptionPreposeEsthetiqueH35() { return i18n.t('Metiers.Metiers_description_prepose_esthetique_h3_5'); }
    static get metiersMetiersDescriptionDgH2() { return i18n.t('Metiers.Metiers_description_dg_h2'); }
    static get metiersMetiersDescriptionDirecteurCarrosserieH34() { return i18n.t('Metiers.Metiers_description_directeur_carrosserie_h3_4'); }
    static get metiersMetiersDescriptionConseillerVenteH32() { return i18n.t('Metiers.Metiers_description_conseiller_vente_h3_2'); }
    static get metiersMetiersDescriptionDirecteurVentesH34() { return i18n.t('Metiers.Metiers_description_directeur_ventes_h3_4'); }
    static get metiersMetiersDescriptionServiceClienteleP2() { return i18n.t('Metiers.Metiers_description_service_clientele_p2'); }
    static get metiersMetiersJobTitleCommissionnaire() { return i18n.t('Metiers.Metiers_jobTitle_commissionnaire'); }
    static get metiersMetiersJobTitlePreposeServiceApresVente() { return i18n.t('Metiers.Metiers_jobTitle_prepose_service_apres_vente'); }
    static get metiersMetiersDescriptionCommissionnaireH34() { return i18n.t('Metiers.Metiers_description_commissionnaire_h3_4'); }
    static get metiersMetiersMetierPourToiCta() { return i18n.t('Metiers.Metiers_metier_pour_toi_cta'); }
    static get metiersMetiersDescriptionDirecteurApresVenteH2() { return i18n.t('Metiers.Metiers_description_directeur_apres_vente_h2'); }
    static get metiersMetiersDescriptionDirecteurPiecesH32() { return i18n.t('Metiers.Metiers_description_directeur_pieces_h3_2'); }
    static get metiersMetiersDescriptionPersonneCourP1() { return i18n.t('Metiers.Metiers_description_personne_cour_p1'); }
    static get metiersMetiersDescriptionAgentDeLiaisonH34() { return i18n.t('Metiers.Metiers_description_agent_de_liaison_h3_4'); }
    static get metiersMetiersDescriptionAdministrateurGarantieH34() { return i18n.t('Metiers.Metiers_description_administrateur_garantie_h3_4'); }
    static get metiersMetiersDescriptionPreposeLivraisonPiecesP2() { return i18n.t('Metiers.Metiers_description_prepose_livraison_pieces_p2'); }
    static get metiersMetiersDescriptionPreposeServiceApresVenteH33() { return i18n.t('Metiers.Metiers_description_prepose_service_apres_vente_h3_3'); }
    static get metiersMetiersDescriptionAdjointDirectionVentesH36() { return i18n.t('Metiers.Metiers_description_adjoint_direction_ventes_h3_6'); }
    static get metiersMetiersDescriptionDgVentesP2() { return i18n.t('Metiers.Metiers_description_dg_ventes_p2'); }
    static get metiersMetiersDescriptionAgentDeLiaisonP2() { return i18n.t('Metiers.Metiers_description_agent_de_liaison_p2'); }
    static get metiersMetiersDescriptionConseillerTechniqueH33() { return i18n.t('Metiers.Metiers_description_conseiller_technique_h3_3'); }
    static get metiersMetiersDescriptionControleurComptableH34() { return i18n.t('Metiers.Metiers_description_controleur_comptable_h3_4'); }
    static get metiersMetiersJobTitleSecretaireVentes() { return i18n.t('Metiers.Metiers_jobTitle_secretaire_ventes'); }
    static get metiersMetiersDescriptionDirecteurPiecesP2() { return i18n.t('Metiers.Metiers_description_directeur_pieces_p2'); }
    static get metiersMetiersDescriptionAdministrateurGarantieH33() { return i18n.t('Metiers.Metiers_description_administrateur_garantie_h3_3'); }
    static get metiersMetiersDescriptionAdjointAdministratifP1() { return i18n.t('Metiers.Metiers_description_adjoint_administratif_p1'); }
    static get metiersMetiersDescriptionDirecteurApresVenteH31() { return i18n.t('Metiers.Metiers_description_directeur_apres_vente_h3_1'); }
    static get metiersMetiersDescriptionTechnicienMecaniqueVehiculeLourdsH37() { return i18n.t('Metiers.Metiers_description_technicien_mecanique_vehicule_lourds_h3_7'); }
    static get metiersMetiersJobTitleCommisEntrepot() { return i18n.t('Metiers.Metiers_jobTitle_commis_entrepot'); }
    static get metiersMetiersDescriptionMecanicienCompagnonP2() { return i18n.t('Metiers.Metiers_description_mecanicien_compagnon_p2'); }
    static get metiersMetiersDescriptionContremaitreAtelierCarrosserieH2() { return i18n.t('Metiers.Metiers_description_contremaitre_atelier_carrosserie_h2'); }
    static get metiersMetiersDescriptionPeintreH35() { return i18n.t('Metiers.Metiers_description_peintre_h3_5'); }
    static get metiersMetiersDescriptionDirecteurRenouvellementsH33() { return i18n.t('Metiers.Metiers_description_directeur_renouvellements_h3_3'); }
    static get metiersMetiersDescriptionAdjointDirectionVentesH35() { return i18n.t('Metiers.Metiers_description_adjoint_direction_ventes_h3_5'); }
    static get metiersMetiersDescriptionReceptionnisteH33() { return i18n.t('Metiers.Metiers_description_receptionniste_h3_3'); }
    static get metiersMetiersDescriptionCommissionnaireP1() { return i18n.t('Metiers.Metiers_description_commissionnaire_p1'); }
    static get metiersMetiersDescriptionAdjointDirectionPiecesOperationsFixesH33() { return i18n.t('Metiers.Metiers_description_adjoint_direction_pieces_operations_fixes_h3_3'); }
    static get metiersMetiersDescriptionPreposeLivraisonPiecesH31() { return i18n.t('Metiers.Metiers_description_prepose_livraison_pieces_h3_1'); }
    static get metiersMetiersDescriptionPreposeEsthetiqueH2() { return i18n.t('Metiers.Metiers_description_prepose_esthetique_h2'); }
    static get metiersMetiersDescriptionCommisComptesRecevoirP2() { return i18n.t('Metiers.Metiers_description_commis_comptes_recevoir_p2'); }
    static get metiersMetiersDescriptionResponsableSanteSecuriteH31() { return i18n.t('Metiers.Metiers_description_responsable_sante_securite_h3_1'); }
    static get metiersMetiersDescriptionAdjointDirectionVentesH32() { return i18n.t('Metiers.Metiers_description_adjoint_direction_ventes_h3_2'); }
    static get metiersMetiersDescriptionCommisComptableH31() { return i18n.t('Metiers.Metiers_description_commis_comptable_h3_1'); }
    static get metiersMetiersDescriptionCoordonnateurVentesH33() { return i18n.t('Metiers.Metiers_description_coordonnateur_ventes_h3_3'); }
    static get metiersMetiersDescriptionCommissionnaireH35() { return i18n.t('Metiers.Metiers_description_commissionnaire_h3_5'); }
    static get metiersMetiersDescriptionConseillerTechniqueH35() { return i18n.t('Metiers.Metiers_description_conseiller_technique_h3_5'); }
    static get metiersMetiersDescriptionVpFinancesH32() { return i18n.t('Metiers.Metiers_description_vp_finances_h3_2'); }
    static get metiersMetiersDescriptionRhP1() { return i18n.t('Metiers.Metiers_description_rh_p1'); }
    static get metiersMetiersDescriptionCommisComptableH34() { return i18n.t('Metiers.Metiers_description_commis_comptable_h3_4'); }
    static get metiersMetiersDescriptionDirecteurAdjointVentesP1() { return i18n.t('Metiers.Metiers_description_directeur_adjoint_ventes_p1'); }
    static get metiersMetiersDescriptionSecretaireVentesH33() { return i18n.t('Metiers.Metiers_description_secretaire_ventes_h3_3'); }
    static get metiersMetiersDescriptionPeintreH36() { return i18n.t('Metiers.Metiers_description_peintre_h3_6'); }
    static get metiersMetiersDescriptionDirecteurCommercialH35() { return i18n.t('Metiers.Metiers_description_directeur_commercial_h3_5'); }
    static get metiersMetiersHeaderTitleRow3() { return i18n.t('Metiers.Metiers_header_title_row3'); }
    static get metiersMetiersDescriptionDirecteurCarrosserieH35() { return i18n.t('Metiers.Metiers_description_directeur_carrosserie_h3_5'); }
    static get metiersMetiersDescriptionControleurComptableH31() { return i18n.t('Metiers.Metiers_description_controleur_comptable_h3_1'); }
    static get metiersMetiersDescriptionServiceClienteleH34() { return i18n.t('Metiers.Metiers_description_service_clientele_h3_4'); }
    static get metiersMetiersDescriptionConseillerTechniqueP1() { return i18n.t('Metiers.Metiers_description_conseiller_technique_p1'); }
    static get metiersMetiersDescriptionCoordonnateurVentesH35() { return i18n.t('Metiers.Metiers_description_coordonnateur_ventes_h3_5'); }
    static get metiersMetiersDescriptionDirecteurPiecesH34() { return i18n.t('Metiers.Metiers_description_directeur_pieces_h3_4'); }
    static get metiersMetiersDescriptionServiceClienteleH36() { return i18n.t('Metiers.Metiers_description_service_clientele_h3_6'); }
    static get metiersMetiersDescriptionServiceClienteleP1() { return i18n.t('Metiers.Metiers_description_service_clientele_p1'); }
    static get metiersMetiersDescriptionDgVentesH34() { return i18n.t('Metiers.Metiers_description_dg_ventes_h3_4'); }
    static get metiersMetiersDescriptionAgentLivraisonP2() { return i18n.t('Metiers.Metiers_description_agent_livraison_p2'); }
    static get metiersMetiersDescriptionPreposeEsthetiqueH32() { return i18n.t('Metiers.Metiers_description_prepose_esthetique_h3_2'); }
    static get metiersMetiersJobTitleDgVentes() { return i18n.t('Metiers.Metiers_jobTitle_dg_ventes'); }
    static get metiersMetiersDescriptionAdjointDirectionPiecesOperationsFixesP2() { return i18n.t('Metiers.Metiers_description_adjoint_direction_pieces_operations_fixes_p2'); }
    static get metiersMetiersDescriptionConseillerVentesCamionsH2() { return i18n.t('Metiers.Metiers_description_conseiller_ventes_camions_h2'); }
    static get metiersMetiersDescriptionVpFinancesP1() { return i18n.t('Metiers.Metiers_description_vp_finances_p1'); }
    static get metiersMetiersDescriptionDirecteurCarrosserieH2() { return i18n.t('Metiers.Metiers_description_directeur_carrosserie_h2'); }
    static get metiersMetiersDescriptionConseillerVenteP2() { return i18n.t('Metiers.Metiers_description_conseiller_vente_p2'); }
    static get metiersMetiersDescriptionCommisPiecesCarrosserieH2() { return i18n.t('Metiers.Metiers_description_commis_pieces_carrosserie_h2'); }
    static get metiersMetiersDescriptionCoordonnateurVentesP1() { return i18n.t('Metiers.Metiers_description_coordonnateur_ventes_p1'); }
    static get metiersMetiersDescriptionPreposeTransportClientsP2() { return i18n.t('Metiers.Metiers_description_prepose_transport_clients_p2'); }
    static get metiersMetiersDescriptionAdjointDirectionPiecesOperationsFixesP1() { return i18n.t('Metiers.Metiers_description_adjoint_direction_pieces_operations_fixes_p1'); }
    static get metiersMetiersDescriptionPreposeEsthetiqueP2() { return i18n.t('Metiers.Metiers_description_prepose_esthetique_p2'); }
    static get metiersMetiersDescriptionAgentDeLiaisonH2() { return i18n.t('Metiers.Metiers_description_agent_de_liaison_h2'); }
    static get metiersMetiersDescriptionAgentLivraisonH35() { return i18n.t('Metiers.Metiers_description_agent_livraison_h3_5'); }
    static get metiersMetiersDescriptionContremaitreAtelierCarrosserieH36() { return i18n.t('Metiers.Metiers_description_contremaitre_atelier_carrosserie_h3_6'); }
    static get metiersMetiersDescriptionCarrossierP2() { return i18n.t('Metiers.Metiers_description_carrossier_p2'); }
    static get metiersMetiersDescriptionConseillerTechniqueH2() { return i18n.t('Metiers.Metiers_description_conseiller_technique_h2'); }
    static get metiersMetiersDescriptionPersonneCourH32() { return i18n.t('Metiers.Metiers_description_personne_cour_h3_2'); }
    static get metiersMetiersDescriptionDgH33() { return i18n.t('Metiers.Metiers_description_dg_h3_3'); }
    static get metiersMetiersDescriptionAdjointDirectionVentesP2() { return i18n.t('Metiers.Metiers_description_adjoint_direction_ventes_p2'); }
    static get metiersMetiersDescriptionSecretaireServicesApresVenteP1() { return i18n.t('Metiers.Metiers_description_secretaire_services_apres_vente_p1'); }
    static get metiersMetiersDescriptionRhP2() { return i18n.t('Metiers.Metiers_description_rh_p2'); }
    static get metiersMetiersDescriptionMecanicienCompagnonH33() { return i18n.t('Metiers.Metiers_description_mecanicien_compagnon_h3_3'); }
    static get metiersMetiersDescriptionConseillerTechniqueH34() { return i18n.t('Metiers.Metiers_description_conseiller_technique_h3_4'); }
    static get metiersMetiersDescriptionDirecteurPiecesH33() { return i18n.t('Metiers.Metiers_description_directeur_pieces_h3_3'); }
    static get metiersMetiersDescriptionConseillerVentesCamionsH33() { return i18n.t('Metiers.Metiers_description_conseiller_ventes_camions_h3_3'); }
    static get metiersMetiersDescriptionConseillerVenteH35() { return i18n.t('Metiers.Metiers_description_conseiller_vente_h3_5'); }
    static get metiersMetiersDescriptionDirecteurVentesH36() { return i18n.t('Metiers.Metiers_description_directeur_ventes_h3_6'); }
    static get metiersMetiersDescriptionEstimateurH34() { return i18n.t('Metiers.Metiers_description_estimateur_h3_4'); }
    static get metiersMetiersDescriptionConseillerVentesCamionsP2() { return i18n.t('Metiers.Metiers_description_conseiller_ventes_camions_p2'); }
    static get metiersMetiersDescriptionConseillerVentesCamionsH34() { return i18n.t('Metiers.Metiers_description_conseiller_ventes_camions_h3_4'); }
    static get metiersMetiersDescriptionCommisReclamationsH36() { return i18n.t('Metiers.Metiers_description_commis_reclamations_h3_6'); }
    static get metiersMetiersDescriptionPreposeTransportClientsH33() { return i18n.t('Metiers.Metiers_description_prepose_transport_clients_h3_3'); }
    static get metiersMetiersJobTitleReceptionniste() { return i18n.t('Metiers.Metiers_jobTitle_receptionniste'); }
    static get metiersMetiersJobTitleDirecteurOperationsFixes() { return i18n.t('Metiers.Metiers_jobTitle_directeur_operations_fixes'); }
    static get metiersMetiersDescriptionCommisPiecesMecaniqueH35() { return i18n.t('Metiers.Metiers_description_commis_pieces_mecanique_h3_5'); }
    static get metiersMetiersDescriptionContremaitreAtelierCarrosserieH32() { return i18n.t('Metiers.Metiers_description_contremaitre_atelier_carrosserie_h3_2'); }
    static get metiersMetiersDescriptionDirecteurAdjointVentesH2() { return i18n.t('Metiers.Metiers_description_directeur_adjoint_ventes_h2'); }
    static get metiersMetiersDescriptionControleurComptableH32() { return i18n.t('Metiers.Metiers_description_controleur_comptable_h3_2'); }
    static get metiersMetiersDescriptionDirecteurAdjointVentesP2() { return i18n.t('Metiers.Metiers_description_directeur_adjoint_ventes_p2'); }
    static get metiersMetiersDescriptionPreposeServiceApresVenteH35() { return i18n.t('Metiers.Metiers_description_prepose_service_apres_vente_h3_5'); }
    static get metiersMetiersDescriptionCommisComptesRecevoirH35() { return i18n.t('Metiers.Metiers_description_commis_comptes_recevoir_h3_5'); }
    static get metiersMetiersDescriptionSecretaireVentesH31() { return i18n.t('Metiers.Metiers_description_secretaire_ventes_h3_1'); }
    static get metiersMetiersFilterBy() { return i18n.t('Metiers.Metiers_filter_by'); }
    static get metiersMetiersDescriptionCommisEntrepotH35() { return i18n.t('Metiers.Metiers_description_commis_entrepot_h3_5'); }
    static get metiersMetiersDescriptionAdministrateurGarantieP2() { return i18n.t('Metiers.Metiers_description_administrateur_garantie_p2'); }
    static get metiersMetiersDescriptionAdjointDirectionPiecesOperationsFixesH2() { return i18n.t('Metiers.Metiers_description_adjoint_direction_pieces_operations_fixes_h2'); }
    static get metiersMetiersDescriptionAgentDeLiaisonH33() { return i18n.t('Metiers.Metiers_description_agent_de_liaison_h3_3'); }
    static get metiersMetiersDescriptionServiceClienteleH37() { return i18n.t('Metiers.Metiers_description_service_clientele_h3_7'); }
    static get metiersMetiersJobTitleConseillerTechnique() { return i18n.t('Metiers.Metiers_jobTitle_conseiller_technique'); }
    static get metiersMetiersDescriptionCommisEntrepotH2() { return i18n.t('Metiers.Metiers_description_commis_entrepot_h2'); }
    static get metiersMetiersDescriptionControleurComptableH35() { return i18n.t('Metiers.Metiers_description_controleur_comptable_h3_5'); }
    static get metiersMetiersDescriptionPreposeTransportClientsH31() { return i18n.t('Metiers.Metiers_description_prepose_transport_clients_h3_1'); }
    static get metiersMetiersJobTitleMecanicienCompagnon() { return i18n.t('Metiers.Metiers_jobTitle_mecanicien_compagnon'); }
    static get metiersMetiersDescriptionMecanicienCompagnonH2() { return i18n.t('Metiers.Metiers_description_mecanicien_compagnon_h2'); }
    static get metiersMetiersDescriptionContremaitreAtelierMecaniqueP1() { return i18n.t('Metiers.Metiers_description_contremaitre_atelier_mecanique_p1'); }
    static get metiersMetiersDescriptionConseillerTechniqueH32() { return i18n.t('Metiers.Metiers_description_conseiller_technique_h3_2'); }
    static get metiersMetiersDescriptionEstimateurP2() { return i18n.t('Metiers.Metiers_description_estimateur_p2'); }
    static get metiersMetiersDescriptionVpFinancesH34() { return i18n.t('Metiers.Metiers_description_vp_finances_h3_4'); }
    static get metiersMetiersDescriptionRhH32() { return i18n.t('Metiers.Metiers_description_rh_h3_2'); }
    static get metiersMetiersDescriptionCarrossierH31() { return i18n.t('Metiers.Metiers_description_carrossier_h3_1'); }
    static get metiersMetiersDescriptionDirecteurCarrosserieH31() { return i18n.t('Metiers.Metiers_description_directeur_carrosserie_h3_1'); }
    static get metiersMetiersDescriptionAdjointAdministratifH2() { return i18n.t('Metiers.Metiers_description_adjoint_administratif_h2'); }
    static get metiersMetiersDescriptionSecretaireServicesApresVenteH36() { return i18n.t('Metiers.Metiers_description_secretaire_services_apres_vente_h3_6'); }
    static get metiersMetiersDescriptionVpFinancesH31() { return i18n.t('Metiers.Metiers_description_vp_finances_h3_1'); }
    static get metiersMetiersDescriptionResponsableSanteSecuriteH35() { return i18n.t('Metiers.Metiers_description_responsable_sante_securite_h3_5'); }
    static get metiersMetiersJobTitleDirecteurCarrosserie() { return i18n.t('Metiers.Metiers_jobTitle_directeur_carrosserie'); }
    static get metiersMetiersJobTitleDirecteurRenouvellements() { return i18n.t('Metiers.Metiers_jobTitle_directeur_renouvellements'); }
    static get metiersMetiersDescriptionCoordonnateurVentesH32() { return i18n.t('Metiers.Metiers_description_coordonnateur_ventes_h3_2'); }
    static get metiersMetiersDescriptionAgentDeLiaisonH35() { return i18n.t('Metiers.Metiers_description_agent_de_liaison_h3_5'); }
    static get metiersMetiersDescriptionAgentDeLiaisonP1() { return i18n.t('Metiers.Metiers_description_agent_de_liaison_p1'); }
    static get metiersMetiersDescriptionCommisPiecesCarrosserieH36() { return i18n.t('Metiers.Metiers_description_commis_pieces_carrosserie_h3_6'); }
    static get metiersMetiersDescriptionDirecteurVentesH32() { return i18n.t('Metiers.Metiers_description_directeur_ventes_h3_2'); }
    static get metiersMetiersDescriptionCoordonnateurVentesH36() { return i18n.t('Metiers.Metiers_description_coordonnateur_ventes_h3_6'); }
    static get metiersMetiersDescriptionDirecteurRenouvellementsH2() { return i18n.t('Metiers.Metiers_description_directeur_renouvellements_h2'); }
    static get metiersMetiersJobTitleContremaitreAtelierCarrosserie() { return i18n.t('Metiers.Metiers_jobTitle_contremaitre_atelier_carrosserie'); }
    static get metiersMetiersDescriptionTechnicienMecaniqueVehiculeLourdsH32() { return i18n.t('Metiers.Metiers_description_technicien_mecanique_vehicule_lourds_h3_2'); }
    static get metiersMetiersDescriptionCommisReclamationsP2() { return i18n.t('Metiers.Metiers_description_commis_reclamations_p2'); }
    static get metiersMetiersDescriptionSecretaireServicesApresVenteH37() { return i18n.t('Metiers.Metiers_description_secretaire_services_apres_vente_h3_7'); }
    static get metiersMetiersDescriptionSecretaireVentesP1() { return i18n.t('Metiers.Metiers_description_secretaire_ventes_p1'); }
    static get metiersMetiersDescriptionAgentLivraisonH34() { return i18n.t('Metiers.Metiers_description_agent_livraison_h3_4'); }
    static get metiersMetiersDescriptionDirecteurAdjointVentesH33() { return i18n.t('Metiers.Metiers_description_directeur_adjoint_ventes_h3_3'); }
    static get metiersMetiersDescriptionDirecteurApresVenteH34() { return i18n.t('Metiers.Metiers_description_directeur_apres_vente_h3_4'); }
    static get metiersMetiersDescriptionReceptionnisteH2() { return i18n.t('Metiers.Metiers_description_receptionniste_h2'); }
    static get metiersMetiersDescriptionCommisComptesRecevoirH32() { return i18n.t('Metiers.Metiers_description_commis_comptes_recevoir_h3_2'); }
    static get metiersMetiersJobTitleAdjointAdministratif() { return i18n.t('Metiers.Metiers_jobTitle_adjoint_administratif'); }
    static get metiersMetiersJobTitleCommisComptesRecevoir() { return i18n.t('Metiers.Metiers_jobTitle_commis_comptes_recevoir'); }
    static get metiersMetiersDescriptionControleurComptableH33() { return i18n.t('Metiers.Metiers_description_controleur_comptable_h3_3'); }
    static get metiersMetiersDescriptionCommisPiecesCarrosserieH37() { return i18n.t('Metiers.Metiers_description_commis_pieces_carrosserie_h3_7'); }
    static get metiersMetiersDescriptionDirecteurRenouvellementsH34() { return i18n.t('Metiers.Metiers_description_directeur_renouvellements_h3_4'); }
    static get metiersMetiersDescriptionDirecteurCommercialP1() { return i18n.t('Metiers.Metiers_description_directeur_commercial_p1'); }
    static get metiersMetiersDescriptionCommissionnaireH32() { return i18n.t('Metiers.Metiers_description_commissionnaire_h3_2'); }
    static get metiersMetiersDescriptionControleurComptableP2() { return i18n.t('Metiers.Metiers_description_controleur_comptable_p2'); }
    static get metiersMetiersDescriptionPreposeServiceApresVenteP1() { return i18n.t('Metiers.Metiers_description_prepose_service_apres_vente_p1'); }
    static get metiersMetiersDescriptionServiceClienteleH31() { return i18n.t('Metiers.Metiers_description_service_clientele_h3_1'); }
    static get metiersMetiersDescriptionConseillerVentesCamionsH36() { return i18n.t('Metiers.Metiers_description_conseiller_ventes_camions_h3_6'); }
    static get metiersMetiersDescriptionAdjointAdministratifH31() { return i18n.t('Metiers.Metiers_description_adjoint_administratif_h3_1'); }
    static get metiersMetiersDescriptionDirecteurOperationsFixesH33() { return i18n.t('Metiers.Metiers_description_directeur_operations_fixes_h3_3'); }
    static get metiersMetiersDescriptionPreposeTransportClientsP1() { return i18n.t('Metiers.Metiers_description_prepose_transport_clients_p1'); }
    static get metiersMetiersDescriptionAdministrateurGarantieH35() { return i18n.t('Metiers.Metiers_description_administrateur_garantie_h3_5'); }
    static get metiersMetiersDescriptionAdjointDirectionPiecesOperationsFixesH31() { return i18n.t('Metiers.Metiers_description_adjoint_direction_pieces_operations_fixes_h3_1'); }
    static get metiersMetiersDescriptionPreposeEsthetiqueP1() { return i18n.t('Metiers.Metiers_description_prepose_esthetique_p1'); }
    static get metiersMetiersDescriptionCommissionnaireH2() { return i18n.t('Metiers.Metiers_description_commissionnaire_h2'); }
    static get metiersMetiersDescriptionReceptionnisteH34() { return i18n.t('Metiers.Metiers_description_receptionniste_h3_4'); }
    static get metiersMetiersDescriptionCommisComptableP2() { return i18n.t('Metiers.Metiers_description_commis_comptable_p2'); }
    static get metiersMetiersDescriptionResponsableSanteSecuriteP2() { return i18n.t('Metiers.Metiers_description_responsable_sante_securite_p2'); }
    static get metiersMetiersDescriptionPreposeTransportClientsH32() { return i18n.t('Metiers.Metiers_description_prepose_transport_clients_h3_2'); }
    static get metiersMetiersDescriptionAdjointDirectionVentesH34() { return i18n.t('Metiers.Metiers_description_adjoint_direction_ventes_h3_4'); }
    static get metiersMetiersDescriptionVpFinancesH36() { return i18n.t('Metiers.Metiers_description_vp_finances_h3_6'); }
    static get metiersMetiersDescriptionAgentDeLiaisonH31() { return i18n.t('Metiers.Metiers_description_agent_de_liaison_h3_1'); }
    static get metiersMetiersJobTitleConseillerVente() { return i18n.t('Metiers.Metiers_jobTitle_conseiller_vente'); }
    static get metiersMetiersDescriptionConseillerVenteP1() { return i18n.t('Metiers.Metiers_description_conseiller_vente_p1'); }
    static get metiersMetiersDescriptionDgVentesH35() { return i18n.t('Metiers.Metiers_description_dg_ventes_h3_5'); }
    static get metiersMetiersDescriptionPreposeLivraisonPiecesH34() { return i18n.t('Metiers.Metiers_description_prepose_livraison_pieces_h3_4'); }
    static get metiersMetiersJobTitleCarrossier() { return i18n.t('Metiers.Metiers_jobTitle_carrossier'); }
    static get metiersMetiersDescriptionServiceClienteleH32() { return i18n.t('Metiers.Metiers_description_service_clientele_h3_2'); }
    static get metiersMetiersDescriptionEstimateurH32() { return i18n.t('Metiers.Metiers_description_estimateur_h3_2'); }
    static get metiersMetiersDescriptionDgVentesH36() { return i18n.t('Metiers.Metiers_description_dg_ventes_h3_6'); }
    static get metiersMetiersJobTitleControleurComptable() { return i18n.t('Metiers.Metiers_jobTitle_controleur_comptable'); }
    static get metiersMetiersJobTitlePreposeTransportClients() { return i18n.t('Metiers.Metiers_jobTitle_prepose_transport_clients'); }
    static get metiersMetiersDescriptionCommisPiecesMecaniqueH36() { return i18n.t('Metiers.Metiers_description_commis_pieces_mecanique_h3_6'); }
    static get metiersMetiersDescriptionCommisComptesRecevoirH34() { return i18n.t('Metiers.Metiers_description_commis_comptes_recevoir_h3_4'); }
    static get metiersMetiersDescriptionPreposeServiceApresVenteH31() { return i18n.t('Metiers.Metiers_description_prepose_service_apres_vente_h3_1'); }
    static get metiersMetiersDescriptionAdjointDirectionVentesH31() { return i18n.t('Metiers.Metiers_description_adjoint_direction_ventes_h3_1'); }
    static get metiersMetiersDescriptionDgH36() { return i18n.t('Metiers.Metiers_description_dg_h3_6'); }
    static get metiersMetiersDescriptionTechnicienMecaniqueVehiculeLourdsH2() { return i18n.t('Metiers.Metiers_description_technicien_mecanique_vehicule_lourds_h2'); }
    static get metiersMetiersJobTitleCoordonnateurVentes() { return i18n.t('Metiers.Metiers_jobTitle_coordonnateur_ventes'); }
    static get metiersMetiersDescriptionEstimateurH33() { return i18n.t('Metiers.Metiers_description_estimateur_h3_3'); }
    static get metiersMetiersCategoryAutres() { return i18n.t('Metiers.Metiers_category_autres'); }
    static get metiersMetiersDescriptionCommisReclamationsH34() { return i18n.t('Metiers.Metiers_description_commis_reclamations_h3_4'); }
    static get metiersMetiersDescriptionCommisPiecesMecaniqueH33() { return i18n.t('Metiers.Metiers_description_commis_pieces_mecanique_h3_3'); }
    static get metiersMetiersDescriptionConseillerTechniqueH31() { return i18n.t('Metiers.Metiers_description_conseiller_technique_h3_1'); }
    static get metiersMetiersDescriptionContremaitreAtelierMecaniqueP2() { return i18n.t('Metiers.Metiers_description_contremaitre_atelier_mecanique_p2'); }
    static get metiersMetiersDescriptionMecanicienCompagnonH32() { return i18n.t('Metiers.Metiers_description_mecanicien_compagnon_h3_2'); }
    static get metiersMetiersDescriptionPeintreH34() { return i18n.t('Metiers.Metiers_description_peintre_h3_4'); }
    static get metiersMetiersDescriptionContremaitreAtelierCarrosserieH35() { return i18n.t('Metiers.Metiers_description_contremaitre_atelier_carrosserie_h3_5'); }
    static get metiersMetiersDescriptionSecretaireVentesH34() { return i18n.t('Metiers.Metiers_description_secretaire_ventes_h3_4'); }
    static get metiersMetiersDescriptionDirecteurCarrosserieH32() { return i18n.t('Metiers.Metiers_description_directeur_carrosserie_h3_2'); }
    static get metiersMetiersJobTitleCommisPiecesMecanique() { return i18n.t('Metiers.Metiers_jobTitle_commis_pieces_mecanique'); }
    static get metiersMetiersDescriptionSecretaireServicesApresVenteP2() { return i18n.t('Metiers.Metiers_description_secretaire_services_apres_vente_p2'); }
    static get metiersMetiersHeaderCta() { return i18n.t('Metiers.Metiers_header_cta'); }
    static get metiersMetiersDescriptionTechnicienMecaniqueVehiculeLourdsH35() { return i18n.t('Metiers.Metiers_description_technicien_mecanique_vehicule_lourds_h3_5'); }
    static get metiersMetiersDescriptionVpFinancesH35() { return i18n.t('Metiers.Metiers_description_vp_finances_h3_5'); }
    static get metiersMetiersDescriptionTechnicienMecaniqueVehiculeLourdsP1() { return i18n.t('Metiers.Metiers_description_technicien_mecanique_vehicule_lourds_p1'); }
    static get metiersMetiersDescriptionAdjointAdministratifH33() { return i18n.t('Metiers.Metiers_description_adjoint_administratif_h3_3'); }
    static get metiersMetiersDescriptionPersonneCourH36() { return i18n.t('Metiers.Metiers_description_personne_cour_h3_6'); }
    static get metiersMetiersDescriptionCommisEntrepotH31() { return i18n.t('Metiers.Metiers_description_commis_entrepot_h3_1'); }
    static get metiersMetiersDescriptionCoordonnateurVentesH34() { return i18n.t('Metiers.Metiers_description_coordonnateur_ventes_h3_4'); }
    static get metiersMetiersDescriptionCarrossierH35() { return i18n.t('Metiers.Metiers_description_carrossier_h3_5'); }
    static get metiersMetiersJobTitleDirecteurPieces() { return i18n.t('Metiers.Metiers_jobTitle_directeur_pieces'); }
    static get metiersMetiersDescriptionPersonneCourH34() { return i18n.t('Metiers.Metiers_description_personne_cour_h3_4'); }
    static get metiersMetiersDescriptionCarrossierP1() { return i18n.t('Metiers.Metiers_description_carrossier_p1'); }
    static get metiersMetiersDescriptionDirecteurCommercialH36() { return i18n.t('Metiers.Metiers_description_directeur_commercial_h3_6'); }
    static get metiersMetiersDescriptionCoordonnateurVentesP2() { return i18n.t('Metiers.Metiers_description_coordonnateur_ventes_p2'); }
    static get metiersMetiersDescriptionDirecteurVentesP2() { return i18n.t('Metiers.Metiers_description_directeur_ventes_p2'); }
    static get metiersMetiersDescriptionCommisPiecesCarrosserieP2() { return i18n.t('Metiers.Metiers_description_commis_pieces_carrosserie_p2'); }
    static get metiersMetiersDescriptionContremaitreAtelierMecaniqueH31() { return i18n.t('Metiers.Metiers_description_contremaitre_atelier_mecanique_h3_1'); }
    static get metiersMetiersDescriptionPersonneCourH33() { return i18n.t('Metiers.Metiers_description_personne_cour_h3_3'); }
    static get metiersMetiersDescriptionDirecteurCarrosserieH33() { return i18n.t('Metiers.Metiers_description_directeur_carrosserie_h3_3'); }
    static get metiersMetiersDescriptionPeintreH33() { return i18n.t('Metiers.Metiers_description_peintre_h3_3'); }
    static get metiersMetiersDescriptionMecanicienApprentiH2() { return i18n.t('Metiers.Metiers_description_mecanicien_apprenti_h2'); }
    static get metiersMetiersDescriptionPreposeTransportClientsH2() { return i18n.t('Metiers.Metiers_description_prepose_transport_clients_h2'); }
    static get metiersMetiersDescriptionMecanicienCompagnonH31() { return i18n.t('Metiers.Metiers_description_mecanicien_compagnon_h3_1'); }
    static get metiersMetiersCategoryServicesAdminisitratifs() { return i18n.t('Metiers.Metiers_category_services_adminisitratifs'); }
    static get metiersMetiersDescriptionResponsableSanteSecuriteH2() { return i18n.t('Metiers.Metiers_description_responsable_sante_securite_h2'); }
    static get metiersMetiersDescriptionPreposeServiceApresVenteH32() { return i18n.t('Metiers.Metiers_description_prepose_service_apres_vente_h3_2'); }
    static get metiersMetiersDescriptionContremaitreAtelierCarrosserieP2() { return i18n.t('Metiers.Metiers_description_contremaitre_atelier_carrosserie_p2'); }
    static get metiersMetiersDescriptionDirecteurAdjointVentesH35() { return i18n.t('Metiers.Metiers_description_directeur_adjoint_ventes_h3_5'); }
    static get metiersMetiersDescriptionMecanicienCompagnonH34() { return i18n.t('Metiers.Metiers_description_mecanicien_compagnon_h3_4'); }
    static get metiersMetiersDescriptionAgentLivraisonH31() { return i18n.t('Metiers.Metiers_description_agent_livraison_h3_1'); }
    static get metiersMetiersDescriptionCommisComptableH2() { return i18n.t('Metiers.Metiers_description_commis_comptable_h2'); }
    static get metiersMetiersDescriptionMecanicienApprentiH36() { return i18n.t('Metiers.Metiers_description_mecanicien_apprenti_h3_6'); }
    static get metiersMetiersDescriptionContremaitreAtelierMecaniqueH34() { return i18n.t('Metiers.Metiers_description_contremaitre_atelier_mecanique_h3_4'); }
    static get metiersMetiersDescriptionCommisComptesRecevoirP1() { return i18n.t('Metiers.Metiers_description_commis_comptes_recevoir_p1'); }
    static get metiersMetiersDescriptionDirecteurVentesH35() { return i18n.t('Metiers.Metiers_description_directeur_ventes_h3_5'); }
    static get metiersMetiersDescriptionAgentLivraisonH2() { return i18n.t('Metiers.Metiers_description_agent_livraison_h2'); }
    static get metiersMetiersJobTitleAgentDeLiaison() { return i18n.t('Metiers.Metiers_jobTitle_agent_de_liaison'); }
    static get metiersMetiersDescriptionTechnicienMecaniqueVehiculeLourdsH31() { return i18n.t('Metiers.Metiers_description_technicien_mecanique_vehicule_lourds_h3_1'); }
    static get metiersMetiersDescriptionDirecteurCommercialP2() { return i18n.t('Metiers.Metiers_description_directeur_commercial_p2'); }
    static get metiersMetiersDescriptionAdministrateurGarantieH2() { return i18n.t('Metiers.Metiers_description_administrateur_garantie_h2'); }
    static get metiersMetiersDescriptionDirecteurAdjointVentesH31() { return i18n.t('Metiers.Metiers_description_directeur_adjoint_ventes_h3_1'); }
    static get metiersMetiersDescriptionAdjointDirectionVentesH2() { return i18n.t('Metiers.Metiers_description_adjoint_direction_ventes_h2'); }
    static get metiersMetiersDescriptionPreposeServiceApresVenteH34() { return i18n.t('Metiers.Metiers_description_prepose_service_apres_vente_h3_4'); }
    static get metiersMetiersDescriptionDgP1() { return i18n.t('Metiers.Metiers_description_dg_p1'); }
    static get metiersMetiersDescriptionDirecteurVentesH33() { return i18n.t('Metiers.Metiers_description_directeur_ventes_h3_3'); }
    static get metiersMetiersDescriptionEstimateurH36() { return i18n.t('Metiers.Metiers_description_estimateur_h3_6'); }
    static get metiersMetiersDescriptionAgentLivraisonP1() { return i18n.t('Metiers.Metiers_description_agent_livraison_p1'); }
    static get metiersMetiersDescriptionSecretaireServicesApresVenteH32() { return i18n.t('Metiers.Metiers_description_secretaire_services_apres_vente_h3_2'); }
    static get metiersMetiersJobTitleTechnicienMecaniqueVehiculeLourds() { return i18n.t('Metiers.Metiers_jobTitle_technicien_mecanique_vehicule_lourds'); }
    static get metiersMetiersDescriptionDirecteurVentesH31() { return i18n.t('Metiers.Metiers_description_directeur_ventes_h3_1'); }
    static get metiersMetiersDescriptionDirecteurCommercialH33() { return i18n.t('Metiers.Metiers_description_directeur_commercial_h3_3'); }
    static get metiersMetiersDescriptionMecanicienCompagnonH36() { return i18n.t('Metiers.Metiers_description_mecanicien_compagnon_h3_6'); }
    static get metiersMetiersDescriptionRhH31() { return i18n.t('Metiers.Metiers_description_rh_h3_1'); }
    static get metiersMetiersDescriptionDgH31() { return i18n.t('Metiers.Metiers_description_dg_h3_1'); }
    static get metiersMetiersDescriptionControleurComptableP1() { return i18n.t('Metiers.Metiers_description_controleur_comptable_p1'); }
    static get metiersMetiersDescriptionAgentLivraisonH33() { return i18n.t('Metiers.Metiers_description_agent_livraison_h3_3'); }
    static get metiersMetiersDescriptionContremaitreAtelierMecaniqueH33() { return i18n.t('Metiers.Metiers_description_contremaitre_atelier_mecanique_h3_3'); }
    static get metiersMetiersDescriptionDgH34() { return i18n.t('Metiers.Metiers_description_dg_h3_4'); }
    static get metiersMetiersDescriptionCommisEntrepotH34() { return i18n.t('Metiers.Metiers_description_commis_entrepot_h3_4'); }
    static get metiersMetiersJobTitleSecretairesVentes() { return i18n.t('Metiers.Metiers_jobTitle_secretaires_ventes'); }
    static get metiersMetiersDescriptionCommisReclamationsH33() { return i18n.t('Metiers.Metiers_description_commis_reclamations_h3_3'); }
    static get metiersMetiersDescriptionDirecteurRenouvellementsH31() { return i18n.t('Metiers.Metiers_description_directeur_renouvellements_h3_1'); }
    static get metiersMetiersJobTitleContremaitreAtelierMecanique() { return i18n.t('Metiers.Metiers_jobTitle_contremaitre_atelier_mecanique'); }
    static get metiersMetiersDescriptionAgentDeLiaisonH32() { return i18n.t('Metiers.Metiers_description_agent_de_liaison_h3_2'); }
    static get metiersMetiersDescriptionPeintreH31() { return i18n.t('Metiers.Metiers_description_peintre_h3_1'); }
    static get metiersMetiersDescriptionPreposeServiceApresVenteP2() { return i18n.t('Metiers.Metiers_description_prepose_service_apres_vente_p2'); }
    static get metiersMetiersDescriptionContremaitreAtelierCarrosserieH33() { return i18n.t('Metiers.Metiers_description_contremaitre_atelier_carrosserie_h3_3'); }
    static get metiersMetiersDescriptionSecretaireServicesApresVenteH31() { return i18n.t('Metiers.Metiers_description_secretaire_services_apres_vente_h3_1'); }
    static get metiersMetiersDescriptionCommisPiecesMecaniqueP1() { return i18n.t('Metiers.Metiers_description_commis_pieces_mecanique_p1'); }
    static get metiersMetiersDescriptionCommisReclamationsH2() { return i18n.t('Metiers.Metiers_description_commis_reclamations_h2'); }
    static get metiersMetiersDescriptionDirecteurVentesP1() { return i18n.t('Metiers.Metiers_description_directeur_ventes_p1'); }
    static get metiersMetiersDescriptionMecanicienApprentiH34() { return i18n.t('Metiers.Metiers_description_mecanicien_apprenti_h3_4'); }
    static get metiersMetiersDescriptionDirecteurOperationsFixesH31() { return i18n.t('Metiers.Metiers_description_directeur_operations_fixes_h3_1'); }
    static get metiersMetiersJobTitlePeintre() { return i18n.t('Metiers.Metiers_jobTitle_peintre'); }
    static get metiersMetiersDescriptionCarrossierH32() { return i18n.t('Metiers.Metiers_description_carrossier_h3_2'); }
    static get metiersMetiersDescriptionCommisReclamationsH31() { return i18n.t('Metiers.Metiers_description_commis_reclamations_h3_1'); }
    static get metiersMetiersDescriptionPreposeEsthetiqueH33() { return i18n.t('Metiers.Metiers_description_prepose_esthetique_h3_3'); }
    static get metiersMetiersDescriptionAdjointDirectionVentesH33() { return i18n.t('Metiers.Metiers_description_adjoint_direction_ventes_h3_3'); }
    static get metiersMetiersDescriptionDirecteurCommercialH31() { return i18n.t('Metiers.Metiers_description_directeur_commercial_h3_1'); }
    static get metiersMetiersJobTitleDirecteurAdjointVentes() { return i18n.t('Metiers.Metiers_jobTitle_directeur_adjoint_ventes'); }
    static get metiersMetiersDescriptionPersonneCourH2() { return i18n.t('Metiers.Metiers_description_personne_cour_h2'); }
    static get metiersMetiersDescriptionCommisComptableH36() { return i18n.t('Metiers.Metiers_description_commis_comptable_h3_6'); }
    static get metiersMetiersJobTitleDirecteurVentes() { return i18n.t('Metiers.Metiers_jobTitle_directeur_ventes'); }
    static get metiersMetiersDescriptionCommisReclamationsH35() { return i18n.t('Metiers.Metiers_description_commis_reclamations_h3_5'); }
    static get metiersMetiersHeaderTitleRow2Word1() { return i18n.t('Metiers.Metiers_header_title_row2_word1'); }
    static get metiersMetiersDescriptionSecretaireServicesApresVenteH2() { return i18n.t('Metiers.Metiers_description_secretaire_services_apres_vente_h2'); }
    static get metiersMetiersDescriptionCommissionnaireP2() { return i18n.t('Metiers.Metiers_description_commissionnaire_p2'); }
    static get metiersMetiersDescriptionRhH2() { return i18n.t('Metiers.Metiers_description_rh_h2'); }
    static get metiersMetiersDescriptionPreposeTransportClientsH35() { return i18n.t('Metiers.Metiers_description_prepose_transport_clients_h3_5'); }
    static get metiersMetiersJobTitlePreposeLivraisonPieces() { return i18n.t('Metiers.Metiers_jobTitle_prepose_livraison_pieces'); }
    static get metiersMetiersDescriptionMecanicienApprentiP1() { return i18n.t('Metiers.Metiers_description_mecanicien_apprenti_p1'); }
    static get metiersMetiersDescriptionPersonneCourH35() { return i18n.t('Metiers.Metiers_description_personne_cour_h3_5'); }
    static get metiersMetiersDescriptionSecretaireServicesApresVenteH35() { return i18n.t('Metiers.Metiers_description_secretaire_services_apres_vente_h3_5'); }
    static get metiersMetiersDescriptionCommisComptableH32() { return i18n.t('Metiers.Metiers_description_commis_comptable_h3_2'); }
    static get metiersMetiersDescriptionContremaitreAtelierCarrosserieH34() { return i18n.t('Metiers.Metiers_description_contremaitre_atelier_carrosserie_h3_4'); }
    static get metiersMetiersDescriptionDirecteurOperationsFixesH36() { return i18n.t('Metiers.Metiers_description_directeur_operations_fixes_h3_6'); }
    static get metiersMetiersDecouverteTitle() { return i18n.t('Metiers.Metiers_decouverte_title'); }
    static get metiersMetiersDescriptionMecanicienApprentiP2() { return i18n.t('Metiers.Metiers_description_mecanicien_apprenti_p2'); }
    static get metiersMetiersDescriptionConseillerVentesCamionsH35() { return i18n.t('Metiers.Metiers_description_conseiller_ventes_camions_h3_5'); }
    static get metiersMetiersDescriptionPreposeLivraisonPiecesP1() { return i18n.t('Metiers.Metiers_description_prepose_livraison_pieces_p1'); }
    static get metiersMetiersDescriptionTechnicienMecaniqueVehiculeLourdsH36() { return i18n.t('Metiers.Metiers_description_technicien_mecanique_vehicule_lourds_h3_6'); }
    static get metiersMetiersDescriptionPreposeEsthetiqueH36() { return i18n.t('Metiers.Metiers_description_prepose_esthetique_h3_6'); }
    static get metiersMetiersJobTitleDg() { return i18n.t('Metiers.Metiers_jobTitle_dg'); }
    static get metiersMetiersDescriptionCarrossierH33() { return i18n.t('Metiers.Metiers_description_carrossier_h3_3'); }
    static get metiersMetiersJobTitlePreposeEsthetique() { return i18n.t('Metiers.Metiers_jobTitle_prepose_esthetique'); }
    static get metiersMetiersDescriptionDirecteurOperationsFixesH2() { return i18n.t('Metiers.Metiers_description_directeur_operations_fixes_h2'); }
    static get metiersMetiersDescriptionRhH34() { return i18n.t('Metiers.Metiers_description_rh_h3_4'); }
    static get metiersMetiersDescriptionDirecteurOperationsFixesH34() { return i18n.t('Metiers.Metiers_description_directeur_operations_fixes_h3_4'); }
    static get metiersMetiersDescriptionConseillerVenteH2() { return i18n.t('Metiers.Metiers_description_conseiller_vente_h2'); }
    static get metiersMetiersDescriptionEstimateurH35() { return i18n.t('Metiers.Metiers_description_estimateur_h3_5'); }
    static get metiersMetiersDescriptionDirecteurVentesH2() { return i18n.t('Metiers.Metiers_description_directeur_ventes_h2'); }
    static get metiersMetiersDescriptionRhH35() { return i18n.t('Metiers.Metiers_description_rh_h3_5'); }
    static get metiersMetiersDescriptionAdministrateurGarantieP1() { return i18n.t('Metiers.Metiers_description_administrateur_garantie_p1'); }
    static get metiersMetiersDescriptionCommisPiecesMecaniqueH31() { return i18n.t('Metiers.Metiers_description_commis_pieces_mecanique_h3_1'); }
    static get metiersMetiersDescriptionSecretaireServicesApresVenteH33() { return i18n.t('Metiers.Metiers_description_secretaire_services_apres_vente_h3_3'); }
    static get metiersMetiersDescriptionMecanicienApprentiH31() { return i18n.t('Metiers.Metiers_description_mecanicien_apprenti_h3_1'); }
    static get metiersMetiersDescriptionTechnicienMecaniqueVehiculeLourdsH33() { return i18n.t('Metiers.Metiers_description_technicien_mecanique_vehicule_lourds_h3_3'); }
    static get metiersMetiersDescriptionDirecteurRenouvellementsP1() { return i18n.t('Metiers.Metiers_description_directeur_renouvellements_p1'); }
    static get metiersMetiersDescriptionDirecteurRenouvellementsH36() { return i18n.t('Metiers.Metiers_description_directeur_renouvellements_h3_6'); }
    static get metiersMetiersDescriptionAdjointDirectionPiecesOperationsFixesH32() { return i18n.t('Metiers.Metiers_description_adjoint_direction_pieces_operations_fixes_h3_2'); }
    static get metiersMetiersDescriptionPreposeLivraisonPiecesH36() { return i18n.t('Metiers.Metiers_description_prepose_livraison_pieces_h3_6'); }
    static get metiersMetiersDescriptionDirecteurApresVenteH35() { return i18n.t('Metiers.Metiers_description_directeur_apres_vente_h3_5'); }
    static get metiersMetiersDescriptionSecretaireServicesApresVenteH34() { return i18n.t('Metiers.Metiers_description_secretaire_services_apres_vente_h3_4'); }
    static get metiersMetiersFilterJobForYou() { return i18n.t('Metiers.Metiers_filter_job_for_you'); }
    static get metiersMetiersDescriptionCoordonnateurVentesH31() { return i18n.t('Metiers.Metiers_description_coordonnateur_ventes_h3_1'); }
    static get metiersMetiersDescriptionContremaitreAtelierCarrosserieP1() { return i18n.t('Metiers.Metiers_description_contremaitre_atelier_carrosserie_p1'); }
    static get metiersMetiersDescriptionDirecteurCarrosserieP1() { return i18n.t('Metiers.Metiers_description_directeur_carrosserie_p1'); }
    static get metiersMetiersJobTitleDirecteurCommercial() { return i18n.t('Metiers.Metiers_jobTitle_directeur_commercial'); }
    static get metiersMetiersJobTitleEstimateur() { return i18n.t('Metiers.Metiers_jobTitle_estimateur'); }
    static get metiersMetiersDescriptionContremaitreAtelierMecaniqueH35() { return i18n.t('Metiers.Metiers_description_contremaitre_atelier_mecanique_h3_5'); }
    static get metiersMetiersJobTitleRh() { return i18n.t('Metiers.Metiers_jobTitle_rh'); }
    static get metiersMetiersDescriptionPreposeEsthetiqueH31() { return i18n.t('Metiers.Metiers_description_prepose_esthetique_h3_1'); }
    static get metiersMetiersDescriptionAdjointAdministratifH35() { return i18n.t('Metiers.Metiers_description_adjoint_administratif_h3_5'); }
    static get metiersMetiersHeaderTitleRow2Word2() { return i18n.t('Metiers.Metiers_header_title_row2_word2'); }
    static get metiersMetiersCategoryServicesVentes() { return i18n.t('Metiers.Metiers_category_services_ventes'); }
    static get metiersMetiersDescriptionConseillerVentesCamionsH32() { return i18n.t('Metiers.Metiers_description_conseiller_ventes_camions_h3_2'); }
    static get metiersMetiersJobTitleServiceClientele() { return i18n.t('Metiers.Metiers_jobTitle_service_clientele'); }
    static get metiersMetiersDescriptionAdjointDirectionPiecesOperationsFixesH36() { return i18n.t('Metiers.Metiers_description_adjoint_direction_pieces_operations_fixes_h3_6'); }
    static get metiersMetiersHeaderTitleRow1() { return i18n.t('Metiers.Metiers_header_title_row1'); }
    static get metiersMetiersDescriptionCommisPiecesCarrosserieH31() { return i18n.t('Metiers.Metiers_description_commis_pieces_carrosserie_h3_1'); }
    static get metiersMetiersDescriptionConseillerVenteCamionsP1() { return i18n.t('Metiers.Metiers_description_conseiller_vente_camions_p1'); }
    static get metiersMetiersDescriptionReceptionnisteP2() { return i18n.t('Metiers.Metiers_description_receptionniste_p2'); }
    static get metiersMetiersDescriptionDirecteurAdjointVentesH32() { return i18n.t('Metiers.Metiers_description_directeur_adjoint_ventes_h3_2'); }
    static get metiersMetiersDescriptionDgH32() { return i18n.t('Metiers.Metiers_description_dg_h3_2'); }
    static get metiersMetiersCategoryMecanique() { return i18n.t('Metiers.Metiers_category_mecanique'); }
    static get metiersMetiersDescriptionControleurComptableH2() { return i18n.t('Metiers.Metiers_description_controleur_comptable_h2'); }
    static get metiersMetiersDescriptionPersonneCourH31() { return i18n.t('Metiers.Metiers_description_personne_cour_h3_1'); }
    static get metiersMetiersDescriptionCommisPiecesMecaniqueH34() { return i18n.t('Metiers.Metiers_description_commis_pieces_mecanique_h3_4'); }
    static get metiersMetiersCategoryCarrosserie() { return i18n.t('Metiers.Metiers_category_carrosserie'); }
    static get metiersMetiersDescriptionAdjointAdministratifP2() { return i18n.t('Metiers.Metiers_description_adjoint_administratif_p2'); }
    static get metiersMetiersJobTitleResponsableSanteSecurite() { return i18n.t('Metiers.Metiers_jobTitle_responsable_sante_securite'); }
    static get metiersMetiersDescriptionCommisPiecesCarrosserieH33() { return i18n.t('Metiers.Metiers_description_commis_pieces_carrosserie_h3_3'); }
    static get metiersMetiersJobTitleCommisComptable() { return i18n.t('Metiers.Metiers_jobTitle_commis_comptable'); }
    static get metiersMetiersDescriptionCommisReclamationsH32() { return i18n.t('Metiers.Metiers_description_commis_reclamations_h3_2'); }
    static get metiersMetiersDescriptionReceptionnisteH35() { return i18n.t('Metiers.Metiers_description_receptionniste_h3_5'); }
    static get metiersMetiersDescriptionDirecteurCommercialH34() { return i18n.t('Metiers.Metiers_description_directeur_commercial_h3_4'); }
    static get metiersMetiersDescriptionReceptionnisteH32() { return i18n.t('Metiers.Metiers_description_receptionniste_h3_2'); }
    static get metiersMetiersDescriptionCommisReclamationsP1() { return i18n.t('Metiers.Metiers_description_commis_reclamations_p1'); }
    static get metiersMetiersDescriptionEstimateurH31() { return i18n.t('Metiers.Metiers_description_estimateur_h3_1'); }
    static get metiersMetiersDescriptionPreposeLivraisonPiecesH2() { return i18n.t('Metiers.Metiers_description_prepose_livraison_pieces_h2'); }
    static get metiersMetiersJobTitlePersonneCour() { return i18n.t('Metiers.Metiers_jobTitle_personne_cour'); }
    static get metiersMetiersDescriptionServiceClienteleH35() { return i18n.t('Metiers.Metiers_description_service_clientele_h3_5'); }
    static get metiersMetiersDescriptionCommisPiecesCarrosserieH34() { return i18n.t('Metiers.Metiers_description_commis_pieces_carrosserie_h3_4'); }
    static get metiersMetiersDescriptionDirecteurCommercialH2() { return i18n.t('Metiers.Metiers_description_directeur_commercial_h2'); }
    static get metiersMetiersDescriptionDirecteurApresVenteH33() { return i18n.t('Metiers.Metiers_description_directeur_apres_vente_h3_3'); }
    static get metiersMetiersDescriptionCommisPiecesCarrosserieP1() { return i18n.t('Metiers.Metiers_description_commis_pieces_carrosserie_p1'); }
    static get metiersMetiersDescriptionSecretaireVentesH32() { return i18n.t('Metiers.Metiers_description_secretaire_ventes_h3_2'); }
    static get metiersMetiersDescriptionDgVentesH31() { return i18n.t('Metiers.Metiers_description_dg_ventes_h3_1'); }
    static get metiersMetiersDescriptionPreposeServiceApresVenteH2() { return i18n.t('Metiers.Metiers_description_prepose_service_apres_vente_h2'); }
    static get metiersMetiersDescriptionVpFinancesP2() { return i18n.t('Metiers.Metiers_description_vp_finances_p2'); }
    static get metiersMetiersDescriptionDgH35() { return i18n.t('Metiers.Metiers_description_dg_h3_5'); }
    static get metiersMetiersDescriptionPreposeEsthetiqueH34() { return i18n.t('Metiers.Metiers_description_prepose_esthetique_h3_4'); }
    static get metiersMetiersDescriptionContremaitreAtelierMecaniqueH2() { return i18n.t('Metiers.Metiers_description_contremaitre_atelier_mecanique_h2'); }
    static get metiersMetiersJobTitleAdministrateurGarantie() { return i18n.t('Metiers.Metiers_jobTitle_administrateur_garantie'); }
    static get metiersMetiersDescriptionDgVentesH2() { return i18n.t('Metiers.Metiers_description_dg_ventes_h2'); }
    static get metiersMetiersDescriptionAdjointDirectionVentesH37() { return i18n.t('Metiers.Metiers_description_adjoint_direction_ventes_h3_7'); }
    static get metiersMetiersDescriptionPeintreH2() { return i18n.t('Metiers.Metiers_description_peintre_h2'); }
    static get metiersMetiersDescriptionAdministrateurGarantieH36() { return i18n.t('Metiers.Metiers_description_administrateur_garantie_h3_6'); }
    static get metiersMetiersDescriptionConseillerVenteH33() { return i18n.t('Metiers.Metiers_description_conseiller_vente_h3_3'); }
    static get metiersMetiersDescriptionSecretaireVentesH2() { return i18n.t('Metiers.Metiers_description_secretaire_ventes_h2'); }
    static get metiersMetiersDescriptionCommisPiecesCarrosserieH32() { return i18n.t('Metiers.Metiers_description_commis_pieces_carrosserie_h3_2'); }
    static get metiersMetiersDescriptionEstimateurH2() { return i18n.t('Metiers.Metiers_description_estimateur_h2'); }
    static get metiersMetiersDescriptionCommisPiecesMecaniqueH2() { return i18n.t('Metiers.Metiers_description_commis_pieces_mecanique_h2'); }
    static get metiersMetiersDescriptionCommisEntrepotH32() { return i18n.t('Metiers.Metiers_description_commis_entrepot_h3_2'); }
    static get metiersMetiersDescriptionPreposeTransportClientsH34() { return i18n.t('Metiers.Metiers_description_prepose_transport_clients_h3_4'); }
    static get metiersMetiersFilterAllCategories() { return i18n.t('Metiers.Metiers_filter_all_categories'); }
    static get metiersMetiersDescriptionPreposeLivraisonPiecesH33() { return i18n.t('Metiers.Metiers_description_prepose_livraison_pieces_h3_3'); }
    static get metiersMetiersDescriptionDgP2() { return i18n.t('Metiers.Metiers_description_dg_p2'); }
    static get metiersMetiersJobTitleSecretaireServicesApresVente() { return i18n.t('Metiers.Metiers_jobTitle_secretaire_services_apres_vente'); }
    static get metiersMetiersDescriptionPeintreH32() { return i18n.t('Metiers.Metiers_description_peintre_h3_2'); }
    static get metiersMetiersDescriptionDirecteurApresVenteP1() { return i18n.t('Metiers.Metiers_description_directeur_apres_vente_p1'); }
    static get metiersMetiersDescriptionDirecteurApresVenteP2() { return i18n.t('Metiers.Metiers_description_directeur_apres_vente_p2'); }
    static get metiersMetiersDescriptionPreposeServiceApresVenteH36() { return i18n.t('Metiers.Metiers_description_prepose_service_apres_vente_h3_6'); }
    static get metiersMetiersDescriptionContremaitreAtelierMecaniqueH32() { return i18n.t('Metiers.Metiers_description_contremaitre_atelier_mecanique_h3_2'); }
    static get metiersMetiersDescriptionContremaitreAtelierMecaniqueH36() { return i18n.t('Metiers.Metiers_description_contremaitre_atelier_mecanique_h3_6'); }
    static get metiersMetiersDescriptionDirecteurPiecesP1() { return i18n.t('Metiers.Metiers_description_directeur_pieces_p1'); }
    static get metiersMetiersDescriptionAdjointDirectionVentesP1() { return i18n.t('Metiers.Metiers_description_adjoint_direction_ventes_p1'); }
    static get metiersMetiersDescriptionDirecteurOperationsFixesP2() { return i18n.t('Metiers.Metiers_description_directeur_operations_fixes_p2'); }
    static get metiersMetiersDescriptionResponsableSanteSecuriteH34() { return i18n.t('Metiers.Metiers_description_responsable_sante_securite_h3_4'); }
    static get metiersMetiersJobTitleAdjointDirectionVentes() { return i18n.t('Metiers.Metiers_jobTitle_adjoint_direction_ventes'); }
    static get metiersMetiersDescriptionResponsableSanteSecuriteH32() { return i18n.t('Metiers.Metiers_description_responsable_sante_securite_h3_2'); }
    static get metiersMetiersDescriptionCommisPiecesMecaniqueH37() { return i18n.t('Metiers.Metiers_description_commis_pieces_mecanique_h3_7'); }
    static get metiersMetiersDescriptionDirecteurRenouvellementsH32() { return i18n.t('Metiers.Metiers_description_directeur_renouvellements_h3_2'); }
    static get metiersMetiersJobTitleConseillerVenteCamions() { return i18n.t('Metiers.Metiers_jobTitle_conseiller_vente_camions'); }
    static get metiersMetiersDescriptionDirecteurRenouvellementsH35() { return i18n.t('Metiers.Metiers_description_directeur_renouvellements_h3_5'); }
    static get metiersMetiersDescriptionConseillerTechniqueP2() { return i18n.t('Metiers.Metiers_description_conseiller_technique_p2'); }
    static get metiersMetiersDescriptionPersonneCourP2() { return i18n.t('Metiers.Metiers_description_personne_cour_p2'); }
    static get metiersMetiersDescriptionCommissionnaireH31() { return i18n.t('Metiers.Metiers_description_commissionnaire_h3_1'); }
    static get metiersMetiersJobTitleMecanicienApprenti() { return i18n.t('Metiers.Metiers_jobTitle_mecanicien_apprenti'); }
    static get metiersMetiersDescriptionServiceClienteleH33() { return i18n.t('Metiers.Metiers_description_service_clientele_h3_3'); }
    static get metiersMetiersJobTitleDirecteurApresVente() { return i18n.t('Metiers.Metiers_jobTitle_directeur_apres_vente'); }
    static get metiersMetiersDescriptionCommisEntrepotP2() { return i18n.t('Metiers.Metiers_description_commis_entrepot_p2'); }
    static get metiersMetiersDescriptionCarrossierH2() { return i18n.t('Metiers.Metiers_description_carrossier_h2'); }
    static get metiersMetiersDescriptionSecretaireVentesH36() { return i18n.t('Metiers.Metiers_description_secretaire_ventes_h3_6'); }
    static get metiersMetiersDescriptionResponsableSanteSecuriteP1() { return i18n.t('Metiers.Metiers_description_responsable_sante_securite_p1'); }
    static get metiersMetiersMetierPourToiText() { return i18n.t('Metiers.Metiers_metier_pour_toi_text'); }
    static get metiersMetiersDescriptionDirecteurApresVenteH32() { return i18n.t('Metiers.Metiers_description_directeur_apres_vente_h3_2'); }
    static get metiersMetiersDescriptionAgentLivraisonH32() { return i18n.t('Metiers.Metiers_description_agent_livraison_h3_2'); }
    static get metiersMetiersDescriptionCoordonnateurVentesH2() { return i18n.t('Metiers.Metiers_description_coordonnateur_ventes_h2'); }
    static get metiersMetiersDescriptionConseillerVentesCamionsH31() { return i18n.t('Metiers.Metiers_description_conseiller_ventes_camions_h3_1'); }
    static get metiersMetiersDescriptionControleurComptableH36() { return i18n.t('Metiers.Metiers_description_controleur_comptable_h3_6'); }
    static get metiersMetiersJobTitleCommisPiecesCarrosserie() { return i18n.t('Metiers.Metiers_jobTitle_commis_pieces_carrosserie'); }
    static get metiersMetiersDescriptionDirecteurOperationsFixesH32() { return i18n.t('Metiers.Metiers_description_directeur_operations_fixes_h3_2'); }
    static get metiersMetiersDescriptionConseillerVenteH34() { return i18n.t('Metiers.Metiers_description_conseiller_vente_h3_4'); }
    static get metiersMetiersDescriptionDirecteurPiecesH35() { return i18n.t('Metiers.Metiers_description_directeur_pieces_h3_5'); }
    static get metiersMetiersDescriptionAdministrateurGarantieH32() { return i18n.t('Metiers.Metiers_description_administrateur_garantie_h3_2'); }
    static get metiersMetiersDescriptionDirecteurOperationsFixesH35() { return i18n.t('Metiers.Metiers_description_directeur_operations_fixes_h3_5'); }
    static get metiersMetiersDescriptionDirecteurOperationsFixesH37() { return i18n.t('Metiers.Metiers_description_directeur_operations_fixes_h3_7'); }
    static get metiersMetiersDescriptionCommisPiecesCarrosserieH35() { return i18n.t('Metiers.Metiers_description_commis_pieces_carrosserie_h3_5'); }
    static get metiersMetiersDescriptionCommisEntrepotH33() { return i18n.t('Metiers.Metiers_description_commis_entrepot_h3_3'); }
    static get metiersMetiersDescriptionAdministrateurGarantieH31() { return i18n.t('Metiers.Metiers_description_administrateur_garantie_h3_1'); }
    static get metiersMetiersDescriptionTechnicienMecaniqueVehiculeLourdsP2() { return i18n.t('Metiers.Metiers_description_technicien_mecanique_vehicule_lourds_p2'); }
    static get metiersMetiersDescriptionPreposeLivraisonPiecesH35() { return i18n.t('Metiers.Metiers_description_prepose_livraison_pieces_h3_5'); }
    static get metiersMetiersDescriptionReceptionnisteH31() { return i18n.t('Metiers.Metiers_description_receptionniste_h3_1'); }
    static get metiersMetiersDescriptionMecanicienApprentiH32() { return i18n.t('Metiers.Metiers_description_mecanicien_apprenti_h3_2'); }
    static get metiersMetiersDescriptionMecanicienCompagnonH35() { return i18n.t('Metiers.Metiers_description_mecanicien_compagnon_h3_5'); }
    static get metiersMetiersDescriptionResponsableSanteSecuriteH33() { return i18n.t('Metiers.Metiers_description_responsable_sante_securite_h3_3'); }
    static get metiersMetiersDescriptionAdjointDirectionPiecesOperationsFixesH35() { return i18n.t('Metiers.Metiers_description_adjoint_direction_pieces_operations_fixes_h3_5'); }
    static get metiersMetiersDescriptionPeintreP2() { return i18n.t('Metiers.Metiers_description_peintre_p2'); }
    static get metiersMetiersDescriptionCommisComptesRecevoirH31() { return i18n.t('Metiers.Metiers_description_commis_comptes_recevoir_h3_1'); }
    static get metiersMetiersDescriptionMecanicienCompagnonP1() { return i18n.t('Metiers.Metiers_description_mecanicien_compagnon_p1'); }
    static get metiersMetiersDescriptionCommissionnaireH33() { return i18n.t('Metiers.Metiers_description_commissionnaire_h3_3'); }
    static get metiersMetiersMetierPourToiTitle() { return i18n.t('Metiers.Metiers_metier_pour_toi_title'); }
    static get metiersMetiersDescriptionCommisComptableP1() { return i18n.t('Metiers.Metiers_description_commis_comptable_p1'); }
    static get metiersMetiersJobTitleVpFinances() { return i18n.t('Metiers.Metiers_jobTitle_vp_finances'); }
    static get metiersMetiersDescriptionTechnicienMecaniqueVehiculeLourdsH34() { return i18n.t('Metiers.Metiers_description_technicien_mecanique_vehicule_lourds_h3_4'); }
    static get metiersMetiersDescriptionSecretaireVentesP2() { return i18n.t('Metiers.Metiers_description_secretaire_ventes_p2'); }
    static get metiersMetiersDescriptionDirecteurRenouvellementsP2() { return i18n.t('Metiers.Metiers_description_directeur_renouvellements_p2'); }
    static get metiersMetiersDescriptionCommisEntrepotP1() { return i18n.t('Metiers.Metiers_description_commis_entrepot_p1'); }
    static get metiersMetiersDescriptionAdjointAdministratifH32() { return i18n.t('Metiers.Metiers_description_adjoint_administratif_h3_2'); }
    static get metiersMetiersDescriptionVpFinancesH33() { return i18n.t('Metiers.Metiers_description_vp_finances_h3_3'); }
    static get metiersMetiersDescriptionDgVentesH32() { return i18n.t('Metiers.Metiers_description_dg_ventes_h3_2'); }
    static get distance() { return i18n.t('distance'); }
    static get locationSearchAddress() { return i18n.t('Location.search_address'); }
    static get concessionDansTonSecteur() { return i18n.t('Concession.dans_ton_secteur'); }
    static get concessionDecouverteDes() { return i18n.t('Concession.decouverte_des'); }
    static get postuler() { return i18n.t('postuler'); }
    static get loginMotDePasseOublie() { return i18n.t('Login.mot_de_passe_oublie'); }
    static get loginEntrerCourriel() { return i18n.t('Login.entrer_courriel'); }
    static get loginMdpReinitSuccesP() { return i18n.t('Login.mdp_reinit_succes_p'); }
    static get loginDefninirNouveauMotDePasse() { return i18n.t('Login.defninir_nouveau_mot_de_passe'); }
    static get loginVerifierBoiteReception() { return i18n.t('Login.verifier_boite_reception'); }
    static get loginInstructionReinitEnvoye() { return i18n.t('Login.instruction_reinit_envoye'); }
    static get loginConfirmationMotDePasse() { return i18n.t('Login.confirmation_mot_de_passe'); }
    static get loginConnexionAuCompte() { return i18n.t('Login.connexion_au_compte'); }
    static get loginMdpReinitSucces() { return i18n.t('Login.mdp_reinit_succes'); }
    static get loginMotDePasse() { return i18n.t('Login.mot_de_passe'); }
    static get loginPasDeCompte() { return i18n.t('Login.pas_de_compte'); }
    static get loginEntrerCourrielAssociee() { return i18n.t('Login.entrer_courriel_associee'); }
    static get allerAuCompte() { return i18n.t('aller_au_compte'); }
    static get contactezNous() { return i18n.t('contactez_nous'); }
    static get selectCustomSelectNoOptions() { return i18n.t('SelectCustom.select_no_options'); }
    static get selectCustomDefaultSelectPlaceholder() { return i18n.t('SelectCustom.default_select_placeholder'); }
    static get selectCustomSelectLoading() { return i18n.t('SelectCustom.select_loading'); }
    static get userVilleRecherche() { return i18n.t('User.ville_recherche'); }
    static get userResetPasswordNewConfirm() { return i18n.t('User.reset_password_new_confirm'); }
    static get userVosReponseAJour() { return i18n.t('User.vos_reponse_a_jour'); }
    static get userEnvoyerUnNouveauCode() { return i18n.t('User.envoyer_un nouveau_code'); }
    static get userMeConnecter() { return i18n.t('User.me_connecter'); }
    static get userInfoPourDebuter() { return i18n.t('User.info_pour_debuter'); }
    static get userCreeCompteP1() { return i18n.t('User.cree_compte_p1'); }
    static get userCompte() { return i18n.t('User.compte'); }
    static get userResetPasswordNew() { return i18n.t('User.reset_password_new'); }
    static get userCompleterProfile() { return i18n.t('User.completer_profile'); }
    static get userDescriptionAPropos() { return i18n.t('User.description_a_propos'); }
    static get userVerifierLeCode() { return i18n.t('User.verifier_le_code'); }
    static get userCreeMonCompte() { return i18n.t('User.cree_mon_compte'); }
    static get userMarqueFavorite() { return i18n.t('User.marque_favorite'); }
    static get userEnvoyerCode() { return i18n.t('User.envoyer_code'); }
    static get userCreerVotre() { return i18n.t('User.creer_votre'); }
    static get userAccepteCondition2() { return i18n.t('User.accepte_condition_2'); }
    static get userCreeCompteP2() { return i18n.t('User.cree_compte_p2'); }
    static get userCreationDuProfile() { return i18n.t('User.creation_du_profile'); }
    static get userEntrerCodeVerif() { return i18n.t('User.entrer_code_verif'); }
    static get userAccepteCondition1() { return i18n.t('User.accepte_condition_1'); }
    static get userDejaUnCompte() { return i18n.t('User.deja_un_compte'); }
    static get userRayonRecherche() { return i18n.t('User.rayon_recherche'); }
    static get userVotreProfEstComplete() { return i18n.t('User.votre_prof_est_complete'); }
    static get userAccepteCondition3() { return i18n.t('User.accepte_condition_3'); }
    static get voir() { return i18n.t('voir'); }
    static get industrieIndustrieTemoignagesCard2Name() { return i18n.t('Industrie.Industrie_temoignages_card2_name'); }
    static get industrieIndustrieMetiersCategory2Description() { return i18n.t('Industrie.Industrie_metiers_category2_description'); }
    static get industrieIndustrieStatsEmplois() { return i18n.t('Industrie.Industrie_stats_emplois'); }
    static get industrieIndustrieMetiersTitleRow1() { return i18n.t('Industrie.Industrie_metiers_title_row1'); }
    static get industrieIndustrieTemoignagesCard2JobTitle() { return i18n.t('Industrie.Industrie_temoignages_card2_job_title'); }
    static get industrieIndustrieStats10000() { return i18n.t('Industrie.Industrie_stats_10000'); }
    static get industrieIndustrieStatsPartoutAu() { return i18n.t('Industrie.Industrie_stats_partout_au'); }
    static get industrieIndustrieMetiersCategory5Description() { return i18n.t('Industrie.Industrie_metiers_category5_description'); }
    static get industrieIndustrieStatsConcessionnaires() { return i18n.t('Industrie.Industrie_stats_concessionnaires'); }
    static get industrieIndustrieMetiersTitleRow2Word2() { return i18n.t('Industrie.Industrie_metiers_title_row2_word2'); }
    static get industrieIndustrieMetiersTitleRow2Word1() { return i18n.t('Industrie.Industrie_metiers_title_row2_word1'); }
    static get industrieIndustrieQuizTitleRow2() { return i18n.t('Industrie.Industrie_quiz_title_row2'); }
    static get industrieIndustrieTemoignagesCard1Quote() { return i18n.t('Industrie.Industrie_temoignages_card1_quote'); }
    static get industrieIndustrieMetiersTextParagraph2() { return i18n.t('Industrie.Industrie_metiers_text_paragraph_2'); }
    static get industrieIndustrieTemoignagesCta() { return i18n.t('Industrie.Industrie_temoignages_cta'); }
    static get industrieIndustrieQuizCta() { return i18n.t('Industrie.Industrie_quiz_cta'); }
    static get industrieIndustrieTemoignagesTitle() { return i18n.t('Industrie.Industrie_temoignages_title'); }
    static get industrieIndustrieMetiersCategory1Description() { return i18n.t('Industrie.Industrie_metiers_category1_description'); }
    static get industrieIndustrieMetiersCategory2Title() { return i18n.t('Industrie.Industrie_metiers_category2_title'); }
    static get industrieIndustrieMetiersTextTitle() { return i18n.t('Industrie.Industrie_metiers_text_title'); }
    static get industrieIndustrieMetiersCta1() { return i18n.t('Industrie.Industrie_metiers_cta1'); }
    static get industrieIndustrieStatsCta() { return i18n.t('Industrie.Industrie_stats_cta'); }
    static get industrieIndustrieIntro1Title() { return i18n.t('Industrie.Industrie_intro1_title'); }
    static get industrieIndustrieMetiersCategory3Title() { return i18n.t('Industrie.Industrie_metiers_category3_title'); }
    static get industrieIndustrieMetiersCategory6Description() { return i18n.t('Industrie.Industrie_metiers_category6_description'); }
    static get industrieIndustrieMetiersCategory3Description() { return i18n.t('Industrie.Industrie_metiers_category3_description'); }
    static get industrieIndustrieIntro2Cta() { return i18n.t('Industrie.Industrie_intro2_cta'); }
    static get industrieIndustrieMetiersTextParagraph1() { return i18n.t('Industrie.Industrie_metiers_text_paragraph_1'); }
    static get industrieIndustrieTemoignagesCard1Description() { return i18n.t('Industrie.Industrie_temoignages_card1_description'); }
    static get industrieIndustrieQuizSubtitle() { return i18n.t('Industrie.Industrie_quiz_subtitle'); }
    static get industrieIndustrieIntro1Cta() { return i18n.t('Industrie.Industrie_intro1_cta'); }
    static get industrieIndustrieTemoignagesCard3Description() { return i18n.t('Industrie.Industrie_temoignages_card3_description'); }
    static get industrieIndustrieIntro1Text() { return i18n.t('Industrie.Industrie_intro1_text'); }
    static get industrieIndustrieStats890() { return i18n.t('Industrie.Industrie_stats_890'); }
    static get industrieIndustrieMetiersCategory4Title() { return i18n.t('Industrie.Industrie_metiers_category4_title'); }
    static get industrieIndustrieTemoignagesCard3JobTitle() { return i18n.t('Industrie.Industrie_temoignages_card3_job_title'); }
    static get industrieIndustrieQuizCtaConnected() { return i18n.t('Industrie.Industrie_quiz_cta_connected'); }
    static get industrieIndustrieTemoignagesVideoDescription() { return i18n.t('Industrie.Industrie_temoignages_video_description'); }
    static get industrieIndustrieQuizTitleRow1() { return i18n.t('Industrie.Industrie_quiz_title_row1'); }
    static get industrieIndustrieTemoignagesCard2Description() { return i18n.t('Industrie.Industrie_temoignages_card2_description'); }
    static get industrieIndustrieTemoignagesCard2Quote() { return i18n.t('Industrie.Industrie_temoignages_card2_quote'); }
    static get industrieIndustrieMetiersCategory5Title() { return i18n.t('Industrie.Industrie_metiers_category5_title'); }
    static get industrieIndustrieTemoignagesCard3Name() { return i18n.t('Industrie.Industrie_temoignages_card3_name'); }
    static get industrieIndustrieMetiersCategory4Description() { return i18n.t('Industrie.Industrie_metiers_category4_description'); }
    static get industrieIndustrieTemoignagesCard3Quote() { return i18n.t('Industrie.Industrie_temoignages_card3_quote'); }
    static get industrieIndustrieMetiersCategory1Title() { return i18n.t('Industrie.Industrie_metiers_category1_title'); }
    static get industrieIndustrieTemoignagesCard1Name() { return i18n.t('Industrie.Industrie_temoignages_card1_name'); }
    static get industrieIndustrieMetiersCta2() { return i18n.t('Industrie.Industrie_metiers_cta2'); }
    static get industrieIndustrieTemoignagesCard1JobTitle() { return i18n.t('Industrie.Industrie_temoignages_card1_job_title'); }
    static get industrieIndustrieStatsQuebec() { return i18n.t('Industrie.Industrie_stats_quebec'); }
    static get industrieIndustrieMetiersCategory6Title() { return i18n.t('Industrie.Industrie_metiers_category6_title'); }
    static get industrieIndustrieIntro2Text() { return i18n.t('Industrie.Industrie_intro2_text'); }
    static get settings() { return i18n.t('settings'); }
    static get statutDeCandidature() { return i18n.t('statut_de_candidature'); }
    static get enRecherche() { return i18n.t('en_recherche'); }
    static get reponse() { return i18n.t('reponse'); }
    static get dateDeSoumission() { return i18n.t('date_de_soumission'); }
    static get oui() { return i18n.t('oui'); }
    static get prenom() { return i18n.t('prenom'); }
    static get nosValeurs() { return i18n.t('nos_valeurs'); }
    static get urlUrlTemoignage() { return i18n.t('Url.url_temoignage'); }
    static get urlUrlNouveauMotDePasse() { return i18n.t('Url.url_nouveau-mot-de-passe'); }
    static get urlUrlNouveauCompte() { return i18n.t('Url.url_nouveau-compte'); }
    static get urlUrlIndustrie() { return i18n.t('Url.url_industrie'); }
    static get urlUrlMetier() { return i18n.t('Url.url_metier'); }
    static get urlUrlConnexion() { return i18n.t('Url.url_connexion'); }
    static get urlUrlConcessions() { return i18n.t('Url.url_concessions'); }
    static get urlUrlQuiz() { return i18n.t('Url.url_quiz'); }
    static get urlUrlProfil() { return i18n.t('Url.url_profil'); }
    static get candidatureInactive() { return i18n.t('candidature_inactive'); }
    static get errorsE001001() { return i18n.t('Errors.E001001'); }
    static get errorsPasswordRequired() { return i18n.t('Errors.password_required'); }
    static get errorsUsernameRequired() { return i18n.t('Errors.username_required'); }
    static get errorsE012002() { return i18n.t('Errors.E012002'); }
    static get errorsPasswordMustMatch() { return i18n.t('Errors.password_must_match'); }
    static get errorsE001003() { return i18n.t('Errors.E001003'); }
    static get errorsPasswordMatch() { return i18n.t('Errors.password_match'); }
    static get errorsEmailError() { return i18n.t('Errors.email_error'); }
    static get errorsRequired() { return i18n.t('Errors.required'); }
    static get candidatAvezVousExperience() { return i18n.t('Candidat.avez_vous_experience'); }
    static get explorerLesMetiers() { return i18n.t('explorer_les_metiers'); }
    static get aucunEmploiPourMoment() { return i18n.t('aucun_emploi_pour_moment'); }
    static get accountAccountFormSubmit() { return i18n.t('Account.Account_form_submit'); }
    static get accountAccountFormBackButton() { return i18n.t('Account.Account_form_back_button'); }
    static get accountAccountFormPhone() { return i18n.t('Account.Account_form_phone'); }
    static get accountAccountFormFirstName() { return i18n.t('Account.Account_form_first_name'); }
    static get accountAccountHi() { return i18n.t('Account.Account_hi'); }
    static get accountAccountRechercheEnEmploi() { return i18n.t('Account.Account_recherche_en_emploi'); }
    static get accountAccountCandidatures() { return i18n.t('Account.Account_candidatures'); }
    static get accountAccountFormCity() { return i18n.t('Account.Account_form_city'); }
    static get accountAccountFormDescription() { return i18n.t('Account.Account_form_description'); }
    static get accountAccountFormCurrentPassword() { return i18n.t('Account.Account_form_current_password'); }
    static get accountAccountFormNewPasswordTitle() { return i18n.t('Account.Account_form_new_password_title'); }
    static get accountAccountLogout() { return i18n.t('Account.Account_logout'); }
    static get accountAccountFormPassword() { return i18n.t('Account.Account_form_password'); }
    static get accountAccountFormModifyPassword() { return i18n.t('Account.Account_form_modify_password'); }
    static get accountAccountFormLastName() { return i18n.t('Account.Account_form_last_name'); }
    static get accountAccountFormFavoriteMake() { return i18n.t('Account.Account_form_favorite_make'); }
    static get accountAccountFormNewPasswordConfirmation() { return i18n.t('Account.Account_form_new_password_confirmation'); }
    static get accountAccountParametres() { return i18n.t('Account.Account_parametres'); }
    static get accountAccountFormCurrentPasswordError() { return i18n.t('Account.Account_form_current_password_error'); }
    static get accountAccountRechercheEnRecherche() { return i18n.t('Account.Account_recherche_en_recherche'); }
    static get accountAccountFormCancel() { return i18n.t('Account.Account_form_cancel'); }
    static get accountAccountFormSearchRadius() { return i18n.t('Account.Account_form_search_radius'); }
    static get accountAccountFormNewPassword() { return i18n.t('Account.Account_form_new_password'); }
    static get accountAccountCta() { return i18n.t('Account.Account_cta'); }
    static get refaireMonQuiz() { return i18n.t('refaire_mon_quiz'); }
    static get all() { return i18n.t('all'); }
    static get cookiesCookiesPreferencesModalSectionsHeadDescription() { return i18n.t('Cookies.Cookies_preferencesModal_sections_head_description'); }
    static get cookiesCookiesConsentModalTitle() { return i18n.t('Cookies.Cookies_consentModal_title'); }
    static get cookiesCookiesPreferencesModalSectionsEssentialCol3BodyCookie1() { return i18n.t('Cookies.Cookies_preferencesModal_sections_essential_col3_body_cookie_1'); }
    static get cookiesCookiesPreferencesModalSectionAnalyticsCol3Header() { return i18n.t('Cookies.Cookies_preferencesModal_section_analytics_col3_header'); }
    static get cookiesCookiesPreferencesModalSectionsMoreDescription() { return i18n.t('Cookies.Cookies_preferencesModal_sections_more_description'); }
    static get cookiesCookiesPreferencesModalTitle() { return i18n.t('Cookies.Cookies_preferencesModal_title'); }
    static get cookiesCookiesPreferencesModalSectionsEssential() { return i18n.t('Cookies.Cookies_preferencesModal_sections_essential'); }
    static get cookiesCookiesPreferencesModalSectionsEssentialCol2Header() { return i18n.t('Cookies.Cookies_preferencesModal_sections_essential_col2_header'); }
    static get cookiesCookiesPreferencesModalSectionsEssentialCol4BodyCookie1() { return i18n.t('Cookies.Cookies_preferencesModal_sections_essential_col4_body_cookie_1'); }
    static get cookiesCookiesPreferencesModalSectionsEssentialCol4BodyCookie0() { return i18n.t('Cookies.Cookies_preferencesModal_sections_essential_col4_body_cookie_0'); }
    static get cookiesCookiesPreferencesModalAcceptNecessaryBtn() { return i18n.t('Cookies.Cookies_preferencesModal_acceptNecessaryBtn'); }
    static get cookiesCookiesPreferencesModalSectionAnalyticsCol4Header() { return i18n.t('Cookies.Cookies_preferencesModal_section_analytics_col4_header'); }
    static get cookiesCookiesPreferencesModalSectionsEssentialDescription() { return i18n.t('Cookies.Cookies_preferencesModal_sections_essential_description'); }
    static get cookiesCookiesConsentModalAcceptAllBtn() { return i18n.t('Cookies.Cookies_consentModal_acceptAllBtn'); }
    static get cookiesCookiesConsentModalShowPreferencesBtn() { return i18n.t('Cookies.Cookies_consentModal_showPreferencesBtn'); }
    static get cookiesCookiesPreferencesModalSectionAnalyticsCol1BodyCookie0() { return i18n.t('Cookies.Cookies_preferencesModal_section_analytics_col1_body_cookie_0'); }
    static get cookiesCookiesPreferencesModalSectionAnalyticsCol4BodyCookie0() { return i18n.t('Cookies.Cookies_preferencesModal_section_analytics_col4_body_cookie_0'); }
    static get cookiesCookiesPreferencesModalSectionsEssentialCol2BodyCookie0() { return i18n.t('Cookies.Cookies_preferencesModal_sections_essential_col2_body_cookie_0'); }
    static get cookiesCookiesConsentModalAcceptNecessaryBtn() { return i18n.t('Cookies.Cookies_consentModal_acceptNecessaryBtn'); }
    static get cookiesCookiesPreferencesModalSectionsPerformance() { return i18n.t('Cookies.Cookies_preferencesModal_sections_performance'); }
    static get cookiesCookiesConsentModalDescription() { return i18n.t('Cookies.Cookies_consentModal_description'); }
    static get cookiesCookiesPreferencesModalSectionAnalyticsCol1Header() { return i18n.t('Cookies.Cookies_preferencesModal_section_analytics_col1_header'); }
    static get cookiesCookiesPreferencesModalSavePreferencesBtn() { return i18n.t('Cookies.Cookies_preferencesModal_savePreferencesBtn'); }
    static get cookiesCookiesPreferencesModalSectionsHeadTitle() { return i18n.t('Cookies.Cookies_preferencesModal_sections_head_title'); }
    static get cookiesCookiesPreferencesModalSectionsEssentialCol1Header() { return i18n.t('Cookies.Cookies_preferencesModal_sections_essential_col1_header'); }
    static get cookiesCookiesPreferencesModalSectionsPerformanceDescription() { return i18n.t('Cookies.Cookies_preferencesModal_sections_performance_description'); }
    static get cookiesCookiesPreferencesModalSectionsEssentialCol3Header() { return i18n.t('Cookies.Cookies_preferencesModal_sections_essential_col3_header'); }
    static get cookiesCookiesPreferencesModalSectionsEssentialCol3BodyCookie0() { return i18n.t('Cookies.Cookies_preferencesModal_sections_essential_col3_body_cookie_0'); }
    static get cookiesCookiesPreferencesModalSectionAnalyticsCol2Header() { return i18n.t('Cookies.Cookies_preferencesModal_section_analytics_col2_header'); }
    static get cookiesCookiesPreferencesModalAcceptAllBtn() { return i18n.t('Cookies.Cookies_preferencesModal_acceptAllBtn'); }
    static get cookiesCookiesPreferencesModalSectionsEssentialCol2BodyCookie1() { return i18n.t('Cookies.Cookies_preferencesModal_sections_essential_col2_body_cookie_1'); }
    static get cookiesCookiesPreferencesModalSectionAnalyticsCol2BodyCookie0() { return i18n.t('Cookies.Cookies_preferencesModal_section_analytics_col2_body_cookie_0'); }
    static get cookiesCookiesPreferencesModalSectionAnalyticsCol3BodyCookie0() { return i18n.t('Cookies.Cookies_preferencesModal_section_analytics_col3_body_cookie_0'); }
    static get cookiesCookiesPreferencesModalSectionsMoreTitle() { return i18n.t('Cookies.Cookies_preferencesModal_sections_more_title'); }
    static get cookiesCookiesPreferencesModalSectionsEssentialCol4Header() { return i18n.t('Cookies.Cookies_preferencesModal_sections_essential_col4_header'); }
    static get cookiesCookiesPreferencesModalSectionsEssentialCol1BodyCookie0() { return i18n.t('Cookies.Cookies_preferencesModal_sections_essential_col1_body_cookie_0'); }
    static get cookiesCookiesPreferencesModalSectionsEssentialCol1BodyCookie1() { return i18n.t('Cookies.Cookies_preferencesModal_sections_essential_col1_body_cookie_1'); }
    static get dejaPostuler() { return i18n.t('deja_postuler'); }
    static get ideal() { return i18n.t('ideal'); }
    static get faireMonQuiz() { return i18n.t('faire_mon_quiz'); }
    static get ordonnerPar() { return i18n.t('ordonner_par'); }
    static get invalidNumber() { return i18n.t('invalid_number'); }
    static get non() { return i18n.t('non'); }
    static get footerTousDroit() { return i18n.t('Footer.tous_droit'); }
    static get footerReglageTemoins() { return i18n.t('Footer.reglage_temoins'); }
    static get footerTermeConditions() { return i18n.t('Footer.terme_conditions'); }
    static get nom() { return i18n.t('nom'); }
    static get termsTermsH28() { return i18n.t('Terms.terms_h2_8'); }
    static get termsTermsH11() { return i18n.t('Terms.terms_h1_1'); }
    static get termsTermsP1() { return i18n.t('Terms.terms_p_1'); }
    static get termsTermsH23() { return i18n.t('Terms.terms_h2_3'); }
    static get termsTermsH21() { return i18n.t('Terms.terms_h2_1'); }
    static get termsTermsH12() { return i18n.t('Terms.terms_h1_2'); }
    static get termsTermsP8() { return i18n.t('Terms.terms_p_8'); }
    static get termsTermsH29() { return i18n.t('Terms.terms_h2_9'); }
    static get termsTermsH211() { return i18n.t('Terms.terms_h2_11'); }
    static get termsTermsP15() { return i18n.t('Terms.terms_p_15'); }
    static get termsTermsH213() { return i18n.t('Terms.terms_h2_13'); }
    static get termsTermsP13() { return i18n.t('Terms.terms_p_13'); }
    static get termsTermsH26() { return i18n.t('Terms.terms_h2_6'); }
    static get termsTermsP7() { return i18n.t('Terms.terms_p_7'); }
    static get termsTermsP11() { return i18n.t('Terms.terms_p_11'); }
    static get termsTermsH210() { return i18n.t('Terms.terms_h2_10'); }
    static get termsTermsP10() { return i18n.t('Terms.terms_p_10'); }
    static get termsTermsH22() { return i18n.t('Terms.terms_h2_2'); }
    static get termsTermsP5() { return i18n.t('Terms.terms_p_5'); }
    static get termsTermsH25() { return i18n.t('Terms.terms_h2_5'); }
    static get termsTermsP12() { return i18n.t('Terms.terms_p_12'); }
    static get termsTermsP6() { return i18n.t('Terms.terms_p_6'); }
    static get termsTermsH212() { return i18n.t('Terms.terms_h2_12'); }
    static get termsTermsH214() { return i18n.t('Terms.terms_h2_14'); }
    static get termsTermsP16() { return i18n.t('Terms.terms_p_16'); }
    static get termsTermsP3() { return i18n.t('Terms.terms_p_3'); }
    static get termsTermsP17() { return i18n.t('Terms.terms_p_17'); }
    static get termsTermsH27() { return i18n.t('Terms.terms_h2_7'); }
    static get termsTermsH24() { return i18n.t('Terms.terms_h2_4'); }
    static get termsTermsP9() { return i18n.t('Terms.terms_p_9'); }
    static get termsTermsP2() { return i18n.t('Terms.terms_p_2'); }
    static get termsTermsP14() { return i18n.t('Terms.terms_p_14'); }
    static get quiz() { return i18n.t('quiz'); }
    static get telephone() { return i18n.t('telephone'); }
    static get statut() { return i18n.t('statut'); }
    static get categorie() { return i18n.t('categorie'); }
    static get sideNavSideNavTemoignages() { return i18n.t('SideNav.SideNav_temoignages'); }
    static get sideNavSideNavConcessions() { return i18n.t('SideNav.SideNav_concessions'); }
    static get sideNavSideNavMetier() { return i18n.t('SideNav.SideNav_metier'); }
    static get sideNavSideNavIndustrie() { return i18n.t('SideNav.SideNav_industrie'); }
    static get marque() { return i18n.t('marque'); }

}
export default Copydeck;