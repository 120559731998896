import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
    CONCESSIONS_URL,
    CONCESSION_URL_REGEX,
    HOME_PAGE_URL,
    METIER_URL,
    METIER_URL_REGEX,
    TEMOIGNAGE_URL,
    TEMOIGNAGE_URL_REGEX,
} from 'Models/Constants';

const useSelectedNav = () => {
    const location = useLocation();

    const [selectedKey, setSelectedKey] = useState<string | null>(null);

    useEffect(() => {
        setSelectedKey(() => {
            if (location.pathname === HOME_PAGE_URL) return HOME_PAGE_URL;
            if (METIER_URL_REGEX.test(location.pathname)) return METIER_URL;
            if (CONCESSION_URL_REGEX.test(location.pathname)) return CONCESSIONS_URL;
            if (TEMOIGNAGE_URL_REGEX.test(location.pathname)) return TEMOIGNAGE_URL;
            return null;
        });
    }, [location.pathname]);

    return selectedKey;
};

export default useSelectedNav;
