import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const ArrowRightIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
                <path
                    d="M15 5L13.59 6.41L18.17 11H2V13H18.17L13.58 17.59L15 19L22 12L15 5Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default ArrowRightIcon;
