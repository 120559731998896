import { MultiSelectCustomOption } from 'Components/select-custom/multi-select/multi-select-common';
import TaglessStaticMultiSelect from 'Components/select-custom/multi-select/tagless-static-multi-select';
import { useStores } from 'Hooks';
import { observer } from 'mobx-react';
import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface MakesFilterProps {
    onChange: (options: MultiSelectCustomOption[]) => void;
    selected: MultiSelectCustomOption[];
}

const MakesFilter: FunctionComponent<MakesFilterProps> = observer(({ onChange, selected }) => {
    const { t } = useTranslation();
    const { makesStore } = useStores();

    useEffect(() => {
        makesStore.cachedGetMakes();
    }, [makesStore.cachedGetMakes, makesStore]);

    return (
        <div className="MakesFilter">
            <TaglessStaticMultiSelect
                onChange={onChange}
                options={makesStore.makesSelectOptions}
                selected={selected.map((x) => x.value)}
                className="make-dropdown"
                closeMenuOnSelect={false}
                placeholderOnSelectedOptions={t('marque')}
            />
        </div>
    );
});

export default MakesFilter;
