import { observable, action } from 'mobx';

interface ToastConfig {
    type: 'info' | 'success' | 'error' | 'warning';
    title?: string;
    subTitle?: string;
}

class ToastStore {
    @observable currentToast: ToastConfig | null = null;

    @action
    genericError(): void {
        this.currentToast = {
            type: 'error',
            title: 'Une erreur inattendue est survenue.',
        };
    }

    @action
    serverError(): void {
        this.currentToast = {
            type: 'error',
            title: 'Une erreur de serveur inattendue est survenue.',
        };
    }

    @action
    toast(config: ToastConfig): void {
        this.currentToast = config;
    }
}

export default ToastStore;
