import { ConcessionProxy } from 'Api/Features/Concessions/ConcessionProxy';
import { ApiService } from './ApiService';
import { inject } from 'aurelia-dependency-injection';
import { GetMakesResponseDto } from 'Api/Features/Concessions/Dtos/GetMakesResponseDto';
import { GetRecruitmentConcessionsPublicRequestDto } from 'Api/Features/Concessions/Dtos/GetRecruitmentConcessionsPublicRequestDto';
import { GetRecruitmentConcessionsPublicResponseDto } from 'Api/Features/Concessions/Dtos/GetRecruitmentConcessionsPublicResponseDto';
import { GetRecruitmentConcessionsPublicItemResponseDto } from 'Api/Features/Concessions/Dtos/GetRecruitmentConcessionsPublicItemResponseDto';
import { RecruitmentConcessionPublicDetailsDto } from 'Api/Features/Concessions/Dtos/RecruitmentConcessionPublicDetailsDto';

@inject(ConcessionProxy)
export class ConcessionService extends ApiService {
    constructor(private readonly concessionProxy: ConcessionProxy) {
        super();
    }

    public async getRecruitmentConcessionsPublic(
        request: GetRecruitmentConcessionsPublicRequestDto | null
    ): Promise<[GetRecruitmentConcessionsPublicItemResponseDto[], number]> {
        const data: GetRecruitmentConcessionsPublicResponseDto | null =
            await this.concessionProxy.getRecruitmentConcessionsPublic(request);
        return [
            data?.items?.filter((x) => x !== null).map((x) => x!) ?? [],
            data?.totalItemCount ?? 0,
        ];
    }

    public async getRecruitmentConcessionPublic(
        concessionId: string
    ): Promise<RecruitmentConcessionPublicDetailsDto | null> {
        const data: RecruitmentConcessionPublicDetailsDto | null =
            await this.concessionProxy.getRecruitmentConcessionPublic(concessionId);
        return data;
    }

    public async getMakes(): Promise<GetMakesResponseDto | null> {
        const data: GetMakesResponseDto | null = await this.concessionProxy.getMakes();
        return data;
    }
}
