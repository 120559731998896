import { FunctionComponent, UIEventHandler, useEffect, useRef } from 'react';
import './index.less';
import FancyArrowIcon from 'Components/icons/fancy-arrow-icon';
import Button from 'Components/button';
import { observer } from 'mobx-react';
import { useStores, useWindowDimensions } from 'Hooks';
import { useHistory } from 'react-router-dom';
import { METIER_URL } from 'Models/Constants';
import Copydeck from 'i18n/Copydeck';
import ScrollAnimationWrapper from 'Components/scroll-animation-wrapper';

const Intro: FunctionComponent = observer(() => {
    const introRef = useRef<HTMLDivElement>(null);
    const intro1Ref = useRef<HTMLDivElement>(null);
    const intro2Ref = useRef<HTMLDivElement>(null);

    const history = useHistory();
    const { industrieIntroStore } = useStores();
    const { windowWidth } = useWindowDimensions();

    const getIntro2ArrowSize = () => {
        if (windowWidth < 1025) return { width: 74, height: 165 };
        return { width: 157, height: 350 };
    };

    const ctaOnClick = () => {
        scrollDown();
        industrieIntroStore.setIndustrieIntroHasBeenScrolled();
    };

    const scrollDown = () => {
        if (intro1Ref.current && intro2Ref.current && introRef.current) {
            intro1Ref.current.style.animation = 'intro1ScrollAnimation 1s forwards';
            intro2Ref.current.style.animation = 'intro2ScrollAnimation 1s forwards';
            introRef.current.style.overflow = 'hidden';
        }
    };

    const handleScroll: UIEventHandler<HTMLDivElement> = (event) => {
        const scrollPosition = event.currentTarget.scrollTop;
        if (scrollPosition > 0 && !industrieIntroStore.industrieIntroHasBeenScrolled) {
            event.currentTarget.scrollTo(0, 0);
            event.currentTarget.style.overflow = 'hidden';
            scrollDown();
            industrieIntroStore.setIndustrieIntroHasBeenScrolled();
        }
    };

    useEffect(() => {
        if (intro1Ref.current && intro2Ref.current) {
            if (industrieIntroStore.industrieIntroHasBeenScrolled) {
                intro1Ref.current.className = 'element-invisible';
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="Intro" id="IndustrieIntro" onScroll={handleScroll} ref={introRef}>
            <div className="intro1" ref={intro1Ref}>
                <ScrollAnimationWrapper containerRef={intro1Ref}>
                    <div className="intro1__content">
                        <h1 className="element-invisible">
                            {Copydeck.industrieIndustrieIntro1Title}
                        </h1>
                        <h2 className="intro1__text">{Copydeck.industrieIndustrieIntro1Text}</h2>
                        <div className="intro1__cta">
                            <div className="intro1__ctaText">
                                {Copydeck.industrieIndustrieIntro1Cta}
                            </div>
                            <div className="intro1__ctaIcon">
                                <FancyArrowIcon
                                    direction="down"
                                    width={30}
                                    height={68}
                                    onClick={ctaOnClick}
                                    className="intro1__icon"
                                />
                            </div>
                        </div>
                    </div>
                </ScrollAnimationWrapper>
            </div>
            <div className="intro2" ref={intro2Ref}>
                <ScrollAnimationWrapper containerRef={intro2Ref}>
                    <div className="intro2__content">
                        <div className="intro2__text">{Copydeck.industrieIndustrieIntro2Text}</div>
                        <div className="intro2__icon">
                            <FancyArrowIcon
                                direction="down"
                                width={getIntro2ArrowSize().width}
                                height={getIntro2ArrowSize().height}
                                fill="#D64932"
                                className="intro2__arrow"
                            />
                        </div>

                        <div className="intro2__buttonContainer">
                            <Button
                                type="red"
                                width="full"
                                height="large"
                                onClick={() => history.push(METIER_URL)}
                                text={Copydeck.industrieIndustrieIntro2Cta}
                                withRightArrow
                            />
                        </div>
                    </div>
                </ScrollAnimationWrapper>
            </div>
        </div>
    );
});

export default Intro;
