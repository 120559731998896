import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const GooglemapMapIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
                <path
                    d="M13.1895 19.0586L7.91321 17.212L3.82412 18.7948C3.53099 18.9121 3.25985 18.8791 3.0107 18.6959C2.76154 18.5127 2.63696 18.2672 2.63696 17.9594V5.64817C2.63696 5.45764 2.69192 5.28909 2.80185 5.14253C2.91177 4.99597 3.06199 4.88605 3.25253 4.81276L7.91321 3.22989L13.1895 5.07658L17.2786 3.4937C17.5717 3.37645 17.8428 3.40943 18.092 3.59263C18.3411 3.77583 18.4657 4.02133 18.4657 4.32911V16.6404C18.4657 16.8309 18.4108 16.9994 18.3008 17.146C18.1909 17.2926 18.0407 17.4025 17.8502 17.4758L13.1895 19.0586ZM12.3101 16.9042V6.61548L8.79259 5.38436V15.673L12.3101 16.9042Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default GooglemapMapIcon;
