import { CONCESSIONS_MAKES_PARAM, CONCESSIONS_SEARCH_PARAM } from 'Models/Constants';
import FilterStore from 'Stores/FilterStore';
import { autorun } from 'mobx';
import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

const useConcessionsUrlParams = (filterStore: FilterStore) => {
    const search = useMemo(() => new URLSearchParams(window.location.search), []);
    const history = useHistory();

    useEffect(() => {
        const makesParam = search.get(CONCESSIONS_MAKES_PARAM);
        const searchParam = search.get(CONCESSIONS_SEARCH_PARAM);

        if (makesParam) {
            filterStore.updateMakes(makesParam.split(',').map((x) => ({ label: x, value: x })));
        }
        if (searchParam) {
            filterStore.updateSearchTerm(searchParam);
        }
    }, [filterStore, search]);

    useEffect(() => {
        const disposer = autorun(() => {
            const makes = filterStore.makes;
            const searchTerms = filterStore.searchTerm;

            if (makes) {
                search.set(CONCESSIONS_MAKES_PARAM, makes.map((x) => x.value).join(','));
            }
            if (searchTerms) {
                search.set(CONCESSIONS_SEARCH_PARAM, searchTerms);
            }
            if (makes?.length === 0) {
                search.delete(CONCESSIONS_MAKES_PARAM);
            }
            if (searchTerms === '') {
                search.delete(CONCESSIONS_SEARCH_PARAM);
            }

            history.replace({
                search: search.toString(),
            });
        });

        return () => {
            disposer();
        };
    }, [filterStore, history, search]);
};

export default useConcessionsUrlParams;
