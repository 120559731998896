import { action, observable } from 'mobx';

class IndustrieIntroStore {
    @observable industrieIntroHasBeenScrolled = false;

    @action
    setIndustrieIntroHasBeenScrolled(): void {
        this.industrieIntroHasBeenScrolled = true;
    }
}

export default IndustrieIntroStore;
