import React, { FunctionComponent, ReactNode } from 'react';
import './index.less';

interface QuestionLayoutProps {
    questionNumber: number;
    totalQuestionsNumber: number;
    question: string;
    answers: ReactNode[];
    previousButton?: ReactNode;
    nextButton?: ReactNode;
}

const QuestionLayout: FunctionComponent<QuestionLayoutProps> = ({
    questionNumber,
    totalQuestionsNumber,
    question,
    answers,
    previousButton,
    nextButton,
}) => {
    return (
        <div className="QuestionLayout" key={question}>
            <div className="text-white text-body-large mb-15">
                {questionNumber}/{totalQuestionsNumber}
            </div>

            <div className="text-white text-headline-medium-bold ml-10">
                {questionNumber}. {question}
            </div>

            <div className="answers-container">{answers}</div>

            <div className="buttons">
                {previousButton}
                <div className="next">{nextButton}</div>
            </div>
        </div>
    );
};

export default QuestionLayout;
