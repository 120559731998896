import FancyArrowIcon from 'Components/icons/fancy-arrow-icon';
import './index.less';
import MANQUE_PAS_TA_SHOT_IMAGE from 'Assets/Images/industrie/MANQUE_PAS_TA_SHOT.webp';
import Button from 'Components/button';
import { useHistory } from 'react-router-dom';
import { QUIZ_URL } from 'Models/Constants';
import Copydeck from 'i18n/Copydeck';
import AnimatedDraggingString from 'Components/animated-dragging-string';
import { useMemo, useRef } from 'react';
import useElementIsVisible from 'Hooks/use-element-is-visible';
import { observer } from 'mobx-react';
import { useStores, useWindowDimensions } from 'Hooks';
import ScrollAnimationWrapper from 'Components/scroll-animation-wrapper';

const Quiz = observer(() => {
    const history = useHistory();
    const { authenticationStore } = useStores();
    const titleRow2Ref = useRef<HTMLDivElement>(null);
    const titleRow2IsVisible = useElementIsVisible(titleRow2Ref);
    const quizRef = useRef<HTMLDivElement>(null);
    const MOBILE_BREAKPOINT = 500;
    const { windowWidth } = useWindowDimensions();

    const ctaText = useMemo(
        () =>
            authenticationStore.loggedIn
                ? Copydeck.industrieIndustrieQuizCtaConnected
                : Copydeck.industrieIndustrieQuizCta,
        [authenticationStore.loggedIn]
    );

    return (
        <div className="QuizSection" ref={quizRef}>
            <ScrollAnimationWrapper containerRef={quizRef}>
                <div className="QuizSection__content">
                    <div className="QuizSection__row QuizSection__row--1">
                        <h2 className="QuizSection__title">
                            <span className="QuizSection__title--row1">
                                {Copydeck.industrieIndustrieQuizTitleRow1}{' '}
                                <FancyArrowIcon
                                    direction="right"
                                    className="QuizSection__arrow"
                                    fill="#e03529"
                                    width={178}
                                    height={78}
                                />
                            </span>
                            <span className="QuizSection__title--row2" ref={titleRow2Ref}>
                                <AnimatedDraggingString
                                    color="white"
                                    textOffset={windowWidth > MOBILE_BREAKPOINT ? -20 : -5}
                                    isVisible={titleRow2IsVisible}
                                    text={Copydeck.industrieIndustrieQuizTitleRow2.split(' ')[0]}
                                    letterScaleX={1.3}
                                    letterWidth="18px"
                                />
                                <span className="lastWord">
                                    {Copydeck.industrieIndustrieQuizTitleRow2
                                        .split(' ')
                                        .slice(1)
                                        .join(' ')}
                                </span>
                            </span>
                        </h2>
                        <img
                            src={MANQUE_PAS_TA_SHOT_IMAGE}
                            alt=""
                            className="QuizSection__titleImage"
                        />
                    </div>
                    <div className="QuizSection__row QuizSection__row--2">
                        <h3 className="QuizSection__subtitle">
                            {Copydeck.industrieIndustrieQuizSubtitle}
                        </h3>
                        <div className="QuizSection__buttonContainer">
                            <Button
                                withRightArrow
                                text={ctaText}
                                type="red"
                                onClick={() => history.push(QUIZ_URL)}
                                width="full"
                                height="large"
                            />
                        </div>
                    </div>
                </div>
            </ScrollAnimationWrapper>
        </div>
    );
});

export default Quiz;
