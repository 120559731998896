import useElementIsVisible from 'Hooks/use-element-is-visible';
import { FC, RefObject, useMemo, useRef } from 'react';
import './index.less';

interface ScrollAnimationWrapperProps {
    containerRef: RefObject<HTMLElement>;
    timingFunction?: string;
    duration?: string;
    verticalOffset?: string;
}

const ScrollAnimationWrapper: FC<ScrollAnimationWrapperProps> = ({
    children,
    containerRef,
    timingFunction = 'ease-out',
    duration = '400ms',
    verticalOffset = '50px',
}) => {
    const componentRef = useRef<HTMLDivElement>(null);

    const componentHeight = useMemo(() => {
        return containerRef.current?.clientHeight;
    }, [containerRef]);

    const visibilityThreshold = useMemo(() => {
        if (!componentHeight) return 0.05;
        if (componentHeight < 300) return 0.1;
        if (componentHeight < 500) return 0.15;
        if (componentHeight < 700) return 0.2;
        if (componentHeight < 900) return 0.25;
        if (componentHeight < 1100) return 0.3;
        return 0.3;
    }, [componentHeight]);

    const isVisible = useElementIsVisible(containerRef, [visibilityThreshold]);
    const className = useMemo(
        () => (isVisible ? 'ScrollAnimationWrapper--visible' : 'ScrollAnimationWrapper'),
        [isVisible]
    );
    return (
        <div
            className={className}
            ref={componentRef}
            style={{
                animationTimingFunction: timingFunction,
                animationDuration: duration,
                transform: `translateY(${verticalOffset})`,
            }}
        >
            {children}
        </div>
    );
};

export default ScrollAnimationWrapper;
