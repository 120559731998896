import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useInfiniteScroll } from 'Hooks/use-infinite-scroll';
import { useFetch } from 'Hooks/use-fetch';
import { useService, useStores } from 'Hooks';
import { ConcessionService } from 'Services/ConcessionService';
import InfiniteScroll from 'Components/infinite-scroll';
import Skeleton, { SkeletonLoadingColor } from 'Components/Skeleton';
import {
    DEBOUNCE_DELAY_400,
    MAX_SEARCH_DISTANCE_RADIUS,
    PAGE_SIZE_INFINITE_LOADING,
} from 'Models/Constants';
import { observer } from 'mobx-react';
import FilterStore from 'Stores/FilterStore';
import { ConcessionViewBaseProps } from 'Routes/concessions/decouverte';
import ConcessionFilters from '../../filters';
import ConcessionCard from '../../concession-card';
import { JobApplicationService } from 'Services/JobApplicationService';
import { JobApplicationDto } from 'Api/Features/JobApplications/Dtos/JobApplicationDto';
import { GetJobApplicationsRequestDto } from 'Api/Features/JobApplications/Dtos/GetJobApplicationsRequestDto';
import { JobApplicationStatusDto } from 'Api/Features/JobApplications/Dtos/JobApplicationStatusDto';
import useConcessionsUrlParams from 'Hooks/use-concessions-url-params';
import { useTranslation } from 'react-i18next';
import useDebouncedFunction from 'Hooks/use-debounced-function';
import useDebouncedFetch from 'Hooks/use-debounced-fetch';
import './index.less';
import { GetRecruitmentConcessionsPublicRequestDto } from 'Api/Features/Concessions/Dtos/GetRecruitmentConcessionsPublicRequestDto';
import { GetRecruitmentConcessionsPublicItemResponseDto } from 'Api/Features/Concessions/Dtos/GetRecruitmentConcessionsPublicItemResponseDto';

const ConcessionListView: FunctionComponent<ConcessionViewBaseProps> = observer(
    ({ userPosition }) => {
        const {
            items,
            setItems,
            totalItemCount,
            setTotalItemCount,
            handleScrollToBottom,
            currentPage,
            setCurrentPage,
        } = useInfiniteScroll<GetRecruitmentConcessionsPublicItemResponseDto>();

        const { t } = useTranslation();
        const filterStoreRef = useRef(new FilterStore());
        useConcessionsUrlParams(filterStoreRef.current);
        const { userStore } = useStores();

        const jobApplicationService = useService(JobApplicationService);
        const [jobApplications, setJobApplications] = useState<JobApplicationDto[]>([]);

        const concessionService = useService(ConcessionService);
        const { apiRequest, loadingStateKeys } = useFetch();

        const fetchJobApplications = useCallback(async () => {
            if (!userStore.userInfo?.id) return;
            const { id: userId } = userStore.userInfo;

            const request: GetJobApplicationsRequestDto = {
                candidateIds: [userId],
            };

            const [applicatons] = await apiRequest({
                requestFunction: (request) => jobApplicationService.getJobApplications(request),
                requestParameters: request,
                useGlobalLoading: false,
            });

            setJobApplications(applicatons);
        }, [jobApplicationService, userStore.userInfo, apiRequest]);

        useEffect(() => {
            fetchJobApplications();
        }, [fetchJobApplications]);

        //reset search results on filter changes
        useDebouncedFunction(
            useCallback(() => {
                setItems([]);
                setCurrentPage(0);
                setTotalItemCount(0);
            }, [setItems, setCurrentPage, setTotalItemCount]),
            {
                searchTerm: filterStoreRef.current.searchTerm,
                makes: filterStoreRef.current.makes,
                userPosition: userPosition,
            },
            //needs to be slightly faster than the fetch
            DEBOUNCE_DELAY_400 - 50
        );

        //fetch concessions
        useDebouncedFetch({
            fetchFunction: useCallback(
                async (params: {
                    currentPage: number;
                    searchTerm?: string;
                    makes?: string[];
                    position?: { lat: number; lng: number };
                }) => {
                    const request: GetRecruitmentConcessionsPublicRequestDto = {
                        searchTerm: params.searchTerm,
                        page: params.currentPage,
                        pageSize: PAGE_SIZE_INFINITE_LOADING + 10, //since its 4 columns to try and always fill a full row
                        makes: params.makes,
                        latitude: params.position?.lat,
                        longitude: params.position?.lng,
                    };

                    const [concessions, totalItemsCount]: [
                        GetRecruitmentConcessionsPublicItemResponseDto[],
                        number
                    ] = await apiRequest({
                        requestFunction: (request) =>
                            concessionService.getRecruitmentConcessionsPublic(request),
                        requestParameters: request,
                        loadingStateKey: 'concessionsLoading',
                    });

                    setItems((prev) => [...prev, ...concessions]);
                    setTotalItemCount(totalItemsCount);
                },
                [concessionService, apiRequest, setItems, setTotalItemCount]
            ),
            fetchFunctionParams: useCallback(() => {
                const filterStore = filterStoreRef.current;

                const params = {
                    currentPage: currentPage,
                    searchTerm: filterStore.searchTerm,
                    pageSize: PAGE_SIZE_INFINITE_LOADING + 10, //since its 4 columns to try and always fill a full row
                    makes: filterStore.makes?.map((x) => x.value),
                    position: userPosition,
                };

                return params;
            }, [currentPage, userPosition]),
        });

        const isInArea = (distance: number): boolean =>
            userStore.userInfo?.searchRadius ? distance <= userStore.userInfo?.searchRadius : false;

        return (
            <div className="ConcessionListView">
                <ConcessionFilters filterStore={filterStoreRef.current} />

                <InfiniteScroll<GetRecruitmentConcessionsPublicItemResponseDto>
                    items={items}
                    RenderItem={(item) => (
                        <ConcessionCard
                            key={item.id}
                            concessionName={item.name || ''}
                            concessionSlug={item.slug || ''}
                            distance={item.distance}
                            hasJobApplication={jobApplications.some(
                                (job) =>
                                    job.candidate?.id === userStore.userInfo?.id &&
                                    job.concession?.id === item.id &&
                                    job.status === JobApplicationStatusDto.AwaitingResponse
                            )}
                            inArea={isInArea(item.distance || MAX_SEARCH_DISTANCE_RADIUS)}
                            isHiring={item.isHiring ?? false}
                            imgUrl={item.mainImage?.url}
                            makes={item.makes}
                        />
                    )}
                    RenderItemSkeleton={() => (
                        <Skeleton
                            isLoading={true}
                            placeholder={
                                <div>
                                    <div className="rect" style={{ width: 267, height: 303 }} />
                                </div>
                            }
                            loadingColor={SkeletonLoadingColor.blue}
                        />
                    )}
                    handleScrollToBottom={handleScrollToBottom}
                    paginationOptions={{
                        pageSize: PAGE_SIZE_INFINITE_LOADING + 10,
                        isLoading: loadingStateKeys.has('concessionsLoading'),
                    }}
                    numberSkeletonLoading={PAGE_SIZE_INFINITE_LOADING + 10}
                    marginTopWhenItemsPresent={20}
                />

                {totalItemCount === 0 && (filterStoreRef.current.makes?.length ?? 0) > 0 && (
                    <div className="text-headline-medium text-white mt-40 t-align-center">
                        {t('Concession.oops_aucun_concession')}
                    </div>
                )}
            </div>
        );
    }
);

export default ConcessionListView;
