import { MultiSelectCustomOption } from './multi-select/multi-select-common';
import { SingleSelectCustomOption } from './single-select/single-select-common';

export const singleSelectMergeSelectedOptionsWithSearchResults = (
    searchResults: SingleSelectCustomOption[],
    selectedOptions: (SingleSelectCustomOption | undefined)[] | undefined
): SingleSelectCustomOption[] => {
    if (selectedOptions === undefined) return searchResults;

    const merged = searchResults.concat(
        selectedOptions.filter((option) => option !== undefined).map((option) => option!)
    );
    for (let i = 0; i < merged.length; ++i) {
        for (let j = i + 1; j < merged.length; ++j) {
            if (merged[i].value === merged[j].value) merged.splice(j--, 1);
        }
    }

    return merged;
};

export const multiSelectMergeSelectedOptionsWithSearchResults = (
    searchResults: MultiSelectCustomOption[],
    selectedOptions: (MultiSelectCustomOption | undefined)[] | undefined
): MultiSelectCustomOption[] => {
    if (selectedOptions === undefined) return searchResults;

    const merged = searchResults.concat(
        selectedOptions.filter((option) => option !== undefined).map((option) => option!)
    );
    for (let i = 0; i < merged.length; ++i) {
        for (let j = i + 1; j < merged.length; ++j) {
            if (merged[i].value === merged[j].value) merged.splice(j--, 1);
        }
    }

    return merged;
};
