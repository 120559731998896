import React from 'react';
import './index.less';

interface ContentProps {
    className?: string;
    children: any | any[];
    smallLayout?: boolean;
}

const Content: React.FunctionComponent<ContentProps> = ({ className, smallLayout, children }) => {
    return (
        <div className={`Content ${className} ${smallLayout ? 'small-layout' : ''}`}>
            {children}
        </div>
    );
};

export default Content;
