import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

interface LongArrowIconProps extends SvgProps {
    direction: 'left' | 'right' | 'up' | 'down';
}

const LongArrowIcon: FunctionComponent<LongArrowIconProps> = ({
    direction,
    width,
    height,
    fill,
    ...props
}) => {
    const getIconRotation = () => {
        switch (direction) {
            case 'left':
                return 'rotate(180deg)';
            case 'up':
                return 'rotate(-90deg)';
            case 'down':
                return 'rotate(90deg)';
            default:
                return 'rotate(0deg)';
        }
    };

    const getSize = () => {
        const defaultWidth = 145;
        const defaultHeight = 24;

        if (direction === 'left' || direction === 'right') {
            return {
                width: width || defaultWidth,
                height: height || defaultHeight,
            };
        }

        return {
            width: height || defaultHeight,
            height: width || defaultWidth,
        };
    };

    return (
        <svg
            width={getSize().width}
            height={getSize().height}
            viewBox="0 0 145 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            style={{
                transform: getIconRotation(),
            }}
        >
            <path
                d="M2 10.5C1.17157 10.5 0.5 11.1716 0.5 12C0.5 12.8284 1.17157 13.5 2 13.5L2 10.5ZM144.061 13.0606C144.646 12.4749 144.646 11.5251 144.061 10.9393L134.515 1.39339C133.929 0.8076 132.979 0.8076 132.393 1.39339C131.808 1.97917 131.808 2.92892 132.393 3.51471L140.879 12L132.393 20.4853C131.808 21.0711 131.808 22.0208 132.393 22.6066C132.979 23.1924 133.929 23.1924 134.515 22.6066L144.061 13.0606ZM2 13.5L143 13.5L143 10.5L2 10.5L2 13.5Z"
                fill={fill || '#E03529'}
            />
        </svg>
    );
};

export default LongArrowIcon;
