interface Quiz {
    questions: QuizQuestion[];
}

interface QuizQuestion {
    id: string;
    title: string;
    textKey: string;
    choices: QuizChoice[];
}

interface QuizChoice {
    id: string;
    text: string;
    categories: JobCategory[];
}

export enum JobCategory {
    Admin = 'Admin',
    Management = 'Management',
    Sales = 'Sales',
    Mechanical = 'Mechanical',
    BodyShop = 'BodyShop',
    Other = 'Other',
}

export const QuizDefinition: Quiz = {
    questions: [
        {
            id: 'c27e4502-3aca-4e4c-9935-6789a8c3c5a0',
            title: 'Compétences et spécialisations',
            textKey: 'Quiz.q1_question',
            choices: [
                {
                    id: 'b9c99bae-8f0f-407e-bf96-04a1850ada13',
                    text: 'Communication et relationnel.',
                    categories: [JobCategory.Admin, JobCategory.Management, JobCategory.Sales],
                },
                {
                    id: 'a0d013a3-5aff-4354-884f-21011709b0ca',
                    text: 'Analyse et résolution de problèmes.',
                    categories: [
                        JobCategory.Management,
                        JobCategory.Mechanical,
                        JobCategory.BodyShop,
                    ],
                },
                {
                    id: '427bf32b-fd5d-42db-938b-444a64e8ff73',
                    text: 'Compétences techniques spécifiques.',
                    categories: [JobCategory.Admin, JobCategory.Mechanical, JobCategory.BodyShop],
                },
            ],
        },
        {
            id: '449ace46-8ea5-4efe-90b4-45be8b9441a8',
            title: 'Intérêts professionnels',
            textKey: 'Quiz.q2_question',
            choices: [
                {
                    id: '4bd8cffe-aa08-4772-9d56-5e1a21b837b9',
                    text: 'Le côté relationnel avec les client.e.s',
                    categories: [JobCategory.Admin, JobCategory.Sales],
                },
                {
                    id: 'c1ee6a42-026c-4d97-b024-14516159d9d0',
                    text: 'La résolution de problèmes techniques.',
                    categories: [JobCategory.Mechanical, JobCategory.BodyShop],
                },
                {
                    id: 'ec0a915f-2e30-4a41-9d93-5cbcb0c98866',
                    text: 'Gestion administrative et opérationnelle.',
                    categories: [JobCategory.Admin, JobCategory.Management],
                },
            ],
        },
        {
            id: 'c4548a3e-cc12-4272-bf87-b2e6be7d2160',
            title: 'Flexibilité et horaires de travail',
            textKey: 'Quiz.q3_question',
            choices: [
                {
                    id: 'f9c8534b-af33-450d-9096-b0c2cd92b7ec',
                    text: 'Un horaire régulier de 9h à 17h du lundi au vendredi.',
                    categories: [JobCategory.Admin, JobCategory.Management, JobCategory.BodyShop],
                },
                {
                    id: 'b50b1558-8b4a-41ea-b341-d9f75a7acc32',
                    text: 'Des horaires flexibles avec la possibilité de travail en soirée ou la fin de semaine',
                    categories: [JobCategory.Admin, JobCategory.Mechanical],
                },
                {
                    id: 'dc32ba6c-c5fd-48ec-b144-afd4b1e91055',
                    text: "Un horaire variable en fonction des besoins de l'entreprise.",
                    categories: [JobCategory.Sales],
                },
            ],
        },
        {
            id: '4e8fb538-5d19-474f-9a65-d0e590601323',
            title: 'Tolérance au bruit',
            textKey: 'Quiz.q4_question',
            choices: [
                {
                    id: 'e5b3f8dc-c057-4c3f-aaad-f401bb218488',
                    text: "Je suis à l'aise dans des environnements bruyants sans que cela ne me dérange.",
                    categories: [JobCategory.Mechanical, JobCategory.BodyShop],
                },
                {
                    id: '826c3d13-0740-40f6-8d00-03a0da0fbf39',
                    text: "J'ai besoin d'un niveau sonore modéré pour me concentrer efficacement.",
                    categories: [JobCategory.Admin, JobCategory.Management, JobCategory.Sales],
                },
                {
                    id: '37d3230b-50e8-4045-9e3f-2c51361c519c',
                    text: 'Je préfère travailler dans un environnement calme et peu bruyant.',
                    categories: [JobCategory.Admin, JobCategory.Management],
                },
            ],
        },
        {
            id: '040b81cc-a0a0-4f1b-8677-d92f08d495b8',
            title: 'Ergonomie au tavail',
            textKey: 'Quiz.q5_question',
            choices: [
                {
                    id: 'a348f00c-3b61-46dc-a04b-16c7e4cdb3cc',
                    text: 'Je préfère travailler principalement en position assise avec une chaise ergonomique, favorisant une posture correcte et un soutien lombaire.',
                    categories: [JobCategory.Admin, JobCategory.Management],
                },
                {
                    id: 'd0703b43-0372-4bc7-a24f-9454d3c275e7',
                    text: "J'opte pour la flexibilité et je préfère passer aisément de la position assise à la position debout pour maintenir une variété de postures tout au long de la journée.",
                    categories: [JobCategory.Sales],
                },
                {
                    id: '5e59f8b2-1ac1-4449-b983-11e4a8d62f9c',
                    text: 'Je préfère un environnement de travail qui encourage la mobilité, le travail physique, etc.',
                    categories: [JobCategory.Mechanical, JobCategory.BodyShop],
                },
            ],
        },
    ],
};
