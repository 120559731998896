import { FunctionComponent } from 'react';
import './index.less';
import { CandidateDetailsDto } from 'Api/Features/Candidates/Dtos/CandidateDetailsDto';
import Copydeck from 'i18n/Copydeck';
import Button from 'Components/button';
import { useHistory } from 'react-router-dom';
import { HOME_PAGE_URL, QUIZ_URL } from 'Models/Constants';
import { useStores, useWindowDimensions } from 'Hooks';
import Content from 'Components/Content';

interface AccountHeaderProps {
    date?: string;
    candidate?: CandidateDetailsDto;
}

const AccountHeader: FunctionComponent<AccountHeaderProps> = ({
    candidate,
    date,
}: AccountHeaderProps) => {
    const history = useHistory();
    const { windowWidth } = useWindowDimensions();
    const { userStore, authenticationStore } = useStores();

    return (
        <div className="AccountHeader">
            <Content className="AccountHeader__content">
                <div className="AccountHeader__candidateInfo">
                    <div className="text-body-large AccountHeader__date">{date}</div>
                    <div className="text-display-large-extenda AccountHeader__name">
                        {`${Copydeck.accountAccountHi} ${candidate?.firstName} !`}
                    </div>
                </div>
                <div className="AccountHeader__ctaContainer">
                    <Button
                        type="red"
                        text={Copydeck.accountAccountCta}
                        onClick={() => history.push(QUIZ_URL)}
                        width={windowWidth > 768 ? 'auto' : 'full'}
                    />
                    <Button
                        type="text"
                        text={Copydeck.accountAccountLogout}
                        leftIcon="LogoutIcon"
                        className="AccountHeader__logoutButton"
                        onClick={() => {
                            userStore.clearUserInfo();
                            authenticationStore.clearSession();
                            history.push(HOME_PAGE_URL);
                        }}
                        textClassName="text-body-large"
                    />
                </div>
            </Content>
        </div>
    );
};
export default AccountHeader;
