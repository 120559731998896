import { FunctionComponent } from 'react';
import './index.less';
import Intro from './intro/Intro';
import Metiers from './metiers';
import StatsConcessionnaire from './stats-concessionnaire';
import Temoignages from './temoignages';
import Quiz from './quiz';

const Industrie: FunctionComponent = () => {
    return (
        <div className="Industrie">
            <div className="Industrie__background"></div>
            <Intro />
            <Metiers />
            <StatsConcessionnaire />
            <Temoignages />
            <Quiz />
        </div>
    );
};

export default Industrie;
