import AnnabelleImage from '../Assets/Images/temoignages/Anabelle.webp';
import CatherineImage from '../Assets/Images/temoignages/Catherine.webp';
import MathieuImage from '../Assets/Images/temoignages/Mathieu.webp';
import SteffyImage from '../Assets/Images/temoignages/Steffy.webp';

export type Temoingnages = {
    quote: string;
    description: string;
    longDescription: string;
    name: string;
    jobTitle: string;
    url: string;
    image: string;
    video?: string;
    id: number;
};

export const TemoignagesDefinition: Temoingnages[] = [
    {
        quote: 'temoignagesTemoignagesCard1Quote',
        description: 'temoignagesTemoignagesCard1Description',
        longDescription: 'temoignagesTemoignagesCard1LongDescription',
        name: 'temoignagesTemoignagesCard1Name',
        jobTitle: 'temoignagesTemoignagesCard1JobTitle',
        url: 'temoignagesTemoignagesCard1Url',
        image: AnnabelleImage,
        video: 'https://www.youtube.com/embed/ZnIMg6tFHd4?si=AlpuIRLkQMYoQSgk',
        id: 1,
    },
    {
        quote: 'temoignagesTemoignagesCard2Quote',
        description: 'temoignagesTemoignagesCard2Description',
        longDescription: 'temoignagesTemoignagesCard2LongDescription',
        name: 'temoignagesTemoignagesCard2Name',
        jobTitle: 'temoignagesTemoignagesCard2JobTitle',
        url: 'temoignagesTemoignagesCard2Url',
        image: CatherineImage,
        video: 'https://www.youtube.com/embed/6hc_1THBLFA?si=4wPMq3mecCJCO6PO',
        id: 2,
    },
    {
        quote: 'temoignagesTemoignagesCard3Quote',
        description: 'temoignagesTemoignagesCard3Description',
        longDescription: 'temoignagesTemoignagesCard3LongDescription',
        name: 'temoignagesTemoignagesCard3Name',
        jobTitle: 'temoignagesTemoignagesCard3JobTitle',
        url: 'temoignagesTemoignagesCard3Url',
        image: MathieuImage,
        video: 'https://www.youtube.com/embed/FOqM_u2UMyY?si=y1oRcj2Kvnki0aSZ',
        id: 3,
    },
    {
        quote: 'temoignagesTemoignagesCard4Quote',
        description: 'temoignagesTemoignagesCard4Description',
        longDescription: 'temoignagesTemoignagesCard4LongDescription',
        name: 'temoignagesTemoignagesCard4Name',
        jobTitle: 'temoignagesTemoignagesCard4JobTitle',
        url: 'temoignagesTemoignagesCard4Url',
        image: SteffyImage,
        video: 'https://www.youtube.com/embed/oRzPwTccWvM?si=QdSlR5R8gY4IxvPX',
        id: 4,
    },
];
