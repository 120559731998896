import { JobApplicationDto } from 'Api/Features/JobApplications/Dtos/JobApplicationDto';
import React, { FunctionComponent } from 'react';
import './index.less';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { FORMAT_MONTH_DATE_COMMA_YEAR } from 'Models/Constants';
import Tag from 'Components/tag/tag';
import { JobApplicationStatusToTagProps } from 'Utils/TagUtils';
import { JobApplicationStatusDto } from 'Api/Features/JobApplications/Dtos/JobApplicationStatusDto';

interface JobApplicationCardProps {
    jobApplication: JobApplicationDto;
}
const JobApplicationCard: FunctionComponent<JobApplicationCardProps> = ({ jobApplication }) => {
    const { t } = useTranslation();

    const info = (label: string, value?: string | null) => (
        <div>
            <div className="text-body-medium-bold">{label}</div>
            <div className="text-body-medium">{value}</div>
        </div>
    );

    return (
        <div className="JobApplicationCard">
            <div>
                <div>
                    {info(
                        t('date_de_soumission'),
                        moment(jobApplication.date).format(FORMAT_MONTH_DATE_COMMA_YEAR.FR)
                    )}
                </div>
                <div>{info(t('concession'), jobApplication.concession?.name)}</div>
                <div className="last-row">
                    {info(
                        t('distance'),
                        `${Math.round(jobApplication.candidateDistance ?? 0).toString()} KM`
                    )}
                    <Tag
                        {...JobApplicationStatusToTagProps(
                            jobApplication.status ?? JobApplicationStatusDto.AwaitingResponse,
                            t
                        )}
                    />
                </div>
            </div>
        </div>
    );
};

export default JobApplicationCard;
