import Carousel from 'Components/carousel';
import './index.less';
import TemoignageCard from 'Components/temoignage-card';
import Button from 'Components/button';
import { useHistory } from 'react-router-dom';
import { TEMOIGNAGE_URL } from 'Models/Constants';
import { useWindowDimensions } from 'Hooks';
import { useRef } from 'react';
import Copydeck from 'i18n/Copydeck';
import ScrollAnimationWrapper from 'Components/scroll-animation-wrapper';
import { TemoignagesDefinition } from 'Models/TemoignagesDefinition';
import YoutubeVideoPlayer from 'Components/youtube-video-player';

const Temoignages = () => {
    const TEMOIGNAGES = TemoignagesDefinition.slice(1, 4);

    const history = useHistory();
    const { windowWidth } = useWindowDimensions();
    const temoignagesRef = useRef<HTMLDivElement>(null);

    const CAROUSEL_BREAKPOINT = 1015;
    const YOUTUBE_VIDEO_BREAKPOINT = 1015;

    return (
        <div className="Temoignages" ref={temoignagesRef}>
            <ScrollAnimationWrapper containerRef={temoignagesRef}>
                <div className="Temoignages__content">
                    <h2
                        className="Temoignages__title"
                        dangerouslySetInnerHTML={{
                            __html: Copydeck.industrieIndustrieTemoignagesTitle,
                        }}
                    />
                    <div className="Temoignages__videoSection">
                        <YoutubeVideoPlayer
                            url={TemoignagesDefinition[0].video!}
                            width={windowWidth > YOUTUBE_VIDEO_BREAKPOINT ? '647px' : '100%'}
                            aspectRatio="647 / 364"
                        />
                        <div
                            className="Temoignages__videoDescription"
                            dangerouslySetInnerHTML={{
                                __html: Copydeck.industrieIndustrieTemoignagesVideoDescription,
                            }}
                        ></div>
                    </div>
                    <div className="Temoignages__carouselSection">
                        {windowWidth > CAROUSEL_BREAKPOINT ? (
                            <ul className="Temoignages__temoignages">
                                {TEMOIGNAGES.map((temoignage, index) => (
                                    <li key={index}>
                                        <TemoignageCard
                                            description={Copydeck[temoignage.description]}
                                            image={temoignage.image}
                                            jobTitle={Copydeck[temoignage.jobTitle]}
                                            name={Copydeck[temoignage.name]}
                                            quote={Copydeck[temoignage.quote]}
                                        />
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <Carousel
                                className="Temoingnages__carousel"
                                elements={TEMOIGNAGES.map((temoignage, index) => (
                                    <TemoignageCard
                                        key={index}
                                        description={Copydeck[temoignage.description]}
                                        image={temoignage.image}
                                        jobTitle={Copydeck[temoignage.jobTitle]}
                                        name={Copydeck[temoignage.name]}
                                        quote={Copydeck[temoignage.quote]}
                                    />
                                ))}
                            />
                        )}
                    </div>

                    <div className="Temoignages__buttonSection">
                        <Button
                            withRightArrow
                            text={Copydeck.industrieIndustrieTemoignagesCta}
                            type="red"
                            onClick={() => history.push(TEMOIGNAGE_URL)}
                            width="full"
                            height="large"
                        />
                    </div>
                </div>
            </ScrollAnimationWrapper>
        </div>
    );
};

export default Temoignages;
