import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const GooglemapMylocationIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
                <path
                    d="M9 6C7.3425 6 6 7.3425 6 9C6 10.6575 7.3425 12 9 12C10.6575 12 12 10.6575 12 9C12 7.3425 10.6575 6 9 6ZM15.705 8.25C15.36 5.1225 12.8775 2.64 9.75 2.295V0.75H8.25V2.295C5.1225 2.64 2.64 5.1225 2.295 8.25H0.75V9.75H2.295C2.64 12.8775 5.1225 15.36 8.25 15.705V17.25H9.75V15.705C12.8775 15.36 15.36 12.8775 15.705 9.75H17.25V8.25H15.705ZM9 14.25C6.0975 14.25 3.75 11.9025 3.75 9C3.75 6.0975 6.0975 3.75 9 3.75C11.9025 3.75 14.25 6.0975 14.25 9C14.25 11.9025 11.9025 14.25 9 14.25Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default GooglemapMylocationIcon;
