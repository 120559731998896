import React, { FunctionComponent, ReactNode } from 'react';
import { Form } from 'antd';
import { FormItemProps } from 'antd/es/form';
import './index.less';
// import { useStores } from 'Hooks';
// import { SupportedLanguage } from 'Stores/LanguageStore';
import { observer } from 'mobx-react';
// import { SupportedLanguage } from 'Stores/LanguageStore';

interface ValidatedFormItemProps extends FormItemProps {
    errors?: Map<string, string[]>;
    label: string;
    labelColor: LabelColor;
    labelClassName?: string;
    disabled?: boolean;
    capitalizeLabel?: boolean;
    labelHtmlTag?: keyof JSX.IntrinsicElements;
}

export enum LabelColor {
    white = 'white',
    grey = 'grey',
}

const ValidatedFormItem: FunctionComponent<ValidatedFormItemProps> = observer(
    ({
        children,
        name,
        label,
        labelColor,
        errors,
        /* capitalizeLabel,*/ disabled,
        labelHtmlTag = 'div',
        labelClassName = '',
        ...props
    }) => {
        // const { languageStore } = useStores();

        const LabelTag = labelHtmlTag as keyof JSX.IntrinsicElements;

        const fieldErrors = errors?.get(name?.toString() || '');
        const errorProps: FormItemProps = {};
        if (fieldErrors) {
            errorProps.hasFeedback = true;
            errorProps.help = fieldErrors.map((errorKey) => `${errorKey}`);
            errorProps.validateStatus = 'error';
        }

        const formattedLabel = (): ReactNode => {
            return (
                <LabelTag
                    className={`${disabled ? 'disabled' : ''} ${'french-label'} ${
                        labelColor === LabelColor.white ? 'label-white' : 'label-grey'
                    } ${labelClassName}`}
                >
                    {label}
                    {props.required && <span>{'*'}</span>}
                </LabelTag>
            );
        };

        return (
            <div className={`ValidatedFormItem ${props.className ?? ''}`}>
                <Form.Item {...props} name={name} label={formattedLabel()} {...errorProps}>
                    {children}
                </Form.Item>
            </div>
        );
    }
);

export default ValidatedFormItem;
