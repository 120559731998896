import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const TemoignageIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <path
                    d="M10.4 15.8H17.6V15.305C17.6 14.645 17.27 14.1125 16.61 13.7075C15.95 13.3025 15.08 13.1 14 13.1C12.92 13.1 12.05 13.3025 11.39 13.7075C10.73 14.1125 10.4 14.645 10.4 15.305V15.8ZM14 12.2C14.495 12.2 14.9187 12.0237 15.2712 11.6712C15.6237 11.3187 15.8 10.895 15.8 10.4C15.8 9.905 15.6237 9.48125 15.2712 9.12875C14.9187 8.77625 14.495 8.6 14 8.6C13.505 8.6 13.0813 8.77625 12.7288 9.12875C12.3763 9.48125 12.2 9.905 12.2 10.4C12.2 10.895 12.3763 11.3187 12.7288 11.6712C13.0813 12.0237 13.505 12.2 14 12.2ZM5 23V6.8C5 6.305 5.17625 5.88125 5.52875 5.52875C5.88125 5.17625 6.305 5 6.8 5H21.2C21.695 5 22.1187 5.17625 22.4713 5.52875C22.8237 5.88125 23 6.305 23 6.8V17.6C23 18.095 22.8237 18.5188 22.4713 18.8713C22.1187 19.2238 21.695 19.4 21.2 19.4H8.6L5 23Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default TemoignageIcon;
