import React, { useEffect, useState } from 'react';
import { CandidateQuizResponseDto } from 'Api/Features/Candidates/Dtos/CandidateQuizResponseDto';
import Content from 'Components/Content';
import Skeleton from 'Components/Skeleton';
import { InputSkeletonShape } from 'Components/Skeleton/generic-component-shapes/inputs';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import StaticSingleSelect from 'Components/select-custom/single-select/static-single-select';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { LabelColor } from 'Components/validated-form-item/validated-form-items';
import { useService, useStores } from 'Hooks';
import { useFetch } from 'Hooks/use-fetch';
import { QuizDefinition } from 'Models/QuizDefinitions';
import { CandidateService } from 'Services/CandidateService';
import { Form } from 'antd';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { UpdateCandidateQuizRequestDto } from 'Api/Features/Candidates/Dtos/UpdateCandidateQuizRequestDto';
import './quiz.less';
import WhiteCard from 'Components/white-card';
import Button from 'Components/button';
import Copydeck from 'i18n/Copydeck';

const AccountQuiz = observer(() => {
    const { t } = useTranslation();
    const { languageStore, userStore } = useStores();
    const { apiRequest } = useFetch();
    const candidateService = useService(CandidateService);
    const [candidateQuizResponses, setCandidateQuizResponses] = useState<
        (CandidateQuizResponseDto | null)[]
    >(
        QuizDefinition.questions.map((x) => ({
            choiceId: x.choices[0].id,
            questionId: x.id,
        }))
    );

    useEffect(() => {
        if (userStore.userQuizAnswers?.responses) {
            setCandidateQuizResponses(userStore.userQuizAnswers.responses);
        }
    }, [userStore.userQuizAnswers?.responses]);

    const submit = async () => {
        const quizRequest: UpdateCandidateQuizRequestDto = {
            responses: candidateQuizResponses ?? [],
        };

        await apiRequest({
            requestFunction: (request) =>
                candidateService.updateCandidateQuiz(userStore.userInfo?.id ?? '', request),
            requestParameters: quizRequest,
            useGlobalLoading: true,
            useDefaultSuccessToast: true,
        });
    };

    return (
        <div className="AccountQuiz">
            <Content>
                <WhiteCard padding={10}>
                    <Form layout="vertical">
                        <div className="quiz-answers-container">
                            {QuizDefinition.questions.map((question, i) => (
                                <div className="quiz-answer" key={i}>
                                    <div className="text-title-medium-bold text-primary question">
                                        <div>{i + 1}.</div>
                                        <div>{t(question.textKey)}</div>
                                    </div>
                                    <div className="answer">
                                        <Skeleton
                                            isLoading={userStore.userQuizAnswers === null}
                                            placeholder={InputSkeletonShape(
                                                t('reponse'),
                                                languageStore.currentLanguage
                                            )}
                                        >
                                            <ValidatedFormItem
                                                label={t('reponse')}
                                                labelColor={LabelColor.grey}
                                            >
                                                <StaticSingleSelect
                                                    onChange={(
                                                        value?: SingleSelectCustomOption
                                                    ) => {
                                                        setCandidateQuizResponses((prev) => {
                                                            prev[i] = {
                                                                choiceId: value?.value,
                                                                questionId: prev[i]?.questionId,
                                                            };
                                                            return [...prev];
                                                        });
                                                    }}
                                                    options={question.choices.map((x) => ({
                                                        label: x.text,
                                                        value: x.id,
                                                    }))}
                                                    selected={candidateQuizResponses?.[i]?.choiceId}
                                                />
                                            </ValidatedFormItem>
                                        </Skeleton>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Form>
                </WhiteCard>

                <div className="submit-btn">
                    <Button
                        text={Copydeck.accountAccountFormSubmit}
                        type="red"
                        onClick={() => submit()}
                        uppercase
                    />
                </div>
            </Content>
        </div>
    );
});

export default AccountQuiz;
