export const ValuesDefinitions: ValueDefinition[] = [
    {
        id: 'c27e4502-3aca-4e4c-9935-6789a8c3c5a0',
        title: 'Bienveillance',
        description:
            "Chez nous, l'empathie, la compassion et le respect sont les piliers d'une expérience positive pour chacun.e.",
    },
    {
        id: 'b87b93ff-1046-435c-a5f0-8c2c7d5cb459',
        title: 'Intégrité',
        description:
            "L'éthique et l'honnêteté guident chacune de nos interactions avec nos client.es, collègues et partenaires.",
    },
    {
        id: '73ce616f-6943-4aa7-9bba-0f3118a5a268',
        title: 'Fiabilité',
        description:
            "Nous valorisons la constance, la précision et la responsabilité, assurant le bon fonctionnement de l'organisation et la satisfaction des client.es.",
    },
    {
        id: 'c3d5e8f1-d1a6-41a7-8901-8d7b4e7bff0f',
        title: 'Respect des Échéanciers',
        description:
            "Nous honorons nos délais, assurant une livraison fiable et efficace pour le succès de toute l'équipe.",
    },
    {
        id: '50c09c44-9c11-4877-a801-d29ffedfb0ea',
        title: 'Méthodique',
        description:
            'Notre approche ordonnée et structurée garantit la précision et la qualité dans chaque projet.',
    },
    {
        id: '5825b6db-23a2-4b7d-bf4e-3d3a0f712a5c',
        title: 'Autonomie',
        description:
            "Encourageant l'initiative individuelle, nous permettons à chacun.e de briller et d'évoluer professionnellement.",
    },
    {
        id: 'f03bdb32-c15a-4b75-bf50-5643d48549d8',
        title: 'Adaptation',
        description:
            "Nous valorisons la flexibilité et la capacité d'ajustement face aux changements pour mieux servir nos client.es.",
    },
    {
        id: '97aa1e3f-7bd4-46f4-8bb5-5ff5d8c0ae34',
        title: 'Transparence',
        description:
            "Nous prônons une transparence totale dans nos actions et communications, renforçant ainsi la confiance et l'intégrité au sein de l'équipe.",
    },
    {
        id: '2ed1e8a3-bd6d-40b3-b648-0e02b0f457e7',
        title: 'Bonne Communication',
        description:
            "Nos échanges sont clairs, ouverts et respectueux, afin d'assurer une collaboration efficace et la réussite collective.",
    },
    {
        id: 'd7a50a53-dbe4-45e5-8463-33e11e2d6b1f',
        title: "Esprit d'Équipe",
        description:
            "Nous valorisons la collaboration, le partage d'idées et le soutien mutuel pour atteindre ensemble nos objectifs.",
    },
    {
        id: '4aa37423-0a0c-4d3c-b5d6-c1b5a832dfed',
        title: 'Éthique',
        description:
            "Notre fondement repose sur le respect, la justice et l'équité, assurant une conduite morale irréprochable dans toutes nos actions.",
    },
    {
        id: '1cf4f95c-f33f-4665-8a69-3ad5a334b8cb',
        title: 'Cohérence',
        description:
            "Nous maintenons une ligne constante dans nos actions et décisions, assurant l'alignement avec nos valeurs et objectifs.",
    },
    {
        id: '723583f7-5e8e-472d-a1f8-3c8a63e55a78',
        title: 'Flexibilité',
        description:
            'Nous nous adaptons avec agilité aux nouvelles situations, répondant efficacement aux besoins changeants.',
    },
    {
        id: '5b2fcf48-b72f-42a4-92f2-3921dd080aac',
        title: 'Résilience',
        description:
            'Face aux défis, nous persévérons et nous adaptons pour surmonter les obstacles et avancer.',
    },
    {
        id: 'c6a579fd-89ef-4ff6-a3e7-56e1c0c24d5e',
        title: 'Influence',
        description:
            "Nous inspirons et motivons positivement notre équipe, ce qui nous permet d'avoir un impact significatif dans le quotidien de tous.tes.",
    },
    {
        id: '43eb8133-13cc-4408-bf6f-d1c9ed61ef39',
        title: 'Expertise',
        description:
            'Nous cultivons une maîtrise approfondie dans nos domaines, assurant excellence et compétence dans tous nos services.',
    },
    {
        id: '7b76f6a6-29a4-4d3e-a8f8-0d975de65969',
        title: 'Résolution de Problèmes',
        description:
            'Nous abordons les défis avec créativité et pragmatisme, trouvant des solutions efficaces et innovantes.',
    },
    {
        id: 'a5cfb26c-2d61-498c-ace6-17373ffed2d1',
        title: 'Agilité',
        description:
            'Notre capacité à nous adapter rapidement aux changements garantit notre réussite dans un environnement dynamique.',
    },
    {
        id: '2b3b8c53-f3a8-4cf3-a229-9de52ff5a6e2',
        title: 'Curiosité',
        description:
            "Nous encourageons une quête constante de savoir et d'innovation, ce qui enrichit notre travail et notre approche.",
    },
    {
        id: '22c4e2cf-f802-401f-8c8f-e8c9b1e0a1c6',
        title: 'Sécurité',
        description:
            'La protection de nos collaborateurs, clients et partenaires est une priorité qui oriente chacune de nos actions.',
    },
];

export interface ValueDefinition {
    id: string;
    title: string;
    description: string;
}
