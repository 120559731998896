import { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import './index.less';
import { theme } from 'Style/theme';
import { useTranslation } from 'react-i18next';
import { useStores } from 'Hooks';
import { SingleValue } from 'Components/select-custom/single-select/single-select-common';

interface GooglePlacesAutoCompleteProps {
    onChange: (value?: string, label?: string) => void;
    defaultValue?: { label: string; value: string };
    isClearable?: boolean;
    className?: string;
    hasError?: boolean;
    blueStyle?: boolean; //SPECIFIC TO CCAQ METIER
}

const GooglePlacesAutoComplete: FunctionComponent<GooglePlacesAutoCompleteProps> = observer(
    ({ onChange, defaultValue, isClearable, className, hasError, blueStyle }) => {
        const { t } = useTranslation();
        const { languageStore } = useStores();

        const handleChange = (event) => {
            if (event === null || event === undefined) onChange(undefined, undefined);
            else {
                onChange(event.value.place_id, event.label);
            }
        };

        //https://react-select.com/styles
        const customStyles = {
            indicatorSeparator: (provided: any) => ({
                ...provided,
                display: 'none',
            }),
            dropdownIndicator: (provided: any) => ({
                ...provided,
                display: 'none',
            }),
            singleValue: (provided: any) => ({
                ...provided,
                fontSize: '14px',
                fontWeight: '400',
                color: theme['text-high-contrast'],
            }),
            placeholder: (provided: any) => ({
                ...provided,
            }),
            input: (provided: any) => ({
                ...provided,
                padding: '8px',
            }),
        };

        return (
            <div className={`GooglePlacesAutoComplete ${className} ${hasError && 'errored'}`}>
                <GooglePlacesAutocomplete
                    autocompletionRequest={{
                        types: ['(cities)'],
                        componentRestrictions: { country: 'ca' },
                    }}
                    selectProps={{
                        defaultValue: defaultValue,
                        onChange: handleChange,
                        styles: customStyles,
                        placeholder: t('Location.search_address'),
                        isClearable: isClearable,
                        className: `SingleSelect ${blueStyle && 'blue-style'}`,
                        components: {
                            SingleValue: (props) => (
                                <SingleValue {...props} isDeepBlue={blueStyle} />
                            ),
                        },
                    }}
                    apiOptions={{ language: languageStore.currentLanguage }}
                />
            </div>
        );
    }
);

export default GooglePlacesAutoComplete;
