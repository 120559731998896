import { FunctionComponent, useState } from 'react';
import './index.less';
import { HeaderProps } from 'Components/header';
import ConnectedUser from '../connected-user';
import Button from 'Components/button';
import Copydeck from 'i18n/Copydeck';
import { ACCOUNT_URL, CREATE_ACCOUNT_URL, LOGIN_URL } from 'Models/Constants';
import MenuIcon from 'Components/icons/menu-icon';
import { CloseIcon } from 'Components/icons';
import useSelectedNav from 'Hooks/use-selected-nav';

const MobileHeaderLayout: FunctionComponent<HeaderProps> = ({
    isLoggedIn,
    navItems,
    onNavigationClick,
}) => {
    const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
    const selectedKey = useSelectedNav();

    return (
        <div className="MobileHeaderLayout">
            {isLoggedIn ? (
                <div role="button" onClick={() => onNavigationClick(ACCOUNT_URL)}>
                    <ConnectedUser isMobile />
                </div>
            ) : (
                <Button
                    type="red"
                    width="hugged"
                    height="small"
                    text={Copydeck.userCreeMonCompte}
                    onClick={() => onNavigationClick(CREATE_ACCOUNT_URL)}
                />
            )}
            <MenuIcon role="button" onClick={() => setMenuIsOpen(true)} />

            <div className={`nav-container ${menuIsOpen ? 'nav-container--open' : ''}`}>
                <div className="top-row">
                    <CloseIcon
                        role="button"
                        onClick={() => setMenuIsOpen(false)}
                        width={30}
                        height={30}
                        fill="white"
                    />
                </div>

                <div className="nav">
                    {navItems.map((x) => (
                        <div
                            className={`nav-item ${
                                x.key === selectedKey ? 'selected-nav' : ''
                            } text-body-large-bold cursor-pointer`}
                            key={x.key}
                            onClick={() => {
                                onNavigationClick(x.key);
                                setMenuIsOpen(false);
                            }}
                        >
                            {x.text}
                        </div>
                    ))}
                </div>
                <hr className="separator" />
                {isLoggedIn ? (
                    <div
                        className="connected-user"
                        onClick={() => {
                            onNavigationClick(ACCOUNT_URL);
                            setMenuIsOpen(false);
                        }}
                    >
                        <ConnectedUser withFullBorder />
                    </div>
                ) : (
                    <div className="none-connected-user">
                        <Button
                            type="red"
                            width="full"
                            height="small"
                            text={Copydeck.userCreeMonCompte}
                            onClick={() => {
                                onNavigationClick(CREATE_ACCOUNT_URL);
                                setMenuIsOpen(false);
                            }}
                        />
                        <div
                            role="button"
                            onClick={() => {
                                onNavigationClick(LOGIN_URL);
                                setMenuIsOpen(false);
                            }}
                            className="text-label-large-bold login-button"
                        >
                            {Copydeck.userMeConnecter}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MobileHeaderLayout;
