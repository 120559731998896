import { useStores } from './use-stores';

export const useFormNavigationBlocker = (): [
    /**Set navigation blocked state */
    (blocked: boolean) => void,
    /**Current navigation blocked state */
    boolean
] => {
    const { navigationStore } = useStores();
    return [
        (blocked: boolean) => navigationStore.setNavigationBlockState(blocked),
        navigationStore.navigationIsBlocked,
    ];
};
