import { FunctionComponent, useRef } from 'react';
import './index.less';

interface AnimatedDraggingStringProps {
    text: string;
    textOffset?: number;
    color: 'blue' | 'white';
    isVisible?: boolean;
    className?: string;
    letterScaleX?: number;
    trailCount?: number;
    trailOffset?: number;
    letterWidth?: string;
    containerHeight?: number;
}

const AnimatedDraggingString: FunctionComponent<AnimatedDraggingStringProps> = ({
    text,
    color,
    textOffset = 0,
    trailOffset = 0,
    className = '',
    isVisible = true,
    letterScaleX = 1,
    trailCount = 5,
    letterWidth = '17px',
    containerHeight = 0,
}) => {
    const containerRef = useRef<HTMLDivElement>(null);

    return (
        <div
            className={`AnimatedDraggingString${!isVisible ? '--noAnimation' : ''} ${className}`}
            ref={containerRef}
            style={{
                height: containerHeight,
            }}
        >
            <div
                className="AnimatedDraggingString__text"
                style={{
                    transform: `translateX(${textOffset}px)`,
                }}
            >
                <span>{text}</span>
            </div>
            <div
                style={{
                    transform: `translateX(${trailOffset}px)`,
                }}
            >
                {Array.from({ length: trailCount }).map((_, index) => (
                    <div
                        key={index}
                        className={`AnimatedDraggingString__letter AnimatedDraggingString__letter--${index} AnimatedDraggingString__letter--${index}--${color}}`}
                        style={{
                            width: letterWidth,
                        }}
                    >
                        <span
                            style={{
                                transform: `scaleX(${letterScaleX})`,
                            }}
                        >
                            I
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
};
export default AnimatedDraggingString;
