import React, { FunctionComponent } from 'react';
import { Input } from 'antd';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { theme } from 'Style/theme';
import FilterStore from 'Stores/FilterStore';
import { MagnifyingGlassIcon } from 'Components/icons';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { VerticalFormlessItemLayout } from 'Components/vertical-formless-item-layout';
import { LabelColor } from 'Components/validated-form-item/validated-form-items';
import MakesFilter from './makes-filter/makes-filter';
import './table-filters.less';
import { MultiSelectCustomOption } from 'Components/select-custom/multi-select/multi-select-common';
import DistanceFilter from './distance-filter';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import JobApplicationFilter from './job-application-filter';

interface TableFiltersProps {
    filterStore: FilterStore;
    includeSearch?: boolean;
    includeMakesSelect?: boolean;
    includeApplicationStatusSelect?: boolean;
    includeDistanceSelect?: boolean;
}
const TableFilters: FunctionComponent<TableFiltersProps> = observer(
    ({
        filterStore,
        includeSearch,
        includeMakesSelect,
        includeApplicationStatusSelect,
        includeDistanceSelect,
    }) => {
        const { t } = useTranslation();

        const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
            filterStore.updateSearchTerm(event.target.value);
        };

        const onApplicationStatusChange = (options: MultiSelectCustomOption[]): void => {
            filterStore.updateJobApplicationStatuses(options);
        };

        const onMakesChange = (options: MultiSelectCustomOption[]): void => {
            filterStore.updateMakes(options);
        };

        const onDistanceChange = (option?: SingleSelectCustomOption): void => {
            filterStore.updateDistance(option);
        };

        return (
            <div className="TableFilters">
                <VerticalFormlessItemLayout className="form-container">
                    {includeApplicationStatusSelect && (
                        <ValidatedFormItem
                            label={t('statut_de_candidature')}
                            labelColor={LabelColor.grey}
                        >
                            <JobApplicationFilter
                                onChange={(options: MultiSelectCustomOption[]) => {
                                    onApplicationStatusChange(options);
                                }}
                                selected={filterStore.jobApplicationStatuses ?? []}
                            />
                        </ValidatedFormItem>
                    )}

                    {includeMakesSelect && (
                        <ValidatedFormItem label={t('marque')} labelColor={LabelColor.grey}>
                            <MakesFilter
                                onChange={(options: MultiSelectCustomOption[]) => {
                                    onMakesChange(options);
                                }}
                                selected={filterStore.makes ?? []}
                            />
                        </ValidatedFormItem>
                    )}

                    {includeDistanceSelect && (
                        <ValidatedFormItem label={t('distance')} labelColor={LabelColor.grey}>
                            <DistanceFilter
                                onChange={(option?: SingleSelectCustomOption) =>
                                    onDistanceChange(option)
                                }
                                selected={filterStore.distance}
                            />
                        </ValidatedFormItem>
                    )}

                    {includeSearch && (
                        <ValidatedFormItem label={t('rechercher')} labelColor={LabelColor.grey}>
                            <Input
                                className="search-bar"
                                onChange={onSearchChange}
                                prefix={
                                    <MagnifyingGlassIcon
                                        fill={theme['brand-primary']}
                                        width={20}
                                        height={20}
                                    />
                                }
                                value={filterStore.searchTerm}
                            />
                        </ValidatedFormItem>
                    )}
                </VerticalFormlessItemLayout>
            </div>
        );
    }
);

export default TableFilters;
