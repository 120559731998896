import Copydeck from 'i18n/Copydeck';
import './index.less';
import headerImageMontage from 'Assets/Images/metiers/HEADER_IMAGE_MONTAGE.webp';
import headerImageMontageMobile from 'Assets/Images/metiers/HEADER_IMAGE_MONTAGE_MOBILE.webp';
import LongArrowIcon from 'Components/icons/long-arrow-icon';
import Button from 'Components/button';
import { useStores, useWindowDimensions } from 'Hooks';
import { useTranslation } from 'react-i18next';
import { QUIZ_URL } from 'Models/Constants';
import { useHistory } from 'react-router-dom';
import AnimatedDraggingString from 'Components/animated-dragging-string';

const MetiersHeader = () => {
    const { windowWidth } = useWindowDimensions();
    const history = useHistory();
    const { t } = useTranslation();
    const {
        userStore: { userQuizAnswers },
    } = useStores();

    const MOBILE_BREAKPOINT = 770;

    const imgSrc = windowWidth < MOBILE_BREAKPOINT ? headerImageMontageMobile : headerImageMontage;
    return (
        <div className="MetiersHeader">
            <div className="MetiersHeader__content">
                <h1 className="MetiersHeader__title">
                    <span className="MetiersHeader__titleRow">
                        {Copydeck.metiersMetiersHeaderTitleRow1}{' '}
                    </span>
                    <span className="MetiersHeader__titleRow MetiersHeader__titleRow--2">
                        {Copydeck.metiersMetiersHeaderTitleRow2Word1}{' '}
                        {windowWidth < 771 ? (
                            Copydeck.metiersMetiersHeaderTitleRow2Word2 + ' '
                        ) : (
                            <AnimatedDraggingString
                                color="white"
                                text={Copydeck.metiersMetiersHeaderTitleRow2Word2}
                                textOffset={-10}
                                letterScaleX={1.5}
                                trailOffset={0}
                                letterWidth="16px"
                            />
                        )}
                    </span>
                    <span className="MetiersHeader__titleRow">
                        {Copydeck.metiersMetiersHeaderTitleRow3}
                        <div className="MetiersHeader__arrowContainer">
                            <LongArrowIcon direction="right" className="MetiersHeader__arrow" />
                        </div>
                    </span>
                </h1>
                <div className="MetiersHeader__buttonContainer">
                    {windowWidth <= MOBILE_BREAKPOINT && (
                        <Button
                            text={
                                userQuizAnswers !== null
                                    ? t('refaire_mon_quiz')
                                    : t('faire_mon_quiz')
                            }
                            type="red"
                            onClick={() => history.push(QUIZ_URL)}
                        />
                    )}
                </div>
                <img src={imgSrc} alt="" className="MetiersHeader__image" />
            </div>
        </div>
    );
};

export default MetiersHeader;
