import { FunctionComponent } from 'react';
import './index.less';
import FancyArrowIcon from 'Components/icons/fancy-arrow-icon';

interface MetierCardProps {
    category: string;
    title: string;
    description: string;
    image: string;
    className?: string;
    onClick?: () => void;
}
const MetierCard: FunctionComponent<MetierCardProps> = ({
    category,
    description,
    title,
    onClick,
    image,
    className = '',
}) => {
    return (
        <div className={`MetierCard ${className}`} onClick={onClick}>
            <div className="MetierCard__categoryContainer">
                <img src={image} alt={title} className="MetierCard__image" />
                <h3 className="MetierCard__category">
                    <div className="text-label-large-bold MetierCard__category--content">
                        {category}
                    </div>
                </h3>
            </div>
            <div className="MetierCard__body">
                <h4 className="text-title-large-alt MetierCard__title">{title}</h4>
                <p className="text-body-medium MetierCard__description">{description}</p>
                <div className="MetierCard__arrowContainer">
                    <FancyArrowIcon direction="right" width={69} height={30} />
                </div>
            </div>
        </div>
    );
};

export default MetierCard;
