import React, { FunctionComponent } from 'react';
import ConcessionInfoContent from './concession-info-content';
import './concession-info-map-overlay.less';

interface ConcessionInfoOverlayProps {
    concessionIds: string[];
    onClose: () => void;
}

const ConcessionInfoMapOverlay: FunctionComponent<ConcessionInfoOverlayProps> = ({
    concessionIds,
    onClose,
}) => {
    return (
        <div className="ConcessionInfoMapOverlay">
            <ConcessionInfoContent concessionIds={concessionIds} onClose={onClose} />
        </div>
    );
};

export default ConcessionInfoMapOverlay;
