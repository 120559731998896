import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import StaticSingleSelect from 'Components/select-custom/single-select/static-single-select';
import { DROPDOWN_ALL } from 'Models/Constants';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface StatusFilterSelectProps {
    onChange: (value?: SingleSelectCustomOption) => void;
    selected?: SingleSelectCustomOption;
}

export enum DistanceFilterSelectOption {
    five = '5 km',
    ten = '10 km',
    twentyfive = '15 km',
    fifty = '50 km',
}

const DistanceFilter: React.FC<StatusFilterSelectProps> = ({ onChange, selected }) => {
    const { t } = useTranslation();

    return (
        <div className="StatusFilter">
            <StaticSingleSelect
                options={[
                    {
                        value: DROPDOWN_ALL,
                        label: t('all'),
                    },
                    {
                        value: DistanceFilterSelectOption.five,
                        label: DistanceFilterSelectOption.five,
                    },
                    {
                        value: DistanceFilterSelectOption.ten,
                        label: DistanceFilterSelectOption.ten,
                    },
                    {
                        value: DistanceFilterSelectOption.twentyfive,
                        label: DistanceFilterSelectOption.twentyfive,
                    },
                    {
                        value: DistanceFilterSelectOption.fifty,
                        label: DistanceFilterSelectOption.fifty,
                    },
                ]}
                onChange={onChange}
                selected={selected?.value}
            />
        </div>
    );
};

export default DistanceFilter;
