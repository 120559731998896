import { Modal } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import React, { ReactElement, ReactFragment, ReactNode } from 'react';
import './index.less';

interface BaseModalProps extends ModalProps {
    headerText?: string;
    oustideOverflow?: { element: ReactElement; height: number };
    footer?: ReactFragment;
    maxHeight?: string;
    noPadding?: boolean;
    centeredHeaderText?: boolean;
    headerActionIcon?: ReactNode;
    /**Most modals take up the height they need and have a max height. If the modal has dynamic content like an infinite load list, set this to true to avoid height flickering */
    fixedHeight?: boolean;
    modalBackgroundColor?: string;
}

const BaseModal: React.FunctionComponent<BaseModalProps> = ({
    headerText,
    oustideOverflow,
    footer,
    width = 1200,
    maxHeight = '85vh',
    noPadding = false,
    maskClosable = false,
    children,
    className,
    centeredHeaderText,
    fixedHeight,
    headerActionIcon,
    modalBackgroundColor,
    ...props
}) => {
    const padding = noPadding ? 0 : '20px';
    const headerHeight = 55;
    const footerHeight = 90;
    let heightToSubtract = 0;

    if (headerText) heightToSubtract = heightToSubtract + headerHeight;
    if (oustideOverflow) heightToSubtract = heightToSubtract + oustideOverflow.height;
    if (footer) heightToSubtract = heightToSubtract + footerHeight;

    const calculatedMaxHeight = `calc(${maxHeight} - ${heightToSubtract}px)`;

    return (
        <Modal
            className={`${className} ${centeredHeaderText ? 'centered-header' : ''}`}
            centered
            footer={null}
            maskClosable={maskClosable}
            width={width}
            {...props}
            maskTransitionName="" // <- Impératif pour : import ImgCrop from 'antd-img-crop' pour prévenir de problème sur safari
            transitionName="" // <- Impératif pour : import ImgCrop from 'antd-img-crop' pour prévenir de problème sur safari
        >
            {(headerText || headerActionIcon) && (
                <div className="modal-header" style={{ height: `${headerHeight}px` }}>
                    <div className="header-text">{headerText}</div>
                    <div className="header-action">{headerActionIcon}</div>
                </div>
            )}

            {oustideOverflow && (
                <div className="oustide-overflow" style={{ height: `${oustideOverflow.height}px` }}>
                    {oustideOverflow.element}
                </div>
            )}

            <div
                className="content"
                style={{
                    height: fixedHeight ? calculatedMaxHeight : undefined,
                    maxHeight: calculatedMaxHeight,
                    padding: padding,
                    backgroundColor: modalBackgroundColor,
                }}
            >
                {children}
            </div>

            {footer && (
                <div className="footer" style={{ height: `${footerHeight}px` }}>
                    {footer}
                </div>
            )}
        </Modal>
    );
};

export default BaseModal;
