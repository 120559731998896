import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const CloseIcon: FunctionComponent<SvgProps> = ({ fill = 'currentColor', onClick, ...props }) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <path
                    d="M8.31336 17.9346C7.94422 18.3037 7.92665 18.9629 8.32215 19.3408C8.70008 19.7363 9.36805 19.7188 9.73719 19.3496L13.9999 15.0869L18.2626 19.3496C18.6405 19.7275 19.2909 19.7363 19.6688 19.3408C20.0643 18.9629 20.0556 18.3037 19.6776 17.9258L15.4149 13.6631L19.6776 9.40918C20.0556 9.02246 20.0643 8.37207 19.6688 7.99414C19.2909 7.59863 18.6405 7.60742 18.2626 7.98535L13.9999 12.248L9.73719 7.98535C9.36805 7.61621 8.70008 7.59863 8.32215 7.99414C7.92665 8.37207 7.94422 9.03125 8.31336 9.40039L12.5761 13.6631L8.31336 17.9346Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default CloseIcon;
