import React from 'react';
import App from './App/App';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { HttpClient } from 'HttpClient/HttpClient';
import { Container } from 'aurelia-dependency-injection';
import ReactDOM from 'react-dom';
import 'reflect-metadata';
import 'mobx-react-lite/batchingForReactDom';
import { Environment } from 'Misc/Environment';
//import 'appcom-timezones/locales/fr'; // Do not remove or locale will not be bundled. -- not needed for now
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from 'AppInsights';

declare global {
    interface Window {
        Environment: Environment;
    }
}

// Setup i18n
import 'i18n';

// Setup DI
const rootContainer = new Container();
rootContainer.makeGlobal();

// Register the object containing environment variables.
if (!window.Environment) {
    throw new Error('Environment object not found.');
}
rootContainer.registerInstance(Environment, window.Environment);

// Register HttpClient under both HttpClient and ApiHttpClient.
rootContainer.registerSingleton(HttpClient, HttpClient);
const httpClient = rootContainer.get(HttpClient);
rootContainer.registerInstance(ApiHttpClient, httpClient);

// Go!
ReactDOM.render(
    <React.StrictMode>
        <AppInsightsContext.Provider value={reactPlugin}>
            <App />
        </AppInsightsContext.Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
