import './index.less';
import { FunctionComponent, useState } from 'react';
import Content from 'Components/Content';
import { CandidateDetailsDto } from 'Api/Features/Candidates/Dtos/CandidateDetailsDto';
import ProfileForm from './profile-form';
import PasswordForm from './password-form';
import Copydeck from 'i18n/Copydeck';
import { Switch } from 'antd';

interface AccountFormProps {
    candidate?: CandidateDetailsDto;
}

const AccountForm: FunctionComponent<AccountFormProps> = ({ candidate }) => {
    const [formStep, setFormStep] = useState<'profile' | 'password'>('profile');
    const [isSeekingJob, setIsSeekingJob] = useState<boolean>(candidate?.isSeekingJob || false);

    return (
        <div className="AccountForm">
            <Content className="AccountForm__content">
                {formStep === 'profile' && (
                    <>
                        <div className="AccountForm__profileTopRow">
                            <h1 className="text-title-large-bold AccountForm__title">
                                {Copydeck.accountAccountParametres}
                            </h1>
                            <div className="text-body-large-bold AccountForm__toggle">
                                {Copydeck.accountAccountRechercheEnRecherche}
                                <Switch
                                    defaultChecked={candidate?.isSeekingJob}
                                    onChange={(checked) => {
                                        setIsSeekingJob(checked);
                                    }}
                                />
                            </div>
                        </div>
                        <ProfileForm
                            onChangePassword={() => setFormStep('password')}
                            candidate={candidate}
                            isSeekingJob={isSeekingJob}
                        />
                    </>
                )}
                {formStep === 'password' && (
                    <PasswordForm
                        candidateEmail={candidate?.email || ''}
                        onBack={() => setFormStep('profile')}
                    />
                )}
            </Content>
        </div>
    );
};

export default AccountForm;
