import { action, observable } from 'mobx';

class NavigationStore {
    @observable navigationIsBlocked = false;

    @action setNavigationBlockState(blocked: boolean): void {
        this.navigationIsBlocked = blocked;
    }
}

export default NavigationStore;
