import { FunctionComponent } from 'react';
import './index.less';

interface CategoryCardProps {
    title: string;
    description: string;
}

const CategoryCard: FunctionComponent<CategoryCardProps> = ({ description, title }) => {
    return (
        <div className="CategoryCard">
            <h3 className="CategoryCard__title">{title}</h3>
            <p className="CategoryCard__description">{description}</p>
        </div>
    );
};

export default CategoryCard;
