import { GetJobApplicationsRequestDto } from 'Api/Features/JobApplications/Dtos/GetJobApplicationsRequestDto';
import { JobApplicationDto } from 'Api/Features/JobApplications/Dtos/JobApplicationDto';
import { JobApplicationStatusDto } from 'Api/Features/JobApplications/Dtos/JobApplicationStatusDto';
import InfiniteScroll from 'Components/infinite-scroll';
import { MultiSelectCustomOption } from 'Components/select-custom/multi-select/multi-select-common';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import { useService } from 'Hooks';
import { useFetch } from 'Hooks/use-fetch';
import { useInfiniteScroll } from 'Hooks/use-infinite-scroll';
import { DEBOUNCE_DELAY_400, DROPDOWN_ALL, PAGE_SIZE_INFINITE_LOADING } from 'Models/Constants';
import { JobApplicationService } from 'Services/JobApplicationService';
import FilterStore from 'Stores/FilterStore';
import debounce from 'lodash.debounce';
import { autorun } from 'mobx';
import React, { FunctionComponent, useCallback, useEffect, useRef } from 'react';
import JobApplicationCard from '../job-application-card';
import Skeleton from 'Components/Skeleton';
import './index.less';

interface JobApplicationsMobileView {
    filterStoreRef: React.MutableRefObject<FilterStore>;
}

const JobApplicationsMobileView: FunctionComponent<JobApplicationsMobileView> = ({
    filterStoreRef,
}) => {
    const {
        items,
        setItems,
        setTotalItemCount,
        handleScrollToBottom,
        currentPage,
        setCurrentPage,
    } = useInfiniteScroll<JobApplicationDto>();

    const jobApplicationService = useService(JobApplicationService);
    const { apiRequest, loadingStateKeys } = useFetch();

    const fetchApplications = useCallback(
        async (
            currentPage: number,
            statuses: MultiSelectCustomOption[],
            makes: MultiSelectCustomOption[],
            maxCandidateDistance?: SingleSelectCustomOption,
            searchTerm?: string
        ) => {
            const request: GetJobApplicationsRequestDto = {
                searchTerm: searchTerm,
                page: currentPage,
                pageSize: PAGE_SIZE_INFINITE_LOADING,
                statuses: statuses.map((x) => x.value as JobApplicationStatusDto),
                makes: makes.map((x) => x.value),
                maxCandidateDistance:
                    maxCandidateDistance && maxCandidateDistance.value !== DROPDOWN_ALL
                        ? Number.parseInt(maxCandidateDistance.value)
                        : undefined,
            };

            const [concessions, totalItemsCount] = await apiRequest({
                requestFunction: (request) => jobApplicationService.getJobApplications(request),
                requestParameters: request,
                loadingStateKey: 'jobApplicationsLoading',
            });

            setItems((prev) => [...prev, ...concessions]);
            setTotalItemCount(totalItemsCount);
        },
        [jobApplicationService, apiRequest, setItems, setTotalItemCount]
    );

    const debounceSearch = useRef(
        debounce(
            (params: {
                currentPage: number;
                statuses: MultiSelectCustomOption[];
                makes: MultiSelectCustomOption[];
                maxCandidateDistance?: SingleSelectCustomOption;
                searchTerm?: string;
            }) => {
                fetchApplications(
                    params.currentPage,
                    params.statuses,
                    params.makes,
                    params.maxCandidateDistance,
                    params.searchTerm
                );
            },
            DEBOUNCE_DELAY_400
        )
    );

    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;

            debounceSearch.current({
                currentPage: currentPage,
                statuses: filterStore.jobApplicationStatuses ?? [],
                makes: filterStore.makes ?? [],
                maxCandidateDistance: filterStore.distance,
                searchTerm: filterStore.searchTerm,
            });
        });

        return (): void => {
            disposer();
        };
    }, [debounceSearch, currentPage, filterStoreRef]);

    const resetSearchResults = useCallback(() => {
        setItems([]);
        setCurrentPage(0);
        setTotalItemCount(0);
    }, [setItems, setCurrentPage, setTotalItemCount]);

    useEffect(() => {
        resetSearchResults();
    }, [
        filterStoreRef.current.searchTerm,
        filterStoreRef.current.makes,
        filterStoreRef.current.distance,
        filterStoreRef.current.jobApplicationStatuses,
        resetSearchResults,
    ]);

    return (
        <div className="JobApplicationsMobileView">
            <InfiniteScroll<JobApplicationDto>
                items={items}
                RenderItem={(item) => <JobApplicationCard key={item.id} jobApplication={item} />}
                RenderItemSkeleton={() => (
                    <Skeleton
                        isLoading={true}
                        placeholder={
                            <div>
                                <div className="rect" style={{ width: '100%', height: 145 }} />
                            </div>
                        }
                    />
                )}
                handleScrollToBottom={handleScrollToBottom}
                paginationOptions={{
                    pageSize: PAGE_SIZE_INFINITE_LOADING,
                    isLoading: loadingStateKeys.has('jobApplicationsLoading'),
                }}
                numberSkeletonLoading={PAGE_SIZE_INFINITE_LOADING}
                marginTopWhenItemsPresent={10}
            />
        </div>
    );
};

export default JobApplicationsMobileView;
