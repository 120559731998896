import { FC, useMemo } from 'react';
import './index.less';
import { useHistory, useParams } from 'react-router-dom';
import { TemoignagesDefinition } from 'Models/TemoignagesDefinition';
import Content from 'Components/Content';
import Button from 'Components/button';
import Copydeck from 'i18n/Copydeck';
import { TEMOIGNAGE_URL } from 'Models/Constants';
import BlueCard from 'Components/blue-card';
import renderTextWithLineBreak from 'Utils/renderTextWithLineBreak';
import { useWindowDimensions } from 'Hooks';
import YoutubeVideoPlayer from 'Components/youtube-video-player';

const TemoignagesDetails: FC = () => {
    const params = useParams<{ id: string }>();
    const history = useHistory();
    const { windowWidth } = useWindowDimensions();

    const MOBILE_BREAKPOINT = 450;

    const isMobile = useMemo(() => windowWidth < MOBILE_BREAKPOINT, [windowWidth]);

    const item = TemoignagesDefinition.find((temoignage) => temoignage.id.toString() === params.id);

    if (!item) {
        return (
            <div className="TemoignageDetails">
                <Content className="TemoignageDetails__content">
                    <div className="text-display-large-extenda TemoignageDetails__title">
                        CE TÉMOIGNAGE N'EXISTE PAS
                    </div>
                </Content>
            </div>
        );
    }

    return (
        <div className="TemoignageDetails">
            <Content className="TemoignageDetails__content">
                <div className="TemoignageDetails__topRow">
                    <Button
                        type="text"
                        leftIcon="ChevronLeftIcon"
                        iconProps={{
                            fill: 'white',
                        }}
                        text={Copydeck.temoignageDetailsTemoignageDetailsBackButton}
                        textClassName="text-label-large-bold text-white"
                        onClick={() => history.push(TEMOIGNAGE_URL)}
                    />
                </div>
                <div className="TemoignageDetails__temoignage text-white">
                    <div
                        className="text-display-medium-extenda TemoignageDetails__quote"
                        dangerouslySetInnerHTML={{
                            __html: Copydeck[item.quote],
                        }}
                    ></div>
                    <div className="TemoignageDetails__author">
                        <img src={item.image} alt={Copydeck[item.name]} />
                        <div className="TemoignageDetails__authorInfo">
                            <div className="text-body-large-bold">{Copydeck[item.name]}</div>
                            <div className="text-body-medium">{Copydeck[item.jobTitle]}</div>
                        </div>
                    </div>
                    <div className="text-body-large TemoignageDetails__description">
                        {renderTextWithLineBreak(Copydeck[item.longDescription])}
                    </div>
                </div>
                <BlueCard className="TemoignageDetails__bluecard">
                    <span className="TemoignageDetails__bluecardText">
                        {Copydeck.temoignageDetailsTemoignageDetailsMoreInfo}
                    </span>
                    <Button
                        withRightArrow={!isMobile}
                        text={Copydeck.temoignageDetailsTemoignageDetailsCta}
                        type="red"
                        height="large"
                        className="TemoignageDetails__cta"
                        onClick={() => history.push(`/metiers/${Copydeck[item.url]}`)}
                    />
                </BlueCard>
                {item.video && (
                    <YoutubeVideoPlayer url={item.video} width="100%" aspectRatio="647 / 364" />
                )}
            </Content>
        </div>
    );
};

export default TemoignagesDetails;
