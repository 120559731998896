import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const ListIcon: FunctionComponent<SvgProps> = ({ fill = 'currentColor', onClick, ...props }) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
                <path
                    d="M4.25 10.5C3.42 10.5 2.75 11.17 2.75 12C2.75 12.83 3.42 13.5 4.25 13.5C5.08 13.5 5.75 12.83 5.75 12C5.75 11.17 5.08 10.5 4.25 10.5ZM4.25 4.5C3.42 4.5 2.75 5.17 2.75 6C2.75 6.83 3.42 7.5 4.25 7.5C5.08 7.5 5.75 6.83 5.75 6C5.75 5.17 5.08 4.5 4.25 4.5ZM4.25 16.5C3.42 16.5 2.75 17.18 2.75 18C2.75 18.82 3.43 19.5 4.25 19.5C5.07 19.5 5.75 18.82 5.75 18C5.75 17.18 5.08 16.5 4.25 16.5ZM7.25 19H21.25V17H7.25V19ZM7.25 13H21.25V11H7.25V13ZM7.25 5V7H21.25V5H7.25Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default ListIcon;
