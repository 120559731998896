import { CloseIcon } from 'Components/icons';
import Icon from 'Components/icons/Icon';
import * as Icons from 'Components/icons';
import { useStores } from 'Hooks';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { SupportedLanguage } from 'Stores/LanguageStore';
import './tag.less';

export enum TagColor {
    blue = 'blue',
    green = 'green',
    grey = 'grey',
    red = 'red',
    black = 'black',
}
export interface TagProps extends TagStyle {
    text: string;
    color: TagColor;
}

interface TagStyle {
    squareTag?: boolean;
    uppercase?: boolean;
    dotTag?: boolean;
    canDelete?: boolean;
    onClick?: () => void;
    iconName?: keyof typeof Icons;
    width?: 'hugged' | 'auto';
}

const Tag: React.FunctionComponent<TagProps> = observer(
    ({
        text,
        color,
        squareTag = false,
        dotTag = false,
        uppercase = false,
        canDelete = false,
        onClick,
        iconName,
        width = 'auto',
    }) => {
        const { languageStore } = useStores();
        const [textTransform, setTextTransform] = useState('capitalize');

        useEffect(() => {
            switch (languageStore.currentLanguage) {
                //English is capitalized by default.
                // case SupportedLanguage.EN:
                //     setTextTransform('capitalize');
                //     break;
                //French never capitlize
                case SupportedLanguage.FR:
                    setTextTransform('');
            }

            //Uppercase overides everything
            if (uppercase) setTextTransform('uppercase');
        }, [uppercase, languageStore.currentLanguage]);

        return (
            <div
                onClick={onClick}
                className={`Tag width-${width} text-label-medium-bold ${color} square-${squareTag} dot-${dotTag} ${textTransform} ${
                    canDelete ? 'hoverPointer' : ''
                }`}
            >
                <div className="tag-inner-container">
                    {canDelete && (
                        <div className="delete">
                            <CloseIcon height={18} width={18} />
                        </div>
                    )}
                    {iconName && <Icon iconName={iconName} />}
                    <div>{text}</div>
                </div>
            </div>
        );
    }
);

export default Tag;
