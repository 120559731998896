import { useRef, useCallback, useEffect } from 'react';
import { autorun } from 'mobx';
import { debounce } from 'lodash';
import { DEBOUNCE_DELAY_400 } from 'Models/Constants';

type FetchFunctionParams = { [key: string]: unknown };

const useDebouncedFetch = <T extends FetchFunctionParams>({
    fetchFunction,
    fetchFunctionParams,
    debounceDelay = DEBOUNCE_DELAY_400,
}: {
    fetchFunction: (params: T) => Promise<void>;
    /**A.K.A Store value changes that trigger a debounced fetch.  */
    fetchFunctionParams: () => T;
    debounceDelay?: number;
}) => {
    const fetchData = useCallback(
        async (params) => {
            await fetchFunction(params);
        },
        [fetchFunction]
    );

    const debouncedFetchData = useRef(
        debounce((params) => {
            fetchData(params);
        }, debounceDelay)
    );

    useEffect(() => {
        const disposer = autorun(() => {
            debouncedFetchData.current(fetchFunctionParams());
        });

        return () => {
            disposer();
        };
    }, [debouncedFetchData, fetchData, fetchFunctionParams]);
};

export default useDebouncedFetch;
