import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { ColumnType, TablePaginationConfig } from 'antd/lib/table';
import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';
import { JobApplicationDto } from 'Api/Features/JobApplications/Dtos/JobApplicationDto';
import {
    CONCESSIONS_URL,
    DEBOUNCE_DELAY_400,
    DROPDOWN_ALL,
    FORMAT_MONTH_DATE_COMMA_YEAR,
} from 'Models/Constants';
import { initialPaginationState } from 'Models/InitialPaginationState';
import FilterStore from 'Stores/FilterStore';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { GetJobApplicationsSortColumnDto } from 'Api/Features/JobApplications/Dtos/GetJobApplicationsSortColumnDto';
import { useService, useStores } from 'Hooks';
import { JobApplicationService } from 'Services/JobApplicationService';
import { useFetch } from 'Hooks/use-fetch';
import { GetJobApplicationsRequestDto } from 'Api/Features/JobApplications/Dtos/GetJobApplicationsRequestDto';
import { autorun } from 'mobx';
import debounce from 'lodash.debounce';
import moment from 'moment';
import Tag from 'Components/tag/tag';
import { JobApplicationStatusToTagProps } from 'Utils/TagUtils';
import { JobApplicationStatusDto } from 'Api/Features/JobApplications/Dtos/JobApplicationStatusDto';
import { MultiSelectCustomOption } from 'Components/select-custom/multi-select/multi-select-common';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import { observer } from 'mobx-react';

interface JobApplicationsDesktopViewProps {
    filterStoreRef: React.MutableRefObject<FilterStore>;
}
const JobApplicationsDesktopView: FunctionComponent<JobApplicationsDesktopViewProps> = observer(
    ({ filterStoreRef }) => {
        const { t } = useTranslation();
        const { userStore } = useStores();
        const history = useHistory();
        const { apiRequest, loadingStateKeys } = useFetch();
        const jobApplicationService = useService(JobApplicationService);

        const [applications, setApplications] = useState<JobApplicationDto[]>([]);
        const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
        const paginationRef = useRef(initialPaginationState);

        const columns: ColumnType<JobApplicationDto>[] = [
            {
                title: t('date_de_soumission'),
                render: (application: JobApplicationDto) =>
                    moment(application.date).format(FORMAT_MONTH_DATE_COMMA_YEAR.FR),
                key: GetJobApplicationsSortColumnDto.Date,
                defaultSortOrder: 'descend',
                sorter: true,
            },
            {
                title: t('concession'),
                render: (application: JobApplicationDto) => application.concession?.name,
                key: GetJobApplicationsSortColumnDto.ConcessionName,
                sorter: true,
            },
            {
                title: t('distance'),
                render: (application: JobApplicationDto) =>
                    `${Math.round(application.candidateDistance ?? 0)} KM`,
                key: GetJobApplicationsSortColumnDto.CandidateDistance,
                sorter: true,
            },
            {
                title: t('statut'),
                render: (application: JobApplicationDto) => (
                    <div className="d-flex">
                        <Tag
                            {...JobApplicationStatusToTagProps(
                                application.status ?? JobApplicationStatusDto.AwaitingResponse,
                                t
                            )}
                        />
                    </div>
                ),
                key: GetJobApplicationsSortColumnDto.Status,
                sorter: true,
            },
        ];

        const fetchJobApplications = useCallback(
            async (params: {
                pagination: TablePaginationConfig;
                searchTerm?: string;
                sortColumn: GetJobApplicationsSortColumnDto | null;
                sortDirection: SortDirection | null;
                statuses: MultiSelectCustomOption[];
                maxCandidateDistance?: SingleSelectCustomOption;
                makes: MultiSelectCustomOption[];
            }) => {
                const request: GetJobApplicationsRequestDto = {
                    pageSize: params.pagination.pageSize || 0,
                    page: (params.pagination.current || 1) - 1,
                    searchTerm: params.searchTerm,
                    sortColumn: params.sortColumn,
                    sortDirection: params.sortDirection,
                    statuses: params.statuses.map((x) => x.value as JobApplicationStatusDto),
                    candidateIds: [userStore.userInfo?.id ?? ''],
                    maxCandidateDistance:
                        params.maxCandidateDistance &&
                        params.maxCandidateDistance.value !== DROPDOWN_ALL
                            ? Number.parseInt(params.maxCandidateDistance.value)
                            : undefined,
                    makes: params.makes.map((x) => x.value),
                };

                const [items, totalItemsCount] = await apiRequest({
                    requestFunction: (request) => jobApplicationService.getJobApplications(request),
                    requestParameters: request,
                    loadingStateKey: 'applicationsLoading',
                });

                setApplications(items);
                setPagination({
                    ...params.pagination,
                    total: totalItemsCount,
                });
            },
            [jobApplicationService, apiRequest, userStore.userInfo?.id]
        );

        const debounceSearch = useRef(
            debounce(
                (params: {
                    searchTerm?: string;
                    statuses: MultiSelectCustomOption[];
                    maxCandidateDistance?: SingleSelectCustomOption;
                    makes: MultiSelectCustomOption[];
                }) => {
                    fetchJobApplications({
                        pagination: {
                            ...paginationRef.current,
                            current: 1,
                        },
                        searchTerm: params.searchTerm,
                        sortColumn: GetJobApplicationsSortColumnDto.Date,
                        sortDirection: SortDirection.Descending,
                        statuses: params.statuses,
                        maxCandidateDistance: params.maxCandidateDistance,
                        makes: params.makes,
                    });
                },
                DEBOUNCE_DELAY_400
            )
        );

        useEffect(() => {
            const disposer = autorun(() => {
                const filterStore = filterStoreRef.current;

                debounceSearch.current({
                    searchTerm: filterStore.searchTerm,
                    makes: filterStore.makes ?? [],
                    statuses: filterStore.jobApplicationStatuses ?? [],
                    maxCandidateDistance: filterStore.distance,
                });
            });

            return (): void => {
                disposer();
            };
        }, [debounceSearch, filterStoreRef]);

        const handleTableChange = async (
            pagination: TablePaginationConfig,
            filter: any,
            sorter: any
        ): Promise<void> => {
            let sortDirection: SortDirection | null;
            switch (sorter.order) {
                case 'ascend':
                    sortDirection = SortDirection.Ascending;
                    break;
                case 'descend':
                    sortDirection = SortDirection.Descending;
                    break;
                default:
                    sortDirection = null;
                    break;
            }
            const { searchTerm, jobApplicationStatuses, makes, distance } = filterStoreRef.current;
            await fetchJobApplications({
                pagination,
                searchTerm,
                sortColumn: sorter.columnKey,
                sortDirection: sortDirection,
                statuses: jobApplicationStatuses ?? [],
                maxCandidateDistance: distance,
                makes: makes ?? [],
            });

            paginationRef.current = pagination;
        };

        return (
            <div className="JobApplicationsDesktopView">
                <Table
                    className="table-action-rows"
                    columns={columns}
                    pagination={pagination}
                    dataSource={applications}
                    onChange={handleTableChange}
                    showSorterTooltip={false}
                    loading={loadingStateKeys.has('applicationsLoading')}
                    onRow={(row: JobApplicationDto) => ({
                        onClick: (): void => {
                            history.push(CONCESSIONS_URL + `/${row.concession?.id}`);
                        },
                    })}
                    rowKey={(record) => record.id ?? ''}
                />
            </div>
        );
    }
);

export default JobApplicationsDesktopView;
