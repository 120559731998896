interface Feedback {
    questions: FeedbackQuestion[];
}
interface FeedbackQuestion {
    id: FeedbackId;
    text: string;
}

export enum FeedbackId {
    facilite = '5e2c04ab-4c36-40b3-a36b-99bc820d4cad',
    embauche = 'faf0d677-650b-47db-9c83-842fe8db8a16',
    recommandation = 'caee8912-52bc-44f5-a999-a568388d30e9',
}

export const FeedbackDefinitions: Feedback = {
    questions: [
        {
            id: FeedbackId.facilite,
            text: 'Avez-vous de la facilité à utiliser la plateforme de recherche?',
        },
        {
            id: FeedbackId.embauche,
            text: 'Avez-vous été embauché par un de nos concessionnaires?',
        },
        {
            id: FeedbackId.recommandation,
            text: 'Recommanderiez-vous le site Ma carrière en concessionnaire à un proche?',
        },
    ],
};
