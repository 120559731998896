import { CheckmarkIcon } from 'Components/icons';
import { theme } from 'Style/theme';
import { ReactNode } from 'react';
import { components } from 'react-select';

export interface SingleSelectCustomOption {
    value: string;
    label: string;
    content?: { node: ReactNode; className?: string; showSelected?: boolean };
    // imageUrl?: string;
    leftIcon?: ReactNode;
    showLeftIconSelected?: boolean;
    hideLeftIconDropDown?: boolean;
    isDisabled?: boolean;
    extraData?: any;
}

export const SingleSelectCommonStyles: any = {
    option: (provided: any) => ({
        ...provided,
        display: 'flex',
        alignItems: 'center',
    }),
    indicatorSeparator: (styles: any) => ({
        ...styles,
        display: 'none',
    }),
    input: (styles: any) => ({
        ...styles,
        marginLeft: '-10px',
        position: 'relative',
        left: '10px',
    }),
    //permet au menu ouvert de sortir de l'epace d'un modal
    menuPortal: (provided: any) => ({
        ...provided,
        zIndex: 1000000,
    }),
    control: (provided: any, state: any) => ({
        ...provided,
        cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    }),
};

export const SingleSelectCommonTheme: any = {
    primary: theme['surface-brand-rest-primary'], //dictates the color of select option
    primary25: theme['surface-brand-rest-secondary'], //dictates the color hovering options
    primary50: theme['surface-brand-rest-secondary'], //dictates the color when clicking an option
};

/* -- The selected value in dropdown -- */
export const selectedOptionDetails = (
    data: SingleSelectCustomOption,
    selectProps: any,
    isDeepBlue: boolean
) => {
    let labelColor = isDeepBlue ? 'white' : 'black';
    if (data.isDisabled) labelColor = theme['surfaces-grey-disabled'];

    return (
        <div className={`selected-option-details`}>
            {selectProps.strongLabel ? (
                <strong>{data.label}</strong>
            ) : (
                <span className="label-in-dropdown" style={{ color: labelColor }}>
                    {data.label}
                </span>
            )}
            {data.content && data.content.showSelected && (
                <div className={`${data.content.className ?? ''}`} style={{ color: labelColor }}>
                    {data.content.node}
                </div>
            )}
        </div>
    );
};
/* -- The selected value in dropdown -- */

/* -- The open dropdown menu -- */
export const menuOptionDetails = (data: SingleSelectCustomOption, selectProps: any) => {
    let labelColor = 'black';
    let contentColor = theme['text-mid-contrast'];
    if (data?.isDisabled) {
        labelColor = theme['text-disabled'];
        contentColor = theme['text-disabled'];
    }

    let optionIsSelected = false;
    if (data?.value === selectProps.value?.value) {
        optionIsSelected = true;
        labelColor = theme.white;
        contentColor = theme.white;
    }

    return (
        <div className="menuOptionDetails">
            {!data.hideLeftIconDropDown && data.leftIcon ? (
                <div className="left-icon">{data.leftIcon}</div>
            ) : null}

            <div className="center-content">
                <div className="dropdown-menu-option-label">
                    {selectProps.strongLabel ? (
                        <strong>{data.label}</strong>
                    ) : (
                        <div style={{ color: labelColor }}>{data.label}</div>
                    )}
                </div>
                {data.content && (
                    <div
                        className={`${data.content.className ?? ''} text-caption-2`}
                        style={{ color: contentColor }}
                    >
                        {data.content.node}
                    </div>
                )}
            </div>

            {optionIsSelected && (
                <div className="selected-checkmark">
                    <CheckmarkIcon />
                </div>
            )}
        </div>
    );
};

export const Option = (props: any): any => {
    return (
        <div>
            <components.Option {...props} className={'single-dropdown-menu-option-container'}>
                {/* {defaultImg && optionImage(props.data, props.selectProps)} */}

                {menuOptionDetails(props.data, props.selectProps)}
            </components.Option>
        </div>
    );
};
/* -- The open dropdown menu -- */

/* -- The selected value in dropdown -- */
export const SingleValue = ({ data, selectProps, isDeepBlue }: any): any => {
    return (
        !selectProps.menuIsOpen && (
            <div className="dropdown-selected-element">
                {/* {defaultImg && optionImage(data, selectProps)} */}
                <div className="with-icon">
                    {data.showLeftIconSelected && data.leftIcon ? data.leftIcon : undefined}
                </div>

                {selectedOptionDetails(data, selectProps, isDeepBlue)}
            </div>
        )
    );
};
/* -- The selected value in dropdown -- */
