import Content from 'Components/Content';
import './index.less';
import Copydeck from 'i18n/Copydeck';
import AnimatedDraggingString from 'Components/animated-dragging-string';
import titleImage from 'Assets/Images/temoignages/temoignages_list_title_image.webp';
import { TemoignagesDefinition } from 'Models/TemoignagesDefinition';
import TemoignageCard from 'Components/temoignage-card';
import { useWindowDimensions } from 'Hooks';
import { useHistory } from 'react-router-dom';
import { TEMOIGNAGE_URL } from 'Models/Constants';

const TemoignageList = () => {
    const { windowWidth } = useWindowDimensions();
    const MOBILE_BREAKPOINT = 780;
    const history = useHistory();

    return (
        <div className="TemoignagesList">
            <Content className="TemoignagesList__content">
                <div className="TemoignagesList__header">
                    <h1 className="TemoignagesList__title">
                        <span className="TemoignagesList__title--1">
                            {Copydeck.temoignagesTemoignagesTitle1}
                        </span>
                        <span className="TemoignagesList__title--2">
                            <AnimatedDraggingString
                                color="white"
                                text={Copydeck.temoignagesTemoignagesTitle2}
                                textOffset={windowWidth > MOBILE_BREAKPOINT ? -15 : -75}
                                trailOffset={windowWidth > MOBILE_BREAKPOINT ? 0 : -15}
                                trailCount={windowWidth > MOBILE_BREAKPOINT ? 5 : 4}
                                letterScaleX={windowWidth > MOBILE_BREAKPOINT ? 1.2 : 3}
                                letterWidth={windowWidth > MOBILE_BREAKPOINT ? '18px' : '9px'}
                            />
                            <span>{Copydeck.temoignagesTemoignagesTitle3}</span>
                        </span>
                    </h1>
                    <div className="TemoignagesList__imageWrapper">
                        <img src={titleImage} alt="" className="TemoignagesList__image" />
                    </div>
                </div>
                <ul className="TemoignagesList__list">
                    {TemoignagesDefinition.map((temoignage) => (
                        <li key={temoignage.id} className="TemoignagesList_listItem">
                            <TemoignageCard
                                description={Copydeck[temoignage.description]}
                                image={temoignage.image}
                                jobTitle={Copydeck[temoignage.jobTitle]}
                                name={Copydeck[temoignage.name]}
                                quote={Copydeck[temoignage.quote]}
                                withRightArrow
                                isClickable
                                onClick={() => history.push(`${TEMOIGNAGE_URL}/${temoignage.id}`)}
                                showVideoLink={!!temoignage.video}
                                videoUrl={temoignage.video}
                            />
                        </li>
                    ))}
                </ul>
            </Content>
        </div>
    );
};

export default TemoignageList;
