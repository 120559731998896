import { observer } from 'mobx-react-lite';
import React, { ReactElement } from 'react';
import './index.less';

interface SkeletonProps {
    className?: string;
    isLoading?: boolean;
    placeholder: ReactElement;
    children?: ReactElement | ReactElement[];
    loadingColor?: SkeletonLoadingColor;
}

export enum SkeletonLoadingColor {
    grey = 'skeletonGrey',
    blue = 'skeletonBlue',
}

const Skeleton: React.FunctionComponent<SkeletonProps> = observer(
    ({
        className,
        isLoading = true,
        placeholder,
        children,
        loadingColor = SkeletonLoadingColor.grey,
    }) => {
        const placeholderWithClassName = React.cloneElement(placeholder, {
            className: `Skeleton ${className ?? ''} ${
                placeholder.props.className
            } animation ${loadingColor}`,
        });

        const nonNullChildren = children ? (
            Array.isArray(children) ? (
                <>{children}</>
            ) : (
                children
            )
        ) : (
            <></>
        );

        return isLoading ? <>{placeholderWithClassName}</> : nonNullChildren;
    }
);
export default Skeleton;
