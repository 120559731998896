import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const EyeIcon: FunctionComponent<SvgProps> = ({ fill = 'currentColor', onClick, ...props }) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <path
                    d="M13.4981 7C9.624 7 6.1432 10.093 4.13775 13.483C4.04655 13.642 4 13.821 4 14C4 14.179 4.0456 14.358 4.1368 14.517C6.1432 17.907 9.624 21 13.4981 21C17.434 21 20.9043 17.91 22.8689 14.507C22.9563 14.351 23 14.175 23 14C23 13.825 22.9563 13.649 22.8689 13.493C20.9043 10.09 17.434 7 13.4981 7ZM13.5 10C15.5976 10 17.3 11.792 17.3 14C17.3 16.208 15.5976 18 13.5 18C11.4024 18 9.7 16.208 9.7 14C9.7 11.792 11.4024 10 13.5 10ZM13.5 11.5C14.811 11.5 15.875 12.62 15.875 14C15.875 15.38 14.811 16.5 13.5 16.5C12.189 16.5 11.125 15.38 11.125 14C11.125 12.62 12.189 11.5 13.5 11.5Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default EyeIcon;
