// GENERATED FILE - DO NOT MODIFY
import URI from 'urijs';
import URITemplate, { URITemplateInput } from 'urijs/src/URITemplate';
import { ApiHttpClient } from 'Api/ApiHttpClient';

/**
 * Base class for all API proxies.
 */
export abstract class ApiProxyBase {
    constructor(protected readonly httpClient: ApiHttpClient) {
    }

    /**
     * Builds the URI for an API call, using template parameters and
     * query string parameters. 
     * @param baseUri Base URI. Can contain template parameters (ex: "/a/b/{param1}/c").
     * @param templateData Object/dictionary containing the values to insert in the URI template.
     * @param queryData Object/dictionary containing the values to add in the query string.
     */
    public buildUri(baseUri: string, templateData: URITemplateInput | null, queryData: Record<string, unknown> | null): string {
        let result: URI;

        // Apply template parameters: "a/b/{param1}/c" --> "a/b/123/c"
        if (templateData) {
            result = new URITemplate(baseUri).expand(templateData, { strict: true });
            result = new URI(result);
        } else {
            result = new URI(baseUri);
        }

        // Add query string parameters
        if (queryData) {
            // Remove undefined properties.
            Object.keys(queryData).forEach(key => (queryData[key] === null || queryData[key] === undefined) && delete queryData[key]);

            result = result.query(URI.buildQuery(queryData, true));
        }

        return result.valueOf();
    }
}
