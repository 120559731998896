// GENERATED FILE - DO NOT MODIFY

/**
 * Interface used by the API proxies to make HTTP calls.
 * Create an implementation of this interface and pass/inject it in proxies.
 */
export abstract class ApiHttpClient {
    abstract get<TR>(uri: string): Promise<TR>;
    abstract getAsBuffer(uri: string): Promise<ArrayBuffer>;
    abstract delete<TD, TR>(uri: string, data?: TD): Promise<TR>;
    abstract post<TD, TR>(uri: string, data?: TD): Promise<TR>;
    abstract postAsBuffer<TD>(uri: string, data?: TD): Promise<ArrayBuffer>;
    abstract put<TD, TR>(uri: string, data?: TD): Promise<TR>;
    abstract patch<TD, TR>(uri: string, data?: TD): Promise<TR>;
}
