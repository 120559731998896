import { ApiService } from './ApiService';
import { inject } from 'aurelia-dependency-injection';
import { JobApplicationProxy } from 'Api/Features/JobApplications/JobApplicationProxy';
import { JobApplicationDetailsDto } from 'Api/Features/JobApplications/Dtos/JobApplicationDetailsDto';
import { GetJobApplicationsRequestDto } from 'Api/Features/JobApplications/Dtos/GetJobApplicationsRequestDto';
import { GetJobApplicationsResponseDto } from 'Api/Features/JobApplications/Dtos/GetJobApplicationsResponseDto';
import { CreateJobApplicationRequestDto } from 'Api/Features/JobApplications/Dtos/CreateJobApplicationRequestDto';
import { CreateJobApplicationResponseDto } from 'Api/Features/JobApplications/Dtos/CreateJobApplicationResponseDto';
import { UpdateJobApplicationStatusRequestDto } from 'Api/Features/JobApplications/Dtos/UpdateJobApplicationStatusRequestDto';
import { JobApplicationDto } from 'Api/Features/JobApplications/Dtos/JobApplicationDto';

@inject(JobApplicationProxy)
export class JobApplicationService extends ApiService {
    constructor(private readonly jobApplicationProxy: JobApplicationProxy) {
        super();
    }

    public async getJobApplication(
        jobApplicationId: string
    ): Promise<JobApplicationDetailsDto | null> {
        const data: JobApplicationDetailsDto | null =
            await this.jobApplicationProxy.getJobApplication(jobApplicationId);
        return data;
    }

    public async getJobApplications(
        request: GetJobApplicationsRequestDto | null
    ): Promise<[JobApplicationDto[], number] | null> {
        const data: GetJobApplicationsResponseDto | null =
            await this.jobApplicationProxy.getJobApplications(request);
        return [
            data?.items?.filter((x) => x !== null).map((x) => x!) ?? [],
            data?.totalItemCount ?? 0,
        ];
    }

    public async createJobApplication(
        request: CreateJobApplicationRequestDto | null
    ): Promise<CreateJobApplicationResponseDto | null> {
        const data: CreateJobApplicationResponseDto | null =
            await this.jobApplicationProxy.createJobApplication(request);
        return data;
    }

    public async updateJobApplicationStatus(
        jobApplicationId: string,
        request: UpdateJobApplicationStatusRequestDto | null
    ): Promise<void> {
        await this.updateJobApplicationStatus(jobApplicationId, request);
    }

    public async withdrawJobApplication(jobApplicationId: string): Promise<void> {
        await this.jobApplicationProxy.withdrawJobApplication(jobApplicationId);
    }
}
