import { InitializeFunction } from 'Hooks/use-ga4';
import { TFunction } from 'i18next';
import { CookieConsentConfig, CookieTable } from 'vanilla-cookieconsent';

const essentialCookies = (t: TFunction): CookieTable => ({
    headers: {
        col1: t('Cookies.Cookies_preferencesModal_sections_essential_col1_header'),
        col2: t('Cookies.Cookies_preferencesModal_sections_essential_col2_header'),
        col3: t('Cookies.Cookies_preferencesModal_sections_essential_col3_header'),
        col4: t('Cookies.Cookies_preferencesModal_sections_essential_col4_header'),
    },
    body: [
        {
            col1: t('Cookies.Cookies_preferencesModal_sections_essential_col1_body_cookie_0'),
            col2: t('Cookies.Cookies_preferencesModal_sections_essential_col2_body_cookie_0'),
            col3: t('Cookies.Cookies_preferencesModal_sections_essential_col3_body_cookie_0'),
            col4: t('Cookies.Cookies_preferencesModal_sections_essential_col4_body_cookie_0'),
        },
        {
            col1: t('Cookies.Cookies_preferencesModal_sections_essential_col1_body_cookie_1'),
            col2: t('Cookies.Cookies_preferencesModal_sections_essential_col2_body_cookie_1'),
            col3: t('Cookies.Cookies_preferencesModal_sections_essential_col3_body_cookie_1'),
            col4: t('Cookies.Cookies_preferencesModal_sections_essential_col4_body_cookie_1'),
        },
    ],
});

const analyticsCookies = (t: TFunction): CookieTable => ({
    headers: {
        col1: t('Cookies.Cookies_preferencesModal_section_analytics_col1_header'),
        col2: t('Cookies.Cookies_preferencesModal_section_analytics_col2_header'),
        col3: t('Cookies.Cookies_preferencesModal_section_analytics_col3_header'),
        col4: t('Cookies.Cookies_preferencesModal_section_analytics_col4_header'),
    },
    body: [
        {
            col1: t('Cookies.Cookies_preferencesModal_section_analytics_col1_body_cookie_0'),
            col2: t('Cookies.Cookies_preferencesModal_section_analytics_col2_body_cookie_0'),
            col3: t('Cookies.Cookies_preferencesModal_section_analytics_col3_body_cookie_0'),
            col4: t('Cookies.Cookies_preferencesModal_section_analytics_col4_body_cookie_0'),
        },
    ],
});

type CookieConfigArgs = {
    t: TFunction;
    initReactGa: InitializeFunction;
    resetReactGa: () => void;
};

export const cookieConfig = ({
    t,
    resetReactGa,
    initReactGa,
}: CookieConfigArgs): CookieConsentConfig => ({
    categories: {
        necessary: {
            enabled: true, // this category is enabled by default
            readOnly: true, // this category cannot be disabled
        },
        analytics: {
            enabled: false,
            readOnly: false,
            services: {
                ga: {
                    label: 'Google Analytics',
                    cookies: [
                        {
                            name: /^(_ga)/,
                        },
                    ],
                    onAccept: () => {
                        initReactGa(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID || '');
                    },
                    onReject: () => {
                        resetReactGa();
                    },
                },
            },
        },
    },
    language: {
        default: 'fr',
        translations: {
            fr: {
                consentModal: {
                    title: t('Cookies.Cookies_consentModal_title'),
                    description: t('Cookies.Cookies_consentModal_description'),
                    acceptAllBtn: t('Cookies.Cookies_consentModal_acceptAllBtn'),
                    acceptNecessaryBtn: t('Cookies.Cookies_consentModal_acceptNecessaryBtn'),
                    showPreferencesBtn: t('Cookies.Cookies_consentModal_showPreferencesBtn'),
                },
                preferencesModal: {
                    title: t('Cookies.Cookies_preferencesModal_title'),
                    acceptAllBtn: t('Cookies.Cookies_preferencesModal_acceptAllBtn'),
                    acceptNecessaryBtn: t('Cookies.Cookies_preferencesModal_acceptNecessaryBtn'),
                    savePreferencesBtn: t('Cookies.Cookies_preferencesModal_savePreferencesBtn'),
                    sections: [
                        {
                            title: t('Cookies.Cookies_preferencesModal_sections_head_title'),
                            description: t(
                                'Cookies.Cookies_preferencesModal_sections_head_description'
                            ),
                        },
                        {
                            title: t('Cookies.Cookies_preferencesModal_sections_essential'),
                            description: t(
                                'Cookies.Cookies_preferencesModal_sections_essential_description'
                            ),

                            //this field will generate a toggle linked to the 'necessary' category
                            linkedCategory: 'necessary',
                            cookieTable: essentialCookies(t),
                        },
                        {
                            title: t('Cookies.Cookies_preferencesModal_sections_performance'),
                            description: t(
                                'Cookies.Cookies_preferencesModal_sections_performance_description'
                            ),
                            linkedCategory: 'analytics',
                            cookieTable: analyticsCookies(t),
                        },
                        {
                            title: t('Cookies.Cookies_preferencesModal_sections_more_title'),
                            description: t(
                                'Cookies.Cookies_preferencesModal_sections_more_description'
                            ),
                        },
                    ],
                },
            },
        },
    },
});
