import Icon, { IconName } from 'Components/icons/Icon';
import React, { HTMLProps } from 'react';
import './index.less';
import FancyArrowIcon from 'Components/icons/fancy-arrow-icon';
import { SvgProps } from 'Components/icons/SvgProps';

const getIcon = (icons: Icons, iconProps?: SvgProps) => {
    if (icons.leftIcon)
        return (
            <span className="icon-container leftIcon ">
                <Icon iconName={icons.leftIcon} {...iconProps} />
            </span>
        );
    if (icons.leftCircleIcon)
        return (
            <span className="icon-container leftCircleIcon">
                <Icon iconName={icons.leftCircleIcon} {...iconProps} />
            </span>
        );
    if (icons.rightIcon)
        return (
            <span className="icon-container rightIcon">
                <Icon iconName={icons.rightIcon} {...iconProps} />
            </span>
        );
    if (icons.rightCircleIcon)
        return (
            <span className="icon-container rightCircleIcon">
                <Icon iconName={icons.rightCircleIcon} />
            </span>
        );

    return null;
};

export type Type =
    | 'primary'
    | 'blue-border'
    | 'white-border'
    | 'red'
    | 'white'
    | 'white-red-text'
    | 'deep-blue'
    | 'text';

export type Width = 'full' | 'auto' | 'hugged';

export type Height = 'small' | 'large';

interface Icons {
    leftIcon?: IconName;
    rightIcon?: IconName;
    leftCircleIcon?: IconName;
    rightCircleIcon?: IconName;
}

interface ButtonProps {
    type: Type;
    text?: string;
    width?: Width;
    height?: Height;
    uppercase?: boolean;
    children?: React.ReactNode;
    withRightArrow?: boolean;
    withLeftArrow?: boolean;
    textClassName?: string;
    iconProps?: SvgProps;
}

type Button = ButtonProps & Icons & HTMLProps<HTMLButtonElement>;

const Button: React.FunctionComponent<Button> = ({
    text,
    type,
    width,
    height,
    leftIcon,
    rightIcon,
    leftCircleIcon,
    rightCircleIcon,
    className,
    disabled = false,
    uppercase = false,
    withRightArrow = false,
    withLeftArrow = false,
    children,
    textClassName = '',
    iconProps,
    ...htmlProps
}) => {
    const classes = `ButtonWrapper type-${type} width-${width} height-${height} disabled-${disabled} uppercase-${uppercase} ${className} `;
    const content = (
        <>
            {getIcon({ leftIcon, leftCircleIcon }, iconProps)}
            <span className={textClassName}>{text || ''}</span>
            {getIcon({ rightIcon, rightCircleIcon }, iconProps)}
        </>
    );

    return (
        <button className={classes} disabled={disabled} {...htmlProps}>
            <div className="Button">
                {withRightArrow || withLeftArrow ? (
                    <div className="buttonContent">
                        {withLeftArrow && (
                            <div className="arrowContainer arrowContainer-left">
                                <FancyArrowIcon width={69} height={30} direction="left" />
                            </div>
                        )}
                        {content}
                        {withRightArrow && (
                            <div className="arrowContainer arrowContainer-right">
                                <FancyArrowIcon width={69} height={30} direction="right" />
                            </div>
                        )}
                    </div>
                ) : (
                    children || content
                )}
            </div>
        </button>
    );
};

export default Button;
