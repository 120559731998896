import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const EnvelopeIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <path
                    d="M14.0088 14.5771C14.3867 14.5771 14.7207 14.4014 15.1162 14.0059L22.7803 6.40332C22.4375 6.0957 21.8311 5.94629 20.9785 5.94629H6.80176C6.0459 5.94629 5.50977 6.08691 5.20215 6.36816L12.9014 14.0059C13.2881 14.4014 13.6309 14.5771 14.0088 14.5771ZM4.3584 19.8945L10.6777 13.6367L4.36719 7.40527C4.20898 7.68652 4.12109 8.15234 4.12109 8.81152V18.5234C4.12109 19.165 4.2002 19.6221 4.3584 19.8945ZM23.6416 19.8857C23.791 19.6133 23.8701 19.1562 23.8701 18.5234V8.81152C23.8701 8.16992 23.7822 7.7041 23.624 7.43164L17.3398 13.6367L23.6416 19.8857ZM7.0127 21.3887H21.1895C21.9629 21.3887 22.5078 21.2393 22.8242 20.9492L16.3906 14.5596L15.8457 15.0957C15.2393 15.6846 14.6592 15.9658 14.0088 15.9658C13.3584 15.9658 12.7695 15.6846 12.1631 15.0957L11.6182 14.5596L5.19336 20.9316C5.54492 21.2305 6.16016 21.3887 7.0127 21.3887Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default EnvelopeIcon;
