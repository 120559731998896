import { FunctionComponent, useState } from 'react';
import './index.less';
import { useHistory } from 'react-router-dom';
import { ACCOUNT_URL, ACTIVE_TAB_PARAM } from 'Models/Constants';

export interface TabItem {
    tabKey: string;
    tab: string;
    children: React.ReactNode;
}
interface TabsCustomProps {
    items: TabItem[];
    tabClassName?: string;
    activeTabClassName?: string;
    defaultActiveTabKey?: string;
    contentClassName?: string;
    className?: string;
}

const TabsCustom: FunctionComponent<TabsCustomProps> = ({
    items,
    defaultActiveTabKey,
    activeTabClassName = '',
    tabClassName = '',
    contentClassName = '',
    className = '',
}) => {
    const [activeTab, setActiveTab] = useState<string>(defaultActiveTabKey || items[0].tabKey);
    const history = useHistory();
    const itemIsActive = (tabKey: string) => tabKey === activeTab;

    const onTabClick = (tabKey: string) => {
        history.push({
            pathname: ACCOUNT_URL,
            search: `?${ACTIVE_TAB_PARAM}=${tabKey}`,
        });
        setActiveTab(tabKey);
    };

    return (
        <div className={`TabsCustom ${className}`}>
            <div className="TabsCustom__tabsRow">
                {items.map((item) => (
                    <div
                        key={item.tabKey}
                        role="tab"
                        onClick={() => onTabClick(item.tabKey)}
                        className={`TabsCustom__tab ${tabClassName} ${
                            itemIsActive(item.tabKey) ? activeTabClassName : ''
                        }`}
                    >
                        {item.tab}
                    </div>
                ))}
            </div>
            <div className={`TabsCustom__content ${contentClassName}`}>
                {items.map((item) =>
                    itemIsActive(item.tabKey) ? (
                        <div key={item.tabKey} className="TabsCustom__contentItem">
                            {item.children}
                        </div>
                    ) : null
                )}
            </div>
        </div>
    );
};

export default TabsCustom;
