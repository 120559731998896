import BlueCard from 'Components/blue-card';
import Button from 'Components/button';
import React, { FunctionComponent, useContext, useRef, useState } from 'react';
import './index.less';
import { useTranslation } from 'react-i18next';
import FadingLetter from 'Components/fading-letter';
import { CREATE_ACCOUNT_URL, METIER_URL, UPDATED_QUIZ_PARAM } from 'Models/Constants';
import AnswerSquare from './components/answer-square';
import QuestionLayout from './components/question-layout';
import { CheckmarkCircleIcon } from 'Components/icons';
import { theme } from 'Style/theme';
import { useHistory } from 'react-router-dom';
import { useFormNavigationBlocker } from 'Hooks/use-form-navigation-blocker';
import Content from 'Components/Content';
import { QuizDefinition } from 'Models/QuizDefinitions';
import { CreateCandidateRequestQuizDto } from 'Api/Features/Candidates/Dtos/CreateCandidateRequestQuizDto';
import metiersData from 'Models/MetierDefinitions';
import { useService, useStores, useWindowDimensions } from 'Hooks';
import { getPreferredJobCategoryForQuiz } from 'Utils/QuizUtils';
import { CandidateQuizDto } from 'Api/Features/Candidates/Dtos/CandidateQuizDto';
import { observer } from 'mobx-react';
import { useFetch } from 'Hooks/use-fetch';
import { CandidateService } from 'Services/CandidateService';
import { ScrollContext } from 'Components/basic-layout';

interface QuizProps {
    isCreateAccountFlow?: boolean;
    onFinaliseAccountClick?: (quiz: CreateCandidateRequestQuizDto) => void;
}

const Quiz: FunctionComponent<QuizProps> = observer(
    ({ isCreateAccountFlow, onFinaliseAccountClick }) => {
        const scrollContext = useContext(ScrollContext);
        const MOBILE_BREAKPOINT = 760;
        const history = useHistory();
        const { t } = useTranslation();
        const { userStore, toastStore } = useStores();
        const { windowWidth } = useWindowDimensions();
        const [setNavBlocked] = useFormNavigationBlocker();
        const { apiRequest } = useFetch();
        const candidateService = useService(CandidateService);

        const GAbtnRef = useRef<HTMLButtonElement>(null);

        const [currentFlowStep, setCurrentFlowStep] = useState<QuizFlowSteps>(
            QuizFlowSteps.landing
        );
        const [currentQuizQuestion, setCurrentQuizQuestion] = useState<{
            index: number;
            questionId: string;
        }>({ index: 0, questionId: QuizDefinition.questions[0].id });
        const TOTAL_NUMBER_QUESTIONS = QuizDefinition.questions.length;

        /**Map <questionId, choiceId> */
        const [allAnswers, setAllAnswers] = useState<Map<string, string | undefined>>(
            QuizDefinition.questions.reduce(
                (map, question) => map.set(question.id, undefined),
                new Map()
            )
        );
        const [jobsFoundAmount, setJobsFoundAmount] = useState(0);

        const udpateUserQuiz = async (quiz: CreateCandidateRequestQuizDto) => {
            try {
                await apiRequest({
                    requestFunction: (request) =>
                        candidateService.updateCandidateQuiz(userStore.userInfo?.id ?? '', request),
                    requestParameters: quiz,
                    useGlobalLoading: true,
                    throwOnError: true,
                });
                await userStore.setUserQuizAnswers(userStore.userInfo?.id ?? '');

                history.push(CREATE_ACCOUNT_URL + `?${UPDATED_QUIZ_PARAM}=true`);
            } catch (e) {
                toastStore.genericError();
            }
        };

        const handleLastQuestionAnswered = () => {
            if (GAbtnRef.current) GAbtnRef.current.click();

            const quizAnswers: CandidateQuizDto = {
                responses: Array.from(allAnswers).map(([questionId, choiceId]) => ({
                    questionId,
                    choiceId,
                })),
            };

            const preferredCategory = getPreferredJobCategoryForQuiz(quizAnswers);

            setJobsFoundAmount(
                metiersData.find((x) => x.categoryType === preferredCategory)?.jobs.length ?? 0
            );

            if (!isCreateAccountFlow) {
                //connected User redoing his quiz
                if (userStore.userInfo) {
                    udpateUserQuiz(quizAnswerToDto());
                }
                //Quiz started outside of creating account flow. Keep the answer in local storage for future use
                else {
                    userStore.setAnonymousQuizAnswers(quizAnswerToDto());
                    setCurrentFlowStep(QuizFlowSteps.success);
                }
            } else {
                setCurrentFlowStep(QuizFlowSteps.success);
            }
        };

        const handleFinaliseAccountClick = () => {
            if (onFinaliseAccountClick) onFinaliseAccountClick(quizAnswerToDto());
            else {
                history.push(CREATE_ACCOUNT_URL);
            }
        };

        const quizAnswerToDto = (): CreateCandidateRequestQuizDto => ({
            responses: Array.from(allAnswers).map(([questionId, choiceId]) => ({
                questionId,
                choiceId,
            })),
        });

        const handleScrollTop = () => {
            scrollContext?.scrollToTop();
        };

        return (
            <div className="Quiz">
                <Content>
                    {currentFlowStep === QuizFlowSteps.landing && (
                        <div className="landing">
                            <div className="custom-font title text-white uppercase">
                                {t('decouvre_ton_metier')}&nbsp;
                                <FadingLetter
                                    side="left"
                                    width={windowWidth > MOBILE_BREAKPOINT ? 73 : 38}
                                    height={windowWidth > MOBILE_BREAKPOINT ? 80 : 38}
                                    wordOffset={-10}
                                    word={t('ideal')}
                                />
                            </div>
                            <div className="text-title-large-bold text-white mb-20 landing-title-2">
                                {t('Quiz.landing_p1')}
                            </div>
                            <BlueCard padding={40} className="start-quiz-container">
                                <div className="custom-font text-white uppercase pret">
                                    {t('Quiz.pret_a_commencer')}
                                </div>
                                <div className="start-quiz-button-container">
                                    <Button
                                        text={t('Quiz.cest_parti')}
                                        type="red"
                                        withRightArrow
                                        height="large"
                                        uppercase
                                        width="full"
                                        onClick={() => {
                                            setNavBlocked(true);
                                            setCurrentFlowStep((prev) => prev + 1);
                                            handleScrollTop();
                                        }}
                                    />
                                </div>
                            </BlueCard>
                        </div>
                    )}

                    {currentFlowStep !== QuizFlowSteps.landing &&
                        currentFlowStep !== QuizFlowSteps.success &&
                        QuizDefinition.questions.map((question, i) => (
                            <>
                                {currentQuizQuestion.index === i && (
                                    <div className="question-layout-wrap">
                                        <QuestionLayout
                                            key={i}
                                            question={t(question.textKey)}
                                            questionNumber={i + 1}
                                            totalQuestionsNumber={TOTAL_NUMBER_QUESTIONS}
                                            answers={question.choices.map((choice, ii) => (
                                                <AnswerSquare
                                                    id={choice.id}
                                                    answer={choice.text}
                                                    index={ii}
                                                    onClick={(id) => {
                                                        setAllAnswers(
                                                            new Map(allAnswers).set(question.id, id)
                                                        );
                                                    }}
                                                    selected={
                                                        allAnswers.get(question.id) === choice.id
                                                    }
                                                />
                                            ))}
                                            nextButton={
                                                <Button
                                                    text={t('Quiz.prochaine_question')}
                                                    type="red"
                                                    width="hugged"
                                                    onClick={() => {
                                                        handleScrollTop();
                                                        if (
                                                            i ===
                                                            QuizDefinition.questions.length - 1
                                                        )
                                                            handleLastQuestionAnswered();

                                                        if (
                                                            currentQuizQuestion.index !==
                                                            QuizDefinition.questions.length - 1
                                                        ) {
                                                            setCurrentQuizQuestion((prev) => {
                                                                const newIndex = prev.index + 1;
                                                                return {
                                                                    index: newIndex,
                                                                    questionId:
                                                                        QuizDefinition.questions[
                                                                            newIndex
                                                                        ].id,
                                                                };
                                                            });
                                                        }
                                                    }}
                                                    disabled={
                                                        allAnswers.get(
                                                            currentQuizQuestion.questionId
                                                        ) === undefined
                                                    }
                                                    uppercase
                                                    withRightArrow
                                                />
                                            }
                                            previousButton={
                                                i !== 0 && (
                                                    <Button
                                                        text={t('Quiz.question_precedente')}
                                                        type="deep-blue"
                                                        width="hugged"
                                                        onClick={() => {
                                                            handleScrollTop();
                                                            setCurrentQuizQuestion((prev) => {
                                                                const newIndex = prev.index - 1;
                                                                return {
                                                                    index: newIndex,
                                                                    questionId:
                                                                        QuizDefinition.questions[
                                                                            newIndex
                                                                        ].id,
                                                                };
                                                            });
                                                        }}
                                                        uppercase
                                                        withLeftArrow
                                                    />
                                                )
                                            }
                                        />
                                    </div>
                                )}
                            </>
                        ))}

                    {currentFlowStep === QuizFlowSteps.success && (
                        <div className="success">
                            <CheckmarkCircleIcon fill={theme.white} width={105} />
                            <div className="text-white text-title-large-bold mt-20 metier uppercase">
                                {jobsFoundAmount} {t('Quiz.metier_trouve')}
                            </div>

                            <div
                                className="text-white mt-40 mb-40"
                                dangerouslySetInnerHTML={{ __html: t('Quiz.grace_a_tes_reponses') }}
                            />

                            {!isCreateAccountFlow && (
                                <Button
                                    text={t('explorer_les_metiers')}
                                    type="white-border"
                                    uppercase
                                    onClick={() => history.push(METIER_URL)}
                                />
                            )}

                            <BlueCard padding={40} className="finalise-card">
                                <div className="custom-font text-white uppercase pret">
                                    {t('Quiz.plus_quune_etape')}
                                </div>
                                <Button
                                    text={t('Quiz.finaliser_le_compte')}
                                    type="red"
                                    uppercase
                                    onClick={() => handleFinaliseAccountClick()}
                                    withRightArrow
                                />
                            </BlueCard>
                        </div>
                    )}
                </Content>

                {/* Marketing teams wants a GA click id when an quiz is completed. We will simulate a click on successful submit */}
                <button ref={GAbtnRef} style={{ display: 'none' }} id="GA-quiz" />
            </div>
        );
    }
);

enum QuizFlowSteps {
    landing,
    quiz,
    success,
}

export default Quiz;
