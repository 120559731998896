import React, { FunctionComponent } from 'react';

interface VerticalFormItemLayoutProps {
    className?: string;
}

export const VerticalFormlessItemLayout: FunctionComponent<VerticalFormItemLayoutProps> = ({
    className,
    children,
}) => {
    return <div className={`ant-form-vertical ${className ?? ''}`}>{children}</div>;
};
