import React, { FunctionComponent, useMemo, useState } from 'react';
import './index.less';
import { CloseIcon, FeedbackIcon } from 'Components/icons';
import { theme } from 'Style/theme';
import Button from 'Components/button';
import { useTranslation } from 'react-i18next';
import { FeedbackDefinitions } from 'Models/FeedbackDefinitions';
import { useFetch } from 'Hooks/use-fetch';
import { useService, useStores } from 'Hooks';
import { CandidateService } from 'Services/CandidateService';
import { observer } from 'mobx-react';
import { UpdateCandidateRecruitmentFeedbackRequestDto } from 'Api/Features/Candidates/Dtos/UpdateCandidateRecruitmentFeedbackRequestDto';
import { CandidateRecruitmentFeedbackResponseDto } from 'Api/Features/Candidates/Dtos/CandidateRecruitmentFeedbackResponseDto';

const FeedbackBox: FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const candidateService = useService(CandidateService);
    const { apiRequest } = useFetch();
    const { userStore } = useStores();

    const currentQuestion = useMemo((): CandidateRecruitmentFeedbackResponseDto | null => {
        return userStore.userFeedback?.responses?.find((x) => x?.askQuestion) ?? null;
    }, [userStore.userFeedback]);

    const submitAnswer = async (answer: boolean) => {
        const request: UpdateCandidateRecruitmentFeedbackRequestDto = {
            responses: [
                {
                    questionId: currentQuestion?.questionId,
                    value: answer,
                },
            ],
        };
        await apiRequest({
            requestFunction: (request) =>
                candidateService.updateCandidateRecruitmentFeedback(
                    userStore.userInfo?.id ?? '',
                    request
                ),
            requestParameters: request,
            useGlobalLoading: true,
        });
        userStore.setUserFeedback(userStore.userInfo?.id ?? '');
    };

    return (
        <div className="FeedbackBox">
            {isOpen ? (
                <div className="open-container">
                    <div className="close">
                        <CloseIcon
                            fill={theme['surface-grey-primary']}
                            onClick={() => setIsOpen(false)}
                        />
                    </div>

                    <div className="title-container">
                        <FeedbackIcon fill={theme['surface-brand-rest-primary']} />
                        <span className="custom-font text-primary uppercase">
                            {t('retroaction')}
                        </span>
                    </div>

                    <div className="question text-body-large">
                        {
                            FeedbackDefinitions.questions.find(
                                (x) => x.id === currentQuestion?.questionId
                            )?.text
                        }
                    </div>

                    <div className="btns">
                        <Button
                            type="blue-border"
                            text={t('non')}
                            width="hugged"
                            onClick={() => submitAnswer(false)}
                        />
                        <Button
                            type="red"
                            text={t('oui')}
                            width="hugged"
                            onClick={() => submitAnswer(true)}
                        />
                    </div>
                </div>
            ) : (
                <div className="closed-container" onClick={() => setIsOpen(true)}>
                    <div className="notification text-body-large text-white">
                        {userStore.userFeedback?.responses?.reduce((accumulated, currentObj) => {
                            return accumulated + (currentObj?.askQuestion ? 1 : 0);
                        }, 0)}
                    </div>
                    <FeedbackIcon fill={theme['surface-brand-rest-primary']} />
                </div>
            )}
        </div>
    );
});

export default FeedbackBox;
