import useSelectedNav from 'Hooks/use-selected-nav';
import './index.less';
import { FunctionComponent } from 'react';
import { HeaderProps } from 'Components/header';
import ConnectedUser from '../connected-user';
import Button from 'Components/button';
import { CREATE_ACCOUNT_URL, LOGIN_URL } from 'Models/Constants';
import { useTranslation } from 'react-i18next';

const DesktopHeaderLayout: FunctionComponent<HeaderProps> = ({
    navItems,
    onNavigationClick,
    isLoggedIn,
}) => {
    const selectedKey = useSelectedNav();
    const { t } = useTranslation();

    return (
        <div className="DesktopLayout header-inner-container">
            <div className="nav-items-container">
                {navItems.map((x) => (
                    <div
                        className={`nav-item text-white text-title-medium-bold uppercase cursor-pointer ${
                            x.key === selectedKey && 'selected-nav'
                        }`}
                        key={x.key}
                        onClick={() => onNavigationClick(x.key)}
                    >
                        {x.text}
                    </div>
                ))}
            </div>
            <div className="left-content">
                {isLoggedIn ? (
                    <div className="connected-container">
                        <ConnectedUser />
                    </div>
                ) : (
                    <div className="not-logged">
                        <Button
                            text={t('User.cree_mon_compte')}
                            type="red"
                            onClick={() => onNavigationClick(CREATE_ACCOUNT_URL)}
                        />
                        <div
                            className="connect text-label-large-bold text-white uppercase cursor-pointer"
                            role="button"
                            onClick={() => onNavigationClick(LOGIN_URL)}
                        >
                            {t('User.me_connecter')}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DesktopHeaderLayout;
