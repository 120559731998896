import { useCallback } from 'react';

const useLocalStorage = () => {
    const setValue = useCallback((key: string, value: string) => {
        localStorage.setItem(key, value);
        const event = new CustomEvent('localStorageChange', {
            detail: { key, value },
        });
        window.dispatchEvent(event);
    }, []);

    const getValue = useCallback((key: string) => {
        return localStorage.getItem(key);
    }, []);

    return {
        setLocalStorage: setValue,
        getLocalStorage: getValue,
    };
};

export default useLocalStorage;
