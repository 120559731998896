import { FunctionComponent, useEffect, useState } from 'react';
import './index.less';
import FancyArrowIcon from 'Components/icons/fancy-arrow-icon';
import { theme } from 'Style/theme';
import PlayVideoAltIcon from 'Components/icons/play-video-alt-icon';
import Copydeck from 'i18n/Copydeck';
import BaseModal from 'Components/modal';
import { CloseIcon } from 'Components/icons';
import YoutubeVideoPlayer from 'Components/youtube-video-player';
import { Tooltip } from 'antd';
import { useWindowDimensions } from 'Hooks';

interface TemoignageCardProps {
    quote: string;
    description: string;
    name: string;
    jobTitle: string;
    image: string;
    videoUrl?: string;
    showVideoLink?: boolean;
    fullWidth?: boolean;
    withRightArrow?: boolean;
    isClickable?: boolean;
    onClick?: () => void;
}

const TemoignageCard: FunctionComponent<TemoignageCardProps> = ({
    quote,
    description,
    name,
    jobTitle,
    image,
    fullWidth = false,
    withRightArrow = false,
    isClickable = false,
    onClick,
    videoUrl,
    showVideoLink = false,
}) => {
    const [modalIsVisible, setModalIsVisible] = useState<boolean>(false);
    const { windowWidth } = useWindowDimensions();

    const YOUTUBE_VIDEO_BREAKPOINT = 850;

    const handleVideoButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setModalIsVisible(true);
    };

    const handleCloseClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setModalIsVisible(false);
    };

    useEffect(() => {
        const handleEscapeDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                setModalIsVisible(false);
            }
        };

        document.addEventListener('keydown', handleEscapeDown);

        return () => {
            document.removeEventListener('keydown', handleEscapeDown);
        };
    }, []);

    return (
        <>
            <div
                className={`TemoignageCard ${fullWidth ? 'TemoignageCard--fullWidth' : ''}${
                    isClickable ? 'TemoignageCard--clickable' : ''
                }`}
                onClick={onClick}
            >
                {modalIsVisible && videoUrl && (
                    <BaseModal
                        visible={modalIsVisible}
                        closeIcon={<CloseIcon fill={theme['white']} />}
                        maskClosable
                        closable
                        width={'auto'}
                        onCancel={handleCloseClick}
                        className="TemoignageCard__modal"
                    >
                        <YoutubeVideoPlayer
                            url={videoUrl}
                            width={windowWidth < YOUTUBE_VIDEO_BREAKPOINT ? '100%' : '741px'}
                            aspectRatio="647 / 364"
                        />
                    </BaseModal>
                )}
                <div
                    className="TemoignageCard__quote"
                    dangerouslySetInnerHTML={{
                        __html: quote,
                    }}
                ></div>
                <div className="TemoignageCard__description">{description}</div>
                {videoUrl && showVideoLink && (
                    <button
                        className="text-label-large-bold TemoignageCard__videoButton"
                        onClick={handleVideoButtonClick}
                    >
                        <PlayVideoAltIcon />
                        {Copydeck['temoignagesTemoignagesCardPlayVideo']}
                    </button>
                )}
                <div className="TemoignageCard__author">
                    <div className="TemoignageCard__authorWrapper">
                        <img
                            src={image}
                            alt="author"
                            className="TemoignageCard__authorImage"
                            width={60}
                            height={60}
                        />
                        <div className="TemoignageCard__authorInfo">
                            <div className="TemoignageCard__authorName">{name}</div>
                            <div className="TemoignageCard__authorJob">{jobTitle}</div>
                        </div>
                    </div>
                    {withRightArrow && (
                        <Tooltip
                            title={Copydeck['temoignageDetailsTemoignageDetailsCta']}
                            color={theme['surface-brand-rest-tertiary']}
                            overlayClassName="TemoignageCard__rightArrowTooltip"
                        >
                            <FancyArrowIcon
                                direction="right"
                                fill={theme['element-onbrandaccent-overlay-primary']}
                                width={60}
                                height={26}
                                className="TemoignageCard__rightArrow"
                            />
                        </Tooltip>
                    )}
                </div>
            </div>
        </>
    );
};

export default TemoignageCard;
