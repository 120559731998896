import { useEffect, useState } from 'react';

const useElementIsVisible = (ref: React.RefObject<HTMLElement>, threshold?: number[]) => {
    const [isIntersecting, setIsIntersecting] = useState<boolean>(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsIntersecting(entry.isIntersecting);
            },
            { threshold }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [ref, threshold]);

    return isIntersecting;
};

export default useElementIsVisible;
