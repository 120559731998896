import { SvgProps } from 'Components/icons/SvgProps';
import { FunctionComponent } from 'react';

const FooterLogo: FunctionComponent<SvgProps> = ({ fill, ...props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="83"
            height="33"
            viewBox="0 0 83 33"
            fill="none"
            {...props}
        >
            <g clipPath="url(#clip0_3721_364)">
                <path
                    d="M33.4787 1.33838H35.0395L35.1529 4.83961L37.1115 1.33838H38.8431L37.4598 6.4019H36.3173L37.2111 3.089L35.302 6.4019H34.2591L34.1311 3.08158L33.2373 6.4019H32.0947L33.4787 1.33838Z"
                    fill={fill || 'white'}
                />
                <path
                    d="M41.3547 1.33838H42.9868L43.3489 6.4019H42.1424L42.0857 5.18662H40.2908L39.5882 6.4019H38.2188L41.3547 1.33838ZM42.0282 4.10185L41.9431 2.35123L40.9213 4.10185H42.0282Z"
                    fill={fill || 'white'}
                />
                <path
                    d="M46.3845 4.44148C46.3845 2.8792 47.4202 1.21533 49.0878 1.21533C50.074 1.21533 51.1525 1.77218 51.0391 3.11795H49.8187C49.8398 2.51736 49.4354 2.33644 49.0667 2.33644C48.1867 2.33644 47.626 3.42863 47.626 4.39106C47.626 5.17925 48.002 5.40391 48.4347 5.40391C48.8885 5.40391 49.2296 5.14366 49.3998 4.63723H50.6129C50.1802 5.91775 49.4631 6.52502 48.4063 6.52502C47.3496 6.52502 46.3838 5.95334 46.3838 4.44148H46.3845Z"
                    fill={fill || 'white'}
                />
                <path
                    d="M53.6926 1.33838H55.3246L55.6868 6.4019H54.4803L54.4235 5.18662H52.6286L51.9261 6.4019H50.5566L53.6926 1.33838ZM54.3668 4.10185L54.2817 2.35123L53.2599 4.10185H54.3668Z"
                    fill={fill || 'white'}
                />
                <path
                    d="M57.8015 1.33838H59.76C60.576 1.33838 61.3782 1.76547 61.3782 2.79241C61.3782 3.63176 60.8815 4.33319 59.8597 4.51411L60.5979 6.4019H59.328L58.6117 4.5282H58.1004L57.5899 6.4019H56.4189L57.8022 1.33838H57.8015ZM59.3906 3.44342C59.944 3.44342 60.1709 3.18316 60.1709 2.90808C60.1709 2.63299 60.0008 2.45949 59.6175 2.45949H58.6669L58.3971 3.44342H59.3906Z"
                    fill={fill || 'white'}
                />
                <path
                    d="M62.8171 1.33838H64.7757C65.5917 1.33838 66.3938 1.76547 66.3938 2.79241C66.3938 3.63176 65.8971 4.33319 64.8753 4.51411L65.6135 6.4019H64.3437L63.6273 4.5282H63.116L62.6055 6.4019H61.4346L62.8178 1.33838H62.8171ZM64.4069 3.44342C64.9604 3.44342 65.1873 3.18316 65.1873 2.90808C65.1873 2.63299 65.0171 2.45949 64.6338 2.45949H63.6833L63.4135 3.44342H64.4069Z"
                    fill={fill || 'white'}
                />
                <path
                    d="M67.8342 1.33838H69.0051L67.6211 6.4019H66.4502L67.8335 1.33838H67.8342Z"
                    fill={fill || 'white'}
                />
                <path
                    d="M70.2114 1.33836H73.3474V2.45947H71.0769L70.8783 3.19723H72.8369V4.31834H70.5736L70.311 5.28077H72.2696V6.40188H68.8281L70.2114 1.33836ZM72.1489 0L72.4754 0.940188H71.4463L71.0492 0H72.1489Z"
                    fill={fill || 'white'}
                />
                <path
                    d="M74.6462 1.33838H76.6048C77.4208 1.33838 78.2229 1.76547 78.2229 2.79241C78.2229 3.63176 77.7262 4.33319 76.7044 4.51411L77.4426 6.4019H76.1728L75.4564 4.5282H74.9451L74.4346 6.4019H73.2637L74.6469 1.33838H74.6462ZM76.2353 3.44342C76.7888 3.44342 77.0157 3.18316 77.0157 2.90808C77.0157 2.63299 76.8455 2.45949 76.4622 2.45949H75.5117L75.2419 3.44342H76.2353Z"
                    fill={fill || 'white'}
                />
                <path
                    d="M79.6616 1.33838H82.7976V2.45949H80.5271L80.3285 3.19725H82.287V4.31836H80.0238L79.7612 5.28079H81.7198V6.4019H78.2783L79.6616 1.33838Z"
                    fill={fill || 'white'}
                />
                <path
                    d="M2.18185 10.0796H5.31785V11.2007H3.04731L2.84876 11.9385H4.80731V13.0596H2.54403L2.28149 14.022H4.24003V15.1431H0.797852L2.18185 10.0796Z"
                    fill={fill || 'white'}
                />
                <path
                    d="M6.61569 10.0796H7.90006L8.72333 13.3636L9.62442 10.0796H10.7953L9.41206 15.1431H8.13497L7.31169 11.8591L6.40333 15.1431H5.23242L6.61642 10.0796H6.61569Z"
                    fill={fill || 'white'}
                />
                <path
                    d="M12.8953 13.1827C12.8953 11.6204 13.9309 9.95654 15.5985 9.95654C16.5847 9.95654 17.6633 10.5134 17.5498 11.8592H16.3294C16.3505 11.2586 15.9462 11.0776 15.5774 11.0776C14.6974 11.0776 14.1367 12.1698 14.1367 13.1323C14.1367 13.9205 14.5127 14.1451 14.9454 14.1451C15.3993 14.1451 15.7403 13.8849 15.9105 13.3784H17.1236C16.6909 14.659 15.9738 15.2662 14.9171 15.2662C13.8603 15.2662 12.8945 14.6946 12.8945 13.1827H12.8953Z"
                    fill={fill || 'white'}
                />
                <path
                    d="M17.9258 13.1686C17.9258 11.5989 19.0327 9.95654 20.6218 9.95654C21.6509 9.95654 22.6371 10.5571 22.6371 12.0542C22.6371 13.5512 21.5301 15.2662 19.9411 15.2662C18.912 15.2662 17.9258 14.6656 17.9258 13.1686ZM19.9694 14.1666C20.7571 14.1666 21.4167 12.9365 21.4167 12.0831C21.4167 11.3453 21.0334 11.0561 20.5934 11.0561C19.7985 11.0561 19.1461 12.2863 19.1461 13.1397C19.1461 13.8775 19.5294 14.1666 19.9694 14.1666Z"
                    fill={fill || 'white'}
                />
                <path
                    d="M24.2124 10.0796H25.4967L26.32 13.3636L27.2211 10.0796H28.392L27.0087 15.1431H25.7316L24.9084 11.8591L24 15.1431H22.8291L24.2131 10.0796H24.2124Z"
                    fill={fill || 'white'}
                />
                <path
                    d="M28.5974 13.1827C28.5974 11.6204 29.633 9.95654 31.3007 9.95654C32.2869 9.95654 33.3654 10.5134 33.252 11.8592H32.0316C32.0527 11.2586 31.6483 11.0776 31.2796 11.0776C30.3996 11.0776 29.8389 12.1698 29.8389 13.1323C29.8389 13.9205 30.2149 14.1451 30.6476 14.1451C31.1014 14.1451 31.4425 13.8849 31.6127 13.3784H32.8258C32.393 14.659 31.676 15.2662 30.6192 15.2662C29.5625 15.2662 28.5967 14.6946 28.5967 13.1827H28.5974Z"
                    fill={fill || 'white'}
                />
                <path
                    d="M34.6216 10.0796H37.7576V11.2007H35.487L35.2885 11.9385H37.247V13.0596H34.9837L34.7212 14.022H36.6797V15.1431H33.2383L34.6223 10.0796H34.6216Z"
                    fill={fill || 'white'}
                />
                <path
                    d="M37.8851 13.291H39.0698C38.9134 13.8693 39.304 14.2022 39.8291 14.2022C40.2763 14.2022 40.5243 13.9487 40.5243 13.6306C40.5243 13.3703 40.3753 13.2035 40.0487 13.0952L39.6582 12.9647C39.1403 12.849 38.5796 12.4368 38.5796 11.5685C38.5796 10.7003 39.2676 9.95508 40.5025 9.95508C41.7374 9.95508 42.4822 10.7143 42.2909 11.7494H41.1062C41.2407 11.2504 40.8793 10.9968 40.4531 10.9968C40.0269 10.9968 39.7862 11.2067 39.7862 11.5033C39.7862 11.7999 39.9993 11.9304 40.3396 12.0534L40.7513 12.227C41.2902 12.3715 41.7374 12.7697 41.7374 13.5216C41.7374 14.4692 41.0494 15.2648 39.7789 15.2648C38.3171 15.2648 37.6785 14.2734 37.8843 13.2902L37.8851 13.291Z"
                    fill={fill || 'white'}
                />
                <path
                    d="M42.5745 13.291H43.7593C43.6029 13.8693 43.9934 14.2022 44.5185 14.2022C44.9658 14.2022 45.2138 13.9487 45.2138 13.6306C45.2138 13.3703 45.0647 13.2035 44.7382 13.0952L44.3476 12.9647C43.8298 12.849 43.2691 12.4368 43.2691 11.5685C43.2691 10.7003 43.9571 9.95508 45.192 9.95508C46.4269 9.95508 47.1716 10.7143 46.9803 11.7494H45.7956C45.9302 11.2504 45.5687 10.9968 45.1425 10.9968C44.7163 10.9968 44.4756 11.2067 44.4756 11.5033C44.4756 11.7999 44.6887 11.9304 45.0291 12.0534L45.4407 12.227C45.9796 12.3715 46.4269 12.7697 46.4269 13.5216C46.4269 14.4692 45.7389 15.2648 44.4683 15.2648C43.0065 15.2648 42.368 14.2734 42.5738 13.2902L42.5745 13.291Z"
                    fill={fill || 'white'}
                />
                <path
                    d="M48.4279 10.0796H49.5989L48.2149 15.1431H47.0439L48.4279 10.0796Z"
                    fill={fill || 'white'}
                />
                <path
                    d="M49.7979 13.1686C49.7979 11.5989 50.9048 9.95654 52.4939 9.95654C53.5229 9.95654 54.5091 10.5571 54.5091 12.0542C54.5091 13.5512 53.4022 15.2662 51.8131 15.2662C50.784 15.2662 49.7979 14.6656 49.7979 13.1686ZM51.8415 14.1666C52.6291 14.1666 53.2888 12.9365 53.2888 12.0831C53.2888 11.3453 52.9055 11.0561 52.4655 11.0561C51.6706 11.0561 51.0182 12.2863 51.0182 13.1397C51.0182 13.8775 51.4015 14.1666 51.8415 14.1666Z"
                    fill={fill || 'white'}
                />
                <path
                    d="M56.0842 10.0796H57.3686L58.1918 13.3636L59.0929 10.0796H60.2638L58.8798 15.1431H57.6027L56.7795 11.8591L55.8711 15.1431H54.7002L56.0835 10.0796H56.0842Z"
                    fill={fill || 'white'}
                />
                <path
                    d="M61.4699 10.0796H62.7543L63.5776 13.3636L64.4787 10.0796H65.6496L64.2656 15.1431H62.9885L62.1652 11.8591L61.2568 15.1431H60.0859L61.4692 10.0796H61.4699Z"
                    fill={fill || 'white'}
                />
                <path
                    d="M68.1604 10.0796H69.7924L70.1546 15.1431H68.9481L68.8913 13.9278H67.0964L66.3939 15.1431H65.0244L68.1604 10.0796ZM68.8346 12.8431L68.7495 11.0924L67.7277 12.8431H68.8346Z"
                    fill={fill || 'white'}
                />
                <path
                    d="M72.2688 10.0796H73.4397L72.0557 15.1431H70.8848L72.268 10.0796H72.2688Z"
                    fill={fill || 'white'}
                />
                <path
                    d="M74.6462 10.0796H76.6048C77.4208 10.0796 78.2229 10.5067 78.2229 11.5336C78.2229 12.373 77.7262 13.0744 76.7044 13.2553L77.4426 15.1431H76.1728L75.4564 13.2694H74.9451L74.4346 15.1431H73.2637L74.6469 10.0796H74.6462ZM76.2353 12.1846C76.7888 12.1846 77.0157 11.9244 77.0157 11.6493C77.0157 11.3742 76.8455 11.2007 76.4622 11.2007H75.5117L75.2419 12.1846H76.2353Z"
                    fill={fill || 'white'}
                />
                <path
                    d="M79.6616 10.0796H82.7976V11.2007H80.5271L80.3285 11.9385H82.287V13.0596H80.0238L79.7612 14.022H81.7198V15.1431H78.2783L79.6616 10.0796Z"
                    fill={fill || 'white'}
                />
                <path
                    d="M12.3989 26.5454C12.3989 25.336 13.2047 23.9072 14.5953 23.9072C15.4011 23.9072 16.1727 24.3721 16.1022 25.3664H15.4949C15.524 24.7169 15.0455 24.4611 14.5836 24.4611C13.62 24.4611 13.0178 25.5629 13.0178 26.5217C13.0178 27.3499 13.4498 27.6887 13.9756 27.6887C14.4666 27.6887 14.8927 27.3551 15.0855 26.8012H15.7044C15.3771 27.7243 14.7815 28.2426 13.9633 28.2426C13.1044 28.2426 12.3975 27.7303 12.3975 26.5454H12.3989Z"
                    fill={fill || 'white'}
                />
                <path
                    d="M16.3008 26.9806C16.3008 26.1049 16.9313 25.2114 17.919 25.2114C18.591 25.2114 19.2041 25.6163 19.2041 26.4742C19.2041 27.332 18.5735 28.2433 17.5917 28.2433C16.9139 28.2433 16.3008 27.8325 16.3008 26.9806ZM17.6157 27.7183C18.223 27.7183 18.6208 27.0696 18.6208 26.5038C18.6208 25.9915 18.2993 25.7356 17.8964 25.7356C17.2891 25.7356 16.8855 26.3844 16.8855 26.9509C16.8855 27.4633 17.2128 27.7191 17.6157 27.7191V27.7183Z"
                    fill={fill || 'white'}
                />
                <path
                    d="M20.2507 25.8671V25.2954H20.7532V25.6528C20.9634 25.3666 21.2907 25.2124 21.6878 25.2124C22.3125 25.2124 22.8092 25.6706 22.5699 26.5167L22.1321 28.1605H21.5714L22.0274 26.487C22.1561 26.0043 21.9161 25.7366 21.5248 25.7366C21.0688 25.7366 20.7598 26.0584 20.6368 26.5048L20.1867 28.1605H19.6201L20.2507 25.8679V25.8671Z"
                    fill={fill || 'white'}
                />
                <path
                    d="M23.2471 26.9925C23.2471 26.1227 23.8602 25.2114 24.8245 25.2114C25.2863 25.2114 25.6187 25.4079 25.7707 25.7535L26.2616 23.9902H26.8282L25.8471 27.5871V28.1588H25.3445V27.7895C25.1169 28.0876 24.8013 28.2418 24.4216 28.2418C23.7969 28.2418 23.2471 27.8133 23.2471 26.991V26.9925ZM24.5445 27.7184C25.1518 27.7184 25.5431 27.0392 25.5431 26.5038C25.5431 25.9974 25.2333 25.7357 24.8303 25.7357C24.2231 25.7357 23.8311 26.4149 23.8311 26.9509C23.8311 27.4574 24.1525 27.7191 24.5438 27.7191L24.5445 27.7184Z"
                    fill={fill || 'white'}
                />
                <path
                    d="M26.9568 26.9383L27.3946 25.2944H27.9553L27.5001 26.9679C27.3771 27.4447 27.6055 27.7183 27.9851 27.7183C28.4411 27.7183 28.7386 27.3965 28.8615 26.9501L29.311 25.2944H29.8775L29.247 27.5871V28.1587H28.7444V27.8191C28.5284 28.0928 28.207 28.2418 27.8215 28.2418C27.2026 28.2418 26.7175 27.7776 26.9568 26.9375V26.9383Z"
                    fill={fill || 'white'}
                />
                <path
                    d="M30.888 25.2947H31.4546L30.672 28.159H30.1055L30.888 25.2947ZM31.0633 24.0327H31.688V24.7178H31.0633V24.0327Z"
                    fill={fill || 'white'}
                />
                <path
                    d="M32.2556 27.4091L32.6934 25.8008H32.0156V25.2944H32.828L33.0614 24.4365H33.6222L33.3887 25.2944H34.2004V25.8008H33.2484L32.828 27.355C32.7698 27.5633 32.828 27.653 33.044 27.653H33.5524V28.1595H32.8164C32.3371 28.1595 32.1153 27.9096 32.2556 27.4091Z"
                    fill={fill || 'white'}
                />
                <path
                    d="M36.3917 25.8671V25.2954H36.8942V25.6647C37.1277 25.3666 37.4608 25.2124 37.8521 25.2124C38.4593 25.2124 38.991 25.641 38.991 26.4573C38.991 27.3271 38.3779 28.2443 37.4135 28.2443C36.9517 28.2443 36.6193 28.0478 36.4673 27.7082L36.0353 29.2327H35.4746L36.3917 25.8679V25.8671ZM37.4084 27.7186C38.0157 27.7186 38.4077 27.0394 38.4077 26.504C38.4077 25.9976 38.0862 25.7359 37.695 25.7359C37.0877 25.7359 36.6964 26.4151 36.6964 26.9512C36.6964 27.4576 37.0062 27.7193 37.4092 27.7193L37.4084 27.7186Z"
                    fill={fill || 'white'}
                />
                <path
                    d="M39.4177 27.4388C39.4177 26.9442 39.7974 26.486 40.5566 26.4445L41.5617 26.3852L41.5908 26.2599C41.6904 25.8906 41.5152 25.6882 41.1115 25.6882C40.7726 25.6882 40.5566 25.8194 40.4454 26.093H39.8788C40.0657 25.5332 40.5035 25.2114 41.1872 25.2114C41.8708 25.2114 42.2912 25.6459 42.0984 26.3311L41.7712 27.5878C41.7595 27.6472 41.777 27.6835 41.841 27.6835H42.0454V28.1603H41.5603C41.3734 28.1603 41.2446 28.035 41.3152 27.8088L41.321 27.7851C41.0992 28.0654 40.737 28.2433 40.3283 28.2433C39.7966 28.2433 39.417 27.9571 39.417 27.4396L39.4177 27.4388ZM40.4337 27.7665C40.9014 27.7665 41.2926 27.421 41.4264 26.9265L41.4497 26.8308L40.585 26.8961C40.1646 26.9198 39.9777 27.1519 39.9777 27.3906C39.9777 27.6294 40.153 27.7658 40.4337 27.7658V27.7665Z"
                    fill={fill || 'white'}
                />
                <path
                    d="M43.2963 25.8671V25.2954H43.7989V25.7418C43.9043 25.4437 44.1378 25.2954 44.4883 25.2954H45.0432V25.8018H44.3829C43.9741 25.8018 43.8279 25.9865 43.6876 26.4506L43.2316 28.1597H42.665L43.2956 25.8671H43.2963Z"
                    fill={fill || 'white'}
                />
                <path
                    d="M46.7079 27.594L47.6889 23.9912H48.2497L47.3035 27.4576C47.2686 27.5948 47.3035 27.6541 47.4439 27.6541H47.7537V28.1605H47.1522C46.7668 28.1605 46.6148 27.9522 46.7086 27.5948L46.7079 27.594Z"
                    fill={fill || 'white'}
                />
                <path
                    d="M48.3325 27.4388C48.3325 26.9442 48.7121 26.486 49.4714 26.4445L50.4765 26.3852L50.5056 26.2599C50.6052 25.8906 50.43 25.6882 50.0263 25.6882C49.6874 25.6882 49.4714 25.8194 49.3601 26.093H48.7936C48.9805 25.5332 49.4183 25.2114 50.102 25.2114C50.7856 25.2114 51.206 25.6459 51.0132 26.3311L50.686 27.5878C50.6743 27.6472 50.6918 27.6835 50.7558 27.6835H50.9601V28.1603H50.4751C50.2881 28.1603 50.1594 28.035 50.23 27.8088L50.2358 27.7851C50.014 28.0654 49.6518 28.2433 49.2423 28.2433C48.7107 28.2433 48.3311 27.9571 48.3311 27.4396L48.3325 27.4388ZM49.3485 27.7665C49.8161 27.7665 50.2074 27.421 50.3412 26.9265L50.3645 26.8308L49.4998 26.8961C49.0794 26.9198 48.8925 27.1519 48.8925 27.3906C48.8925 27.6294 49.0678 27.7658 49.3485 27.7658V27.7665Z"
                    fill={fill || 'white'}
                />
                <path
                    d="M57.6695 26.6025H57.6709C58.0208 26.6025 58.3204 26.3556 58.4011 26.0086C58.7902 24.3484 60.0738 23.039 61.7015 22.6423C62.0411 22.5592 62.284 22.2537 62.284 21.8978C62.284 21.4025 61.8295 21.0377 61.3575 21.1519C59.1764 21.6806 57.4571 23.4342 56.9378 25.6578C56.8251 26.1391 57.1837 26.6025 57.6695 26.6025Z"
                    fill={fill || 'white'}
                />
                <path
                    d="M67.6303 30.5321C68.3037 29.5481 68.7001 28.3529 68.7001 27.0642C68.7001 24.2058 66.7539 21.8027 64.1423 21.1576C63.7379 21.0575 63.3081 21.3022 63.2121 21.7152C63.1132 22.1416 63.3721 22.5457 63.767 22.6406C65.7306 23.1159 67.1961 24.9191 67.1961 27.0642C67.1961 27.7493 67.0455 28.3981 66.7779 28.9809C66.7263 29.0929 66.5772 29.1099 66.5037 29.0113L63.5852 25.0719C63.159 24.4965 62.3103 24.4965 61.8841 25.0719L58.9699 29.0091C58.8972 29.1077 58.7474 29.0906 58.6964 28.9794C58.575 28.7147 58.4768 28.4374 58.407 28.1475C58.3248 27.8064 58.0412 27.5395 57.6972 27.5276C57.1983 27.5106 56.8244 27.9814 56.9386 28.4715C57.4572 30.6944 59.1757 32.4473 61.3554 32.9767C61.8288 33.0916 62.2841 32.7276 62.2841 32.2315V32.23C62.2841 31.8756 62.0455 31.5686 61.7074 31.4871C61.0477 31.3277 60.4448 31.017 59.9343 30.5951C59.8463 30.5224 59.8339 30.3897 59.9023 30.297L62.4848 26.8299C62.6121 26.6594 62.8637 26.6594 62.9903 26.8299L65.5692 30.3007C65.6375 30.3927 65.6252 30.5261 65.5372 30.5988C65.0274 31.0192 64.4259 31.3284 63.7677 31.4878C63.4295 31.5694 63.191 31.8771 63.191 32.2315C63.191 32.7268 63.6463 33.0916 64.119 32.9774C65.0492 32.7513 65.895 32.302 66.6004 31.6895L67.3437 32.6898C67.4914 32.8929 67.7183 33.0004 67.9488 33.0004C68.1044 33.0004 68.2608 32.9515 68.3954 32.8499C68.7292 32.5978 68.799 32.1181 68.5524 31.7777L67.6303 30.5328V30.5321Z"
                    fill={fill || 'white'}
                />
                <path
                    d="M71.7178 26.665V26.6576C71.7178 25.9265 72.2581 25.3281 73.0327 25.3281C73.4109 25.3281 73.6669 25.4312 73.8756 25.5891C73.933 25.6329 73.984 25.7137 73.984 25.813C73.984 25.9673 73.8618 26.0889 73.7105 26.0889C73.6349 26.0889 73.5847 26.0592 73.5447 26.034C73.3898 25.9161 73.2276 25.8501 73.0298 25.8501C72.6043 25.8501 72.2981 26.2097 72.2981 26.6509V26.6583C72.2981 27.0988 72.597 27.4665 73.0298 27.4665C73.264 27.4665 73.4189 27.3931 73.5774 27.2649C73.621 27.2278 73.6785 27.2026 73.7432 27.2026C73.8836 27.2026 74.0029 27.3205 74.0029 27.4636C74.0029 27.5518 73.96 27.6215 73.909 27.666C73.6821 27.8677 73.4152 27.9893 73.0123 27.9893C72.2705 27.9893 71.7192 27.405 71.7192 26.6672L71.7178 26.665Z"
                    fill={fill || 'white'}
                />
                <path
                    d="M74.4277 26.665V26.6576C74.4277 25.9265 74.9681 25.3281 75.7426 25.3281C76.1208 25.3281 76.3768 25.4312 76.5855 25.5891C76.643 25.6329 76.6939 25.7137 76.6939 25.813C76.6939 25.9673 76.5717 26.0889 76.4205 26.0889C76.3448 26.0889 76.2946 26.0592 76.2546 26.034C76.0997 25.9161 75.9375 25.8501 75.7397 25.8501C75.3143 25.8501 75.0081 26.2097 75.0081 26.6509V26.6583C75.0081 27.0988 75.307 27.4665 75.7397 27.4665C75.9739 27.4665 76.1288 27.3931 76.2874 27.2649C76.3303 27.2278 76.3885 27.2026 76.4532 27.2026C76.5936 27.2026 76.7128 27.3205 76.7128 27.4636C76.7128 27.5518 76.6699 27.6215 76.619 27.666C76.3921 27.8677 76.1252 27.9893 75.7215 27.9893C74.9797 27.9893 74.4285 27.405 74.4285 26.6672L74.4277 26.665Z"
                    fill={fill || 'white'}
                />
                <path
                    d="M77.1804 27.5792L78.0597 25.555C78.1208 25.4156 78.2328 25.3311 78.3841 25.3311H78.4161C78.5673 25.3311 78.6757 25.4156 78.7368 25.555L79.6161 27.5792C79.6343 27.62 79.6452 27.6563 79.6452 27.6926C79.6452 27.8432 79.5295 27.9648 79.3819 27.9648C79.2524 27.9648 79.1659 27.8877 79.1157 27.7705L78.9463 27.3664H77.8364L77.6597 27.789C77.6132 27.8995 77.5193 27.9655 77.4037 27.9655C77.2597 27.9655 77.1484 27.8476 77.1484 27.7008C77.1484 27.6608 77.163 27.62 77.1804 27.5799V27.5792ZM78.7404 26.8666L78.3906 26.0184L78.0408 26.8666H78.7397H78.7404Z"
                    fill={fill || 'white'}
                />
                <path
                    d="M80.002 26.665V26.6576C80.002 25.9265 80.5678 25.3281 81.346 25.3281C82.1241 25.3281 82.6827 25.9198 82.6827 26.6502V26.6576C82.6827 26.9297 82.5998 27.1826 82.4594 27.3961L82.5678 27.488C82.6252 27.5392 82.6689 27.6096 82.6689 27.7008C82.6689 27.8477 82.5532 27.9693 82.4092 27.9693C82.3336 27.9693 82.2689 27.9359 82.2252 27.8921L82.0885 27.7676C81.8761 27.907 81.6201 27.9878 81.3394 27.9878C80.5612 27.9878 80.0027 27.3961 80.0027 26.6657L80.002 26.665ZM81.3132 26.9549C81.3132 26.8081 81.4281 26.6865 81.5729 26.6865C81.6485 26.6865 81.6987 26.7125 81.7561 26.7636L82.0339 27.0172C82.0769 26.9141 82.1023 26.7933 82.1023 26.665V26.6576C82.1023 26.2172 81.7852 25.8494 81.3387 25.8494C80.8921 25.8494 80.5823 26.209 80.5823 26.6502V26.6576C80.5823 27.098 80.8994 27.4658 81.346 27.4658C81.4681 27.4658 81.5801 27.4435 81.6776 27.3961L81.4143 27.1685C81.3569 27.1173 81.3132 27.0469 81.3132 26.9557V26.9549Z"
                    fill={fill || 'white'}
                />
            </g>
            <defs>
                <clipPath id="clip0_3721_364">
                    <rect
                        width="82"
                        height="33"
                        fill={fill || 'white'}
                        transform="translate(0.797852)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default FooterLogo;
