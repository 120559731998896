import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const MapArrowIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
                <path d="M21 3L3 10.53V11.51L9.84 14.16L12.48 21H13.46L21 3Z" fill={fill} />
            </svg>
        </span>
    );
};

export default MapArrowIcon;
