import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
// import { createBrowserHistory } from 'history';

// const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: window.Environment.REACT_APP_APP_INSIGHT_INSTRUMENTATION_KEY,
        extensions: [reactPlugin],
        // extensionConfig: {
        //     [reactPlugin.identifier]: { history: browserHistory },
        // },
    },
});

appInsights.loadAppInsights();

appInsights.addTelemetryInitializer((t) => {
    // Only send specific telemetry types for now. Enable others as needed.

    // Our custom events
    if (t.baseType == 'EventData') return true;

    // Unhandled exceptions
    if (t.baseType == 'ExceptionData') return true;

    // Remote dependency failures (ex: unsuccessful API calls).
    if (
        t.baseType == 'RemoteDependencyData' &&
        t.baseData?.responseCode &&
        t.baseData?.responseCode >= 400
    )
        return true;

    return false; // disable everything else
});

export { reactPlugin, appInsights };
