import { MultiSelectCustomOption } from 'Components/select-custom/multi-select/multi-select-common';
import { MetiersCategory, RawJob } from 'Models/MetierDefinitions';
import { JobCategory } from 'Models/QuizDefinitions';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import metiersData from 'Models/MetierDefinitions';

const METIERS_CATEGORIES: MetiersCategory[] = [
    MetiersCategory.all,
    MetiersCategory.serviceAdministratifs,
    MetiersCategory.gestion,
    MetiersCategory.servicesDesVentes,
    MetiersCategory.atelierMecanique,
    MetiersCategory.atelierCarrosserie,
    MetiersCategory.serviceDesPieces,
    MetiersCategory.autres,
];

interface UseMetiersRenderingParams {
    initialCategories?: string[];
    preferredJobCategory?: JobCategory | null;
}

const useMetiersRendering = (params?: UseMetiersRenderingParams) => {
    const { preferredJobCategory, initialCategories } = params || {};
    const [selectedMetiers, setSelectedMetiers] = useState<RawJob[]>([]);
    const { t } = useTranslation();

    const sortCategories = useCallback(
        (a: string, b: string): number => {
            if (a === t(MetiersCategory['forYou'])) {
                return -1;
            }
            if (b === t(MetiersCategory['forYou'])) {
                return 1;
            }

            if (a === t(MetiersCategory.all)) {
                return -1;
            }

            if (b === t(MetiersCategory.all)) {
                return 1;
            }

            if (a === t(MetiersCategory.autres)) {
                return 1;
            }
            if (b === t(MetiersCategory.autres)) {
                return -1;
            }
            return a.localeCompare(b);
        },
        [t]
    );

    const preferredCategory = useMemo(() => {
        switch (preferredJobCategory) {
            case JobCategory.Admin:
                return MetiersCategory.serviceAdministratifs;
            case JobCategory.Management:
                return MetiersCategory.gestion;
            case JobCategory.Sales:
                return MetiersCategory.servicesDesVentes;
            case JobCategory.Mechanical:
                return MetiersCategory.atelierMecanique;
            case JobCategory.BodyShop:
                return MetiersCategory.atelierCarrosserie;
            default:
                return null;
        }
    }, [preferredJobCategory]);

    const [selectedCategories, setSelectedCategories] = useState<string[]>(initialCategories ?? []);

    const categories = useMemo(() => {
        const metiers_categories = METIERS_CATEGORIES.map((x) => ({
            label: t(x),
            value: t(x),
        })).sort((a, b) => sortCategories(t(a.label), t(b.label)));

        if (preferredJobCategory) {
            return metiers_categories;
        }

        return metiers_categories.filter((category) => category.value !== t(MetiersCategory.all));
    }, [preferredJobCategory, sortCategories, t]);

    const getAllMetiers = useMemo((): RawJob[] => {
        return metiersData
            .flatMap((x) =>
                x.jobs.map((y) => ({
                    category: t(y.category),
                    title: t(y.title),
                    description: {
                        p1: t(y.description.p1),
                        p2: t(y.description.p2),
                        h2: t(y.description.h2),
                        h3: y.description.h3.map((z) => t(z)),
                    },
                    to: y.to,
                    image: y.image,
                    testimonyId: y.testimonyId,
                }))
            )
            .sort((a, b) => t(a.title).localeCompare(t(b.title)))
            .sort((a, b) => sortCategories(a.category, b.category));
    }, [sortCategories, t]);

    useEffect(() => {
        if (!preferredCategory && selectedCategories.length === 0) {
            return setSelectedMetiers(getAllMetiers);
        }
        if (selectedCategories.length === 0 && preferredCategory) {
            return setSelectedMetiers(
                getAllMetiers.filter((x) => t(x.category) === t(preferredCategory))
            );
        } else {
            return setSelectedMetiers(
                getAllMetiers
                    .filter(
                        (x) =>
                            selectedCategories.some((x) => x === t(MetiersCategory.all)) ||
                            selectedCategories.some((category) => t(category) === x.category)
                    )
                    .sort((a, b) => sortCategories(t(a.category), t(b.category)))
            );
        }
    }, [preferredCategory, selectedCategories, getAllMetiers, t, sortCategories]);

    const handleFilterChange = useCallback((data: MultiSelectCustomOption[]) => {
        setSelectedCategories((prevCategories) => {
            const allCategories = METIERS_CATEGORIES;
            const categoriesSelected = data.map((category) => category.value);
            const allCategoryIsChecked = categoriesSelected.includes(MetiersCategory.all);

            const allCategoryHasBeenChecked =
                categoriesSelected.includes(MetiersCategory.all) &&
                !prevCategories.includes(MetiersCategory.all);
            const allCategoryHasBeenUnChecked =
                !categoriesSelected.includes(MetiersCategory.all) &&
                prevCategories.includes(MetiersCategory.all);

            if (allCategoryHasBeenChecked) return allCategories;

            if (allCategoryHasBeenUnChecked) return [];

            if (allCategoryIsChecked)
                return categoriesSelected.filter((x) => x !== MetiersCategory.all);

            return categoriesSelected;
        });
    }, []);

    return {
        selectedMetiers,
        handleFilterChange,
        categories,
        selectedCategories,
        setSelectedCategories,
        allMetiers: getAllMetiers,
    };
};

export default useMetiersRendering;
