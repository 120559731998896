import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const GooglemapPhoneIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
                <path
                    d="M17.5424 19.3886C15.7103 19.3886 13.9003 18.9892 12.1122 18.1905C10.3242 17.3917 8.69732 16.2595 7.2317 14.7939C5.76607 13.3283 4.63388 11.7014 3.83511 9.91336C3.03635 8.1253 2.63696 6.31525 2.63696 4.48322C2.63696 4.21941 2.7249 3.99956 2.90078 3.82369C3.07665 3.64781 3.29649 3.55988 3.56031 3.55988H7.12178C7.32696 3.55988 7.51017 3.62949 7.67138 3.76873C7.8326 3.90796 7.92787 4.07284 7.95718 4.26338L8.52878 7.34119C8.55809 7.57569 8.55076 7.77355 8.50679 7.93477C8.46282 8.09598 8.38221 8.23522 8.26496 8.35247L6.13248 10.5069C6.4256 11.0492 6.77369 11.5732 7.17674 12.0788C7.57978 12.5845 8.02313 13.0718 8.50679 13.5408C8.96113 13.9951 9.43746 14.4165 9.93578 14.8049C10.4341 15.1933 10.9617 15.5487 11.5187 15.8711L13.5852 13.8046C13.7171 13.6727 13.8893 13.5738 14.1018 13.5078C14.3143 13.4419 14.5232 13.4235 14.7284 13.4528L17.7622 14.0684C17.9674 14.127 18.1359 14.2333 18.2679 14.3872C18.3998 14.5411 18.4657 14.7133 18.4657 14.9038V18.4653C18.4657 18.7291 18.3778 18.9489 18.2019 19.1248C18.026 19.3007 17.8062 19.3886 17.5424 19.3886Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default GooglemapPhoneIcon;
