import './index.less';
import MetiersHeader from './metiers-header';
import MetiersList from './metiers-list';

const Metiers = () => {
    return (
        <div className="MetiersPage">
            <MetiersHeader />
            <MetiersList />
        </div>
    );
};

export default Metiers;
