//to enable color referencing in components

export const theme = {
    'surface-brand-rest-primary': '#2139b0',
    'surface-brand-rest-secondary': '#dbe0f8',
    'surface-brand-rest-tertiary': '#14236b',

    'surface-brand-overlay-primary': '#9caaec',
    'surface-brand-overlay-secondary': '#dbe0f8',
    'surface-brand-overlay-tertiary': '#101c56',

    'surface-brand-accent-rest-primary': '#e03529',
    'surface-brand-accent-secondary': '#fdf2f2',
    'surface-brand-accent-tertiary': '#6f1610',

    'surface-brand-accent-overlay-primary': '#f3b0ab',
    'surface-brand-accent-overlay-secondary': '#fbe3e1',
    'surface-brand-accent-overlay-tertiary': '#59120d',

    'surface-grey-opposite': '#1a1a1a',
    'surface-grey-primary': '#b3b3b3',
    'surface-grey-secondary': '#d6d6d6',
    'surface-grey-tertiary': '#ededed',
    'surface-grey-disabled': '#f7f7f7',

    'surface-success-rest-primary': '#37be6e',
    'surface-success-rest-secondary': '#afe5c5',
    'surface-success-overlay-primary': '#5fcb8b',
    'surface-success-overlay-secondary': '#d7f2e2',

    'surface-warning-rest-primary': '#f2da52',
    'surface-warning-rest-secondary': '#faf0ba',
    'surface-warning-overlay-primary': '#f5e175',
    'surface-warning-overlay-secondary': '#fcf8dc',

    'surface-error-rest-primary': '#d7382d',
    'surface-error-rest-secondary': '#efafab',
    'surface-error-overlay-primary': '#df6057',
    'surface-error-overlay-secondary': '#f7d7d5',

    //font colors
    'element-greys-primary': '#2b2b2b',
    'element-greys-secondary': '#8a8a8a',
    'element-greys-tertiary': '#b3b3b3',

    'element-onbrand-primary': '#fbfcfe',
    'element-onbrand-secondary': '#192b85',

    'element-onbrandaccent-primary': '#fdf2f2',
    'element-onbrandaccent-secondary': '#f3b0ab',

    'element-onbrandaccent-overlay-primary': '#e5574d',

    'element-onsuccess-primary': '#ecf9f1',
    'element-onsuccess-secondary': '#1c5f37',

    'element-onwarning-primary': '#796d29',
    'element-onwarning-secondary': '#796d29',

    'element-onerror-primary': '#fceceb',
    'element-onerror-secondary': '#d7382d',

    white: '#ffffff',
    black: '#000000',
};
