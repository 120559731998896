import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const IndustrieIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <path
                    d="M12.2 22.415V17.285L13.46 13.685C13.535 13.52 13.6437 13.3812 13.7862 13.2688C13.9288 13.1562 14.105 13.1 14.315 13.1H20.885C21.095 13.1 21.2712 13.1562 21.4137 13.2688C21.5563 13.3812 21.665 13.52 21.74 13.685L23 17.285V22.415C23 22.58 22.9438 22.7188 22.8312 22.8312C22.7188 22.9438 22.58 23 22.415 23H21.785C21.62 23 21.4812 22.9438 21.3687 22.8312C21.2563 22.7188 21.2 22.58 21.2 22.415V21.65H14V22.415C14 22.58 13.9437 22.7188 13.8313 22.8312C13.7188 22.9438 13.58 23 13.415 23H12.785C12.62 23 12.4812 22.9438 12.3687 22.8312C12.2562 22.7188 12.2 22.58 12.2 22.415ZM14 16.25H21.2L20.57 14.45H14.63L14 16.25ZM14.9 19.85C15.155 19.85 15.3688 19.7637 15.5413 19.5912C15.7137 19.4187 15.8 19.205 15.8 18.95C15.8 18.695 15.7137 18.4812 15.5413 18.3088C15.3688 18.1362 15.155 18.05 14.9 18.05C14.645 18.05 14.4312 18.1362 14.2587 18.3088C14.0863 18.4812 14 18.695 14 18.95C14 19.205 14.0863 19.4187 14.2587 19.5912C14.4312 19.7637 14.645 19.85 14.9 19.85ZM20.3 19.85C20.555 19.85 20.7687 19.7637 20.9412 19.5912C21.1137 19.4187 21.2 19.205 21.2 18.95C21.2 18.695 21.1137 18.4812 20.9412 18.3088C20.7687 18.1362 20.555 18.05 20.3 18.05C20.045 18.05 19.8313 18.1362 19.6587 18.3088C19.4862 18.4812 19.4 18.695 19.4 18.95C19.4 19.205 19.4862 19.4187 19.6587 19.5912C19.8313 19.7637 20.045 19.85 20.3 19.85ZM8.6 15.8V14H10.4V15.8H8.6ZM13.1 10.4V8.6H14.9V10.4H13.1ZM8.6 19.4V17.6H10.4V19.4H8.6ZM8.6 23V21.2H10.4V23H8.6ZM5 23V10.4H9.5V5H18.5V11.3H16.7V6.8H11.3V12.2H6.8V23H5Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default IndustrieIcon;
