import React, { FunctionComponent, useEffect, useState } from 'react';
import './index.less';
import { Form, Input, Spin } from 'antd';
import Button from 'Components/button';
import { useTranslation } from 'react-i18next';
import { useFormValidation, useService } from 'Hooks';
import { LoadingOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { useFetch } from 'Hooks/use-fetch';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { LabelColor } from 'Components/validated-form-item/validated-form-items';
import BlueCard from 'Components/blue-card';
import { EnvelopeIcon, EyeIcon, PadlockIcon } from 'Components/icons';
import { theme } from 'Style/theme';
import { ForgotPasswordSchema, LoginRequestSchema } from 'Schemas/LoginRequestSchema';
import { AuthenticationService } from 'Services/AuthenticationService';
import { CREATE_ACCOUNT_URL, E001001, HOME_PAGE_URL } from 'Models/Constants';
import { useHistory } from 'react-router-dom';
import { CandidateService } from 'Services/CandidateService';

export const Login: FunctionComponent = observer(() => {
    const history = useHistory();
    const { t } = useTranslation();
    const [revealPassword, setRevealPassword] = useState(false);
    const [form] = Form.useForm();
    const [forgotPassword, setForgotPassword] = useState(false);
    const [forgotPasswordSent, setForgotPasswordSent] = useState(false);
    const authenticationService = useService(AuthenticationService);
    const candidateService = useService(CandidateService);
    const [errors, validateForm] = useFormValidation(LoginRequestSchema, form);
    const [errorsForgot, validateForgotForm] = useFormValidation(ForgotPasswordSchema, form);
    const { apiRequest, loadingStateKeys } = useFetch();

    const [errorString, setErrorString] = useState<string | undefined>(undefined);

    const handleError = (error) => {
        if (error.response?.data?.error_code == E001001) {
            setErrorString(t('Errors.E001001'));
        }
    };

    const submit = async (): Promise<void> => {
        const formValues = form.getFieldsValue();
        if (!(await validateForm(formValues))) return;

        const userId = await apiRequest({
            requestFunction: (request) =>
                authenticationService.initAccessToken(request.userName, request.password),
            requestParameters: { userName: formValues.username, password: formValues.password },
            customErrorHandler: handleError,
            loadingStateKey: 'loading',
        });

        if (userId !== undefined) history.push(HOME_PAGE_URL);
    };

    const submitForgotPassword = async (): Promise<void> => {
        const formValues = form.getFieldsValue();

        if (!(await validateForgotForm(formValues))) return;

        await apiRequest({
            requestFunction: (request) => candidateService.candidateForgotPassword(request),
            requestParameters: { email: formValues.email },
        });

        setForgotPasswordSent(true);
    };

    useEffect(() => {
        if (errors.size > 0) {
            const errorStrings: string[] = [];
            errors.forEach((values) => errorStrings.push(values.join()));
            setErrorString(errorStrings.join(''));
        }
    }, [errors]);

    return (
        <div className="Login">
            {!forgotPassword ? (
                <BlueCard padding={40} paddingLeft={125} paddingRight={125}>
                    <div className="title custom-font">{t('Login.connexion_au_compte')}</div>

                    <Form form={form} onFinish={submit} className="form" layout="vertical">
                        <ValidatedFormItem
                            label={t('Login.entrer_courriel')}
                            name="username"
                            labelColor={LabelColor.white}
                            errors={errors}
                        >
                            <Input
                                type="email"
                                className="top-input blue"
                                prefix={<EnvelopeIcon width={20} height={20} fill={theme.white} />}
                            />
                        </ValidatedFormItem>

                        <ValidatedFormItem
                            label={t('Login.mot_de_passe')}
                            name="password"
                            errors={errors}
                            labelColor={LabelColor.white}
                        >
                            <Input
                                type={revealPassword ? 'text' : 'password'}
                                className="bottom-input blue"
                                prefix={<PadlockIcon width={20} height={20} fill={theme.white} />}
                                suffix={
                                    <div className="show-psw cursor-pointer">
                                        <EyeIcon
                                            width={20}
                                            height={20}
                                            onClick={() => setRevealPassword((prev) => !prev)}
                                            fill={theme.white}
                                        />
                                    </div>
                                }
                            />
                        </ValidatedFormItem>
                        <div className="bottom-section">
                            <span
                                className="text-label-large-bold text-white forgot-password cursor-pointer"
                                onClick={() => setForgotPassword(true)}
                            >
                                {t('Login.mot_de_passe_oublie')}
                            </span>

                            {errorString && (
                                <span className="text-label-large-bold text-red error-message">
                                    {errorString}
                                </span>
                            )}

                            {loadingStateKeys.has('loading') && (
                                <div className="spin-container">
                                    <Spin
                                        indicator={
                                            <LoadingOutlined
                                                translate={{}}
                                                style={{
                                                    fontSize: 24,
                                                    color: theme[
                                                        'surface-brand-accent-rest-primary'
                                                    ],
                                                }}
                                                spin
                                            />
                                        }
                                        size="large"
                                    />
                                </div>
                            )}

                            <Button
                                text={t('User.me_connecter')}
                                type="red"
                                width="full"
                                onClick={() => submit()}
                            />

                            <span
                                onClick={() => history.push(CREATE_ACCOUNT_URL)}
                                className="text-label-large-bold text-white cursor-pointer"
                            >
                                {t('Login.pas_de_compte')}
                            </span>
                        </div>
                    </Form>
                </BlueCard>
            ) : (
                <>
                    {!forgotPasswordSent ? (
                        <BlueCard padding={40} className="forgot-password">
                            <div className="text-container">
                                <div className="text-title-large-bold text-white">
                                    {t('Login.mot_de_passe_oublie')}
                                </div>
                                <div className="text-body-large text-white paragraph">
                                    {t('Login.entrer_courriel_associee')}
                                </div>
                            </div>

                            <Form form={form} onFinish={submit} className="form" layout="vertical">
                                <ValidatedFormItem
                                    label={t('Login.entrer_courriel')}
                                    name="email"
                                    labelColor={LabelColor.white}
                                    errors={errorsForgot}
                                >
                                    <Input
                                        className="top-input blue"
                                        type="email"
                                        prefix={
                                            <EnvelopeIcon
                                                width={20}
                                                height={20}
                                                fill={theme.white}
                                            />
                                        }
                                    />
                                </ValidatedFormItem>
                            </Form>

                            <Button
                                text={t('envoyer')}
                                type="red"
                                width="full"
                                onClick={() => submitForgotPassword()}
                            />
                        </BlueCard>
                    ) : (
                        <BlueCard padding={40} className="forgot-password-sent">
                            <div className="text-container">
                                <div className="text-title-large-bold text-white">
                                    {t('Login.instruction_reinit_envoye')}
                                </div>
                                <div className="text-body-large text-white paragraph">
                                    {t('Login.verifier_boite_reception')}
                                </div>
                            </div>

                            <Button
                                text={t('User.me_connecter')}
                                type="red"
                                width="full"
                                onClick={() => {
                                    setForgotPasswordSent(false);
                                    setForgotPassword(false);
                                }}
                            />
                        </BlueCard>
                    )}
                </>
            )}
        </div>
    );
});
