import * as yup from 'yup';

export const LoginRequestSchema = yup.object({
    username: yup.string().required('Errors.required'),
    password: yup.string().required('Errors.password_required'),
});

export const ForgotPasswordSchema = yup.object({
    email: yup.string().required('Errors.required').email('Errors.email_error'),
});

export const ResetPasswordSchema = yup.object({
    newPassword: yup.string().required('Errors.required'),
    newPasswordConfirm: yup
        .string()
        .required('Errors.required')
        .oneOf([yup.ref('newPassword')], 'Errors.password_must_match'),
    token: yup.string().required('Errors.required'),
    email: yup.string().required('Errors.required'),
});
