import { useCallback, useState } from 'react';
import { useStores } from './use-stores';
import { useTranslation } from 'react-i18next';

export const useFetch = () => {
    const { t } = useTranslation();
    const { toastStore, globalLoadingStore } = useStores();
    //multiple calls could be loading at same time for same hook since we call apiRequest from outside the hook
    const [loadingStateKeys, setLoadingStateKeys] = useState<Map<string, string>>(new Map());

    const apiRequest = useCallback(
        async (props: ApiRequestProps): Promise<any | [any[], number]> => {
            try {
                if (props.loadingStateKey)
                    setLoadingStateKeys((prev) => new Map(prev).set(props.loadingStateKey!, ''));

                if (props.useGlobalLoading) globalLoadingStore.addLoading();

                const response = await props.requestFunction(props.requestParameters);

                if (props.useDefaultSuccessToast) {
                    toastStore.toast({
                        type: 'success',
                        title: t('ToastStore.default_success_toast'),
                    });
                }

                return response;
            } catch (e: any) {
                if (props.customErrorHandler) {
                    props.customErrorHandler(e);
                    e.treated = true;
                }
                if (!e.treated) {
                    toastStore.genericError();
                }
                if (props.throwOnError) {
                    throw e;
                }
            } finally {
                if (props.loadingStateKey) {
                    setLoadingStateKeys((prev) => {
                        const newState = new Map(prev).set(props.loadingStateKey!, '');
                        newState.delete(props.loadingStateKey!);
                        return newState;
                    });
                }

                if (props.useGlobalLoading) globalLoadingStore.removeLoading();
            }
        },
        [globalLoadingStore, t, toastStore]
    );

    return { apiRequest, loadingStateKeys };
};

export interface ApiRequestProps {
    /**Service function */
    requestFunction: (requestParameters) => Promise<any | [any[], number]>;

    /**RequestDto for the service function and such */
    requestParameters: any;

    /**If loading state of the request needs to be used for loading skeleton, pass in a key to keep track of loading and render accordingly.
     *  Use loadingStates.has(loadingStateKey) in the skeleton; if key is present, the call is loading*/
    loadingStateKey?: string;

    /**Override the default generic toast error */
    customErrorHandler?: (error: any) => void;

    /**Fetching should trigger the global loading spinner */
    useGlobalLoading?: boolean;

    /**Should error be thrown again */
    throwOnError?: boolean;

    /**Post or Patch with the default success toast */
    useDefaultSuccessToast?: boolean;
}
