import { useRef, useEffect } from 'react';
import { autorun } from 'mobx';
import { debounce } from 'lodash';
import { DEBOUNCE_DELAY_400 } from 'Models/Constants';

const useDebouncedFunction = (callBackFunction, triggers, debounceDelay = DEBOUNCE_DELAY_400) => {
    const debouncedFunction = useRef(
        debounce(() => {
            callBackFunction();
        }, debounceDelay)
    );

    useEffect(() => {
        debouncedFunction.current = debounce(() => {
            callBackFunction();
        }, debounceDelay);
    }, [callBackFunction, debounceDelay]);

    useEffect(() => {
        const disposer = autorun(() => {
            debouncedFunction.current();
        });

        return () => {
            disposer();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...Object.values(triggers), callBackFunction]);
};

export default useDebouncedFunction;
