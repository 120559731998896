import React, { FunctionComponent, useEffect } from 'react';
import './index.less';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { MagnifyingGlassIcon } from 'Components/icons';
import { theme } from 'Style/theme';
import FilterStore from 'Stores/FilterStore';
import { observer } from 'mobx-react';
import { useStores } from 'Hooks';
import { MultiSelectCustomOption } from 'Components/select-custom/multi-select/multi-select-common';
import TaglessStaticMultiSelect from 'Components/select-custom/multi-select/tagless-static-multi-select';

interface ConcessionFiltersProps {
    filterStore: FilterStore;
}

const ConcessionFilters: FunctionComponent<ConcessionFiltersProps> = observer(({ filterStore }) => {
    const { t } = useTranslation();
    const { makesStore } = useStores();

    useEffect(() => {
        makesStore.cachedGetMakes();
    }, [makesStore.makesSelectOptions, makesStore.cachedGetMakes, makesStore]);

    return (
        <div className="ConcessionFilters">
            <div className="filter-with-label">
                {t('filter_par')}
                <TaglessStaticMultiSelect
                    onChange={(options: MultiSelectCustomOption[]) => {
                        filterStore.updateMakes(options);
                    }}
                    options={makesStore.makesSelectOptions}
                    selected={filterStore.makes?.map((x) => x.value)}
                    className="make-dropdown"
                    blueStyle
                    closeMenuOnSelect={false}
                    placeholderOnSelectedOptions={t('marque')}
                />
            </div>

            <Input
                className="search-bar deep-blue"
                placeholder={t('rechercher')}
                onChange={(e) => filterStore.updateSearchTerm(e.target.value)}
                prefix={
                    <MagnifyingGlassIcon fill={theme['brand-primary']} width={20} height={20} />
                }
                value={filterStore.searchTerm}
            />
        </div>
    );
});

export default ConcessionFilters;
