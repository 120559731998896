import { useState } from 'react';

interface UseInfiniteScrollProps<I> {
    /** Set the totalItemCount from the api paged response
     * 
     export interface GetConcessionsResponseDto {
        items?: (ConcessionDto | null)[] | null;
        totalItemCount?: number;  <===
    } */
    setTotalItemCount: React.Dispatch<React.SetStateAction<number>>;

    /** Reference to setTotalItemCount value*/
    totalItemCount: number;

    /**Current page state used in your callback function to fetch another page */
    currentPage: number;

    /**Update currant page state */
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;

    /**To be passed into InfiniteScroll handleScrollToBottom={handleScrollToBottom}/> */
    handleScrollToBottom: () => void;

    /**Accumulated objects returned from api fetch to be passed into InfiniteScroll items={items}/> */
    items: I[];

    /**Accumulated objects from api response. Set the objects returned from api fetch while accumulating previous objects.
     *
     * setItems((prevItems) => [...prevItems, ...currentFetchResponse]) */
    setItems: React.Dispatch<React.SetStateAction<any[]>>;
}

export const useInfiniteScroll = function <I = any>(): UseInfiniteScrollProps<I> {
    const [totalItemCount, setTotalItemCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [items, setItems] = useState<I[]>([]);

    const handleScrollToBottom = () => {
        const maxIsAlreadyLoaded = totalItemCount > 0 ? items.length >= totalItemCount : false;

        if (maxIsAlreadyLoaded) return;

        setCurrentPage((prev) => prev + 1);
    };

    return {
        setTotalItemCount,
        totalItemCount,
        items,
        setItems,
        handleScrollToBottom,
        currentPage,
        setCurrentPage,
    };
};
