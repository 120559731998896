import Button from 'Components/button';
import { EyeIcon, PadlockIcon } from 'Components/icons';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { LabelColor } from 'Components/validated-form-item/validated-form-items';
import { useFormValidation, useService, useStores, useWindowDimensions } from 'Hooks';
import { useFetch } from 'Hooks/use-fetch';
import { FORM_GUTTER } from 'Models/Constants';
import { UpdatePasswordSchema } from 'Schemas/UpdateAccountSchema';
import { CandidateService } from 'Services/CandidateService';
import { theme } from 'Style/theme';
import { Col, Form, Input, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Copydeck from 'i18n/Copydeck';
import { FunctionComponent, useMemo, useState } from 'react';
import { UpdateCandidatePasswordAnonymousRequestDto } from 'Api/Features/Candidates/Dtos/UpdateCandidatePasswordAnonymousRequestDto';
import { observer } from 'mobx-react';
import { AxiosError } from 'axios';

interface PasswordFormProps {
    onBack: () => void;
    candidateEmail: string;
}

const PasswordForm: FunctionComponent<PasswordFormProps> = observer(
    ({ candidateEmail, onBack }) => {
        const candidateService = useService(CandidateService);
        const { windowWidth } = useWindowDimensions();
        const [form] = useForm();
        const { toastStore } = useStores();
        const { apiRequest } = useFetch();
        const formValidation = useFormValidation(UpdatePasswordSchema, form);
        const profileErrors = formValidation[0];
        const profileValidateForm = formValidation[1];
        const setErrors = formValidation[3];

        const [revealPassword, setRevealPassword] = useState<boolean>(false);

        const validatePassword = async () => {
            const formValues = form.getFieldsValue();

            if (!(await profileValidateForm(formValues))) return;
            const requestObject: UpdateCandidatePasswordAnonymousRequestDto = {
                currentPassword: formValues.currentPassword,
                newPassword: formValues.newPassword,
                email: candidateEmail,
            };

            try {
                await apiRequest({
                    requestFunction: (request) =>
                        candidateService.updateCandidatePasswordAnonymous(request),
                    requestParameters: requestObject,
                    useGlobalLoading: true,
                    throwOnError: true,
                    useDefaultSuccessToast: true,
                });
                form.resetFields();
            } catch (error) {
                if (error instanceof AxiosError && error.response?.status === 403) {
                    setErrors(
                        new Map([
                            ['currentPassword', [Copydeck.accountAccountFormCurrentPasswordError]],
                        ])
                    );
                }
                toastStore.genericError();
            }
        };

        const colSpan = useMemo(() => (windowWidth > 640 ? 12 : 24), [windowWidth]);

        return (
            <>
                <Button
                    type="text"
                    leftIcon="ChevronLeftIcon"
                    text={Copydeck.accountAccountFormBackButton}
                    textClassName="text-label-large-bold AccountForm__backButton"
                    onClick={() => onBack()}
                />

                <h1 className="text-display-large-extenda AccountForm__newPasswordTitle">
                    {Copydeck.accountAccountFormNewPasswordTitle}
                </h1>

                <Form layout="vertical" className="form" form={form}>
                    <Row gutter={FORM_GUTTER}>
                        <Col span={24}>
                            <ValidatedFormItem
                                label={Copydeck.accountAccountFormCurrentPassword}
                                name="currentPassword"
                                labelColor={LabelColor.grey}
                                errors={profileErrors}
                                labelClassName="text-body-small"
                            >
                                <Input
                                    type={revealPassword ? 'text' : 'password'}
                                    prefix={
                                        <PadlockIcon width={20} height={20} fill={theme.white} />
                                    }
                                    suffix={
                                        <div className="show-psw cursor-pointer">
                                            <EyeIcon
                                                width={20}
                                                height={20}
                                                onClick={() => setRevealPassword((prev) => !prev)}
                                                fill={theme['element-greys-secondary']}
                                            />
                                        </div>
                                    }
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <hr className="AccountForm__passwordSeparator" />
                    <Row gutter={FORM_GUTTER}>
                        <Col span={colSpan}>
                            <ValidatedFormItem
                                label={Copydeck.accountAccountFormNewPassword}
                                name="newPassword"
                                labelColor={LabelColor.grey}
                                errors={profileErrors}
                                labelClassName="text-body-small"
                            >
                                <Input
                                    type={revealPassword ? 'text' : 'password'}
                                    prefix={
                                        <PadlockIcon width={20} height={20} fill={theme.white} />
                                    }
                                    suffix={
                                        <div className="show-psw cursor-pointer">
                                            <EyeIcon
                                                width={20}
                                                height={20}
                                                onClick={() => setRevealPassword((prev) => !prev)}
                                                fill={theme['element-greys-secondary']}
                                            />
                                        </div>
                                    }
                                />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={colSpan}>
                            <ValidatedFormItem
                                label={Copydeck.accountAccountFormNewPasswordConfirmation}
                                name="newPasswordConfirmation"
                                labelColor={LabelColor.grey}
                                errors={profileErrors}
                                labelClassName="text-body-small"
                            >
                                <Input
                                    type={revealPassword ? 'text' : 'password'}
                                    prefix={
                                        <PadlockIcon width={20} height={20} fill={theme.white} />
                                    }
                                    suffix={
                                        <div className="show-psw cursor-pointer">
                                            <EyeIcon
                                                width={20}
                                                height={20}
                                                onClick={() => setRevealPassword((prev) => !prev)}
                                                fill={theme['element-greys-secondary']}
                                            />
                                        </div>
                                    }
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <div className="AccountForm__buttonGroup">
                        <Button
                            type="blue-border"
                            text={Copydeck.accountAccountFormCancel}
                            className="AccountForm__passwordCancel"
                            onClick={() => onBack()}
                        />
                        <Button
                            type="red"
                            text={Copydeck.accountAccountFormSubmit}
                            className="AccountForm__passwordSubmit"
                            onClick={() => validatePassword()}
                        />
                    </div>
                </Form>
            </>
        );
    }
);

export default PasswordForm;
