import { CandidateDetailsDto } from 'Api/Features/Candidates/Dtos/CandidateDetailsDto';
import { CandidateQuizDto } from 'Api/Features/Candidates/Dtos/CandidateQuizDto';
import { CandidateRecruitmentFeedbackDto } from 'Api/Features/Candidates/Dtos/CandidateRecruitmentFeedbackDto';
import { CreateCandidateRequestQuizDto } from 'Api/Features/Candidates/Dtos/CreateCandidateRequestQuizDto';
import { FeedbackId } from 'Models/FeedbackDefinitions';
import { JobCategory } from 'Models/QuizDefinitions';
import { CandidateService } from 'Services/CandidateService';
import { getPreferredJobCategoryForQuiz } from 'Utils/QuizUtils';
import { inject } from 'aurelia-dependency-injection';
import { action, computed, observable } from 'mobx';

@inject(CandidateService)
class UserStore {
    constructor(private readonly candidateService: CandidateService) {}

    @observable
    public userInfo: CandidateDetailsDto | null = null;
    private userInfoPromise: Promise<CandidateDetailsDto | null> | null = null;

    @observable
    public userQuizAnswers: CandidateQuizDto | null = null;
    private userQuizAnswersPromise: Promise<CandidateQuizDto | null> | null = null;

    @observable
    public userFeedback: CandidateRecruitmentFeedbackDto | null = null;
    private userFeedbackPromise: Promise<CandidateRecruitmentFeedbackDto | null> | null = null;

    @action
    async setUserInfo(userId: string): Promise<CandidateDetailsDto | null> {
        if (!this.userInfoPromise) {
            this.userInfoPromise = this.candidateService.getCandidate(userId);
        }

        const userInfo = await this.userInfoPromise;
        this.userInfo = userInfo;
        this.userInfoPromise = null;

        if (!this.userQuizAnswersPromise) {
            this.userQuizAnswersPromise = this.candidateService.getCandidateQuiz(userId);
        }

        const quiz = await this.userQuizAnswersPromise;
        this.userQuizAnswers = quiz;
        this.userQuizAnswersPromise = null;

        this.setUserFeedback(userId);

        return userInfo;
    }

    @action
    async setUserQuizAnswers(userId: string) {
        if (!this.userQuizAnswersPromise) {
            this.userQuizAnswersPromise = this.candidateService.getCandidateQuiz(userId);
        }
        const quiz = await this.userQuizAnswersPromise;
        this.userQuizAnswers = quiz;
    }

    @action
    setAnonymousQuizAnswers(quizAnswers: CandidateQuizDto | CreateCandidateRequestQuizDto) {
        sessionStorage.quizAnswers = JSON.stringify(quizAnswers);
    }

    @computed
    get anonymousQuizAnswers(): CandidateQuizDto | null {
        if (sessionStorage.quizAnswers) return JSON.parse(sessionStorage.quizAnswers);
        return null;
    }

    @computed
    get preferredJobCategory(): JobCategory | null {
        if (this.userQuizAnswers == null && this.anonymousQuizAnswers == null) return null;

        return getPreferredJobCategoryForQuiz(this.userQuizAnswers ?? this.anonymousQuizAnswers!);
    }

    @action
    async setUserFeedback(userId: string) {
        if (!this.userFeedbackPromise) {
            this.userFeedbackPromise =
                this.candidateService.getCandidateRecruitmentFeedback(userId);
        }

        const feedback = await this.userFeedbackPromise;

        this.userFeedback = feedback;
        this.userFeedbackPromise = null;
    }

    @action
    //The only feedback question we can never rely on backend to know if we need to ask FeedbackId.facilite.
    setUserFeedbackAskFaciliteQuestion() {
        const currentValue = this.userFeedback?.responses?.find(
            (x) => x?.questionId === FeedbackId.facilite
        );
        if (
            currentValue &&
            (currentValue.value === null || currentValue.value === undefined) &&
            this.userFeedback?.responses
        ) {
            currentValue!.askQuestion = true;
            this.userFeedback = { responses: [...this.userFeedback.responses] };
        }
    }

    @action
    //When user changes his search status to not searching we need to ask question wether or not backend says so
    setUserFeedbackAskEmbaucheQuestion() {
        const currentValue = this.userFeedback?.responses?.find(
            (x) => x?.questionId === FeedbackId.embauche
        );
        if (currentValue && this.userFeedback?.responses) {
            currentValue!.askQuestion = true;
            this.userFeedback = { responses: [...this.userFeedback.responses] };
        }
    }

    @action
    clearUserInfo(): void {
        this.userInfo = null;
        this.userQuizAnswers = null;
    }
}

export default UserStore;
