import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import './index.less';
import FadingLetter from 'Components/fading-letter';
import { useTranslation } from 'react-i18next';
import BlueCard from 'Components/blue-card';
import { Checkbox, Col, Form, Input, Row } from 'antd';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { LabelColor } from 'Components/validated-form-item/validated-form-items';
import { theme } from 'Style/theme';
import {
    BriefcaseIcon,
    CheckmarkCircleIcon,
    ChevronRightIcon,
    EnvelopeIcon,
    EyeIcon,
    FeedbackIcon,
    PadlockIcon,
    ProfileIcon,
} from 'Components/icons';
import Button from 'Components/button';
import { useHistory } from 'react-router-dom';
import {
    ACCOUNT_URL,
    E001003,
    FORM_GUTTER,
    LOGIN_URL,
    METIER_URL,
    TERMS_CONDITIONS_URL,
    UPDATED_QUIZ_PARAM,
} from 'Models/Constants';
import { useFetch } from 'Hooks/use-fetch';
import { useFormValidation, useService, useStores, useWindowDimensions } from 'Hooks';
import { CandidateService } from 'Services/CandidateService';
import { VerifyCandidateAccountRequestDto } from 'Api/Features/Candidates/Dtos/VerifyCandidateAccountRequestDto';
import { useForm } from 'antd/lib/form/Form';
import { ProfileSchema, VerifyCodeSchema, VerifyEmailSchema } from 'Schemas/CreateAccountSchema';
import PhoneInput from 'Components/phone-input';
import { toE164 } from 'Utils/PhoneNumberUtils';
import StaticSingleSelect from 'Components/select-custom/single-select/static-single-select';
import { SearchDistanceRadiusEnum } from 'Models/SearchDistanceRadius';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import GooglePlacesAutoComplete from 'Components/google-places-autocomplete';
import { VerifyCandidateAccountResponseDto } from 'Api/Features/Candidates/Dtos/VerifyCandidateAccountResponseDto';
import AsyncSingleSelect from 'Components/select-custom/single-select/async-single-select';
import { useAsyncSingleSelectProps } from 'Hooks/use-async-single-select-props';
import { AuthenticationService } from 'Services/AuthenticationService';
import { CreateCandidateRequestQuizDto } from 'Api/Features/Candidates/Dtos/CreateCandidateRequestQuizDto';
import { CreateCandidateRequestDto } from 'Api/Features/Candidates/Dtos/CreateCandidateRequestDto';
import Quiz from 'Routes/quiz';
import ConfirmationModal from 'Components/confirmation-modal/confirmation-modal';
import { observer } from 'mobx-react';
import { ScrollContext } from 'Components/basic-layout';

const CreateAccount = observer(() => {
    const searchParams = useMemo(() => new URLSearchParams(location.search), []);
    const { t } = useTranslation();
    const history = useHistory();
    const [form] = useForm();
    const scrollContext = useContext(ScrollContext);
    const { apiRequest } = useFetch();
    const { toastStore, userStore, makesStore } = useStores();
    const { windowWidth } = useWindowDimensions();
    const candidateService = useService(CandidateService);
    const authenticationService = useService(AuthenticationService);
    const [verifyEmailErrors, verifyEmailValidateForm] = useFormValidation(VerifyEmailSchema, form);
    const [verifyCodeErrors, verifyCodeValidateForm] = useFormValidation(VerifyCodeSchema, form);
    const [profileErrors, profileValidateForm] = useFormValidation(ProfileSchema, form);

    const [currentStep, setCurrentStep] = useState<AccountStep>(AccountStep.verifyEmail);
    const [token, setToken] = useState<string>();
    const [currentEmail, setCurrentEmail] = useState();
    const [acceptedConditions, setAcceptedConditions] = useState(false);
    const [revealPassword, setRevealPassword] = useState(false);
    const [selectedDistance, setSelectedDistance] = useState<SingleSelectCustomOption>();
    const [selectedGooglePlaceId, setSelectedGooglePlaceId] = useState<string | undefined>();
    const [selectedMake, setSelectedMake] = useState<SingleSelectCustomOption | undefined>();
    const [profileInfo, setProfileInfo] = useState<any>();
    const [isNewBloodModalVisible, setIsNewBloodModalVisible] = useState(true);
    const [phonenumberIsValid, setPhoneNumberIsValid] = useState(true);
    const [errorMemorizedQuiz, setErrorMemorizedQuiz] = useState<CreateCandidateRequestQuizDto>();

    const GAbtnRef = useRef<HTMLButtonElement>(null);

    const MOBILE_BREAKPOINT = 850;

    const { asyncSingleSelectProps } = useAsyncSingleSelectProps({
        fetchProps: {
            fetchFunction: async () => await makesStore.cachedGetMakes(),
        },
        entityToSingleSelectCustomOption: (makeOption: SingleSelectCustomOption) => {
            return {
                value: makeOption.value,
                label: makeOption.label,
            } as SingleSelectCustomOption;
        },
    });

    const formColSpan = useMemo((): number => {
        if (windowWidth > MOBILE_BREAKPOINT) return 12;
        else return 24;
    }, [windowWidth]);

    const sendVerifyEmail = useCallback(
        async (email?: string) => {
            const formValues = form.getFieldsValue();
            const validationObject = {
                email: email ?? formValues.email,
            };
            if (!(await verifyEmailValidateForm(validationObject))) return;

            const request: VerifyCandidateAccountRequestDto = {
                email: validationObject.email,
            };
            try {
                await apiRequest({
                    requestFunction: (request) => candidateService.verifyCandidateAccount(request),
                    requestParameters: request,
                    useGlobalLoading: true,
                    throwOnError: true,
                    customErrorHandler: () => {
                        //
                    },
                });
                setCurrentEmail(formValues.email);
                setCurrentStep(AccountStep.verifyCode);
            } catch (e: any) {
                if (e.response?.data?.errorCode === E001003) {
                    toastStore.toast({
                        type: 'error',
                        title: t('Errors.E001003'),
                    });
                }
            }
        },
        [apiRequest, candidateService, form, t, toastStore, verifyEmailValidateForm]
    );

    const sendVerifyCode = useCallback(async () => {
        const formValues = form.getFieldsValue();

        if (!(await verifyCodeValidateForm(formValues))) return;

        const request: VerifyCandidateAccountRequestDto = {
            email: currentEmail,
            verificationCode: formValues.verificationCode,
        };

        try {
            const response: VerifyCandidateAccountResponseDto = await apiRequest({
                requestFunction: (request) => candidateService.verifyCandidateAccount(request),
                requestParameters: request,
                useGlobalLoading: true,
                throwOnError: true,
            });
            if (response.token) setToken(response.token);
            setCurrentStep(AccountStep.profileInfo);
        } catch (e) {
            toastStore.toast({
                type: 'error',
                title: t('Errors.E012002'),
            });
        }
    }, [apiRequest, candidateService, currentEmail, form, t, toastStore, verifyCodeValidateForm]);

    const submitCreateCandidate = useCallback(
        async (quiz: CreateCandidateRequestQuizDto, profile?: any) => {
            const profileData = profile ?? profileInfo;
            const request: CreateCandidateRequestDto = {
                quiz,
                ...profileData,
                searchRadius: Number.parseInt(selectedDistance?.value ?? '5'),
                favoriteMakes: [selectedMake?.value],
                token: token,
                isNewBlood: true,
            };
            try {
                await apiRequest({
                    requestFunction: (request) => candidateService.createCandidate(request),
                    requestParameters: request,
                    useGlobalLoading: true,
                    throwOnError: true,
                });
                await apiRequest({
                    requestFunction: () =>
                        authenticationService.initAccessToken(
                            request.email ?? '',
                            request.password ?? ''
                        ),
                    requestParameters: undefined,
                });
                setCurrentStep(AccountStep.success);
                if (GAbtnRef.current) GAbtnRef.current.click();
            } catch (e: any) {
                if (e.response?.data?.errors?.['phoneNumber']) {
                    setPhoneNumberIsValid(false);
                    setCurrentStep(AccountStep.profileInfo);
                    setErrorMemorizedQuiz(quiz);
                }
                toastStore.genericError();
            }
        },
        [
            apiRequest,
            authenticationService,
            candidateService,
            profileInfo,
            selectedDistance?.value,
            selectedMake?.value,
            toastStore,
            token,
        ]
    );

    const validateProfile = useCallback(async () => {
        const formValues = form.getFieldsValue();

        const validationObject = {
            ...formValues,
            favoriteMakes: selectedMake?.value,
            searchLocationGooglePlaceId: selectedGooglePlaceId,
            email: currentEmail,
            isSeekingJob: true,
        };

        if (!(await profileValidateForm(validationObject))) return;
        setProfileInfo(validationObject);

        if (userStore.anonymousQuizAnswers || errorMemorizedQuiz) {
            submitCreateCandidate(
                userStore.anonymousQuizAnswers ?? errorMemorizedQuiz!,
                validationObject
            );
        } else setCurrentStep(AccountStep.quiz);
        scrollContext?.scrollToTop();
    }, [
        form,
        selectedMake?.value,
        selectedGooglePlaceId,
        currentEmail,
        profileValidateForm,
        userStore.anonymousQuizAnswers,
        errorMemorizedQuiz,
        scrollContext,
        submitCreateCandidate,
    ]);

    const submitIsNewBlood = async (isNewBlood: boolean) => {
        if (userStore.userInfo) {
            await apiRequest({
                requestFunction: () =>
                    candidateService.updateCandidate(userStore.userInfo?.id ?? '', {
                        ...userStore.userInfo,
                        isNewBlood,
                    }),
                requestParameters: undefined,
            });
            setIsNewBloodModalVisible(false);
        }
    };

    const isUpdatingquiz = useMemo(() => {
        return searchParams.get(UPDATED_QUIZ_PARAM) === 'true';
    }, [searchParams]);

    useEffect(() => {
        if (isUpdatingquiz) setCurrentStep(AccountStep.success);
    }, [isUpdatingquiz]);

    const sendCodeContent = useCallback(() => {
        return (
            <BlueCard padding={40} className="sendCode">
                <div className="text-body-large-bold text-white">{t('User.cree_compte_p1')}</div>
                <div className="text-body-medium text-white mt-20 mb-40">
                    {t('User.cree_compte_p2')}
                </div>
                <Form layout="vertical" className="form" form={form}>
                    <ValidatedFormItem
                        label={t('Login.entrer_courriel')}
                        name="email"
                        labelColor={LabelColor.white}
                        errors={verifyEmailErrors}
                    >
                        <Input
                            type="email"
                            className="blue"
                            prefix={<EnvelopeIcon width={20} height={20} fill={theme.white} />}
                        />
                    </ValidatedFormItem>
                </Form>

                <Button
                    text={t('User.envoyer_code')}
                    type="red"
                    width="full"
                    onClick={() => sendVerifyEmail()}
                    uppercase
                />
                <div
                    onClick={() => history.push(LOGIN_URL)}
                    className="cursor-pointer text-white text-label-large-bold mt-20"
                >
                    {t('User.deja_un_compte')}
                </div>
            </BlueCard>
        );
    }, [form, history, sendVerifyEmail, t, verifyEmailErrors]);

    const verifyCodeContent = useCallback(() => {
        return (
            <BlueCard padding={40} className="verifyCode">
                <div className="text-body-large text-white ">
                    {t('User.entrer_code_verif_explanation')}
                </div>
                <div className="text-body-large text-white mb-20">
                    {t('User.entrer_code_verif_explanation_2')}
                </div>
                <div className="text-body-large-bold text-white">{t('User.entrer_code_verif')}</div>
                <Form layout="vertical" className="form" form={form}>
                    <ValidatedFormItem
                        label={''}
                        name="verificationCode"
                        labelColor={LabelColor.white}
                        errors={verifyCodeErrors}
                    >
                        <Input className="blue" />
                    </ValidatedFormItem>
                </Form>

                <Button
                    text={t('User.verifier_le_code')}
                    type="red"
                    width="full"
                    onClick={() => sendVerifyCode()}
                    uppercase
                />
                <div
                    className="cursor-pointer text-white text-label-large-bold mt-20"
                    onClick={() => sendVerifyEmail(currentEmail)}
                >
                    {t('User.envoyer_un nouveau_code')}
                </div>
            </BlueCard>
        );
    }, [currentEmail, form, t, sendVerifyCode, sendVerifyEmail, verifyCodeErrors]);

    const profileInfoContent = useCallback(() => {
        return (
            <div className="profileInfo">
                <Form layout="vertical" className="form" form={form}>
                    <Row gutter={FORM_GUTTER}>
                        <Col span={formColSpan}>
                            <ValidatedFormItem
                                label={t('prenom')}
                                name="firstName"
                                labelColor={LabelColor.white}
                                errors={profileErrors}
                            >
                                <Input className="deep-blue" />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={formColSpan}>
                            <ValidatedFormItem
                                label={t('nom')}
                                name="lastName"
                                labelColor={LabelColor.white}
                                errors={profileErrors}
                            >
                                <Input className="deep-blue" />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={FORM_GUTTER}>
                        <Col span={formColSpan}>
                            <ValidatedFormItem
                                label={t('telephone')}
                                name="phoneNumber"
                                labelColor={LabelColor.white}
                                errors={profileErrors}
                            >
                                <PhoneInput
                                    formErrors={profileErrors}
                                    onChange={(fullNumber?: string, extension?: string) => {
                                        form.setFieldsValue({
                                            phoneNumber: toE164(fullNumber, extension),
                                        });
                                    }}
                                    formInputName="phoneNumber"
                                    classNames="deep-blue"
                                    isValid={phonenumberIsValid}
                                />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={formColSpan}>
                            <ValidatedFormItem
                                label={t('User.marque_favorite')}
                                name="favoriteMakes"
                                labelColor={LabelColor.white}
                                errors={profileErrors}
                            >
                                <AsyncSingleSelect
                                    {...asyncSingleSelectProps}
                                    onChange={(option?: SingleSelectCustomOption) => {
                                        setSelectedMake(option);
                                    }}
                                    selected={selectedMake?.value}
                                    isSearchable={false}
                                    placeholder={''}
                                    blueStyle
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={FORM_GUTTER}>
                        <Col span={formColSpan}>
                            <ValidatedFormItem
                                label={t('User.ville_recherche')}
                                name="searchLocationGooglePlaceId"
                                labelColor={LabelColor.white}
                                errors={profileErrors}
                            >
                                <GooglePlacesAutoComplete
                                    onChange={(value?: string) => {
                                        setSelectedGooglePlaceId(value);
                                    }}
                                    className="deep-blue"
                                    hasError={profileErrors.has('searchLocationGooglePlaceId')}
                                    blueStyle
                                />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={formColSpan}>
                            <ValidatedFormItem
                                label={t('User.rayon_recherche')}
                                name="searchRadius"
                                labelColor={LabelColor.white}
                                errors={profileErrors}
                            >
                                <StaticSingleSelect
                                    blueStyle
                                    options={[
                                        {
                                            value: SearchDistanceRadiusEnum.five,
                                            label: SearchDistanceRadiusEnum.five,
                                        },
                                        {
                                            value: SearchDistanceRadiusEnum.ten,
                                            label: SearchDistanceRadiusEnum.ten,
                                        },
                                        {
                                            value: SearchDistanceRadiusEnum.twentyfive,
                                            label: SearchDistanceRadiusEnum.twentyfive,
                                        },
                                        {
                                            value: SearchDistanceRadiusEnum.fifty,
                                            label: SearchDistanceRadiusEnum.fifty,
                                        },
                                    ]}
                                    onChange={(value?: SingleSelectCustomOption) =>
                                        setSelectedDistance(value)
                                    }
                                    selected={selectedDistance?.value}
                                    placeholder={''}
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={FORM_GUTTER}>
                        <Col span={24}>
                            <ValidatedFormItem
                                label={t('User.description_a_propos')}
                                name="description"
                                labelColor={LabelColor.white}
                                errors={profileErrors}
                            >
                                <Input.TextArea className="deep-blue" />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={FORM_GUTTER}>
                        <Col span={formColSpan}>
                            <ValidatedFormItem
                                label={t('Login.mot_de_passe')}
                                name="password"
                                labelColor={LabelColor.white}
                                errors={profileErrors}
                            >
                                <Input
                                    type={revealPassword ? 'text' : 'password'}
                                    className="bottom-input deep-blue"
                                    prefix={
                                        <PadlockIcon width={20} height={20} fill={theme.white} />
                                    }
                                    suffix={
                                        <div className="show-psw cursor-pointer">
                                            <EyeIcon
                                                width={20}
                                                height={20}
                                                onClick={() => setRevealPassword((prev) => !prev)}
                                                fill={theme.white}
                                            />
                                        </div>
                                    }
                                />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={formColSpan}>
                            <ValidatedFormItem
                                label={t('Login.confirmation_mot_de_passe')}
                                name="confirmPassword"
                                labelColor={LabelColor.white}
                                errors={profileErrors}
                            >
                                <Input
                                    type={revealPassword ? 'text' : 'password'}
                                    className="bottom-input deep-blue"
                                    prefix={
                                        <PadlockIcon width={20} height={20} fill={theme.white} />
                                    }
                                    suffix={
                                        <div className="show-psw cursor-pointer">
                                            <EyeIcon
                                                width={20}
                                                height={20}
                                                onClick={() => setRevealPassword((prev) => !prev)}
                                                fill={theme.white}
                                            />
                                        </div>
                                    }
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                </Form>

                <div className="profileInfo_bottom">
                    <Checkbox onChange={(e) => setAcceptedConditions(e.target.checked)}>
                        {t('User.accepte_condition_1')}&nbsp;
                        <a
                            className="terms-link"
                            href={TERMS_CONDITIONS_URL}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {t('User.accepte_condition_2')}
                        </a>
                        &nbsp;
                        {t('User.accepte_condition_3')}
                    </Checkbox>
                    <Button
                        text={
                            userStore.anonymousQuizAnswers !== null
                                ? t('User.completer_profile')
                                : t('etape_suivante')
                        }
                        type="red"
                        width="full"
                        onClick={() => validateProfile()}
                        uppercase
                        disabled={!acceptedConditions}
                    />
                </div>
            </div>
        );
    }, [
        acceptedConditions,
        asyncSingleSelectProps,
        form,
        formColSpan,
        phonenumberIsValid,
        profileErrors,
        revealPassword,
        selectedDistance?.value,
        selectedMake?.value,
        t,
        userStore.anonymousQuizAnswers,
        validateProfile,
    ]);

    const successContent = useCallback(() => {
        return (
            <div className="success">
                <div className="cursor-pointer goto-box" onClick={() => history.push(ACCOUNT_URL)}>
                    <BlueCard padding={40} paddingLeft={20} paddingRight={20}>
                        <ProfileIcon fill={theme.white} />
                        <div className="text-white text-headline-small-bold uppercase">
                            {t('aller_au_compte')}
                        </div>
                        <div className="text-white text-label-large-bold chevron">
                            {t('voir')}
                            <ChevronRightIcon width={18} />
                        </div>
                    </BlueCard>
                </div>

                <div className="cursor-pointer goto-box" onClick={() => history.push(METIER_URL)}>
                    <BlueCard padding={40} paddingLeft={20} paddingRight={20}>
                        <BriefcaseIcon width={50} height={50} fill={theme.white} />
                        <div className="text-white text-headline-small-bold uppercase">
                            {t('explorer_les_metiers')}
                        </div>
                        <div className="text-white text-label-large-bold chevron">
                            {t('explorer')}
                            <ChevronRightIcon width={18} />
                        </div>
                    </BlueCard>
                </div>
            </div>
        );
    }, [history, t]);

    const getContent = useMemo(() => {
        switch (currentStep) {
            case AccountStep.verifyEmail:
                return sendCodeContent();
            case AccountStep.verifyCode:
                return verifyCodeContent();
            case AccountStep.profileInfo:
                return profileInfoContent();
            case AccountStep.quiz:
                return (
                    <Quiz
                        isCreateAccountFlow
                        onFinaliseAccountClick={(quiz: CreateCandidateRequestQuizDto) =>
                            submitCreateCandidate(quiz)
                        }
                    />
                );
            case AccountStep.success:
                return successContent();
        }
    }, [
        currentStep,
        profileInfoContent,
        sendCodeContent,
        submitCreateCandidate,
        successContent,
        verifyCodeContent,
    ]);

    const title1 = useMemo(
        () => (
            <div className="title custom-font text-white">
                {windowWidth > MOBILE_BREAKPOINT ? (
                    <>
                        {t('User.creer_votre')}{' '}
                        <FadingLetter
                            side="left"
                            width={70}
                            height={80}
                            wordOffset={-15}
                            word={t('compte')}
                        />
                    </>
                ) : (
                    <span>
                        {t('User.creer_votre')} {t('compte')}
                    </span>
                )}
            </div>
        ),
        [windowWidth, t]
    );

    const title2 = useMemo(
        () => (
            <div className="title custom-font text-white uppercase">
                {t('User.creation_du_profile')}
            </div>
        ),
        [t]
    );

    const title3 = useMemo(
        () => (
            <div className="success-title">
                <div className="title custom-font text-white uppercase success-title">
                    <CheckmarkCircleIcon />
                    <div className="mt-20">
                        {t('felicitations')}&nbsp;&nbsp;
                        {windowWidth > MOBILE_BREAKPOINT ? (
                            <FadingLetter
                                side="right"
                                width={73}
                                height={80}
                                wordOffset={-90}
                                word="!"
                            />
                        ) : (
                            '!'
                        )}
                    </div>
                </div>
                <div className="text-body-large-bold text-white uppercase">
                    {searchParams.get(UPDATED_QUIZ_PARAM)
                        ? t('User.vos_reponse_a_jour')
                        : t('User.votre_prof_est_complete')}
                </div>
            </div>
        ),
        [searchParams, t, windowWidth]
    );

    const getTitle = useMemo(() => {
        switch (currentStep) {
            case AccountStep.verifyEmail:
                return title1;
            case AccountStep.verifyCode:
                return title1;
            case AccountStep.profileInfo:
                return title2;
            case AccountStep.quiz:
                return '';
            case AccountStep.success:
                return title3;
        }
    }, [currentStep, title1, title2, title3]);

    return (
        <div className="CreateAccount">
            <div className="inner">
                {getTitle}
                {getContent}
            </div>

            {userStore.userInfo?.id &&
                currentStep === AccountStep.success &&
                isNewBloodModalVisible &&
                !isUpdatingquiz && (
                    <ConfirmationModal
                        id="newBlood"
                        title={t('retroaction')}
                        icon={<FeedbackIcon fill={theme.white} />}
                        message={t('Candidat.avez_vous_experience')}
                        positive={{ action: () => submitIsNewBlood(true), text: t('oui') }}
                        negative={{ action: () => submitIsNewBlood(false), text: t('non') }}
                    />
                )}

            {/* Marketing teams wants a GA click id when an account is created. We will simulate a click on successful submit */}
            <button ref={GAbtnRef} style={{ display: 'none' }} id="GA-compte" />
        </div>
    );
});

enum AccountStep {
    verifyEmail = 'verifyEmail',
    verifyCode = 'verifyCode',
    profileInfo = 'profileInfo',
    quiz = 'quiz',
    success = 'success',
}

export default CreateAccount;
