import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const FeedbackIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick} {...props}>
            <svg
                width="44"
                height="44"
                viewBox="0 0 44 44"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M7.33341 11.0001H3.66675V36.6667C3.66675 38.6834 5.31675 40.3334 7.33341 40.3334H33.0001V36.6667H7.33341V11.0001ZM36.6667 3.66675H14.6667C12.6501 3.66675 11.0001 5.31675 11.0001 7.33341V29.3334C11.0001 31.3501 12.6501 33.0001 14.6667 33.0001H36.6667C38.6834 33.0001 40.3334 31.3501 40.3334 29.3334V7.33341C40.3334 5.31675 38.6834 3.66675 36.6667 3.66675ZM36.6667 29.3334H14.6667V7.33341H36.6667V29.3334ZM24.7684 18.6267C25.5201 17.2884 26.9317 16.5001 27.7567 15.3267C28.6367 14.0801 28.1417 11.7701 25.6667 11.7701C24.0534 11.7701 23.2467 12.9984 22.9167 14.0251L20.4051 12.9801C21.1017 10.9267 22.9534 9.16675 25.6484 9.16675C27.9034 9.16675 29.4617 10.1934 30.2501 11.4767C30.9284 12.5767 31.3134 14.6484 30.2684 16.1884C29.1134 17.8934 28.0134 18.4067 27.4084 19.5067C27.1701 19.9467 27.0784 20.2401 27.0784 21.6701H24.2917C24.3101 20.9184 24.1817 19.6901 24.7684 18.6267ZM23.7417 25.5751C23.7417 24.4934 24.6034 23.6684 25.6667 23.6684C26.7484 23.6684 27.5734 24.4934 27.5734 25.5751C27.5734 26.6384 26.7667 27.5001 25.6667 27.5001C24.6034 27.5001 23.7417 26.6384 23.7417 25.5751Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default FeedbackIcon;
