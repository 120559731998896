import React, { FunctionComponent, ReactNode } from 'react';
import './index.less';

interface ViewSwitchProps {
    onChange: (selectedKey: string | number) => void;
    icons: [ViewSwitchIcon, ViewSwitchIcon];
    selectedKey: string | number;
}

interface ViewSwitchIcon {
    icon: ReactNode;
    key: string | number;
}

const ViewSwitch: FunctionComponent<ViewSwitchProps> = ({ onChange, icons, selectedKey }) => {
    return (
        <div className="ViewSwitch">
            {icons.map((x) => (
                <div
                    className={`circle ${selectedKey === x.key && 'selected'}`}
                    key={x.key}
                    onClick={() => onChange(x.key)}
                >
                    {x.icon}
                </div>
            ))}
        </div>
    );
};

export default ViewSwitch;
