import React, { FunctionComponent } from 'react';
import './index.less';
import BlueCard from 'Components/blue-card';

interface AnswerSquareProps {
    index: number;
    id: string;
    answer: string;
    onClick: (id: string) => void;
    selected?: boolean;
}
const AnswerSquare: FunctionComponent<AnswerSquareProps> = ({
    index,
    id,
    answer,
    onClick,
    selected,
}) => {
    const letter = index === 0 ? 'A' : index === 1 ? 'B' : 'C';
    return (
        <div
            className={`AnswerSquare cursor-pointer ${selected && 'selected'}`}
            id={`${selected && 'parent'}`}
            onClick={() => onClick(id)}
            key={id}
        >
            <BlueCard padding={40} paddingLeft={20} paddingRight={20}>
                <div className="letter-square text-white text-title-large-bold">{letter}</div>
                <div className="text-white text-body-large answer">{answer}</div>
            </BlueCard>

            <div className="blur" />
        </div>
    );
};

export default AnswerSquare;
