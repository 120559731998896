import React, { FunctionComponent } from 'react';
import './index.less';
import { useTranslation } from 'react-i18next';
import { ChevronRightIcon, MapArrowIcon } from 'Components/icons';
import { theme } from 'Style/theme';
import { truncate } from 'Utils/TextUtils';
import { useHistory } from 'react-router-dom';
import { CONCESSIONS_URL } from 'Models/Constants';
import Tag, { TagColor } from 'Components/tag/tag';
import DefaultImg from 'Assets/Images/concession_default_img.webp';
import { MakeDto } from 'Api/Features/Concessions/Dtos/MakeDto';

interface ConcessionCardProps {
    concessionName: string;
    concessionSlug: string;
    imgUrl?: string | null;
    distance?: number | null;
    inArea?: boolean;
    isHiring?: boolean;
    hasJobApplication?: boolean;
    makes?: (MakeDto | null)[] | null;
}

const ConcessionCard: FunctionComponent<ConcessionCardProps> = ({
    concessionName,
    concessionSlug,
    imgUrl,
    distance,
    inArea,
    isHiring,
    hasJobApplication,
    makes,
}) => {
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <div className="ConcessionCard">
            <div className="img-container">
                {inArea && isHiring && (
                    <div className="in-area-tag text-white text-body-small-bold">
                        <MapArrowIcon width={16} height={16} fill={theme.white} />
                        {t('Concession.dans_ton_secteur')}
                    </div>
                )}

                {!isHiring && (
                    <div className="inactive-tag text-white text-body-small-bold">
                        {t('candidature_inactive')}
                    </div>
                )}
                {hasJobApplication && (
                    <div
                        className={`has-job-application-tag text-white text-body-small-bold ${
                            inArea && 'offset'
                        }`}
                    >
                        {t('candidature_envoyee')}
                    </div>
                )}
                <img src={imgUrl ? imgUrl : DefaultImg} />
            </div>
            <div className="info-container text-white">
                <div className="text-title-medium-bold">{truncate(concessionName, 25)}</div>
                <div className="text-body-small">
                    {truncate((makes?.map((x) => x?.name) ?? [])?.join(', '), 125)}
                </div>

                <div className="bottom-actions">
                    {distance && (
                        <div className="distance text-label-large-bold">
                            <Tag
                                color={TagColor.blue}
                                text={`${t('distance')}: ${Math.round(distance)}KM`}
                            ></Tag>
                        </div>
                    )}
                    <div
                        className="see-more text-label-large-bold"
                        onClick={() => history.push(CONCESSIONS_URL + `/${concessionSlug}`)}
                    >
                        {t('voir_plus')} <ChevronRightIcon width={18} height={18} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConcessionCard;
