import { action, computed, observable } from 'mobx';

class LoadingStore {
    parent: LoadingStore;
    constructor(parent: LoadingStore) {
        this.parent = parent;
    }

    @observable loadingCount = 0;

    @computed get isLoading(): boolean {
        return !(this.parent && this.parent.isLoading) && this.loadingCount > 0;
    }

    @action addLoading(): void {
        this.loadingCount++;
    }

    @action removeLoading(): void {
        if (this.loadingCount > 0) this.loadingCount--;
    }

    @action removeAllLoading(): void {
        this.loadingCount = 0;
    }
}

export default LoadingStore;
