import { SvgProps } from 'Components/icons/SvgProps';
import * as React from 'react';
import { FunctionComponent } from 'react';

const FadeEffectLeftSvg: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg
            width="73"
            height="93"
            viewBox={`0 0 ${props.width ?? '73'} ${props.height ?? '93'}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <rect
                x="10.0958"
                width="62.9042"
                height={props.height ?? '93'}
                fill={props.fill ?? 'white'}
                fillOpacity="0.2"
            />
            <rect
                width="73"
                height={props.height ?? '93'}
                fill={props.fill ?? 'white'}
                fillOpacity="0.2"
            />
            <rect
                x="20.1914"
                width="52.8085"
                height={props.height ?? '93'}
                fill={props.fill ?? 'white'}
                fillOpacity="0.2"
            />
            <rect
                x="31.0637"
                width="41.9362"
                height={props.height ?? '93'}
                fill={props.fill ?? 'white'}
                fillOpacity="0.2"
            />
            <rect
                x="42.7126"
                width="30.2872"
                height={props.height ?? '93'}
                fill={props.fill ?? 'white'}
                fillOpacity="0.2"
            />
        </svg>
    );
};

export default FadeEffectLeftSvg;
