import { FunctionComponent, useMemo } from 'react';
import './index.less';
import { observer } from 'mobx-react';
import { useStores, useWindowDimensions } from 'Hooks';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CONCESSIONS_URL, HOME_PAGE_URL, METIER_URL, TEMOIGNAGE_URL } from 'Models/Constants';
import DesktopHeaderLayout from './components/desktop-header-layout';
import MobileHeaderLayout from './components/mobile-header-layout';

export interface NavItem {
    key: string;
    text: string;
}
export interface HeaderProps {
    navItems: NavItem[];
    onNavigationClick: (key: string) => void;
    isLoggedIn: boolean;
}

const Header: FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const { authenticationStore } = useStores();
    const history = useHistory();
    const { windowWidth } = useWindowDimensions();

    const isDesktop = useMemo(() => windowWidth > 880, [windowWidth]);

    const navItems: { key: string; text: string }[] = [
        { key: HOME_PAGE_URL, text: t('SideNav.SideNav_industrie') },
        { key: METIER_URL, text: t('SideNav.SideNav_metier') },
        { key: CONCESSIONS_URL, text: t('SideNav.SideNav_concessions') },
        { key: TEMOIGNAGE_URL, text: t('SideNav.SideNav_temoignages') },
    ];

    const onNavigationClick = (key: string): void => {
        history.push(key);
    };

    return (
        <div className="Header">
            {isDesktop ? (
                <DesktopHeaderLayout
                    isLoggedIn={authenticationStore.loggedIn}
                    navItems={navItems}
                    onNavigationClick={onNavigationClick}
                />
            ) : (
                <MobileHeaderLayout
                    isLoggedIn={authenticationStore.loggedIn}
                    navItems={navItems}
                    onNavigationClick={onNavigationClick}
                />
            )}
        </div>
    );
});

export default Header;
