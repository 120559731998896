import { SvgProps } from 'Components/icons/SvgProps';
import * as React from 'react';
import { FunctionComponent } from 'react';

const FadeEffectRightSvg: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg
            width="73"
            height="93"
            viewBox={`0 0 ${props.width ?? '73'} ${props.height ?? '93'}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <rect
                width="64.257"
                height={props.height ?? '93'}
                fill={props.fill ?? 'white'}
                fillOpacity="0.2"
            />
            <rect
                width="74.5699"
                height={props.height ?? '93'}
                fill={props.fill ?? 'white'}
                fillOpacity="0.2"
            />
            <rect
                width="53.9442"
                height={props.height ?? '93'}
                fill={props.fill ?? 'white'}
                fillOpacity="0.2"
            />
            <rect
                width="42.838"
                height={props.height ?? '93'}
                fill={props.fill ?? 'white'}
                fillOpacity="0.2"
            />
            <rect
                width="30.9386"
                height={props.height ?? '93'}
                fill={props.fill ?? 'white'}
                fillOpacity="0.2"
            />
        </svg>
    );
};

export default FadeEffectRightSvg;
