import Content from 'Components/Content';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './index.less';
import ViewSwitch from 'Components/view-switch';
import { ListIcon, MapIcon } from 'Components/icons';
import { theme } from 'Style/theme';
import ConcessionListView from './components/viewtype/list';
import ConcessionMapView from './components/viewtype/map';
import { useStores, useWindowDimensions } from 'Hooks';
import { observer } from 'mobx-react';
import AnimatedDraggingString from 'Components/animated-dragging-string';

const Concessions = observer(() => {
    const { t } = useTranslation();
    const { userStore } = useStores();
    const [viewType, setViewType] = useState<ViewType>(ViewType.list);
    const [userPosition, setUserPosition] = useState<{ lat: number; lng: number } | undefined>();
    const { windowWidth } = useWindowDimensions();
    const ANIMATED_TITLE_BREAKPOINT = 1052;
    const ANIMATED_TITLE_BREAKPOINT_MOBILE = 430;

    //request geolocation
    useEffect(() => {
        if (
            userStore.userInfo?.searchLocation?.latitude &&
            userStore.userInfo?.searchLocation?.longitude
        ) {
            setUserPosition({
                lat: userStore.userInfo.searchLocation.latitude,
                lng: userStore.userInfo.searchLocation.longitude,
            });
        } else {
            navigator.geolocation.getCurrentPosition((position) => {
                const { latitude, longitude } = position.coords;
                if (latitude && longitude) {
                    setUserPosition({ lat: latitude, lng: longitude });
                }
            });
        }
    }, [userStore.userInfo]);

    return (
        <div className="Concessions">
            <Content>
                <div className="top">
                    <div className="custom-font text-white top-title uppercase">
                        {t('Concession.decouverte_des')}&nbsp;
                        {windowWidth > ANIMATED_TITLE_BREAKPOINT_MOBILE ? (
                            <AnimatedDraggingString
                                text={t('concessions')}
                                color="white"
                                textOffset={-7}
                                letterScaleX={windowWidth > ANIMATED_TITLE_BREAKPOINT ? 1.3 : 1.7}
                                letterWidth="18px"
                                containerHeight={65}
                            />
                        ) : (
                            t('concessions')
                        )}
                    </div>

                    <ViewSwitch
                        onChange={(selected) => setViewType(selected as ViewType)}
                        selectedKey={viewType}
                        icons={[
                            { key: ViewType.list, icon: <ListIcon fill={theme.white} /> },
                            { key: ViewType.map, icon: <MapIcon fill={theme.white} /> },
                        ]}
                    />
                </div>

                {viewType === ViewType.list && <ConcessionListView userPosition={userPosition} />}

                {viewType === ViewType.map && <ConcessionMapView userPosition={userPosition} />}
            </Content>
        </div>
    );
});

enum ViewType {
    list = 'list',
    map = 'map',
}

export interface ConcessionViewBaseProps {
    userPosition?: { lat: number; lng: number };
}
export default Concessions;
