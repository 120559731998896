import React, { FunctionComponent, ReactNode, useEffect, useState, useCallback } from 'react';
import './single-select-common.less';
import Select, { components } from 'react-select';
import {
    SingleSelectCommonStyles,
    SingleSelectCustomOption,
    SingleSelectCommonTheme,
    Option,
    SingleValue,
} from './single-select-common';
import { useTranslation } from 'react-i18next';

export interface StaticSingleSelectProps {
    isLoading?: boolean;
    className?: string;
    options?: SingleSelectCustomOption[];
    onChange: (value?: SingleSelectCustomOption) => void | undefined;
    placeholder?: string | null;
    // defaultImg?: ReactNode;
    closeMenuOnSelect?: boolean;
    hideSelectedOptions?: boolean;
    selected?: string | null;
    disabled?: boolean;
    isClearable?: boolean;
    borderless?: boolean;
    round?: boolean;
    dropdownIndicatorIcon?: ReactNode;
    blueStyle?: boolean; //SPECIFIC TO CCAQ METIER
    /**document.body  permet au menu ouvert de sortir de l'espace modal*/
    menuPortalTarget?: HTMLElement;
}

const StaticSingleSelect: FunctionComponent<StaticSingleSelectProps> = ({
    isLoading = false,
    className,
    options,
    onChange,
    placeholder,
    // defaultImg,
    isClearable = false,
    closeMenuOnSelect = true,
    hideSelectedOptions = false,
    selected,
    disabled,
    borderless,
    round,
    dropdownIndicatorIcon,
    blueStyle,
    menuPortalTarget,
}) => {
    const { t } = useTranslation();
    const [value, setValue] = useState<SingleSelectCustomOption | null>();

    useEffect(() => {
        if (options === undefined) return;
        if (selected) {
            setValue(options.find((x) => x.value === selected));
        } else {
            setValue(null);
        }
    }, [selected, options]);

    const handleOnChange = (data: any): void => {
        if (onChange) {
            onChange(data ?? undefined);
        }
    };

    // -- 02/11/2022 as of now, never have an option tu put image --
    // const optionImage = (data: SingleSelectCustomOption, selectProps: any) => {
    //     return (
    //         <>
    //             {data.imageUrl ? (
    //                 <img
    //                     style={{ flex: '0 0 auto' }}
    //                     className="dropdown-option-img"
    //                     width="24px"
    //                     height="24px"
    //                     src={data.imageUrl}
    //                     alt=""
    //                 />
    //             ) : (
    //                 <span className="dropdown-option-img">{selectProps.defaultImg}</span>
    //             )}
    //         </>
    //     );
    // };

    const Input = useCallback(({ ...props }: any): any => {
        return <components.Input {...props} autoComplete="new-password" />;
    }, []);

    const DropdownIndicator = useCallback(
        (props): any => {
            return dropdownIndicatorIcon ? (
                <components.DropdownIndicator {...props}>
                    {dropdownIndicatorIcon}
                </components.DropdownIndicator>
            ) : (
                <components.DropdownIndicator {...props}></components.DropdownIndicator>
            );
        },
        [dropdownIndicatorIcon]
    );

    return (
        <Select
            components={{
                Option,
                SingleValue: (props: any) => <SingleValue {...props} isDeepBlue={blueStyle} />,
                DropdownIndicator,
                Input,
            }}
            theme={(defaultTheme) => ({
                ...defaultTheme,
                colors: {
                    ...defaultTheme.colors,
                    ...SingleSelectCommonTheme,
                },
            })}
            className={`SingleSelect StaticSingleSelect ${className || ''} ${
                disabled || isLoading ? 'disabled' : ''
            } ${borderless ? 'borderless' : ''}  ${round ? 'round' : ''} ${
                blueStyle && 'blue-style'
            }`}
            value={value}
            styles={SingleSelectCommonStyles}
            options={options}
            onChange={handleOnChange}
            isClearable={isClearable}
            closeMenuOnSelect={closeMenuOnSelect}
            hideSelectedOptions={hideSelectedOptions}
            placeholder={placeholder ?? t('SelectCustom.default_select_placeholder')}
            isDisabled={disabled || isLoading}
            noOptionsMessage={(): string => t('SelectCustom.select_no_options')}
            isLoading={isLoading}
            menuPlacement="auto"
            menuPortalTarget={menuPortalTarget}
        />
    );
};

export default StaticSingleSelect;
