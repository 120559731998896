import Button from 'Components/button';
import PAS_JUSTE_DES_VENDEURS_IMAGE from 'Assets/Images/industrie/PAS_JUSTE_DES_VENDEURS.webp';
import './index.less';
import AnimatedDraggingString from 'Components/animated-dragging-string';
import CategoryCard from 'Components/category-card';
import FancyArrowIcon from 'Components/icons/fancy-arrow-icon';
import { theme } from 'Style/theme';
import { useHistory } from 'react-router-dom';
import { CONCESSIONS_URL, CREATE_ACCOUNT_URL, METIER_URL } from 'Models/Constants';
import { useStores, useWindowDimensions } from 'Hooks';
import Copydeck from 'i18n/Copydeck';
import { useMemo, useRef } from 'react';
import useElementIsVisible from 'Hooks/use-element-is-visible';
import { observer } from 'mobx-react';
import ScrollAnimationWrapper from 'Components/scroll-animation-wrapper';

const Metiers = observer(() => {
    const CATEGORIES = [
        {
            title: Copydeck.industrieIndustrieMetiersCategory1Title,
            description: Copydeck.industrieIndustrieMetiersCategory1Description,
        },
        {
            title: Copydeck.industrieIndustrieMetiersCategory2Title,
            description: Copydeck.industrieIndustrieMetiersCategory2Description,
        },
        {
            title: Copydeck.industrieIndustrieMetiersCategory3Title,
            description: Copydeck.industrieIndustrieMetiersCategory3Description,
        },
        {
            title: Copydeck.industrieIndustrieMetiersCategory4Title,
            description: Copydeck.industrieIndustrieMetiersCategory4Description,
        },
        {
            title: Copydeck.industrieIndustrieMetiersCategory5Title,
            description: Copydeck.industrieIndustrieMetiersCategory5Description,
        },
        {
            title: Copydeck.industrieIndustrieMetiersCategory6Title,
            description: Copydeck.industrieIndustrieMetiersCategory6Description,
        },
    ];

    const containerRef = useRef<HTMLDivElement>(null);
    const titleRow2Ref = useRef<HTMLDivElement>(null);
    const isElementVisible = useElementIsVisible(titleRow2Ref);

    const { authenticationStore } = useStores();
    const history = useHistory();
    const { windowWidth } = useWindowDimensions();

    const arrowSize =
        windowWidth <= 540
            ? {
                  width: 77,
                  height: 34,
              }
            : {
                  width: 103,
                  height: 46,
              };

    const ctaUrlRedirection = useMemo(
        () => (authenticationStore.loggedIn ? CONCESSIONS_URL : CREATE_ACCOUNT_URL),
        [authenticationStore.loggedIn]
    );

    return (
        <div className="Metiers" ref={containerRef}>
            <ScrollAnimationWrapper containerRef={containerRef}>
                <div className="Metiers__content">
                    <div className="Metiers__row Metiers__row--1">
                        <h2 className="Metiers__title">
                            <span className="Metiers__titleRow">
                                {Copydeck.industrieIndustrieMetiersTitleRow1}
                            </span>
                            <span
                                className="Metiers__titleRow Metiers__titleRow--2"
                                ref={titleRow2Ref}
                            >
                                <FancyArrowIcon
                                    direction="right"
                                    fill={theme['surface-brand-accent-tertiary']}
                                    width={arrowSize.width}
                                    height={arrowSize.height}
                                    className="Metiers__titleArrow"
                                />
                                <AnimatedDraggingString
                                    isVisible={isElementVisible}
                                    color="blue"
                                    textOffset={-5}
                                    text={Copydeck.industrieIndustrieMetiersTitleRow2Word1}
                                    className="Metiers__titleAnimation"
                                    letterScaleX={1.3}
                                    letterWidth="18px"
                                />
                                <img src={PAS_JUSTE_DES_VENDEURS_IMAGE} alt="" />
                            </span>
                            <span className="Metiers__titleRow Metiers__titleRow--3">
                                {Copydeck.industrieIndustrieMetiersTitleRow2Word2}
                            </span>
                        </h2>
                        <div className="Metiers__text">
                            <h2 className="Metiers__textTitle">
                                {Copydeck.industrieIndustrieMetiersTextTitle}
                            </h2>
                            <div className="Metiers__textParagraph">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: Copydeck.industrieIndustrieMetiersTextParagraph1,
                                    }}
                                ></p>
                                <p className="Metiers__textParagraph--accent">
                                    {Copydeck.industrieIndustrieMetiersTextParagraph2}
                                </p>
                                <Button
                                    text={Copydeck.industrieIndustrieMetiersCta1}
                                    withRightArrow
                                    type="red"
                                    width="full"
                                    height="large"
                                    onClick={() => history.push(ctaUrlRedirection)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="Metiers__row Metiers__row--2">
                        <ul className="Metiers__categories">
                            {CATEGORIES.map((category, index) => (
                                <li className="Metiers__categoryItem" key={index}>
                                    <CategoryCard
                                        title={category.title}
                                        description={category.description}
                                    />
                                </li>
                            ))}
                        </ul>
                        <div className="Metiers__buttonContainer">
                            <Button
                                type="red"
                                withRightArrow
                                text={Copydeck.industrieIndustrieMetiersCta2}
                                height="large"
                                width="full"
                                className="Metiers__metiersButton"
                                onClick={() => history.push(METIER_URL)}
                            />
                        </div>
                    </div>
                </div>
            </ScrollAnimationWrapper>
        </div>
    );
});

export default Metiers;
