import { CreateJobApplicationRequestDto } from 'Api/Features/JobApplications/Dtos/CreateJobApplicationRequestDto';
import { CreateJobApplicationResponseDto } from 'Api/Features/JobApplications/Dtos/CreateJobApplicationResponseDto';
import { GetJobApplicationsRequestDto } from 'Api/Features/JobApplications/Dtos/GetJobApplicationsRequestDto';
import { JobApplicationDto } from 'Api/Features/JobApplications/Dtos/JobApplicationDto';
import { JobApplicationStatusDto } from 'Api/Features/JobApplications/Dtos/JobApplicationStatusDto';
import Skeleton from 'Components/Skeleton';
import Button, { Type } from 'Components/button';
import { useService, useStores } from 'Hooks';
import { useFetch } from 'Hooks/use-fetch';
import { LOGIN_URL } from 'Models/Constants';
import { JobApplicationService } from 'Services/JobApplicationService';
import { observer } from 'mobx-react';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

interface PostulerButtonProps extends React.HTMLProps<HTMLButtonElement> {
    className?: string;
    type: Type;
    concessionId?: string;
    concessionIsHiring: boolean;
    userId?: string;
    width?: 'auto' | 'full' | 'hugged';
    applicationSent?: boolean;
    onApplicationSent?: () => void;
}

const PostulerButton: FunctionComponent<PostulerButtonProps> = observer(
    ({
        className,
        type,
        concessionId,
        concessionIsHiring,
        userId,
        width = 'auto',
        applicationSent = false,
        onApplicationSent,
    }) => {
        const { t } = useTranslation();
        const jobApplicationService = useService(JobApplicationService);
        const { toastStore, userStore } = useStores();
        const { apiRequest, loadingStateKeys } = useFetch();
        const history = useHistory();
        const [hasActiveApplication, setHasActiveApplication] = useState(false);

        const getActiveApplications = useCallback(async () => {
            if (userId === undefined || concessionId == undefined) return;
            const request: GetJobApplicationsRequestDto = {
                candidateIds: [userId],
                concessionIds: [concessionId],
            };
            const [response]: [JobApplicationDto[], number] = await apiRequest({
                requestFunction: (request) => jobApplicationService.getJobApplications(request),
                requestParameters: request,
                loadingStateKey: 'activeApplicationsLoading',
            });
            setHasActiveApplication(
                response.some(
                    (x) =>
                        x.concession?.id === concessionId &&
                        x.status === JobApplicationStatusDto.AwaitingResponse
                )
            );
        }, [apiRequest, userId, jobApplicationService, concessionId]);

        useEffect(() => {
            getActiveApplications();
        }, [userId, concessionId, getActiveApplications]);

        const postuler = async () => {
            if (userId === undefined) {
                history.push(LOGIN_URL);
                return;
            }
            if (concessionId === undefined) return;

            const request: CreateJobApplicationRequestDto = {
                candidateId: userId,
                concessionId: concessionId,
            };

            const response: CreateJobApplicationResponseDto = await apiRequest({
                requestFunction: (request) => jobApplicationService.createJobApplication(request),
                requestParameters: request,
                useGlobalLoading: true,
            });
            if (response?.id) {
                toastStore.toast({
                    type: 'success',
                    title: t('ToastStore.default_success_toast'),
                });
                userStore.setUserFeedbackAskFaciliteQuestion();
                setHasActiveApplication(true);
                if (onApplicationSent) onApplicationSent();
            }
        };

        useEffect(() => {
            if (applicationSent) setHasActiveApplication(true);
        }, [applicationSent]);

        return (
            <Skeleton
                placeholder={
                    <div>
                        <div className="rect" style={{ width: '100%', height: 40 }} />
                    </div>
                }
                isLoading={loadingStateKeys.has('activeApplicationsLoading')}
            >
                <Button
                    text={
                        hasActiveApplication
                            ? t('deja_postuler')
                            : concessionIsHiring
                            ? t('postuler')
                            : t('aucun_emploi_pour_moment')
                    }
                    type={type}
                    className={className}
                    onClick={() => postuler()}
                    disabled={!concessionIsHiring || hasActiveApplication}
                    width={width}
                />
            </Skeleton>
        );
    }
);

export default PostulerButton;
