import React, { ReactElement, useCallback, useRef } from 'react';

interface InfiniteScrollProps<T> {
    handleScrollToBottom: () => void;
    items: T[];
    RenderItem: (item: T) => ReactElement;
    RenderItemSkeleton: () => ReactElement;
    paginationOptions: {
        pageSize: number;
        isLoading: boolean;
    };
    numberSkeletonLoading?: number;
    marginTopWhenItemsPresent?: number;
}

function InfiniteScroll<T = any>({
    handleScrollToBottom,
    items,
    RenderItem,
    RenderItemSkeleton,
    paginationOptions,
    numberSkeletonLoading = Math.floor(paginationOptions.pageSize / 2),
    marginTopWhenItemsPresent,
}: InfiniteScrollProps<T>) {
    const observer: any = useRef();

    const lastItemInList = useCallback(
        (node) => {
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    handleScrollToBottom();
                    observer.current.disconnect();
                }
            });
            if (node) observer.current.observe(node);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [observer]
    );

    return (
        <div className="InfiniteScroll">
            <div
                className="wrapper-loaded-items"
                style={{
                    marginTop:
                        marginTopWhenItemsPresent && items.length > 0
                            ? marginTopWhenItemsPresent
                            : undefined,
                }}
            >
                {items.map((item, index) => {
                    const isLast = items.length - 1 === index;

                    return (
                        <div
                            className="wrapper-loaded-items-item-wrapper"
                            key={index}
                            ref={isLast ? lastItemInList : undefined}
                        >
                            {RenderItem(item)}
                        </div>
                    );
                })}
            </div>

            {paginationOptions.isLoading && (
                <div className="wrapper-skeleton-loading-items">
                    {[...Array(numberSkeletonLoading)].map((item, key) => {
                        return (
                            <div
                                className="wrapper-skeleton-loading-items-item-wrapper"
                                key={`${key}+loading`}
                            >
                                <RenderItemSkeleton />
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
}

export default InfiniteScroll;
