import { FunctionComponent } from 'react';
import './index.less';
import { Carousel as ACarousel } from 'antd';

interface CarouselProps {
    elements: JSX.Element[];
    dotPosition?: 'top' | 'bottom' | 'left' | 'right';
    autoplay?: boolean;
    effect?: 'scrollx' | 'fade';
    easing?: string;
    beforeChange?: (from: number, to: number) => void;
    afterChange?: (current: number) => void;
    dots?: boolean | { className: string };
    className?: string;
}

const Carousel: FunctionComponent<CarouselProps> = ({ elements, className, dots, ...props }) => {
    const classes = className ? `Carousel ${className}` : 'Carousel';
    const getDotsProp = () => {
        if (typeof dots === 'boolean') {
            return dots ? { className: 'Carousel__dots' } : false;
        }
        return dots ? dots : { className: 'Carousel__dots' };
    };

    return (
        <div className="Carousel">
            <ACarousel className={classes} {...props} dots={getDotsProp()}>
                {elements}
            </ACarousel>
        </div>
    );
};

export default Carousel;
