import FadeEffectLeftSvg from 'Assets/Images/FadeEffectLeftSvg';
import { theme } from 'Style/theme';
import React, { FunctionComponent } from 'react';
import './index.less';
import FadeEffectRightSvg from 'Assets/Images/FadeEffectRightSvg';

interface FadingLetterProps {
    height: number;
    width: number;
    word: string;
    side: 'right' | 'left';
    fadeColor?: FadeColor;
    wordOffset: number;
}

export enum FadeColor {
    white = 'white',
    blue = 'blue',
}

const FadingLetter: FunctionComponent<FadingLetterProps> = ({
    height,
    width,
    word,
    side,
    fadeColor = FadeColor.white,
    wordOffset,
}) => {
    return (
        <div className="FadingLetter">
            {side === 'left' ? (
                <FadeEffectLeftSvg
                    height={height}
                    width={width}
                    fill={
                        fadeColor === FadeColor.white
                            ? theme.white
                            : theme['surface-brand-rest-primary']
                    }
                />
            ) : (
                <FadeEffectRightSvg
                    height={height}
                    width={width}
                    fill={
                        fadeColor === FadeColor.white
                            ? theme.white
                            : theme['surface-brand-rest-primary']
                    }
                />
            )}

            <span style={{ marginLeft: wordOffset }}>{word}</span>
        </div>
    );
};

export default FadingLetter;
