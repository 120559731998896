import { useMemo } from 'react';
import './index.less';
import AccountHeader from './components/account-header';
import AccountTabs from './components/account-tabs';
import { observer } from 'mobx-react';
import { useStores } from 'Hooks';

const Account = observer(() => {
    const { userStore } = useStores();
    const { userInfo } = userStore;

    const date = useMemo(() => {
        const now = new Date();
        const weekday = now.toLocaleDateString('fr-FR', {
            weekday: 'long',
        });

        // Dont forget commat after the week day and Uppercase at the beggining
        return `${weekday.charAt(0).toUpperCase() + weekday.slice(1)}, ${now.toLocaleDateString(
            'fr-FR',
            {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
            }
        )}`;
    }, []);

    return (
        <div className="Account">
            {userInfo && (
                <>
                    <AccountHeader candidate={userInfo} date={date} />
                    <AccountTabs candidate={userInfo} />
                </>
            )}
        </div>
    );
});

export default Account;
