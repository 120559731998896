import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const CheckmarkCircleIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="105" height="105" viewBox="0 0 105 105" fill="none" {...props}>
                <path
                    d="M52.5 8.75C28.35 8.75 8.75 28.35 8.75 52.5C8.75 76.65 28.35 96.25 52.5 96.25C76.65 96.25 96.25 76.65 96.25 52.5C96.25 28.35 76.65 8.75 52.5 8.75ZM52.5 87.5C33.2063 87.5 17.5 71.7938 17.5 52.5C17.5 33.2063 33.2063 17.5 52.5 17.5C71.7938 17.5 87.5 33.2063 87.5 52.5C87.5 71.7938 71.7938 87.5 52.5 87.5ZM69.475 36.2687L43.75 61.9938L35.525 53.7687C33.8188 52.0625 31.0625 52.0625 29.3563 53.7687C27.65 55.475 27.65 58.2313 29.3563 59.9375L40.6875 71.2688C42.3937 72.975 45.15 72.975 46.8563 71.2688L75.6875 42.4375C77.3937 40.7312 77.3937 37.975 75.6875 36.2687C73.9812 34.5625 71.1813 34.5625 69.475 36.2687Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default CheckmarkCircleIcon;
