// GENERATED FILE - DO NOT MODIFY
import { CandidateDetailsDto } from 'Api/Features/Candidates/Dtos/CandidateDetailsDto';
import { CandidateForgotPasswordRequestDto } from 'Api/Features/Candidates/Dtos/CandidateForgotPasswordRequestDto';
import { CandidateForgotPasswordResponseDto } from 'Api/Features/Candidates/Dtos/CandidateForgotPasswordResponseDto';
import { CandidateQuizDto } from 'Api/Features/Candidates/Dtos/CandidateQuizDto';
import { CandidateRecruitmentFeedbackDto } from 'Api/Features/Candidates/Dtos/CandidateRecruitmentFeedbackDto';
import { CreateCandidateRequestDto } from 'Api/Features/Candidates/Dtos/CreateCandidateRequestDto';
import { CreateCandidateResponseDto } from 'Api/Features/Candidates/Dtos/CreateCandidateResponseDto';
import { GetCandidatesRequestDto } from 'Api/Features/Candidates/Dtos/GetCandidatesRequestDto';
import { GetCandidatesResponseDto } from 'Api/Features/Candidates/Dtos/GetCandidatesResponseDto';
import { UpdateCandidatePasswordAnonymousRequestDto } from 'Api/Features/Candidates/Dtos/UpdateCandidatePasswordAnonymousRequestDto';
import { UpdateCandidateQuizRequestDto } from 'Api/Features/Candidates/Dtos/UpdateCandidateQuizRequestDto';
import { UpdateCandidateRecruitmentFeedbackRequestDto } from 'Api/Features/Candidates/Dtos/UpdateCandidateRecruitmentFeedbackRequestDto';
import { UpdateCandidateRequestDto } from 'Api/Features/Candidates/Dtos/UpdateCandidateRequestDto';
import { VerifyCandidateAccountRequestDto } from 'Api/Features/Candidates/Dtos/VerifyCandidateAccountRequestDto';
import { VerifyCandidateAccountResponseDto } from 'Api/Features/Candidates/Dtos/VerifyCandidateAccountResponseDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class CandidateProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getCandidate(candidateId: string): Promise<CandidateDetailsDto | null> {
        const uri = this.buildUri(
            "/candidates/{candidateId}",
            { candidateId: candidateId },
            null
        );

        const data: CandidateDetailsDto | null = await this.httpClient.get<CandidateDetailsDto | null>(uri);
        return data;
    }

    public async getCandidates(request: GetCandidatesRequestDto | null): Promise<GetCandidatesResponseDto | null> {
        const uri = this.buildUri(
            "/candidates",
            null,
            { ...request || undefined }
        );

        const data: GetCandidatesResponseDto | null = await this.httpClient.get<GetCandidatesResponseDto | null>(uri);
        return data;
    }

    public async verifyCandidateAccount(request: VerifyCandidateAccountRequestDto | null): Promise<VerifyCandidateAccountResponseDto | null> {
        const uri = this.buildUri(
            "/verify-candidate",
            null,
            null
        );

        const data: VerifyCandidateAccountResponseDto | null = await this.httpClient.post<VerifyCandidateAccountRequestDto | null, VerifyCandidateAccountResponseDto | null>(uri, request);
        return data;
    }

    public async createCandidate(request: CreateCandidateRequestDto | null): Promise<CreateCandidateResponseDto | null> {
        const uri = this.buildUri(
            "/candidates",
            null,
            null
        );

        const data: CreateCandidateResponseDto | null = await this.httpClient.post<CreateCandidateRequestDto | null, CreateCandidateResponseDto | null>(uri, request);
        return data;
    }

    public async updateCandidate(candidateId: string, request: UpdateCandidateRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/candidates/{candidateId}",
            { candidateId: candidateId },
            null
        );

        await this.httpClient.patch<UpdateCandidateRequestDto | null, void>(uri, request);
    }

    public async candidateForgotPassword(request: CandidateForgotPasswordRequestDto | null): Promise<CandidateForgotPasswordResponseDto | null> {
        const uri = this.buildUri(
            "/candidates/forgot-password",
            null,
            null
        );

        const data: CandidateForgotPasswordResponseDto | null = await this.httpClient.post<CandidateForgotPasswordRequestDto | null, CandidateForgotPasswordResponseDto | null>(uri, request);
        return data;
    }

    public async updateCandidatePasswordAnonymous(request: UpdateCandidatePasswordAnonymousRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/candidates/set-password",
            null,
            null
        );

        await this.httpClient.post<UpdateCandidatePasswordAnonymousRequestDto | null, void>(uri, request);
    }

    public async getCandidateQuiz(candidateId: string): Promise<CandidateQuizDto | null> {
        const uri = this.buildUri(
            "/candidates/{candidateId}/quiz",
            { candidateId: candidateId },
            null
        );

        const data: CandidateQuizDto | null = await this.httpClient.get<CandidateQuizDto | null>(uri);
        return data;
    }

    public async updateCandidateQuiz(candidateId: string, request: UpdateCandidateQuizRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/candidates/{candidateId}/quiz",
            { candidateId: candidateId },
            null
        );

        await this.httpClient.post<UpdateCandidateQuizRequestDto | null, void>(uri, request);
    }

    public async getCandidateRecruitmentFeedback(candidateId: string): Promise<CandidateRecruitmentFeedbackDto | null> {
        const uri = this.buildUri(
            "/candidates/{candidateId}/recruitment-feedback",
            { candidateId: candidateId },
            null
        );

        const data: CandidateRecruitmentFeedbackDto | null = await this.httpClient.get<CandidateRecruitmentFeedbackDto | null>(uri);
        return data;
    }

    public async updateCandidateRecruitmentFeedback(candidateId: string, request: UpdateCandidateRecruitmentFeedbackRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/candidates/{candidateId}/recruitment-feedback",
            { candidateId: candidateId },
            null
        );

        await this.httpClient.post<UpdateCandidateRecruitmentFeedbackRequestDto | null, void>(uri, request);
    }
}