import { Layout } from 'antd';
import { FunctionComponent, createContext, useEffect, useMemo, useRef, useState } from 'react';
import './index.less';
import Header from 'Components/header';
import Footer from 'Components/footer';
import { useStores } from 'Hooks';
import { observer } from 'mobx-react';
import FeedbackBox from 'Components/feedback-box';
import { useHistory } from 'react-router-dom';
import useHeadDocumentTags from 'Hooks/use-head-document-tags';
import { Helmet } from 'react-helmet-async';
import QuizCtaBox from 'Components/quiz-cta-box';
import { LOCALSTORAGE_QUIZ_CTA } from 'Models/Constants';
import useLocalStorage from 'Hooks/use-local-storage';

interface BasicLayoutProps {
    useWithIndustriePage?: boolean;
}

interface ScrollContext {
    scrollToTop: () => void;
}

export const ScrollContext = createContext<ScrollContext | undefined>(undefined);

const BasicLayout: FunctionComponent<BasicLayoutProps> = observer(
    ({ useWithIndustriePage, children }) => {
        const { description, title } = useHeadDocumentTags();
        const { industrieIntroStore, userStore, authenticationStore } = useStores();
        const { industrieIntroHasBeenScrolled } = industrieIntroStore;
        const { getLocalStorage } = useLocalStorage();
        const history = useHistory();
        const containerRef = useRef<HTMLDivElement>(null);
        const [scrollContext, setScrollContext] = useState<ScrollContext | undefined>(undefined);
        const [showQuizCta, setShowQuizCta] = useState<boolean>(false);

        const getBasicLayoutClassName = useMemo(() => {
            if (!useWithIndustriePage) {
                return 'BasicLayout';
            }
            return industrieIntroHasBeenScrolled
                ? 'IndustrieBasicLayout'
                : 'IndustrieBasicLayout IndustrieBasicLayout--blockScrolling';
        }, [useWithIndustriePage, industrieIntroHasBeenScrolled]);

        const showFeedbackBox = useMemo(() => {
            return (
                authenticationStore.loggedIn &&
                userStore.userFeedback?.responses?.some((x) => x?.askQuestion)
            );
        }, [authenticationStore.loggedIn, userStore.userFeedback?.responses]);

        useEffect(() => {
            scrollToTop();
        }, [history.location.pathname]);

        useEffect(() => {
            const handleStorageEvent = (event: CustomEventInit) => {
                const { key } = event.detail;

                if (key === LOCALSTORAGE_QUIZ_CTA) {
                    setShowQuizCta(false);
                }
            };

            window.addEventListener('localStorageChange', handleStorageEvent);

            return () => {
                window.removeEventListener('localStorageChange', handleStorageEvent);
            };
        }, []);

        useEffect(() => {
            if (!authenticationStore.loggedIn) {
                setShowQuizCta(!getLocalStorage(LOCALSTORAGE_QUIZ_CTA));
            } else {
                setShowQuizCta(false);
            }
        }, [authenticationStore.loggedIn, getLocalStorage]);

        const scrollToTop = () => {
            if (containerRef.current) {
                containerRef.current.scrollTo({
                    top: 0,
                    // behavior: 'smooth', // not smooth
                });
            }
        };

        useEffect(() => {
            setScrollContext({
                scrollToTop,
            });
        }, []);

        return (
            <div ref={containerRef} className={getBasicLayoutClassName}>
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={description} />
                </Helmet>
                <ScrollContext.Provider value={scrollContext}>
                    <Layout>
                        <Header />
                        <Layout className={`site-layout`}>
                            <div className="site-layout-content">{children}</div>
                            <Footer />
                        </Layout>
                        <div className="feedbacks-container">
                            {showFeedbackBox && <FeedbackBox />}
                            {showQuizCta && <QuizCtaBox />}
                        </div>
                    </Layout>
                </ScrollContext.Provider>
            </div>
        );
    }
);

export default BasicLayout;
