import { PASSWORD_REGEX } from 'Models/Constants';
import * as yup from 'yup';

export const VerifyEmailSchema = yup.object({
    email: yup.string().required('Errors.required').email('Errors.email_error'),
});

export const VerifyCodeSchema = yup.object({
    verificationCode: yup.string().required('Errors.required'),
});

export const ProfileSchema = yup.object({
    firstName: yup.string().required('Errors.required'),
    lastName: yup.string().required('Errors.required'),
    phoneNumber: yup.string().required('Errors.required'),
    searchLocationGooglePlaceId: yup.string().required('Errors.required'),
    searchRadius: yup.string().required('Errors.required'),
    description: yup.string().required('Errors.required'),
    favoriteMakes: yup.string().required('Errors.required'),
    password: yup
        .string()
        .matches(PASSWORD_REGEX, 'Errors.password_match')
        .required('Errors.required'),
    confirmPassword: yup
        .string()
        .required('Errors.required')
        .oneOf([yup.ref('password')], 'Errors.password_must_match'),
});
