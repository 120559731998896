import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const LinkedinIcon: FunctionComponent<SvgProps> = ({ fill, ...props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="49"
            viewBox="0 0 48 49"
            fill="none"
            {...props}
        >
            <path
                d="M18.5069 33.4781V21.3631H14.4807V33.4781H18.5069ZM16.4943 19.7081C17.8983 19.7081 18.7723 18.7778 18.7723 17.6152C18.7461 16.4264 17.8984 15.522 16.521 15.522C15.1438 15.522 14.2432 16.4265 14.2432 17.6152C14.2432 18.7778 15.1169 19.7081 16.468 19.7081H16.4942H16.4943ZM20.7354 33.4781H24.7616V26.7125C24.7616 26.3505 24.7878 25.9887 24.8941 25.7299C25.1852 25.0065 25.8476 24.2572 26.9598 24.2572C28.4168 24.2572 28.9996 25.3682 28.9996 26.9968V33.4781H33.0256V26.5314C33.0256 22.8102 31.0392 21.0787 28.3902 21.0787C26.2183 21.0787 25.2646 22.293 24.7348 23.12H24.7617V21.3629H20.7355C20.7883 22.4997 20.7355 33.4779 20.7355 33.4779L20.7354 33.4781Z"
                fill={fill || 'white'}
            />
        </svg>
    );
};

export default LinkedinIcon;
