import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const CarIcon: FunctionComponent<SvgProps> = ({ fill = 'currentColor', onClick, ...props }) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <path
                    d="M22.6042 10.1111C22.8227 10.1111 23 10.2853 23 10.5V10.8974C23 11.5142 22.2669 11.6667 21.7207 11.6667L20.8609 10.1111H22.6042ZM21.4634 12.838C21.9692 13.7604 22.2123 14.5896 22.2123 15.6893C22.2123 16.7549 21.898 17.6594 21.4167 18.7857V20.2222C21.4167 20.6516 21.062 21 20.625 21H19.4375C19.0005 21 18.6458 20.6516 18.6458 20.2222V19.4444H8.35417V20.2222C8.35417 20.6516 7.9995 21 7.5625 21H6.375C5.938 21 5.58333 20.6516 5.58333 20.2222V18.7857C5.102 17.6594 4.78771 16.7549 4.78771 15.6893C4.78771 14.5896 5.03075 13.7604 5.53662 12.838C6.19292 11.6426 7.002 10.1523 7.85621 8.75311C8.51171 7.67822 8.85529 7.42544 9.65963 7.28389C10.8725 7.07 11.9697 7 13.5 7C15.0303 7 16.1275 7.07 17.3404 7.28389C18.1447 7.42544 18.4883 7.67822 19.1438 8.75311C19.998 10.1523 20.8071 11.6426 21.4634 12.838ZM8.75 15.1667C8.75 14.5227 8.218 14 7.5625 14C6.907 14 6.375 14.5227 6.375 15.1667C6.375 15.8107 6.907 16.3333 7.5625 16.3333C8.218 16.3333 8.75 15.8107 8.75 15.1667ZM16.6667 15.9444C16.6667 15.7298 16.4893 15.5556 16.2708 15.5556H10.7292C10.5107 15.5556 10.3333 15.7298 10.3333 15.9444C10.3333 16.1591 10.5107 16.3333 10.7292 16.3333H16.2708C16.4893 16.3333 16.6667 16.1591 16.6667 15.9444ZM18.995 11.6457C18.995 11.6457 18.4091 10.2254 17.7037 9.20189C17.5438 8.97011 17.296 8.81144 17.0158 8.76167C15.8196 8.55011 14.7714 8.48244 13.5 8.48244C12.2286 8.48244 11.1804 8.55011 9.98421 8.76167C9.70396 8.81067 9.45617 8.96933 9.29625 9.20189C8.59087 10.2247 8.00504 11.6457 8.00504 11.6457C9.30337 11.8891 11.4163 12.0322 13.5 12.0322C15.5837 12.0322 17.6966 11.8891 18.995 11.6457ZM20.625 15.1667C20.625 14.5227 20.093 14 19.4375 14C18.782 14 18.25 14.5227 18.25 15.1667C18.25 15.8107 18.782 16.3333 19.4375 16.3333C20.093 16.3333 20.625 15.8107 20.625 15.1667ZM6.13908 10.1111H4.39583C4.17733 10.1111 4 10.2853 4 10.5V10.8974C4 11.5142 4.73308 11.6667 5.27933 11.6667L6.13908 10.1111Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default CarIcon;
