import { observer } from 'mobx-react';
import { FunctionComponent, useMemo } from 'react';
import './index.less';
import Tag, { TagColor } from 'Components/tag/tag';
import { useStores } from 'Hooks';
import { useHistory } from 'react-router-dom';
import { ACCOUNT_URL } from 'Models/Constants';
import { useTranslation } from 'react-i18next';
import UserIcon from 'Components/icons/user-icon';

interface ConnectedUserProps {
    isMobile?: boolean;
    withFullBorder?: boolean;
}

const ConnectedUser: FunctionComponent<ConnectedUserProps> = observer(
    ({ isMobile, withFullBorder = false }) => {
        const { userStore } = useStores();
        const { t } = useTranslation();
        const history = useHistory();

        const connectedUserClassName = useMemo(
            () =>
                `ConnectedUser ${isMobile ? 'ConnectedUser--mobile' : ''} ${
                    withFullBorder ? 'ConnectedUser--withFullBorder' : ''
                }`,
            [isMobile, withFullBorder]
        );

        return (
            <div
                className={connectedUserClassName}
                role="button"
                onClick={() => history.push(ACCOUNT_URL)}
            >
                {isMobile ? (
                    <UserIcon />
                ) : (
                    <div className="button">
                        <div className="text-body-large-bold text-white button-name">
                            {userStore.userInfo?.firstName}
                        </div>
                        <div className="text-body-small text-white button-email">
                            {userStore.userInfo?.email}
                        </div>
                    </div>
                )}
                {userStore.userInfo?.isSeekingJob && (
                    <Tag color={TagColor.green} text={t('en_recherche')} width="hugged" />
                )}
            </div>
        );
    }
);

export default ConnectedUser;
