import Content from 'Components/Content';
import WhiteCard from 'Components/white-card';
import React from 'react';
import Termsh1 from './components/terms-h1';
import { useTranslation } from 'react-i18next';
import './index.less';
import Termsh2 from './components/terms-h2';
import Termsp from './components/terms-p';

const TermsConditions = () => {
    const { t } = useTranslation();
    return (
        <div className="TermsConditions">
            <Content>
                <WhiteCard padding={20}>
                    <Termsh1>{t('Terms.terms_h1_1')}</Termsh1>

                    <Termsp>{t('Terms.terms_p_1')}</Termsp>

                    <Termsh2>{t('Terms.terms_h2_1')}</Termsh2>

                    <Termsp>{t('Terms.terms_p_2')}</Termsp>

                    <Termsh2>{t('Terms.terms_h2_2')}</Termsh2>

                    <Termsp>{t('Terms.terms_p_3')}</Termsp>

                    <Termsp>{t('Terms.terms_h2_3')}</Termsp>

                    <Termsp>{t('Terms.terms_p_5')}</Termsp>

                    <Termsh2>{t('Terms.terms_h2_4')}</Termsh2>

                    <Termsp>{t('Terms.terms_p_6')}</Termsp>

                    <Termsh2>{t('Terms.terms_h2_5')}</Termsh2>

                    <Termsp>{t('Terms.terms_p_7')}</Termsp>

                    <Termsh2>{t('Terms.terms_h2_6')}</Termsh2>

                    <Termsp>{t('Terms.terms_p_8')}</Termsp>

                    <Termsh1>{t('Terms.terms_h1_2')}</Termsh1>

                    <Termsp>{t('Terms.terms_p_9')}</Termsp>

                    <Termsh2>{t('Terms.terms_h2_7')}</Termsh2>
                    <Termsp>{t('Terms.terms_p_10')}</Termsp>

                    <Termsh2>{t('Terms.terms_h2_8')}</Termsh2>

                    <Termsp>{t('Terms.terms_p_11')}</Termsp>

                    <Termsh2>{t('Terms.terms_h2_9')}</Termsh2>

                    <Termsp>{t('Terms.terms_p_12')}</Termsp>

                    <Termsh2>{t('Terms.terms_h2_10')}</Termsh2>

                    <Termsp>{t('Terms.terms_p_13')}</Termsp>

                    <Termsh2>{t('Terms.terms_h2_11')}</Termsh2>

                    <Termsp>{t('Terms.terms_p_14')}</Termsp>

                    <Termsh2>{t('Terms.terms_h2_12')}</Termsh2>

                    <Termsp>{t('Terms.terms_p_15')}</Termsp>

                    <Termsh2>{t('Terms.terms_h2_13')}</Termsh2>

                    <Termsp>{t('Terms.terms_p_16')}</Termsp>

                    <Termsh2>{t('Terms.terms_h2_14')}</Termsh2>

                    <Termsp>{t('Terms.terms_p_17')}</Termsp>
                </WhiteCard>
            </Content>
        </div>
    );
};

export default TermsConditions;
