import BlueCard from 'Components/blue-card';
import Button from 'Components/button';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { LabelColor } from 'Components/validated-form-item/validated-form-items';
import { useFormValidation, useService, useStores } from 'Hooks';
import { ResetPasswordSchema } from 'Schemas/LoginRequestSchema';
import { Form, Input } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
    HOME_PAGE_URL,
    RESET_PASSWORD_EMAIL_PARAM,
    RESET_PASSWORD_TOKEN_PARAM,
} from 'Models/Constants';
import { CheckmarkIcon } from 'Components/icons';
import { theme } from 'Style/theme';
import './index.less';
import { useFetch } from 'Hooks/use-fetch';
import { CandidateService } from 'Services/CandidateService';
import { UpdateCandidatePasswordAnonymousRequestDto } from 'Api/Features/Candidates/Dtos/UpdateCandidatePasswordAnonymousRequestDto';
import { AuthenticationService } from 'Services/AuthenticationService';

const ResetPassword = () => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { toastStore } = useStores();
    const [errors, validateForm] = useFormValidation(ResetPasswordSchema, form);
    const [success, setSuccess] = useState(false);
    const history = useHistory();
    const searchParams = useMemo(() => new URLSearchParams(location.search), []);
    const { apiRequest } = useFetch();
    const candidatService = useService(CandidateService);
    const authenticationService = useService(AuthenticationService);

    const [newPasswordRef, setNewPasswordRef] = useState();

    useEffect(() => {
        if (
            !searchParams.get(RESET_PASSWORD_EMAIL_PARAM) ||
            !searchParams.get(RESET_PASSWORD_TOKEN_PARAM)
        )
            toastStore.genericError();
    }, [searchParams, toastStore]);

    const submit = async () => {
        const formValues = form.getFieldsValue();

        const validationObject = {
            email: searchParams.get(RESET_PASSWORD_EMAIL_PARAM),
            token: searchParams.get(RESET_PASSWORD_TOKEN_PARAM),
            newPassword: formValues.newPassword,
            newPasswordConfirm: formValues.newPasswordConfirm,
        };

        if (!(await validateForm(validationObject))) return;

        const request: UpdateCandidatePasswordAnonymousRequestDto = {
            newPassword: formValues.newPassword,
            email: validationObject.email,
            token: validationObject.token,
        };

        try {
            await apiRequest({
                requestFunction: (request) =>
                    candidatService.updateCandidatePasswordAnonymous(request),
                requestParameters: request,
                useGlobalLoading: true,
                throwOnError: true,
            });
            setNewPasswordRef(formValues.newPassword);
            setSuccess(true);
        } catch (e) {
            toastStore.genericError();
        }
    };

    const login = async () => {
        const userId = await apiRequest({
            requestFunction: (request) =>
                authenticationService.initAccessToken(request.userName, request.password),
            requestParameters: {
                userName: searchParams.get(RESET_PASSWORD_EMAIL_PARAM),
                password: newPasswordRef,
            },
        });

        if (userId !== undefined) history.push(HOME_PAGE_URL);
    };

    return (
        <div className="ResetPassword">
            {!success ? (
                <BlueCard padding={40} paddingLeft={125} paddingRight={125}>
                    <div className="text-title-large-bold text-white mb-40 title">
                        {t('Login.defninir_nouveau_mot_de_passe')}
                    </div>

                    <Form form={form} className="form" layout="vertical">
                        <ValidatedFormItem
                            label={t('User.reset_password_new')}
                            name="newPassword"
                            labelColor={LabelColor.white}
                            errors={errors}
                        >
                            <Input className="blue" />
                        </ValidatedFormItem>

                        <ValidatedFormItem
                            label={t('User.reset_password_new_confirm')}
                            name="newPasswordConfirm"
                            labelColor={LabelColor.white}
                            errors={errors}
                        >
                            <Input className="blue" />
                        </ValidatedFormItem>
                    </Form>

                    <Button
                        text={t('mettre_a_jour')}
                        type="red"
                        width="hugged"
                        onClick={() => submit()}
                        uppercase
                    />
                </BlueCard>
            ) : (
                <BlueCard padding={40} paddingLeft={125} paddingRight={125}>
                    <CheckmarkIcon width={105} height={105} fill={theme.white} />
                    <div className="text-container">
                        <div className="text-title-large-bold text-white mb-40">
                            {t('Login.mdp_reinit_succes')}
                        </div>

                        <div className="text-body-large text-white">
                            {t('Login.mdp_reinit_succes_p')}
                        </div>
                    </div>

                    <Button
                        text={t('User.me_connecter')}
                        type="red"
                        width="hugged"
                        onClick={() => login()}
                        uppercase
                    />
                </BlueCard>
            )}
        </div>
    );
};

export default ResetPassword;
