import React, {
    FunctionComponent,
    ReactNode,
    useEffect,
    useState,
    useCallback,
    useRef,
} from 'react';
import './single-select-common.less';
import Select, { components, InputActionMeta } from 'react-select';
import { theme } from 'Style/theme';
import debounce from 'lodash.debounce';
import {
    SingleSelectCommonStyles,
    SingleSelectCustomOption,
    SingleSelectCommonTheme,
    Option,
    SingleValue,
} from './single-select-common';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

export interface SelectFetchFunctionPromise {
    maxResultHit: boolean;
}

export interface AsyncSingleSelectProps {
    fetchFunction: (
        page: number,
        searchTerm: string,
        extraParams?: any
    ) => Promise<SelectFetchFunctionPromise>;
    fetchFunctionExtraParams?: {};
    resetSearchResults: () => void;
    className?: string;
    options?: SingleSelectCustomOption[];
    onChange: (value?: SingleSelectCustomOption) => void | undefined;
    placeholder?: string | null;
    // defaultImg?: ReactNode;
    closeMenuOnSelect?: boolean;
    hideSelectedOptions?: boolean;
    selected?: string | null;
    disabled?: boolean;
    isClearable?: boolean;
    borderless?: boolean;
    round?: boolean;
    dropdownIndicatorIcon?: ReactNode;
    isSearchable?: boolean;
    blueStyle?: boolean; //SPECIFIC TO CCAQ METIER
    /**document.body  permet au menu ouvert de sortir de l'espace modal*/
    menuPortalTarget?: HTMLElement;
}

const AsyncSingleSelect: FunctionComponent<AsyncSingleSelectProps> = ({
    fetchFunction,
    fetchFunctionExtraParams,
    resetSearchResults,
    className,
    options,
    onChange,
    placeholder,
    // defaultImg,
    isClearable = false,
    closeMenuOnSelect = true,
    hideSelectedOptions = false,
    selected,
    disabled,
    borderless,
    round,
    dropdownIndicatorIcon,
    isSearchable = true,
    blueStyle,
    menuPortalTarget,
}) => {
    const { t } = useTranslation();
    const [value, setValue] = useState<SingleSelectCustomOption | null>();

    useEffect(() => {
        if (options === undefined) return;

        if (selected) {
            setValue(options.find((x) => x.value === selected) || null);
        } else {
            setValue(null);
        }
    }, [selected, options]);

    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [maxResultHit, setMaxResultHit] = useState(false);
    const [selectLoading, setSelectLoading] = useState(false);
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const handleResetSearch = useCallback((): void => {
        setCurrentPage(0);
        setSearchTerm('');
        resetSearchResults();
    }, [setCurrentPage, setSearchTerm, resetSearchResults]);

    const debounceSearch = useRef(
        debounce((page: number, searchTerm: string, extraParams?: any) => {
            setSelectLoading(true);
            fetchFunction(page, searchTerm, extraParams).then((result) => {
                setSelectLoading(false);
                setMaxResultHit(result.maxResultHit);
            });
        }, 300)
    );

    const handleKeywordChange = useCallback(
        (value: string): void => {
            handleResetSearch();
            setSearchTerm(value.trimStart().trimEnd());
        },
        [handleResetSearch]
    );

    const HandleMenuScrollToBottom = async (): Promise<void> => {
        if (!maxResultHit) setCurrentPage((prev) => prev + 1);
    };

    useEffect(() => {
        if (menuIsOpen) {
            debounceSearch.current(currentPage, searchTerm, fetchFunctionExtraParams);
        }
    }, [currentPage, searchTerm, menuIsOpen, fetchFunctionExtraParams]);

    const handleOnChange = (data: any): void => {
        if (onChange) {
            onChange(data ?? undefined);
        }
    };

    // -- 02/11/2022 as of now, never have an option tu put image --
    // const optionImage = (data: SingleSelectCustomOption, selectProps: any) => {
    //     return (
    //         <>
    //             {data.imageUrl ? (
    //                 <img
    //                     style={{ flex: '0 0 auto' }}
    //                     className="dropdown-option-img"
    //                     width="24px"
    //                     height="24px"
    //                     src={data.imageUrl}
    //                     alt=""
    //                 />
    //             ) : (
    //                 <span className="dropdown-option-img">{selectProps.defaultImg}</span>
    //             )}
    //         </>
    //     );
    // };

    const Input = useCallback(({ ...props }: any): any => {
        return <components.Input {...props} autoComplete="new-password" />;
    }, []);

    const DropdownIndicator = useCallback(
        (props): any => (
            <components.DropdownIndicator {...props}>
                {selectLoading ? (
                    <Spin
                        indicator={
                            <LoadingOutlined
                                style={{ fontSize: 20, color: theme['secondary-color'] }}
                                translate={'no'}
                                spin
                            />
                        }
                        size="large"
                    />
                ) : (
                    dropdownIndicatorIcon
                )}
            </components.DropdownIndicator>
        ),
        [dropdownIndicatorIcon, selectLoading]
    );

    return (
        <Select
            components={{
                Option,
                SingleValue: (props: any) => <SingleValue {...props} isDeepBlue={blueStyle} />,
                DropdownIndicator,
                Input,
            }}
            theme={(defaultTheme) => ({
                ...defaultTheme,
                colors: {
                    ...defaultTheme.colors,
                    ...SingleSelectCommonTheme,
                },
            })}
            className={`SingleSelect AsyncSingleSelect ${className || ''} ${
                disabled ? 'disabled' : ''
            } ${borderless ? 'borderless' : ''}  ${round ? 'round' : ''} isSearchable-${
                isSearchable ? 'true' : 'false'
            } ${blueStyle && 'blue-style'}`}
            value={value}
            styles={SingleSelectCommonStyles}
            options={options}
            onChange={handleOnChange}
            onInputChange={(newValue: string, actionMeta: InputActionMeta) => {
                if (actionMeta.action === 'input-change') handleKeywordChange(newValue);
                if (actionMeta.action === 'menu-close' && searchTerm !== '') handleResetSearch();
            }}
            onMenuScrollToBottom={HandleMenuScrollToBottom}
            isClearable={isClearable}
            closeMenuOnSelect={closeMenuOnSelect}
            hideSelectedOptions={hideSelectedOptions}
            placeholder={placeholder ?? t('SelectCustom.default_select_placeholder')}
            isDisabled={disabled}
            noOptionsMessage={(): string =>
                selectLoading
                    ? t('SelectCustom.select_loading')
                    : t('SelectCustom.select_no_options')
            }
            onMenuOpen={() => setMenuIsOpen(true)}
            onMenuClose={() => setMenuIsOpen(false)}
            //dont let the dropdown internally filter results. We already filter with api
            filterOption={() => true}
            isSearchable={isSearchable}
            menuPlacement="auto"
            menuPortalTarget={menuPortalTarget}
        />
    );
};

export default AsyncSingleSelect;
