import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../Hooks';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

import './global-loading-container.less';
import { theme } from 'Style/theme';

const GlobalLoadingContainer: FunctionComponent = observer(() => {
    const { globalLoadingStore } = useStores();
    return (
        <div className={`GlobalLoadingContainer ${globalLoadingStore.isLoading ? 'loading' : ''}`}>
            {globalLoadingStore.isLoading && (
                <Spin
                    indicator={
                        <LoadingOutlined
                            style={{ fontSize: 50, color: theme['secondary-color'] }}
                            spin
                            translate={{}}
                        />
                    }
                    size="large"
                />
            )}
        </div>
    );
});

export default GlobalLoadingContainer;
