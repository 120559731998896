import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const ProfileIcon: FunctionComponent<SvgProps> = ({ fill = 'currentColor', onClick, ...props }) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    d="M24.9998 4.16663C13.4998 4.16663 4.1665 13.5 4.1665 25C4.1665 36.5 13.4998 45.8333 24.9998 45.8333C36.4998 45.8333 45.8332 36.5 45.8332 25C45.8332 13.5 36.4998 4.16663 24.9998 4.16663ZM24.9998 12.5C29.0207 12.5 32.2915 15.7708 32.2915 19.7916C32.2915 23.8125 29.0207 27.0833 24.9998 27.0833C20.979 27.0833 17.7082 23.8125 17.7082 19.7916C17.7082 15.7708 20.979 12.5 24.9998 12.5ZM24.9998 41.6666C20.7707 41.6666 15.7707 39.9583 12.2082 35.6666C15.729 32.9166 20.1665 31.25 24.9998 31.25C29.8332 31.25 34.2707 32.9166 37.7915 35.6666C34.229 39.9583 29.229 41.6666 24.9998 41.6666Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default ProfileIcon;
