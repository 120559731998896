import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../Hooks';
import ConfirmationModal from './confirmation-modal';

const ConfirmationModalContainer: FunctionComponent = observer(() => {
    const {
        confirmationModalStore: { currentModal },
    } = useStores();
    return currentModal ? <ConfirmationModal key={currentModal.id} {...currentModal} /> : null;
});

export default ConfirmationModalContainer;
