import { useEffect, useState } from 'react';
import { METIER_URL_REGEX, CONCESSION_URL_REGEX, TEMOIGNAGE_URL_REGEX } from 'Models/Constants';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface HeadDocumentTags {
    title: string;
    description: string;
}

const useHeadDocumentTags = (): HeadDocumentTags => {
    const { pathname } = useLocation();
    const { t } = useTranslation();

    const [tagValues, setTagValues] = useState<HeadDocumentTags>({
        title: 'Ma carrière en concessionnaire - CCAQ',
        description: 'Ma carrière en concessionnaire - CCAQ',
    });

    // Définir les valeurs de balises de document en fonction des textes qui seront fournis
    useEffect(() => {
        if (METIER_URL_REGEX.test(pathname)) {
            setTagValues({
                title: t('MetaTag.MetaTag_metiers_title'),
                description: t('MetaTag.MetaTag_metiers_description'),
            });
        }

        if (CONCESSION_URL_REGEX.test(pathname)) {
            setTagValues({
                title: t('MetaTag.MetaTag_concessions_title'),
                description: t('MetaTag.MetaTag_concessions_description'),
            });
        }

        if (TEMOIGNAGE_URL_REGEX.test(pathname)) {
            setTagValues({
                title: t('MetaTag.MetaTag_temoignages_title'),
                description: t('MetaTag.MetaTag_temoignages_description'),
            });
        }
    }, [pathname, t]);

    return tagValues;
};

export default useHeadDocumentTags;
