import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const FacebookIcon: FunctionComponent<SvgProps> = ({ fill, ...props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="49"
            viewBox="0 0 48 49"
            fill="none"
            {...props}
        >
            <path
                d="M25.7592 33.4781V25.3008H28.5179L28.928 22.0991H25.7592V20.0598C25.7592 19.1359 26.0166 18.5033 27.3426 18.5033H29.0227V15.6489C28.2052 15.5612 27.3835 15.5189 26.5614 15.5221C24.1229 15.5221 22.4488 17.0107 22.4488 19.7435V22.0931H19.708V25.2948H22.4547V33.4781H25.7592Z"
                fill={fill || 'white'}
            />
        </svg>
    );
};

export default FacebookIcon;
