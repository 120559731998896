// GENERATED FILE - DO NOT MODIFY
import { CreateAccessTokenRequestDto } from 'Api/Features/Authentication/Dtos/CreateAccessTokenRequestDto';
import { CreateAccessTokenResponseDto } from 'Api/Features/Authentication/Dtos/CreateAccessTokenResponseDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class AuthenticationProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async createAccessTokenForm(request: CreateAccessTokenRequestDto | null): Promise<CreateAccessTokenResponseDto | null> {
        const uri = this.buildUri(
            "/token",
            null,
            null
        );

        const data: CreateAccessTokenResponseDto | null = await this.httpClient.post<CreateAccessTokenRequestDto | null, CreateAccessTokenResponseDto | null>(uri, request);
        return data;
    }

    public async createAccessToken(request: CreateAccessTokenRequestDto | null): Promise<CreateAccessTokenResponseDto | null> {
        const uri = this.buildUri(
            "/token",
            null,
            null
        );

        const data: CreateAccessTokenResponseDto | null = await this.httpClient.post<CreateAccessTokenRequestDto | null, CreateAccessTokenResponseDto | null>(uri, request);
        return data;
    }

    public async createCandidateAccessTokenForm(request: CreateAccessTokenRequestDto | null): Promise<CreateAccessTokenResponseDto | null> {
        const uri = this.buildUri(
            "/candidate-token",
            null,
            null
        );

        const data: CreateAccessTokenResponseDto | null = await this.httpClient.post<CreateAccessTokenRequestDto | null, CreateAccessTokenResponseDto | null>(uri, request);
        return data;
    }

    public async createCandidateAccessToken(request: CreateAccessTokenRequestDto | null): Promise<CreateAccessTokenResponseDto | null> {
        const uri = this.buildUri(
            "/candidate-token",
            null,
            null
        );

        const data: CreateAccessTokenResponseDto | null = await this.httpClient.post<CreateAccessTokenRequestDto | null, CreateAccessTokenResponseDto | null>(uri, request);
        return data;
    }
}