import { CandidateProxy } from 'Api/Features/Candidates/CandidateProxy';
import { ApiService } from './ApiService';
import { inject } from 'aurelia-dependency-injection';
import { CandidateDetailsDto } from 'Api/Features/Candidates/Dtos/CandidateDetailsDto';
import { GetCandidatesRequestDto } from 'Api/Features/Candidates/Dtos/GetCandidatesRequestDto';
import { GetCandidatesResponseDto } from 'Api/Features/Candidates/Dtos/GetCandidatesResponseDto';
import { VerifyCandidateAccountRequestDto } from 'Api/Features/Candidates/Dtos/VerifyCandidateAccountRequestDto';
import { VerifyCandidateAccountResponseDto } from 'Api/Features/Candidates/Dtos/VerifyCandidateAccountResponseDto';
import { CreateCandidateRequestDto } from 'Api/Features/Candidates/Dtos/CreateCandidateRequestDto';
import { CreateCandidateResponseDto } from 'Api/Features/Candidates/Dtos/CreateCandidateResponseDto';
import { UpdateCandidateRequestDto } from 'Api/Features/Candidates/Dtos/UpdateCandidateRequestDto';
import { CandidateQuizDto } from 'Api/Features/Candidates/Dtos/CandidateQuizDto';
import { UpdateCandidateQuizRequestDto } from 'Api/Features/Candidates/Dtos/UpdateCandidateQuizRequestDto';
import { CandidateDto } from 'Api/Features/Candidates/Dtos/CandidateDto';
import { CandidateForgotPasswordRequestDto } from 'Api/Features/Candidates/Dtos/CandidateForgotPasswordRequestDto';
import { UpdateCandidatePasswordAnonymousRequestDto } from 'Api/Features/Candidates/Dtos/UpdateCandidatePasswordAnonymousRequestDto';
import { CandidateRecruitmentFeedbackDto } from 'Api/Features/Candidates/Dtos/CandidateRecruitmentFeedbackDto';
import { UpdateCandidateRecruitmentFeedbackRequestDto } from 'Api/Features/Candidates/Dtos/UpdateCandidateRecruitmentFeedbackRequestDto';

@inject(CandidateProxy)
export class CandidateService extends ApiService {
    constructor(private readonly candidateProxy: CandidateProxy) {
        super();
    }

    public async getCandidate(candidateId: string): Promise<CandidateDetailsDto | null> {
        const data: CandidateDetailsDto | null = await this.candidateProxy.getCandidate(
            candidateId
        );
        return data;
    }

    public async getCandidates(
        request: GetCandidatesRequestDto | null
    ): Promise<[CandidateDto[], number]> {
        const data: GetCandidatesResponseDto | null = await this.candidateProxy.getCandidates(
            request
        );
        return [
            data?.items?.filter((x) => x !== null).map((x) => x!) ?? [],
            data?.totalItemCount ?? 0,
        ];
    }

    public async verifyCandidateAccount(
        request: VerifyCandidateAccountRequestDto | null
    ): Promise<VerifyCandidateAccountResponseDto | null> {
        const data: VerifyCandidateAccountResponseDto | null =
            await this.candidateProxy.verifyCandidateAccount(request);
        return data;
    }

    public async createCandidate(
        request: CreateCandidateRequestDto | null
    ): Promise<CreateCandidateResponseDto | null> {
        const data: CreateCandidateResponseDto | null = await this.candidateProxy.createCandidate(
            request
        );
        return data;
    }

    public async updateCandidate(
        candidateId: string,
        request: UpdateCandidateRequestDto | null
    ): Promise<void> {
        await this.candidateProxy.updateCandidate(candidateId, request);
    }

    public async getCandidateQuiz(candidateId: string): Promise<CandidateQuizDto | null> {
        const data: CandidateQuizDto | null = await this.candidateProxy.getCandidateQuiz(
            candidateId
        );
        return data;
    }

    public async updateCandidateQuiz(
        candidateId: string,
        request: UpdateCandidateQuizRequestDto | null
    ): Promise<void> {
        await this.candidateProxy.updateCandidateQuiz(candidateId, request);
    }

    public async candidateForgotPassword(
        request: CandidateForgotPasswordRequestDto | null
    ): Promise<void> {
        await this.candidateProxy.candidateForgotPassword(request);
    }

    public async updateCandidatePasswordAnonymous(
        request: UpdateCandidatePasswordAnonymousRequestDto | null
    ): Promise<void> {
        await this.candidateProxy.updateCandidatePasswordAnonymous(request);
    }

    public async getCandidateRecruitmentFeedback(
        candidateId: string
    ): Promise<CandidateRecruitmentFeedbackDto | null> {
        const data: CandidateRecruitmentFeedbackDto | null =
            await this.candidateProxy.getCandidateRecruitmentFeedback(candidateId);
        return data;
    }

    public async updateCandidateRecruitmentFeedback(
        candidateId: string,
        request: UpdateCandidateRecruitmentFeedbackRequestDto | null
    ): Promise<void> {
        await this.candidateProxy.updateCandidateRecruitmentFeedback(candidateId, request);
    }
}
