import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const PlayVideoAltIcon: FunctionComponent<SvgProps> = ({ fill, ...props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            {...props}
        >
            <path
                d="M9 1.63721C4.86 1.63721 1.5 4.99721 1.5 9.13721C1.5 13.2772 4.86 16.6372 9 16.6372C13.14 16.6372 16.5 13.2772 16.5 9.13721C16.5 4.99721 13.14 1.63721 9 1.63721ZM7.5 12.5122V5.76221L12 9.13721L7.5 12.5122Z"
                fill={fill || '#2139B0'}
            />
        </svg>
    );
};

export default PlayVideoAltIcon;
