import { action, computed, observable } from 'mobx';
import moment from 'moment';

class DeploymentCacheStore {
    private updateFrequency = 5;
    @observable lastUpdate = { time: moment() };
    @observable checkForUpdate = { time: moment() };

    @computed
    get bustCache(): boolean {
        return this.lastUpdate.time
            .clone()
            .add(this.updateFrequency, 'minute')
            .isBefore(this.checkForUpdate.time);
    }

    @action
    async updateLast() {
        this.lastUpdate = { time: moment() };
    }

    @action
    async checkForVersionUpdate() {
        this.checkForUpdate = { time: moment() };
    }
}

export default DeploymentCacheStore;
