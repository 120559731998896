import React, { Children, cloneElement, FunctionComponent, useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { RouteProps } from 'react-router';
import { observer } from 'mobx-react';
import { useStores } from 'Hooks';
import { LOGIN_URL } from 'Models/Constants';
import BasicLayout from 'Components/basic-layout';
import NotFound from 'Routes/not-found';

interface ValidatedRouteProps extends RouteProps {
    isProtected?: boolean;
}

const ValidatedRoute: FunctionComponent<ValidatedRouteProps> = observer(
    ({ children, isProtected = false, ...routeProps }) => {
        const { authenticationStore, authorizationStore } = useStores();
        const history = useHistory();

        const updateChildrenWithProps = Children.map(children, (child: any) => {
            return cloneElement(child, {
                routeProps,
            });
        });

        useEffect(() => {
            if (isProtected && !authenticationStore.loggedIn) {
                history.push(LOGIN_URL);
            }
        }, [authenticationStore.loggedIn, history, isProtected]);

        return (
            <Route {...routeProps}>
                {authorizationStore.currentRequestNotFound ? (
                    <BasicLayout>
                        <NotFound />
                    </BasicLayout>
                ) : (
                    updateChildrenWithProps
                )}
            </Route>
        );
    }
);

export default ValidatedRoute;
