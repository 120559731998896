import { MultiSelectCustomOption } from 'Components/select-custom/multi-select/multi-select-common';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import { observable, action } from 'mobx';

class FilterStore {
    @observable searchTerm: string | undefined = undefined;
    @observable makes: MultiSelectCustomOption[] | undefined = undefined;
    @observable jobApplicationStatuses: MultiSelectCustomOption[] | undefined = undefined;
    @observable distance: SingleSelectCustomOption | undefined = undefined;

    @action
    updateSearchTerm(term?: string): void {
        this.searchTerm = term;
    }

    @action
    updateMakes(makes?: MultiSelectCustomOption[]): void {
        this.makes = makes;
    }

    @action
    updateJobApplicationStatuses(statuses?: MultiSelectCustomOption[]): void {
        this.jobApplicationStatuses = statuses;
    }

    @action
    updateDistance(option?: SingleSelectCustomOption): void {
        this.distance = option;
    }

    @action
    clearAll(): void {
        this.searchTerm = undefined;
        this.makes = undefined;
        this.distance = undefined;
        this.jobApplicationStatuses = undefined;
    }
}

export default FilterStore;
