import { HttpClient } from 'HttpClient/HttpClient';
import { InternalAxiosRequestConfig } from 'axios';
import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';
import AuthenticationStore from 'Stores/AuthenticationStore';
import { SupportedLanguage } from 'Stores/LanguageStore';
import { AuthenticationProxy } from 'Api/Features/Authentication/AuthenticationProxy';
import { CreateAccessTokenRequestDto } from 'Api/Features/Authentication/Dtos/CreateAccessTokenRequestDto';
import { AccessTokenGrantType } from 'Api/Features/Authentication/Dtos/AccessTokenGrantType';
import { CreateAccessTokenResponseDto } from 'Api/Features/Authentication/Dtos/CreateAccessTokenResponseDto';
import { ANONYMOUS_CALLS_URL_REGEX, LOGIN_URL } from 'Models/Constants';
import UserStore from 'Stores/UserStore';
import DeploymentCacheStore from 'Stores/DeploymentCacheStore';

@inject(HttpClient, AuthenticationStore, AuthenticationProxy, UserStore, DeploymentCacheStore)
export class AuthenticationService extends ApiService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly authenticationStore: AuthenticationStore,
        private readonly authenticationProxy: AuthenticationProxy,
        private readonly userStore: UserStore,
        private readonly deploymentCacheStore: DeploymentCacheStore
    ) {
        super();
    }

    public refreshTokenPromise: any;
    public runSignInPromise: any;

    public async initAccessToken(userName: string, password: string): Promise<string | undefined> {
        const request: CreateAccessTokenRequestDto = {
            grant_type: AccessTokenGrantType.password,
            username: userName,
            password: password,
            refresh_token: null,
        };
        const response: CreateAccessTokenResponseDto | null =
            await this.authenticationProxy.createCandidateAccessToken(request);

        if (response !== null) {
            this.authenticationStore.setSession(response);
            this.userStore.setUserInfo(response.user_id!);
            return response.user_id!;
        }

        return undefined;
    }

    public installInterceptors(lang: SupportedLanguage): void {
        this.httpClient.addRequestInterceptor((requestConfig: InternalAxiosRequestConfig) =>
            this.onBeforeRequest(requestConfig, lang)
        );
    }

    public async refreshAccessToken(refreshToken: string): Promise<void> {
        const request: CreateAccessTokenRequestDto = {
            grant_type: AccessTokenGrantType.refresh_token,
            username: null,
            password: null,
            refresh_token: refreshToken,
        };
        try {
            const response: CreateAccessTokenResponseDto | null =
                await this.authenticationProxy.createCandidateAccessToken(request);
            if (response != null) {
                this.authenticationStore.setSession(response);
            }
        } catch (e) {
            window.location = LOGIN_URL as any;
        }
    }

    public async refreshAccessTokenIfNeeded(): Promise<void> {
        //Retrieve refresh token from store.
        const refreshToken = this.authenticationStore.refreshToken;
        const tokenExpiration = this.authenticationStore.expirationTimeStamp;

        // No token currently set.
        if (!refreshToken || !tokenExpiration) {
            return;
        }

        if (Math.round(Date.now() / 1000) > tokenExpiration) {
            if (!this.refreshTokenPromise) {
                this.refreshTokenPromise = this.refreshAccessToken(refreshToken);
            }
            await this.refreshTokenPromise;
            this.refreshTokenPromise = null;
        }
    }

    public async onBeforeRequest(
        requestConfig: InternalAxiosRequestConfig,
        lang: SupportedLanguage
    ): Promise<InternalAxiosRequestConfig> {
        this.deploymentCacheStore.checkForVersionUpdate();

        if (
            requestConfig.url &&
            !ANONYMOUS_CALLS_URL_REGEX.some((x) => x.test(requestConfig.url!))
        ) {
            await this.refreshAccessTokenIfNeeded();

            //Retrieve access token from store.
            const accessToken = this.authenticationStore.accessToken;

            //Add Authorization header in request
            requestConfig.headers.Authorization = `bearer ${accessToken}`;

            //Add language in header to localise api error responses
            requestConfig.headers['Accept-Language'] = lang;
        }

        return requestConfig;
    }
}
