import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const ArrowLeftIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
                <path
                    d="M9 19L10.41 17.59L5.83 13H22V11H5.83L10.42 6.41L9 5L2 12L9 19Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default ArrowLeftIcon;
