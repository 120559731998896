import { FC } from 'react';
import './index.less';

interface YoutubeVideoPlayerProps {
    url: string;
    width?: string;
    aspectRatio?: string;
}

const YoutubeVideoPlayer: FC<YoutubeVideoPlayerProps> = ({ url, width, aspectRatio }) => {
    return (
        <div
            className="YoutubeVideo"
            style={{
                width: width || '100%',
                aspectRatio: aspectRatio,
            }}
        >
            <iframe
                src={url}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
            ></iframe>
        </div>
    );
};

export default YoutubeVideoPlayer;
