import ReactGA from 'react-ga4';
import { useCallback, useEffect } from 'react';
import { GaOptions, InitOptions } from 'react-ga4/types/ga4';
import * as VanillaCookieconsent from 'vanilla-cookieconsent';

export type InitializeFunction = (
    GA_MEASUREMENT_ID: InitializeGaMeasurementId,
    options?: InitializeOptions
) => void;

type InitializeGaMeasurementId = string | InitOptions[];
type InitializeOptions = {
    nonce?: string | undefined;
    testMode?: boolean | undefined;
    gtagUrl?: string | undefined;
    gaOptions?: GaOptions | any;
    gtagOptions?: any;
};

const useGa4 = (
    gaMeasurementId: InitializeGaMeasurementId | string,
    initializeOptions?: InitializeOptions
) => {
    const acceptedCategoryAnalytics = VanillaCookieconsent.acceptedCategory('analytics');

    const initialize: InitializeFunction = useCallback(() => {
        const initOptions: InitializeGaMeasurementId =
            typeof gaMeasurementId === 'string'
                ? gaMeasurementId
                : gaMeasurementId.map((id) => ({
                      trackingId: id.trackingId,
                      gaOptions: initializeOptions?.gaOptions,
                      gtagOptions: initializeOptions?.gtagOptions,
                  }));

        ReactGA.initialize(initOptions, initializeOptions);
    }, [gaMeasurementId, initializeOptions]);

    useEffect(() => {
        if (VanillaCookieconsent.acceptedCategory('analytics')) {
            ReactGA.send({
                hitType: 'pageview',
                page: location.pathname + location.search,
            });
        }
    }, [acceptedCategoryAnalytics]);

    return {
        initReactGa: initialize,
        resetReactGa: ReactGA.reset,
    };
};

export default useGa4;
